import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { authSlice } from '../store/reducers/AuthSlice';
import { AppDispatch } from '../store/store';
import { Auth } from '../types/types';

export const authStudentStaff =
    (authInformation: Auth) => async (dispatch: AppDispatch) => {
        const url = `${process.env.REACT_APP_BASE_URL}api/v1/token/candidate_or_staff/`;
        dispatch(authSlice.actions.loginStart());
        await axios
            .post<{
                refresh: string;
                access: string;
                user_type: string;
                is_verified: string;
                user_type_id: string;
            }>(url, authInformation)
            .then((data) => {
                localStorage.setItem('token', data.data.access);
                localStorage.setItem('refresh', data.data.refresh); // added by backend
                localStorage.setItem('role', data.data.user_type);
                localStorage.setItem('id', data.data.user_type_id);
                localStorage.setItem('isVerified', data.data.is_verified);
                localStorage.setItem('isLogin', 'true'); // added by backend

                dispatch(authSlice.actions.loginSuccess(data.data));
            })
            .catch((e: AxiosError<{ invalid_account_type: string }>) => {
                const status = e.response?.status;
                if (e.response?.data.invalid_account_type) {
                    return toast.error(
                        e.response?.data.invalid_account_type[0],
                    );
                }
                if (status && status < 500 && status >= 400) {
                    return toast.error('Неверный логин/пароль');
                } else {
                    return toast.error('Ошибка сервера. Повторите позже');
                }
            });
    };

export const authCompany =
    (authInformation: Auth) => async (dispatch: AppDispatch) => {
        const url = `${process.env.REACT_APP_BASE_URL}api/v1/token/company/`;
        await axios
            .post<{
                refresh: string;
                access: string;
                user_type: string;
                is_verified: string;
                user_type_id: string;
            }>(url, authInformation)
            .then((data) => {
                localStorage.setItem('token', data.data.access);
                localStorage.setItem('refresh', data.data.refresh); // added by backend
                localStorage.setItem('role', data.data.user_type);
                localStorage.setItem('id', data.data.user_type_id);
                localStorage.setItem('isVerified', data.data.is_verified);
                localStorage.setItem('isLogin', 'true'); // added by backend

                dispatch(authSlice.actions.loginSuccess(data.data));
            })
            .catch((e: AxiosError<{ invalid_account_type: string }>) => {
                const status = e.response?.status;
                if (e.response?.data.invalid_account_type) {
                    return toast.error(
                        e.response?.data.invalid_account_type[0],
                    );
                }
                if (status && status < 500 && status >= 400) {
                    return toast.error('Неверный логин/пароль');
                } else {
                    return toast.error('Ошибка сервера. Повторите позже');
                }
            });
    };
