import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { Candidate } from '../../types/types';

export type StudentListType = {
    page_count: number;
    current_page: number;
    objects: Candidate[];
};
const initialState: StudentState = {
    //фильтры
    grade: '',
    experience: [],
    years: [1990, 2030],
    language: '',
    languageLevels: [],

    //фильтрованные студенты с резюме
    filtredStudents: [],
    filtredCount: 0, // общее количество страниц отфильтрованных студентов
    filtredCurrentPage: 1, // текущая страница для пагинации

    //все подтвержденные студенты
    verifiedStudents: [],
    verifiedCount: 0,
    verifiedCurrentPage: 1,

    students: [],
    isLoading: false,
    error: null,

    unverifiedStudentList: undefined,
    unverifiedStudentListPage: 1,
    unverifiedStudentListIsLoading: false,
    unverifiedStudentListError: null,
};

interface StudentState {
    grade: string;
    experience: string[];
    years: [number, number];
    language: string;
    languageLevels: string[];

    filtredStudents: Candidate[];
    filtredCount: number;
    filtredCurrentPage: number;

    verifiedStudents: Candidate[];
    verifiedCount: number;
    verifiedCurrentPage: number;

    students: Candidate[];
    isLoading: boolean;
    error: any;

    unverifiedStudentList: StudentListType | undefined;
    unverifiedStudentListPage: number;
    unverifiedStudentListIsLoading: boolean;
    unverifiedStudentListError: any;
}

export const confirmStudent = createAsyncThunk(
    'students/confirmStudent',
    async function (id: number, { rejectWithValue }) {
        try {
            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/verified/`,
                    { is_verified: true },
                )
                .then(() => {
                    toast.success('Студент успешно верифицирован');
                })
                .catch(() => toast.error('Студент не верифицирован'));
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const deleteStudent = createAsyncThunk(
    'students/deleteStudent',
    async function (id: number, { rejectWithValue }) {
        try {
            await axios
                .delete(
                    `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`,
                )
                .then(() => {
                    toast.success('Заявка удалена');
                })
                .catch(() => toast.error('Заявка не удалена'));
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

// фетч неподтвержденных админом студентов для страницы верификации
export const getUnverifiedStudentList = createAsyncThunk(
    'students/getUnverifiedList',
    async function (
        { page = 1, size = 10 }: { page: number; size: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/unverified/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

// фетч студентов с заполненным резюме (studentsWithResume)
export const fetchFiltredStudents = createAsyncThunk(
    'students/fetchFiltredStudents',
    async function (
        {
            page = 1,
            grade,
            language,
            language_level,
            required_experience,
            year_from,
            year_to,
        }: {
            page: number;
            grade?: string;
            language?: string;
            language_level?: string | string[];
            required_experience?: string | string[];
            year_from?: string;
            year_to?: string;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/resume/`,
                {
                    params: {
                        page,
                        size: 10,
                        grade: grade || undefined,
                        language: language || undefined,
                        year_from,
                        year_to,
                        language_level,
                        required_experience,
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

// фетч верифицированных студентов
export const fetchVerifiedStudents = createAsyncThunk(
    'students/fetchVerifiedStudents',
    async function ({ page = 1 }: { page: number }, { rejectWithValue }) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/`,
                { params: { only_verified: true, page: page, size: 10 } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        //смена страницы в пагинации
        changeFiltredPage(state, action: PayloadAction<number>) {
            state.filtredCurrentPage = action.payload;
        },
        changeVerifiedPage(state, action: PayloadAction<number>) {
            state.verifiedCurrentPage = action.payload;
        },
        updateUnverifiedStudentListPage(state, action: PayloadAction<number>) {
            state.unverifiedStudentListPage = action.payload;
        },

        resetFilters(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            state.grade = '';
            state.language = '';
            state.languageLevels = [];
            state.experience = [];
            state.years = [1990, new Date().getFullYear() + 6];
        },

        changeGrade(state, action: PayloadAction<string>) {
            state.grade = action.payload;
        },

        changeExperience(state, action: PayloadAction<string>) {
            if (state.experience.includes(action.payload)) {
                state.experience = state.experience.filter(
                    (el) => el !== action.payload,
                );
                return;
            }
            state.experience.push(action.payload);
        },

        changeDateRange(state, action: PayloadAction<[number, number]>) {
            state.years = action.payload;
        },
        changeStartDate(state, action: PayloadAction<number>) {
            state.years[0] = action.payload;
        },
        changeEndDate(state, action: PayloadAction<number>) {
            state.years[1] = action.payload;
        },

        changeLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload;
        },
        changeLanguageLevels(state, action: PayloadAction<string>) {
            const isLevelInArray = state.languageLevels.some(
                (element) => element === action.payload,
            );
            if (isLevelInArray) {
                state.languageLevels = state.languageLevels.filter(
                    (language) => language !== action.payload,
                );
                return;
            }
            state.languageLevels.push(action.payload);
        },
    },
    extraReducers: (builder) => {
        // фетч неподтвержденных админом студентов
        builder.addCase(getUnverifiedStudentList.pending, (state) => {
            state.unverifiedStudentListIsLoading = true;
        });
        builder.addCase(getUnverifiedStudentList.fulfilled, (state, action) => {
            state.unverifiedStudentListIsLoading = false;
            state.unverifiedStudentList = action.payload;
        });
        builder.addCase(getUnverifiedStudentList.rejected, (state, action) => {
            state.unverifiedStudentListIsLoading = false;
            state.unverifiedStudentListError = action.payload;
        });

        // фетч подтвержденных студентов
        builder.addCase(fetchVerifiedStudents.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchVerifiedStudents.fulfilled, (state, action) => {
            state.isLoading = false;
            state.verifiedStudents = action.payload.objects;
            state.verifiedCount = action.payload.page_count;
        });
        builder.addCase(fetchVerifiedStudents.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        // фетч студентов с заполненным резюме
        builder.addCase(fetchFiltredStudents.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchFiltredStudents.fulfilled, (state, action) => {
            state.isLoading = false;
            state.filtredStudents = action.payload.objects;
            state.filtredCount = action.payload.page_count;
        });
        builder.addCase(fetchFiltredStudents.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const {
    changeFiltredPage,
    changeVerifiedPage,
    updateUnverifiedStudentListPage,
    resetFilters,
    changeGrade,
    changeExperience,
    changeDateRange,
    changeLanguage,
    changeLanguageLevels,
    changeStartDate,
    changeEndDate,
} = studentsSlice.actions;

export default studentsSlice.reducer;
