/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Input } from '../../../../UI/Input/Input';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../../routes/RouterConfig';
import { getCompanyRetrieve } from '../../../../store/reducers/CompanySlice';
import styles from './EditProfile.module.scss';
import { validationContactSchema } from './validationSchema';

export const EditContactProfile = () => {
    const id = useAppSelector((state) => state.authReducer.id);
    const company = useAppSelector((state) => state.companyReducer.company);

    const dispatch = useAppDispatch();

    const formik = useFormik({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
            contact_email: company?.contact_email ? company?.contact_email : '',
            contact_phone: company?.contact_phone && company?.contact_phone,
            first_name: company?.first_name,
            second_name: company?.second_name,
            last_name: company?.last_name,
            contact_person_position: company?.contact_person_position,
        },
        onSubmit: async (values) => {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/`;
            try {
                axios
                    .put(url, {
                        ...formik.values,
                    })
                    .then(() => {
                        toast.success('Данные профиля успешно изменены');
                        dispatch(getCompanyRetrieve(Number(id)));
                    })
                    .catch(() => toast.error('Произошла ошибка'));
            } catch (e) {
                toast.error('Произошла ошибка. Попробуйте позже');
            }
        },
        validationSchema: validationContactSchema,
    });

    return (
        <div className={styles.companyEditProfile}>
            <form onSubmit={formik.handleSubmit} className={styles.EditProfile}>
                <EditWrapper
                    saveLink={PATH_DASHBOARD.company.root}
                    rejectLink={PATH_DASHBOARD.company.root}
                    title={'Контактное лицо'}
                    isValid={formik.isValid}
                    dirty={formik.dirty}
                    saveOnclick={formik.handleSubmit}
                >
                    <div className={styles.EditAbout__wrapper}>
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Фамилия'}
                            placeholder={'Фамилия'}
                            name="second_name"
                            value={formik.values.second_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.second_name}
                            isValid={
                                Boolean(formik.values.second_name) &&
                                !formik.errors.second_name
                            }
                        />
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Имя'}
                            placeholder={'Имя'}
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.first_name}
                            isValid={
                                Boolean(formik.values.first_name) &&
                                !formik.errors.first_name
                            }
                        />
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Отчество'}
                            placeholder={'Отчество'}
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.last_name}
                            isValid={
                                Boolean(formik.values.last_name) &&
                                !formik.errors.last_name
                            }
                        />
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Email'}
                            placeholder={'Email'}
                            name="contact_email"
                            value={formik.values.contact_email}
                            errorMessage={formik.errors.contact_email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.contact_email) &&
                                !formik.errors.contact_email
                            }
                        />
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Телефон'}
                            placeholder={'+78008008080'}
                            name={'contact_phone'}
                            value={formik.values.contact_phone}
                            errorMessage={formik.errors.contact_phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.contact_phone) &&
                                !formik.errors.contact_phone
                            }
                        />
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Должность'}
                            placeholder={'Должность'}
                            name="contact_person_position"
                            value={formik.values.contact_person_position}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.contact_person_position}
                            isValid={
                                Boolean(
                                    formik.values.contact_person_position,
                                ) && !formik.errors.contact_person_position
                            }
                        />
                    </div>
                </EditWrapper>
            </form>
        </div>
    );
};
