import axios from 'axios';
import cn from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CrossButton, EditAvatarIcon } from '../../assets/icons';
import { useAuthCheck } from '../../hooks/useAuthCheck';
import styles from './AvatarUploader.module.scss';

import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { Role } from '../../types/types';

const DEFAULT_PHOTO_URL =
    'https://png.pngtree.com/png-vector/20220522/ourmid/pngtree-personal-account-avatar-body-boy-png-image_4714973.png';

export const AvatarUploader = () => {
    useAuthCheck();
    const id = localStorage.getItem('id');
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    //функции для работы с селектом
    const imageRef = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        imageRef?.current?.click();
    };

    const avatarUrl = useMemo(() => {
        if (role === Role.candidate) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/photo/`;
        }
        if (role === Role.staff) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/staff/${id}/photo/`;
        }
        return `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/photo/`;
    }, [id, role]);

    //удаление аватарки с сервера и клиента
    const handleClear = () => {
        setSelectedFile(null);
        const inputField = imageRef.current;
        if (inputField) {
            inputField.value = '';
        }
        axios
            .delete(avatarUrl, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then(() => {
                toast.success(
                    'Фотография успешно удалена, обновление страницы...',
                );
                setTimeout(() => {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }, 3000);
            })
            .catch(() => toast.error('При удалении фото произошла ошибка'));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!event.target.files?.length) {
            return;
        }
        const file = event?.target?.files[0];
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
        const formData = new FormData();
        if (file) {
            formData.append('photo', file);
        }
        axios
            .post(avatarUrl, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                toast.success(
                    'Фотография успешно изменена, обновление страницы...',
                );
                setTimeout(() => {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                }, 3000);
            })
            .catch(async () => {
                toast.error('При изменении фотографии произошла ошибка');
            });
    };
    return (
        <div className={styles.AvatarUploader}>
            <SetAvatar handleClick={handleClick} />
            <ClearAvatar handleClear={handleClear} />
            <input
                className={styles.edit_hidden}
                type="file"
                onChange={handleChange}
                ref={imageRef}
                accept="image/png, image/jpeg"
            ></input>
            <Image selectedFile={selectedFile} />
        </div>
    );
};

//компонента отрисовки фото
const Image = ({ selectedFile }: ImageProps) => {
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        if (selectedFile) {
            setImage(URL.createObjectURL(selectedFile));
        }
    }, [selectedFile]);

    const candidatePhoto = useAppSelector(
        (state) => state.candidateReducer.candidate?.photo,
    );
    const companyPhoto = useAppSelector(
        (state) => state.companyReducer.company?.photo,
    );
    const staffPhoto = useAppSelector(
        (state) => state.staffReducer.staff?.photo,
    );

    const role = localStorage.getItem('role');

    const photo = useMemo(() => {
        if (role === Role.candidate) return candidatePhoto;
        if (role === Role.organization) return companyPhoto;
        if (role === Role.staff) return staffPhoto;
        return '';
    }, [candidatePhoto, companyPhoto, role, staffPhoto]);

    return (
        <div className={styles.AvatarUploader__circle}>
            <img
                src={image || photo || DEFAULT_PHOTO_URL}
                alt={'avatar'}
                className={styles.AvatarUploader__photo}
            />
        </div>
    );
};

//компонента установления фото
const SetAvatar = ({ handleClick }: SetAvatarProps) => {
    return (
        <div
            className={cn(styles.edit, styles.edit__set)}
            onClick={handleClick}
        >
            <EditAvatarIcon className={styles.edit__icon} />
        </div>
    );
};

//компонента удаления фото
const ClearAvatar = ({ handleClear }: ClearAvatarProps) => {
    return (
        <div
            className={cn(styles.edit, styles.edit__clear)}
            onClick={() => handleClear()}
        >
            <CrossButton className={styles.edit__icon} />
        </div>
    );
};

interface ClearAvatarProps {
    handleClear: () => void;
}

interface SetAvatarProps {
    handleClick: () => void;
}

interface ImageProps {
    selectedFile: File | null;
}
