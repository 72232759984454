// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_checkbox__SEHMP {\n  margin-right: auto;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 15px;\n}\n.Checkbox_checkbox__text__lZtQv {\n  font: var(--font-s);\n  font-size: 12px;\n}\n.Checkbox_checkbox__text__link__1FMZB {\n  font: var(--font-s);\n  font-size: 12px;\n}\n.Checkbox_checkbox__text__link__1FMZB:hover {\n  opacity: 60%;\n}\n\n.Checkbox_checkbox__SEHMP input[placeholder] {\n  font-size: 15px;\n}", "",{"version":3,"sources":["webpack://./src/UI/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAAI;EACI,mBAAA;EACA,eAAA;AAER;AAAI;EACI,mBAAA;EACA,eAAA;AAER;AADQ;EACI,YAAA;AAGZ;;AAEA;EACC,eAAA;AACD","sourcesContent":[".checkbox{\n    margin-right: auto;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-bottom: 15px;\n    &__text{\n        font: var(--font-s);\n        font-size: 12px;\n    }\n    &__text__link{\n        font: var(--font-s);\n        font-size: 12px;\n        &:hover {\n            opacity: 60%;\n        }\n    }\n}\n\n.checkbox input[placeholder] {\n\tfont-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "Checkbox_checkbox__SEHMP",
	"checkbox__text": "Checkbox_checkbox__text__lZtQv",
	"checkbox__text__link": "Checkbox_checkbox__text__link__1FMZB"
};
export default ___CSS_LOADER_EXPORT___;
