import { PATH_DASHBOARD } from '../routes/RouterConfig';
import { Role } from '../types/types';

//функция навигирует между рутами, положенных разным ролям
export const roleNavigate = () => {
    const role = localStorage.getItem('role');

    if (role === Role.candidate) return PATH_DASHBOARD.student.root;
    if (role === Role.organization) return PATH_DASHBOARD.company.root;
    if (role === Role.staff) return PATH_DASHBOARD.staff.root;

    return '/';
};
