import styles from "./Burger.module.scss";

interface BurgerProps {
	onClick: () => void;
}

export const Burger = ({ onClick }: BurgerProps) => {
	return (
		<div
			className={styles.Burger}
			onClick={() => {
				onClick();
			}}
		>
			<span className={styles.Burger__element}></span>
			<span className={styles.Burger__element}></span>
			<span className={styles.Burger__element}></span>
		</div>
	);
};
