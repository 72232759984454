import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type CompanyListItemType = {
    is_verified: boolean;
    full_title: string;
    short_title: string;
    status: string;
    city: string;
    address: string;
    about: string;
    tg: string;
    contact_email: string;
    contact_phone: string;
    contact_person: string;
    contact_person_position: string;
    site: string;
    favorite_events: number[];
    email: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    photo: string;
    id: number;
    has_subscription: boolean;
    subscription_from: string;
    subscription_to: string;
};

export type LogoCompanyListItemType = {
    id: number;
    title: string;
    image: string;
};

export type CompanyListType = {
    page_count: number;
    current_page: number;
    objects: CompanyListItemType[];
};

const initialState: CompanyState = {
    companyList: undefined,
    companyListPage: 1,
    companyListIsLoading: false,

    logoCompanyList: undefined,
    logoCompanyListIsLoading: false,
};

interface CompanyState {
    companyList: CompanyListType | undefined;
    companyListPage: number;
    companyListIsLoading: boolean;

    logoCompanyList: LogoCompanyListItemType[] | undefined;
    logoCompanyListIsLoading: boolean;
}

export const getCompanyList = createAsyncThunk(
    'company/getCompanyList',
    async function name(
        { page, size, search }: { page: number; size: number; search?: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/`,
                { params: { page, size, full_title: search } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getLogoCompanyList = createAsyncThunk(
    'company/getLogoCompanyList',
    async function name(_, { rejectWithValue }) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/logo/`,
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateCompanyListPage(state, action: PayloadAction<number>) {
            state.companyListPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyList.pending, (state) => {
            state.companyListIsLoading = true;
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {
            state.companyListIsLoading = false;
            state.companyList = action.payload;
        });
        builder.addCase(getCompanyList.rejected, (state, action) => {
            state.companyListIsLoading = false;
        });

        builder.addCase(getLogoCompanyList.pending, (state) => {
            state.logoCompanyListIsLoading = true;
        });
        builder.addCase(getLogoCompanyList.fulfilled, (state, action) => {
            state.logoCompanyListIsLoading = false;
            state.logoCompanyList = action.payload;
        });
        builder.addCase(getLogoCompanyList.rejected, (state, action) => {
            state.logoCompanyListIsLoading = false;
        });
    },
});

export const { updateCompanyListPage } = partnerSlice.actions;

export default partnerSlice.reducer;
