import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type NewsItemType = {
    id: number;
    title: string;
    create_date: string;
    publish_date: string;
    text: string;
    images: {
        id: number;
        path: string;
    }[];
};

export type NewsListType = {
    page_count: number;
    current_page: number;
    objects: NewsItemType[];
};

const initialState: NewsState = {
    newsList: undefined,
    newsListPage: 1,
    newsListIsLoading: false,
    newsListError: null,

    news: undefined,
    newsIsLoading: false,
    newsError: null,
};

interface NewsState {
    newsList: NewsListType | undefined;
    newsListPage: number;
    newsListIsLoading: boolean;
    newsListError: any;

    news: NewsItemType | undefined;
    newsIsLoading: boolean;
    newsError: any;
}

export const getNewsList = createAsyncThunk(
    'staff/getNewsList',
    async function name(
        {
            page,
            size,
        }: {
            page: number;
            size: number;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/news/`,
                {
                    params: {
                        page,
                        size,
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getNewsRetrieve = createAsyncThunk(
    'staff/getNewsRetrieve',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/news/${id}/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        updateNewsListPage(state, action: PayloadAction<number>) {
            state.newsListPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNewsList.pending, (state) => {
            state.newsListIsLoading = true;
        });
        builder.addCase(getNewsList.fulfilled, (state, action) => {
            state.newsListIsLoading = false;
            state.newsList = action.payload;
        });
        builder.addCase(getNewsList.rejected, (state, action) => {
            state.newsListIsLoading = false;
        });

        builder.addCase(getNewsRetrieve.pending, (state) => {
            state.newsIsLoading = true;
        });
        builder.addCase(getNewsRetrieve.fulfilled, (state, action) => {
            state.newsIsLoading = false;
            state.news = action.payload;
        });
        builder.addCase(getNewsRetrieve.rejected, (state, action) => {
            state.newsIsLoading = false;
        });
    },
});

export const { updateNewsListPage } = newsSlice.actions;

export default newsSlice.reducer;
