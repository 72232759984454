import axios from 'axios';
import { authSlice } from '../store/reducers/AuthSlice';
import { candidateSlice } from '../store/reducers/CandidateSlice';
import { companySlice } from '../store/reducers/CompanySlice';
import { educationSlice } from '../store/reducers/EducationSlice';
import { experianceSlice } from '../store/reducers/ExperianceSlice';
import { languagesSlice } from '../store/reducers/LanguagesSlice';
import { socialActivitiesSlice } from '../store/reducers/SocialActivitiesSlice';
import { staffSlice } from '../store/reducers/StaffSlice';
import { AppDispatch } from '../store/store';

// следует вызывать при выходе пользователя для очистки связанных редюсеров,
// если вызвать только logOut() то, хоть юзер будет не авторизован,
// но в сторайдже будут образования, языки и пр. (если он студент)
export const LogoutUser = (dispatch: AppDispatch) => {
    delete axios.defaults.headers.common.Authorization;
    dispatch(authSlice.actions.logOut());
    dispatch(candidateSlice.actions.clearCandidate());
    dispatch(educationSlice.actions.clearEducations());
    dispatch(experianceSlice.actions.clearExperiences());
    dispatch(languagesSlice.actions.clearLanguages());
    dispatch(socialActivitiesSlice.actions.clearSocialActivities());
    dispatch(companySlice.actions.clearCompany());
    dispatch(staffSlice.actions.clearStaff());
    localStorage.clear();
};
