import { Link, useMatch } from "react-router-dom";
import styles from "./AppLink.module.scss";


interface AppLinkProps {
	to: string;
	children: React.ReactNode;
}

const AppLink = ({ to, children, ...props }: AppLinkProps) => {
	const match = useMatch(to);
	return (
		<Link
			style={{ color: match ? "#11139F" : "#000000" }}
			className={styles.AppLink}
			to={to}
			{...props}
		>
			{children}
		</Link>
	);
};

export default AppLink;
