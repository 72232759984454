/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { Candidate } from '../../types/types';

import { authSlice } from './AuthSlice';
const initialState: CandidateState = {
    candidate: null,
    isLoading: false,
    error: null,
};

interface CandidateState {
    candidate: Candidate | null;
    isLoading: boolean;
    error: any;
}

//асинхронная позгрузка инфы о пользователе по айди
export const fetchStudentInfo = createAsyncThunk(
    'candidate/fetchStudentInfo',
    async function (id: number, { rejectWithValue, dispatch }) {
        const token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;

        const data = await axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((data) => data.data)
            .catch(async (error: AxiosError<{ code: string }>) => {
                if (error.response?.data.code === 'token_not_valid') {
                    const refresh = localStorage.getItem('refresh');
                    const urlRefresh = `${process.env.REACT_APP_BASE_URL}api/v1/token/refresh/`;

                    await axios
                        .post<{
                            access: string;
                            refresh: string;
                        }>(urlRefresh, {
                            refresh: refresh,
                        })
                        .then((data) => {
                            if (data.data.access) {
                                dispatch(
                                    authSlice.actions.refreshToken({
                                        token: data.data.access,
                                    }),
                                );
                                localStorage.setItem('token', data.data.access);
                            }
                        });

                    const dataRefresh = await axios
                        .get(url)
                        .then((data) => data.data);

                    return dataRefresh;
                }
            });

        return data;
    },
);

export const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        clearCandidate(state) {
            state.candidate = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudentInfo.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchStudentInfo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.candidate = action.payload;
        });
        builder.addCase(fetchStudentInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default candidateSlice.reducer;
