import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../UI/Button/Button';
import { Input } from '../../../UI/Input/Input';
import { Textarea } from '../../../UI/Textarea/Textarea';
import formPhoto from '../../../assets/images/helpForm.png';
import { Accordeon } from '../../../components/Accordeon/Accordeon';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { PATH_DASHBOARD } from '../../../routes/RouterConfig';
import styles from './HelpPage.module.scss';
import { validationSchema } from './validationSchema';

const QUESTIONS_MOCK = [
    {
        id: 1,
        question: 'Почему не получается зарегистрироваться?',
        answer: (
            <>
                На портале могут быть зарегистрированы только студенты,
                выпускники и партнеры Юридического факультета МГУ имени
                М.В.Ломоносова. После регистрации каждый аккаунт проходит
                процедуру верификации сотрудниками Центра карьеры, авторизация
                становится доступна только после успешного прохождения
                верификации. Обычно процесс верификации аккаунта занимает до 3
                рабочих дней.
            </>
        ),
    },
    {
        id: 2,
        question: 'Почему не получается авторизоваться?',
        answer: (
            <>
                После регистрации каждый аккаунт проходит процедуру верификации
                сотрудниками Центра карьеры Юридического факультета МГУ имени
                М.В.Ломоносова, авторизация становится доступна только после
                успешного прохождения верификации. Обычно процесс верификации
                аккаунта занимает до 3 рабочих дней.
            </>
        ),
    },
    {
        id: 3,
        question: 'Как я могу связаться с соискателем/ работодателем?',
        answer: (
            <>
                Для удобства пользователей коммуникация между студентами/
                выпускниками и партнерами Юридического факультета происходит за
                пределами портала. Для связи с соискателем представитель
                работодателя может использовать контактные данные, указанные в
                резюме. Для связи с работодателем соискатель должен откликнуться
                на вакансию и ожидать обратной связи от представителей
                работодателя.
            </>
        ),
    },
    {
        id: 4,
        question:
            'Кто может посещать мероприятия Центра карьеры Юридического факультета?',
        answer: (
            <>
                Ограничений на посещение большинства мероприятий Центра карьеры
                Юридического факультета МГУ имени М.В.Ломоносова нет. Для
                посещения мероприятия необходимо пройти регистрацию на
                интересующее мероприятие в разделе «Мероприятия». Регистрацию
                возможно пройти без регистрации/ авторизации на портале.
            </>
        ),
    },
];

export const HelpPage = () => {
    return (
        <div className={styles.help}>
            <PagePagination
                linkName={'Поддержка'}
                route={PATH_DASHBOARD.help}
            />
            <PageHeader title={'Часто задаваемые вопросы'} />
            <HelpAccordeons />
            <HelpForm />
        </div>
    );
};

const HelpAccordeons = () => {
    return (
        <div className={styles.help__accordeon}>
            {QUESTIONS_MOCK.length > 0 &&
                QUESTIONS_MOCK.map((question, index) => (
                    <Accordeon
                        id={question.id}
                        question={question.question}
                        answer={question.answer}
                        key={index}
                    />
                ))}
        </div>
    );
};

const HelpForm = () => {
    return (
        <div className={styles.HelpForm}>
            <h3 className={styles.HelpForm__header}>
                Если Вы не нашли ответ на свой вопрос, касающийся работы
                карьерного портала, пожалуйста, напишите нам
            </h3>
            <div className={styles.HelpForm__wrapper}>
                <Form />
                <img
                    alt={''}
                    className={styles.HelpForm__image}
                    src={formPhoto}
                />
            </div>
        </div>
    );
};

const Form = () => {
    const formik = useFormik({
        initialValues: {
            email: '',
            fio: '',
            organization_institution: '',
            text: '',
        },
        onSubmit: async (e) => {
            axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}api/v1/appeal/`,
                    formik.values,
                )
                .then(() => {
                    toast.success('Вопрос успешно отправлен');
                })
                .catch(() =>
                    toast.error('При отправке вопроса произошла ошибка'),
                );
        },
        validationSchema: validationSchema,
    });
    return (
        <form onSubmit={formik.handleSubmit} className={styles.form}>
            <Input
                placeholder="ФИО"
                name="fio"
                isValid={Boolean(formik.values.fio) && !formik.errors.fio}
                errorMessage={formik.errors.fio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <Input
                placeholder="Организация/ВУЗ"
                name="organization_institution"
                isValid={
                    Boolean(formik.values.organization_institution) &&
                    !formik.errors.organization_institution
                }
                errorMessage={formik.errors.organization_institution}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <Textarea
                placeholder="Ваш вопрос"
                areaClassName={styles.form__textarea}
                errorMessage={formik.errors.text}
                isValid={Boolean(formik.values.text) && !formik.errors.text}
                name={'text'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
            />
            <Input
                placeholder="Почта"
                name={'email'}
                errorMessage={formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={Boolean(formik.values.email) && !formik.errors.email}
            />
            <Button
                className={cn(styles.form__button, {
                    [styles.form__button_valid]: formik.isValid && formik.dirty,
                })}
                onClick={formik.handleSubmit}
            >
                Отправить
            </Button>
        </form>
    );
};
