import axios from 'axios';
import { useEffect, useState } from 'react';
import { IEventCard } from '../types/types';

export const useFetchEvents = (page: number, size: number = 10) => {
    const [eventsData, setEventsData] = useState<IEventCard[] | null>(null);
    const [totalCount, setTotalCount] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const eventsUrl = `${process.env.REACT_APP_BASE_URL}api/v1/event/?page=${page}&size=${size}`;

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(eventsUrl)
            .then((response) => {
                setTotalCount(response.data.page_count); // максимальное количество страниц
                setEventsData(response.data.objects); // данные
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return { eventsData, isLoading, totalCount };
};
