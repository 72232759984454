// функция берет текущую дату и возвращает ее в формате, удобном для прокидывания в интупы с типом date
export const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month =
        date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};
