import cn from 'classnames';
import { useState } from 'react';
import { KeydownArrow } from '../../assets/icons';
import styles from './Accordeon.module.scss';

interface AccordeonProps {
    id: number;
    question: string;
    answer: string | JSX.Element;
}

export const Accordeon = ({ id, question, answer }: AccordeonProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className={styles.accordeon} onClick={toggleOpen}>
            <div className={styles.accordeon__wrapper}>
                <IndexPoint className={styles.accordeon__index} index={id} />
                <h3 className={styles.accordeon__header}>{question}</h3>
                <div
                    className={cn(styles.accordeon__icon, {
                        [styles.accordeon__icon_opened]: isOpen,
                    })}
                >
                    <KeydownArrow />
                </div>
            </div>
            <div
                className={cn(styles.accordeon__answer, {
                    [styles.accordeon__answer_opened]: isOpen,
                })}
            >
                {isOpen && answer}
            </div>
        </div>
    );
};

interface IndexPointProps {
    index: number;
    className: string;
}

const IndexPoint = ({ index, className }: IndexPointProps) => {
    return <div className={cn(styles.point, className)}>{index}</div>;
};
