// хелпер для адаптивной верстки
export const slideCounter = (width: number): number => {
    let count;
    if (width > 1500) {
        return (count = 3);
    } else if (width < 1000) {
        return (count = 1);
    } else {
        return (count = 2);
    }
};
