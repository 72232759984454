import { useAppDispatch } from './reduxHooks/useAppDispatch';
import { useAppSelector } from './reduxHooks/useAppSelector';
import { authSlice } from '../store/reducers/AuthSlice';
import { useEffect } from 'react';

//кастомный хук, заполняющий стор из стораджа
//(стор при обновлении пропадает и я его заполняю значениями из стораджа если они есть)
export const useAuthCheck = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storage = {
        token: localStorage.getItem('token'),
        refresh: localStorage.getItem('refresh'),
        role: localStorage.getItem('role'),
        id: localStorage.getItem('id'),
        isVerified: localStorage.getItem('isVerified'),
    };

    const { token, refresh, role, id, isVerified } = useAppSelector(
        (state) => state.authReducer,
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (
            token !== storage.token &&
            refresh !== storage.refresh &&
            role !== storage.role &&
            id !== storage.id &&
            isVerified !== storage.isVerified &&
            storage.isVerified &&
            storage.token &&
            storage.role &&
            storage.id
        ) {
            dispatch(authSlice.actions.authCheck(storage));
        }
    }, [storage, token, refresh, role, isVerified, id, dispatch]);
};
