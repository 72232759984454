export interface IVacancy {
    id: number;
    name: string;
    pay: string;
    company: string;
    description: string;
}

export interface ArrowSVGProps {
    className?: string;
}

export enum CardPlacement {
    MAIN = 'main',
    PERSONAL_PAGE = 'personalPage',
}

export interface IStudent {
    document_number: string;
    email: string;
    first_name: string;
    second_name: string;
    password: string;
    confirm_password: string;
}

export interface IOrganization {
    full_title: string;
    short_title: string;
    email: string;
    password: string;
    confirm_password: string;
}

export enum Role {
    candidate = 'candidate',
    staff = 'staff',
    organization = 'company',
}

export interface Token {
    access: string;
    refresh: string;
    user_type: Role;
    user_type_id: number;
}

export interface Auth {
    email: string;
    password: string;
}

export interface AsideElement {
    id?: number;
    icon: JSX.Element;
    name: string;
    link?: string | null;
    dropdownElements?: Array<DropdownElements>;
    onClick?: () => void;
}

export interface DropdownElements {
    title: string;
    dropdownId: number;
    src: string;
}

export interface SelectedImage {
    id: string;
    file: File;
    src: string;
}

export const enum UploadStatus {
    ERROR = 'ERROR',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    NOT_STARTED = 'NOT_STARTED',
}

export interface SelectOption {
    value: string;
    label: string;
}

export interface EducationData {
    map: any;
    id: number;
    grade: Grade;
    college: string;
    faculty: string;
    specialty: string;
    specialization: string;
    start: string;
    end: string;
    average_mark: number;
    course_number: number;
    candidate: number;
}

export type Grade = 'BH' | 'SP' | 'MG';

export interface Language {
    level: string;
    title: string;
    id?: number;
    candidate?: number; // id of candidate in DB
}

export interface SocialActivities {
    organization: string;
    site: string;
    start: string;
    end: string;
    description: string;
    skills: string;
    format: string;
    by_current_time: boolean;
}

export interface Experience {
    candidate: number;
    company_title: string;
    description: string;
    end: string;
    id: number;
    is_internship: false;
    position: string;
    site: string;
    skills: string;
    start: string;
    by_current_time: boolean;
}

export interface NewsCard {
    id: number;
    title: string;
    create_date: string;
    publish_date: string;
    text: string;
    images: NewsCardImage[];
}

export interface NewsCardImage {
    id: number;
    path: string;
}

export interface IEventCard {
    id: number;
    title: string;
    datetime: string;
    publish_date: string;
    format: string;
    address: string;
    description: string;
    image: string | null;
    companies: string;
    members: string[];
    in_user_favorite_events: boolean;
    is_user_member: boolean;
    previous_fresh_event?: number;
    next_fresh_event?: number;
    is_pdf?: boolean;
}

export interface IEventMemberCard {
    id: number;
    email: string;
    fio: string;
    organization_institution: string;
    event: number;
    candidate: number;
    company: number;
    registrated_date: string;
}

export interface INewsCard {
    id: number;
    title: string;
    create_date: string;
    publish_date: string;
    text: string;
    previous_news?: number | null;
    next_news?: number | null;
    images: NewsCardImage[];
}

export interface DropdownOption {
    value: string;
    label: string;
}

export interface Candidate {
    about: string;
    birthday: string;
    citizenship: string;
    conscription: boolean;
    disability: boolean;
    document_number: string;
    email: string;
    employment_form: string[];
    favorite_events: number[];
    first_name: string;
    contact_email: string | null;
    contact_phone: string | null;
    id: number;
    is_student: boolean;
    is_verified: boolean;
    is_open: boolean;
    is_resume_fill: boolean;
    last_name: string | null;
    migration_status: string | null;
    phone: string | null;
    position: string | null;
    salary: string | null;
    second_name: string;
    skills: string | null;
    status: string | null;
    city: string | null;
    is_no_experience: boolean;
    is_no_public_activity: boolean;
    photo: string;
    educations: EducationData[];
    languages: Language[];
    experience: Experience[];
}

export interface Staff {
    grade: string;
    email: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    photo: string;
    id: number;
}
