import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    first_name: yup
        .string()
        .required('Поле обязательно')
        .max(50, 'Поле не должно содержать более 50 символов'),
    second_name: yup
        .string()
        .required('Поле обязательно')
        .max(50, 'Поле не должно содержать более 50 символов'),
    last_name: yup
        .string()
        .required('Поле обязательно')
        .max(50, 'Поле не должно содержать более 50 символов'),
    phone: yup
        .string()
        .required('Поле обязательно')
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?:\d{11}|\+\d{11})$/,
            'Некорректный номер телефона',
        ),
});
