import { ErrorMessage, Field } from 'formik';
import styles from './FormikFields.module.scss';
import cn from 'classnames';
import { ChangeEvent } from 'react';

interface FormikInputProps {
    index: number;
    error?: string;
    name: string;
    wrapperClassName?: string;
    fieldClassName?: string;
    title: string;
    placeholder?: string;
    isValid?: boolean;
    type?: string;
    onClick?: () => void;
    onChange?: () => void;
    disabled?: boolean;
    value?: any;
}

const FormikInput = ({
    name,
    wrapperClassName,
    fieldClassName,
    title,
    placeholder,
    isValid,
    type,
    onClick,
    onChange,
    disabled,
    value,
}: FormikInputProps) => {
    return (
        <div className={cn(styles.field, wrapperClassName)}>
            <h3 className={styles.field__header}>{title}</h3>
            <Field
                className={cn(styles.field__input, fieldClassName, {
                    [styles.field__input_valid]: isValid,
                })}
                type={type ? type : 'text'}
                name={name}
                placeholder={placeholder}
                onClick={onClick}
                disabled={disabled}
            />
            <div className={styles.field__error}>
                <ErrorMessage component={'span'} name={name} />
            </div>
        </div>
    );
};

const FormikSelect = ({
    name,
    fieldClassName,
    wrapperClassName,
    title,
    placeholder,
    isValid,
    options,
    type,
    onChange,
    value,
}: FormikSelectProps) => {
    return (
        <div className={styles.field}>
            <h3 className={styles.field__header}>{title}</h3>
            <Field
                id={name}
                as="select"
                className={cn(styles.select, {
                    [styles.select_valid]: isValid,
                })}
                name={name}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (!onChange) {
                        return;
                    }
                    onChange(e);
                }}
            >
                {options.map((option) => (
                    <option value={option.value}>{option.label}</option>
                ))}
            </Field>
        </div>
    );
};

interface FormikSelectProps {
    options: Option[];
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
    value: any;
    index: number;
    error?: string;
    name: string;
    wrapperClassName?: string;
    fieldClassName?: string;
    title: string;
    placeholder?: string;
    isValid?: boolean;
    type?: string;
    onClick?: () => void;
    disabled?: boolean;
}

interface Option {
    value: string;
    label: string;
}

const FormikTextarea = ({
    fieldClassName,
    wrapperClassName,
    headerClassName,
    title,
    name,
    error,
    isValid,
}: FormikTextareaProps) => {
    return (
        <div className={cn(styles.TextArea, wrapperClassName)}>
            <h2 className={cn(styles.TextArea__header, headerClassName)}>
                {title}
            </h2>
            <Field
                as={'textarea'}
                name={name}
                className={cn(styles.TextArea__area, fieldClassName, {
                    [styles.TextArea__area_valid]: isValid,
                })}
            ></Field>
            <div className={styles.TextArea__error}>
                {error ? <span>{error}</span> : ''}
            </div>
        </div>
    );
};

interface FormikTextareaProps {
    fieldClassName?: string;
    wrapperClassName?: string;
    headerClassName?: string;
    title?: string;
    name?: string;
    error: string;
    isValid: boolean;
}

export { FormikInput, FormikSelect, FormikTextarea };
