import styles from './AddShow.module.scss';

import axios from 'axios';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Input } from '../../../../UI/Input/Input';
import { AddEventImage } from '../../../../components/AddEventImage/AddEventImage';
import { AddNewsHeader } from '../../../../components/AddNewsHeader/AddNewsHeader';
import { validationSchema } from './validationSchema';

export const AddShow = () => {
    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);

    const validImage = useMemo(() => {
        if (selectedImages?.length && selectedImages[0].size < 512000) {
            return true;
        }
        if (!selectedImages?.length) {
            return true;
        }
        return false;
    }, [selectedImages]);

    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            title: '',
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            if (selectedImages === null) {
                return;
            }
            const formData = new FormData();
            formData.append('image', selectedImages[0]);
            formData.append('title', formik.values.title);

            await axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}api/v1/logo/create_logo/`,
                    formData,
                )
                .then(() => {
                    toast.success('Партнер успешно добавлен!');
                    formik.resetForm();
                    setSelectedImages(null);
                    setIsLoading(false);
                    window.history.go(-1);
                })
                .catch(() => {
                    toast.error('При загрузке партнера произошла ошибка');
                    setIsLoading(false);
                });

            setIsLoading(false);
        },
        validationSchema: validationSchema,
    });
    return (
        <form onSubmit={formik.handleSubmit} className={styles.addevent}>
            <AddNewsHeader
                dirty={
                    formik.isValid &&
                    validImage &&
                    selectedImages !== null &&
                    selectedImages.length > 0
                }
                isValid={
                    formik.isValid &&
                    validImage &&
                    selectedImages !== null &&
                    selectedImages.length > 0
                }
                extraCondition
                isLoading={isLoading}
                onSubmit={formik.handleSubmit}
                resetForm={() => {
                    formik.resetForm();
                    window.history.go(-1);
                }}
                title={'Добавить партнера'}
            />
            <AddEventImage
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
            />
            {!validImage ? (
                <span className={styles.validImage}>
                    Максимальный размер изображения 500Кб
                </span>
            ) : null}
            <div className={styles.addevent__content}>
                <Input
                    wrapperClassName={[styles.addevent_first]}
                    placeholder="Название"
                    inputHeader={'Название'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name={'title'}
                    errorMessage={formik.errors.title}
                    isValid={
                        formik.touched &&
                        !formik.errors.title &&
                        !!formik.values.title
                    }
                />
            </div>
        </form>
    );
};
