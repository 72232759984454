import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    // email: yup
    //     .string()
    //     .matches(
    //         /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
    //         'Некорректный email',
    //     )
    //     .required('Поле обязательно')
    //     .max(50, 'Email не может быть больше 50 символов'),
    // phone: yup
    //     .string()
    //     .typeError('Должно быть строкой')
    //     .nullable()
    //     .matches(
    //         // eslint-disable-next-line no-useless-escape
    //         /^(?:\d{11}|\+\d{11})$/,
    //         'Некорректный номер телефона',
    //     ),

    position: yup
        .string()
        .required('Поле обязательно')
        .max(50, 'Должность не может быть больше 50 символов'),

    description: yup
        .string()
        .required('Поле обязательно')
        .max(5000, 'Описание не может быть больше 5000 символов'),
    required_experience: yup.string().required('Поле обязательно'),
    employment_form: yup
        .array()
        .min(1)
        .of(yup.object({ value: yup.string(), label: yup.string() })),
    salaryFrom: yup
        .string()
        .matches(/^[0-9]+$/, 'Введите числовое значение')
        .required('Поле обязательно')
        .max(50, 'Email не может быть больше 50 символов'),
    salaryTo: yup
        .string()
        .matches(/^[0-9]+$/, 'Введите числовое значение')
        .required('Поле обязательно')
        .test(
            'isLarger',
            'Минимальная заработная плата выше максимальной',
            (value, testContext) => {
                if (
                    value &&
                    Number(testContext.parent.salaryFrom) > Number(value)
                )
                    return false;
                return true;
            },
        ),
    // contact_phone: yup
    //     .string()
    //     .typeError('Должно быть строкой')
    //     .nullable()
    //     .matches(
    //         // eslint-disable-next-line no-useless-escape
    //         /^(?:\d{11}|\+\d{11})$/,
    //         'Некорректный номер телефона',
    //     ),
});
