import cn from 'classnames';
import styles from './StudentsList.module.scss';

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    changeDateRange,
    changeEndDate,
    changeExperience,
    changeFiltredPage,
    changeGrade,
    changeStartDate,
    changeVerifiedPage,
    fetchFiltredStudents,
    fetchVerifiedStudents,
    resetFilters,
} from '../../../store/reducers/StudentsSlice';

import Button from '../../../UI/Button/Button';
import { Dropdown } from '../../../UI/Dropdown/Dropdown';
import { MultiCheckbox } from '../../../UI/MultiCheckbox/MultiCheckbox';
import { RangeInput } from '../../../UI/RangeInput/RangeInput';
import {
    FileDownloader,
    FileType,
} from '../../../components/FileDownloader/FileDownloader';
import { LanguagesFilters } from '../../../components/LanguageFilter/LanguageFilter';
import { StudentCard } from '../../../components/StudentCard/StudentCard';
import { Candidate, Role } from '../../../types/types';

import { useSearchParams } from 'react-router-dom';
import {
    EXPERIANCE_CONSTANT,
    GRADE_CONSTANT,
    GRADE_DROPDOWN_OPTIONS,
} from '../../../constants';
import Pagination from '../SupportList/Pagination';

enum TogglerElements {
    ALL = 'all',
    PUBLISHED = 'published',
}

export const StudentsList = () => {
    const { filtredCurrentPage, verifiedCurrentPage } = useAppSelector(
        (state) => state.studentsReducer,
    );

    const { role } = useAppSelector((state) => state.authReducer);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchFiltredStudents({ page: filtredCurrentPage })); // массив данных для  <StudentsWithResume />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtredCurrentPage]);

    useEffect(() => {
        dispatch(fetchVerifiedStudents({ page: verifiedCurrentPage })); // массив данных для  <AllStudents />
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifiedCurrentPage]);

    const [togglerElement, setTogglerElement] = useState<TogglerElements>(
        role === Role.organization
            ? TogglerElements.PUBLISHED
            : TogglerElements.ALL,
    );

    return (
        <div className={styles.StudentsList}>
            <StudentListToggler
                setTogglerElement={setTogglerElement}
                togglerElement={togglerElement}
            />
            {togglerElement === TogglerElements.ALL ? (
                <AllStudents />
            ) : (
                <StudentsWithResume />
            )}
        </div>
    );
};

interface StudentListTogglerProps {
    setTogglerElement: Dispatch<SetStateAction<TogglerElements>>;
    togglerElement: TogglerElements;
}

const StudentListToggler = ({
    setTogglerElement,
    togglerElement,
}: StudentListTogglerProps) => {
    const toggleList = (name: TogglerElements) => {
        setTogglerElement(name);
    };
    const { role } = useAppSelector((state) => state.authReducer);

    const [params, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (params.get('mode') === TogglerElements.ALL) {
            toggleList(TogglerElements.ALL);
        } else {
            toggleList(TogglerElements.PUBLISHED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    return (
        <div className={styles.toggler}>
            {role === Role.staff ? (
                <Button
                    className={cn(styles.button, {
                        [styles.button_active]:
                            togglerElement === TogglerElements.ALL,
                    })}
                    onClick={() => {
                        toggleList(TogglerElements.ALL);
                        params.set('mode', TogglerElements.ALL);
                        setSearchParams(params);
                    }}
                >
                    Все студенты
                </Button>
            ) : null}
            <Button
                className={cn(styles.button, {
                    [styles.button_active]:
                        togglerElement === TogglerElements.PUBLISHED,
                })}
                onClick={() => {
                    toggleList(TogglerElements.PUBLISHED);
                    params.set('mode', TogglerElements.PUBLISHED);
                    setSearchParams(params);
                }}
            >
                Опубликованные резюме
            </Button>
        </div>
    );
};

// все студенты
const AllStudents = () => {
    const { verifiedStudents } = useAppSelector(
        (state) => state.studentsReducer,
    );
    return (
        <div className={styles.all}>
            <AllStudentList students={verifiedStudents} />
            <AllStudentsSidebar />
        </div>
    );
};

const AllStudentsSidebar = () => {
    return (
        <div className={styles.sidebar}>
            <FileDownloader
                fileType={FileType.EXCEL}
                url={`${process.env.REACT_APP_BASE_URL}api/v1/students/excel/`}
                name={'Скачать XLSX'}
                downloadName={'Students'}
            />
        </div>
    );
};

interface AllStudentListProps {
    students: Candidate[];
}

const AllStudentList = ({ students }: AllStudentListProps) => {
    const { verifiedCurrentPage, verifiedCount, isLoading } = useAppSelector(
        (state) => state.studentsReducer,
    );

    const dispatch = useAppDispatch();
    const changeCurrentPage = (page: number) => {
        dispatch(changeVerifiedPage(page));
    };

    return (
        <div className={styles.cards}>
            {students.length > 0 &&
                students.map((student) => (
                    <StudentCard
                        disabled={!student.is_resume_fill || !student.is_open}
                        id={student.id}
                        key={student.id}
                        initials={`${student.second_name || ''} ${
                            student.first_name || ''
                        } ${student.last_name || ''}`}
                        birthday={student.birthday}
                        photo={student.photo}
                        education={student.educations}
                    />
                ))}
            <Pagination
                handlePagination={(p) => changeCurrentPage(p)}
                list={{
                    page_count: verifiedCount,
                    current_page: verifiedCurrentPage,
                }}
                isLoading={isLoading}
            />
        </div>
    );
};

// подстраница - студенты с резюме
const StudentsWithResume = () => {
    const { filtredCurrentPage, filtredCount, filtredStudents, isLoading } =
        useAppSelector((state) => state.studentsReducer);

    const dispatch = useAppDispatch();
    const changeCurrentPage = (page: number) => {
        dispatch(changeFiltredPage(page));
    };
    const { role } = useAppSelector((state) => state.authReducer);

    const { company } = useAppSelector((state) => state.companyReducer);

    if (role === Role.organization && !company?.has_subscription) {
        return (
            <div className={styles.allBlur}>
                <StudentsWithResumeList
                    studentsWithResume={filtredStudents
                        .slice(0, 2)
                        .map((item) => ({
                            ...item,
                            first_name: 'Лев',
                            second_name: 'Васильев',
                            id: 0,
                        }))}
                />
                <span className={styles.tariffNotActive}>
                    Вы не можете просматривать резюме соискателей, которые не
                    откликнулись на Вашу вакансию, без подписки. Узнать
                    подробнее и оплатить подписку:{' '}
                    <a href="mailto:career.law.msu@gmail.com">
                        career.law.msu@gmail.com
                    </a>
                </span>
            </div>
        );
    }
    return (
        <div className={styles.all}>
            <div>
                <StudentsWithResumeList studentsWithResume={filtredStudents} />

                <Pagination
                    handlePagination={(p) => changeCurrentPage(p)}
                    list={{
                        page_count: filtredCount,
                        current_page: filtredCurrentPage,
                    }}
                    isLoading={isLoading}
                />
            </div>
            <FiltersWidget />
        </div>
    );
};

interface StudentsWithResumeListProps {
    studentsWithResume: Candidate[];
}

const FiltersWidget = () => {
    const dispatch = useAppDispatch();

    const { grade, language, languageLevels, experience, years } =
        useAppSelector((state) => state.studentsReducer);

    const applyFilters = () => {
        dispatch(
            fetchFiltredStudents({
                page: 1,
                grade: grade,
                language: language,
                language_level: languageLevels,
                required_experience: experience,
                year_from: String(years[0]),
                year_to: String(years[1]),
            }),
        );
    };

    const cvBook = useMemo(() => {
        const languageList = languageLevels
            .map((item) => `&language_level=${item}`)
            .join('');

        const experienceList = experience
            .map((item) => `&required_experience=${item}`)
            .join('');

        const gradeItem = grade ? `&grade=${grade}` : '';

        const languageItem = language ? `&language=${language}` : '';

        const dataItem = years
            ? `&year_from=${years[0]}&year_to=${years[1]}`
            : '';

        return `${process.env.REACT_APP_BASE_URL}api/v1/candidate/cv-book/${gradeItem}${languageItem}${languageList}${experienceList}${dataItem}`.replace(
            'cv-book/&',
            'cv-book/?',
        );
    }, [grade, language, languageLevels, experience, years]);

    return (
        <div className={styles.widget}>
            <FileDownloader
                className={styles.filedownloader}
                url={cvBook}
                name={'Скачать CV-book'}
            />
            <div className={styles.filters}>
                <GradeDropdown />
                <ExperienceMultiCheckbox />
                <YearsRangeInput />
                <LanguagesFilters />
                <Button
                    className={styles.button__accept}
                    onClick={applyFilters}
                >
                    Применить
                </Button>
                <Button
                    className={styles.button__reset}
                    onClick={() => {
                        dispatch(resetFilters());
                        fetchFiltredStudents({
                            page: 1,
                        });
                    }}
                >
                    Сбросить фильтры
                </Button>
            </div>
        </div>
    );
};

const GradeDropdown = () => {
    const dispatch = useAppDispatch();
    const changeOption = (value: string) => {
        dispatch(changeGrade(value));
    };
    const { grade } = useAppSelector((state) => state.studentsReducer);
    return (
        <div className={styles.dropdown}>
            <h3 className={styles.dropdown__header}>Уровень образования</h3>
            <Dropdown
                options={GRADE_DROPDOWN_OPTIONS}
                className={styles.dropdown__wrapper}
                dropdownElementClassname={styles.dropdown__text}
                placeholderClassname={styles.dropdown__placeholder}
                changeOption={changeOption}
                option={GRADE_CONSTANT[grade]}
            />
        </div>
    );
};

const ExperienceMultiCheckbox = () => {
    const dispatch = useAppDispatch();
    const changeOptions = (value: string) => {
        dispatch(changeExperience(value));
    };
    const currentCheckedValues = useAppSelector(
        (state) => state.studentsReducer.experience,
    );
    return (
        <MultiCheckbox
            title={'Опыт работы'}
            options={EXPERIANCE_CONSTANT}
            changeOptions={changeOptions}
            currentValues={currentCheckedValues}
        />
    );
};

const YearsRangeInput = () => {
    const dispatch = useAppDispatch();
    const changeRange = (range: [number, number]) => {
        dispatch(changeDateRange(range));
    };
    const yearsRange = useAppSelector((state) => state.studentsReducer.years);

    //граничные значения
    const startDate = 1990;
    const endDate = new Date().getFullYear() + 6;

    const startValue = yearsRange[0];
    const endValue = yearsRange[1];

    const changeStart = (value: string) => {
        dispatch(changeStartDate(Number(value)));
    };
    const changeEnd = (value: string) => {
        dispatch(changeEndDate(Number(value)));
    };
    return (
        <RangeInput
            title={'Год выпуска'}
            min={startDate}
            max={endDate}
            changeRange={changeRange}
            startValue={startValue}
            endValue={endValue}
            changeStart={changeStart}
            changeEnd={changeEnd}
        />
    );
};

const StudentsWithResumeList = ({
    studentsWithResume,
}: StudentsWithResumeListProps) => {
    return (
        <div className={styles.resumelist}>
            {studentsWithResume.length > 0 ? (
                studentsWithResume.map((student) => (
                    <StudentCard
                        disabled={!student.is_resume_fill || !student.is_open}
                        key={student.id}
                        id={student.id}
                        initials={`${student.second_name || ''} ${
                            student.first_name || ''
                        } ${student.last_name || ''}`}
                        birthday={student.birthday}
                        photo={student.photo}
                        education={student.educations}
                    />
                ))
            ) : (
                <p className={styles.resumelist__nostudents}>
                    Студенты отсутствуют
                </p>
            )}
        </div>
    );
};
