import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Textarea } from '../../../UI/Textarea/Textarea';
import { CloseIcon } from '../../../assets/icons';
import { Loader } from '../../../components/Loader/Loader';
import { Modal } from '../../../components/Modal/Modal';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    getCandidateVacancyReactionList,
    updateCandidateVacancyReaction,
    updateCandidateVacancyReactionListPage,
} from '../../../store/reducers/CandidateVacancySlice';
import Pagination from '../../StaffPages/SupportList/Pagination';
import { StudentVacancyListItem } from './VacancyItem';
import styles from './VacancyItem/index.module.scss';

export const StudentVacancyReactionList = () => {
    const dispatch = useAppDispatch();

    const [modalId, setModalId] = useState<
        { id: number; name: string } | undefined
    >(undefined);

    const {
        candidateVacancyReactionList: candidateVacancyList,
        candidateVacancyReactionListIsLoading: candidateVacancyListIsLoading,
    } = useAppSelector((state) => state.candidateVacancyReducer);

    const { candidate } = useAppSelector((state) => state.candidateReducer);

    const refetchCompanyVacancyList = () => {
        dispatch(
            getCandidateVacancyReactionList({
                page: 1,
                size: 10,
                id: candidate?.id,
            }),
        );
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            text: '',
        },
        onSubmit: async () => {
            if (candidate?.id && modalId?.id) {
                await dispatch(
                    updateCandidateVacancyReaction({
                        id: candidate?.id,
                        vacancy_id: modalId?.id,
                        comment: formik.values.text,
                    }),
                ).then(() => setModalId(undefined));
                refetchCompanyVacancyList();
            }
        },
        validationSchema: yup.object().shape({
            text: yup
                .string()
                .required('Поле обязательно')
                .max(1000, 'Достигнута максимальная длина письма'),
        }),
    });

    useEffect(() => {
        if (candidate?.id) {
            refetchCompanyVacancyList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidate?.id]);

    return (
        <>
            {modalId ? (
                <Modal>
                    <form
                        onSubmit={formik.handleSubmit}
                        className={styles.modal}
                    >
                        <h3>Отклик на вакансию</h3>
                        <span>Название вакансии</span>
                        <Textarea
                            name="text"
                            placeholder="Сопроводительное письмо"
                            value={formik.values.text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.text}
                            isValid={
                                Boolean(formik.values.text) &&
                                !formik.errors.text
                            }
                        />
                        <button
                            type="submit"
                            className={styles.vacancyAddButtonAccept}
                            disabled={!formik.isValid || !formik.dirty}
                        >
                            Отправить
                        </button>
                        <button
                            className={styles.closeButton}
                            onClick={() => setModalId(undefined)}
                        >
                            <CloseIcon />
                        </button>
                    </form>
                </Modal>
            ) : null}
            <div className={styles.companyVacancyList}>
                <div className={styles.companyVacancyListGrid}>
                    {candidateVacancyListIsLoading ? <Loader /> : null}

                    {!candidateVacancyListIsLoading &&
                    candidateVacancyList?.objects.length === 0 ? (
                        <span className={styles.notificationText}>
                            Вакансий пока нет!
                        </span>
                    ) : null}

                    {!candidateVacancyListIsLoading &&
                        candidateVacancyList &&
                        candidateVacancyList.objects.map((item) => (
                            <StudentVacancyListItem
                                key={`student-vacancy-${item.id}`}
                                vacancy={item.vacancy}
                                refetch={() => refetchCompanyVacancyList()}
                                setModal={() =>
                                    setModalId({
                                        id: item.id,
                                        name: item.vacancy.position,
                                    })
                                }
                            />
                        ))}

                    <Pagination
                        list={candidateVacancyList}
                        handlePagination={(p) => {
                            dispatch(updateCandidateVacancyReactionListPage(p));
                        }}
                        isLoading={candidateVacancyListIsLoading}
                    />
                </div>
            </div>
        </>
    );
};
