/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Input } from '../../../../UI/Input/Input';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../../routes/RouterConfig';
import { fetchStaffInfo } from '../../../../store/reducers/StaffSlice';
import styles from './EditProfile.module.scss';
import { validationSchema } from './validationSchema';

export const EditProfile = () => {
    const id = useAppSelector((state) => state.authReducer.id);
    const staff = useAppSelector((state) => state.staffReducer.staff);

    const dispatch = useAppDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            phone: staff?.phone,
            first_name: staff?.first_name,
            second_name: staff?.second_name,
            last_name: staff?.last_name,
        },
        onSubmit: async (values) => {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/staff/${id}/`;
            try {
                axios
                    .put(url, {
                        ...formik.values,
                    })
                    .then(() => {
                        toast.success('Данные профиля успешно изменены');
                        dispatch(fetchStaffInfo(Number(id)));
                    })
                    .catch(() => toast.error('Произошла ошибка'));
            } catch (e) {
                toast.error('Произошла ошибка. Попробуйте позже');
            }
        },
        validationSchema: validationSchema,
    });

    return (
        <div className={styles.companyEditProfile}>
            <form onSubmit={formik.handleSubmit} className={styles.EditProfile}>
                <EditWrapper
                    saveLink={PATH_DASHBOARD.staff.root}
                    rejectLink={PATH_DASHBOARD.staff.root}
                    isValid={formik.isValid && formik.dirty}
                    dirty={formik.dirty}
                    title={'Данные сотрудника'}
                    saveOnclick={formik.handleSubmit}
                >
                    <div className={styles.EditAbout__wrapper}>
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Фамилия'}
                            placeholder={'Фамилия'}
                            name="second_name"
                            value={formik.values.second_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.second_name}
                            isValid={
                                Boolean(formik.values.second_name) &&
                                !formik.errors.second_name
                            }
                        />
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Имя'}
                            placeholder={'Имя'}
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.first_name}
                            isValid={
                                Boolean(formik.values.first_name) &&
                                !formik.errors.first_name
                            }
                        />
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Отчество'}
                            placeholder={'Отчество'}
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.last_name}
                            isValid={
                                Boolean(formik.values.last_name) &&
                                !formik.errors.last_name
                            }
                        />
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Телефон'}
                            placeholder={'+78008008080'}
                            name={'phone'}
                            value={formik.values.phone}
                            errorMessage={formik.errors.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.phone) &&
                                !formik.errors.phone
                            }
                        />
                    </div>
                </EditWrapper>
            </form>
        </div>
    );
};
