import cn from 'classnames';
import styles from './Textarea.module.scss';

interface TextAreaProps {
    areaClassName?: string;
    wrapperClassName?: string;
    headerClassName?: string;
    title?: string;
    isFieldValid?: boolean;
    onChange?: (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    value?: any;
    name?: string;
    errorMessage?: string | undefined;
    isValid?: boolean;
    defaultValue?: string | null | undefined;
    placeholder?: string;
}

export const Textarea = ({
    areaClassName,
    title,
    wrapperClassName,
    headerClassName,
    onChange,
    onBlur,
    value,
    name,
    errorMessage,
    isValid,
    defaultValue,
    placeholder,
}: TextAreaProps) => {
    return (
        <div className={cn(styles.TextArea, wrapperClassName)}>
            {title ? (
                <h2 className={cn(styles.TextArea__header, headerClassName)}>
                    {title}
                </h2>
            ) : null}
            <textarea
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value ? value : ''}
                name={name}
                className={cn(styles.TextArea__area, areaClassName, {
                    [styles.TextArea__area_valid]: isValid,
                })}
            ></textarea>
            <div className={styles.TextArea__error}>
                {errorMessage ? errorMessage : ''}
            </div>
        </div>
    );
};
