import styles from './SupportList.module.scss';

import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    SupportListItemType,
    getStaffSupportList,
    getSupportList,
    updatePage,
    updateStatus,
    updateSupportList,
} from '../../../store/reducers/SupportSlice';

import { Checkbox } from '../../../UI/Checkbox/Checkbox';
import { CustomSelect } from '../../../UI/CustomSelect/CustomSelect';
import { Loader } from '../../../components/Loader/Loader';
import Pagination from './Pagination';

type SupportListItemProps = {
    item: SupportListItemType;
    isStaff: boolean;
};
const SupportListItem: React.FC<SupportListItemProps> = ({ item, isStaff }) => {
    const dispatch = useAppDispatch();
    const { page, status: statusList } = useAppSelector(
        (state) => state.supportReducer,
    );
    const { id: staffId } = useAppSelector((state) => state.authReducer);
    const modifiedStatus = useMemo(() => {
        if (item.status === 'NV') {
            return 'Не просмотрено';
        }
        if (item.status === 'AW') {
            return 'В работе';
        }
        return 'Выполнено';
    }, [item.status]);

    const [isText, setIsText] = useState(false);

    const options: { value: 'NV' | 'AW' | 'CM'; label: string }[] = [
        { value: 'NV', label: 'Не просмотрено' },
        { value: 'AW', label: 'В работе' },
        { value: 'CM', label: 'Выполнено' },
    ];
    return (
        <div className={styles.supportListItem}>
            <div className={styles.supportListItemContent}>
                <div className={styles.supportListItemData}>
                    <div>
                        <span>{item.fio}</span>
                        <a
                            className={styles.supportListItemEmail}
                            href={`mailto:${item.email}`}
                        >
                            {item.email}
                        </a>
                    </div>
                </div>
                <div className={styles.supportListItemStatus}>
                    <span>
                        {new Date(item.updated_at)
                            .toLocaleDateString('ru-RU', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                            })
                            .replace(',', '')}
                    </span>
                    <CustomSelect
                        onChange={async (e: {
                            value: 'NV' | 'AW' | 'CM';
                            label: string;
                        }) => {
                            if (staffId) {
                                await dispatch(
                                    updateSupportList({
                                        id: item.id,
                                        email: item.email,
                                        fio: item.fio,
                                        organization_institution:
                                            item.organization_institution,
                                        text: item.text,
                                        staff: staffId,
                                        status: e.value,
                                    }),
                                );
                                if (isStaff) {
                                    dispatch(
                                        getStaffSupportList({
                                            page: page,
                                            size: 20,
                                            status: statusList,
                                        }),
                                    );
                                } else {
                                    dispatch(
                                        getSupportList({
                                            page: page,
                                            size: 20,
                                            status: statusList,
                                        }),
                                    );
                                }
                            }
                        }}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        defaultValue={{
                            value: item.status,
                            label: modifiedStatus,
                        }}
                        options={options}
                        isSearchable={false}
                    />
                </div>
            </div>
            <div className={styles.supportListItemText}>
                <span>Сообщение</span>
                <div>
                    <p className={!isText ? styles.withLineClamp : ''}>
                        {item.text}
                    </p>
                    <button onClick={() => setIsText((text) => !text)}>
                        {!isText ? 'Смотреть все' : 'Уменьшить блок'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const SupportList: React.FC = () => {
    const { supportList, isLoading, page, status } = useAppSelector(
        (state) => state.supportReducer,
    );
    const dispatch = useAppDispatch();

    const [isStaffSupport, setIsStaffSupport] = useState(false);

    useLayoutEffect(() => {
        if (isStaffSupport) {
            dispatch(
                getStaffSupportList({ page: page, size: 20, status: status }),
            );
        } else {
            dispatch(getSupportList({ page: page, size: 20, status: status }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, page, isStaffSupport]);

    useEffect(() => {
        dispatch(updatePage(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, isStaffSupport]);

    useEffect(() => {
        if (isStaffSupport && status === 'NV') {
            dispatch(updateStatus('AW'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStaffSupport]);

    return supportList ? (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.statusWrapper}>
                    <div className={styles.status}>
                        {!isStaffSupport ? (
                            <button
                                className={`${styles.statusItem} ${
                                    status === 'NV'
                                        ? styles.statusItemActive
                                        : null
                                }`}
                                onClick={() => dispatch(updateStatus('NV'))}
                            >
                                Не просмотрено
                            </button>
                        ) : null}
                        <button
                            className={`${styles.statusItem} ${
                                status === 'AW' ? styles.statusItemActive : null
                            }`}
                            onClick={() => dispatch(updateStatus('AW'))}
                        >
                            В работе
                        </button>
                        <button
                            className={`${styles.statusItem} ${
                                status === 'CM' ? styles.statusItemActive : null
                            }`}
                            onClick={() => dispatch(updateStatus('CM'))}
                        >
                            Выполнено
                        </button>
                    </div>
                    <Checkbox
                        text="Мои запросы"
                        onChange={() => setIsStaffSupport((value) => !value)}
                        value={isStaffSupport}
                    />
                </div>
                {status === 'CM' ? (
                    <span className={styles.completeText}>
                        Выполненные запросы скрываются автоматически через 30
                        дней
                    </span>
                ) : null}

                {isLoading ? <Loader /> : null}

                {!isLoading && supportList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        Обращений пока нет!
                    </span>
                ) : null}

                {!isLoading &&
                    supportList?.objects.map((item) => (
                        <SupportListItem
                            key={`support_item_${item.id}`}
                            item={item}
                            isStaff={isStaffSupport}
                        />
                    ))}

                <Pagination
                    list={supportList}
                    handlePagination={(p) => {
                        dispatch(updatePage(p));
                    }}
                    isLoading={isLoading}
                />
            </div>
        </div>
    ) : null;
};
