/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Input } from '../../../../UI/Input/Input';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../../routes/RouterConfig';
import { getCompanyRetrieve } from '../../../../store/reducers/CompanySlice';
import styles from './EditProfile.module.scss';
import { validationSchema } from './validationSchema';

export const EditProfile = () => {
    const id = useAppSelector((state) => state.authReducer.id);
    const company = useAppSelector((state) => state.companyReducer.company);

    const dispatch = useAppDispatch();

    const formik = useFormik({
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
            full_title: company?.full_title,
            short_title: company?.short_title,
            phone: company?.phone,
        },
        onSubmit: async (values) => {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/`;
            try {
                axios
                    .put(url, {
                        ...formik.values,
                    })
                    .then(() => {
                        toast.success('Данные профиля успешно изменены');
                        dispatch(getCompanyRetrieve(Number(id)));
                    })
                    .catch(() => toast.error('Произошла ошибка'));
            } catch (e) {
                toast.error('Произошла ошибка. Попробуйте позже');
            }
        },
        validationSchema: validationSchema,
    });

    return (
        <div className={styles.companyEditProfile}>
            <form onSubmit={formik.handleSubmit} className={styles.EditProfile}>
                <EditWrapper
                    saveLink={PATH_DASHBOARD.company.root}
                    rejectLink={PATH_DASHBOARD.company.root}
                    title={'О компании'}
                    isValid={formik.isValid}
                    saveOnclick={formik.handleSubmit}
                >
                    <div className={styles.EditAbout__wrapper}>
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Полное название'}
                            placeholder={'Полное название'}
                            name="full_title"
                            value={formik.values.full_title}
                            errorMessage={formik.errors.full_title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.full_title) &&
                                !formik.errors.full_title
                            }
                        />
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Название'}
                            placeholder={'Название'}
                            name="short_title"
                            value={formik.values.short_title}
                            errorMessage={formik.errors.short_title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.short_title) &&
                                !formik.errors.short_title
                            }
                        />
                        <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Телефон'}
                            placeholder={'+78008008080'}
                            name={'phone'}
                            value={formik.values.phone}
                            errorMessage={formik.errors.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.phone) &&
                                !formik.errors.phone
                            }
                        />
                        {/* <Input
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            inputHeader={'Сайт'}
                            placeholder={'Сайт'}
                            name={'site'}
                            value={formik.values.site}
                            errorMessage={formik.errors.site}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={
                                Boolean(formik.values.site) &&
                                !formik.errors.site
                            }
                        /> */}
                    </div>
                </EditWrapper>
            </form>
        </div>
    );
};
