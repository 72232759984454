import axios from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../UI/Button/Button';
import { RegistrationCheckbox } from '../../UI/Checkbox/Checkbox';
import { Input } from '../../UI/Input/Input';
import { CrossButton } from '../../assets/icons';
import { scrollAvailable } from '../../helpers/scrollToggler';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { Role } from '../../types/types';
import { Modal } from '../Modal/Modal';
import styles from './EventRegistration.module.scss';
import { validationSchema } from './validationSchema';

export const EventRegistration = () => {
    //логика работы с попапом
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const closePopup = () => {
        navigate(pathname);
        scrollAvailable();
    };
    return (
        <Modal>
            <div className={styles.EventRegistration}>
                <CrossButton
                    className={styles.EventRegistration__closebutton}
                    onClick={closePopup}
                />
                <h2 className={styles.EventRegistration__header}>
                    Регистрация на мероприятие
                </h2>
                <EventRegistrationForm closePopup={closePopup} />
            </div>
        </Modal>
    );
};

interface RegistrationPopupProps {
    closePopup: () => void;
}

const EventRegistrationForm = ({ closePopup }: RegistrationPopupProps) => {
    //логика работы с формой
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const { candidate } = useAppSelector((state) => state.candidateReducer);

    const { id, role } = useAppSelector((state) => state.authReducer);

    const pathname = useLocation();
    const eventId = pathname.pathname.split('/')[2]; // достаю айдишник мероприятия из новости, тк пропсами не прокинуть в портал

    const registerStudentOnEventUrl = useMemo(() => {
        if (role === Role.candidate) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/event/${eventId}/candidate_member/${id}/`;
        }
        if (role === Role.organization) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/event/${eventId}/company_member/${id}/`;
        }
        return `${process.env.REACT_APP_BASE_URL}api/v1/event/${eventId}/member/`;
    }, [eventId, id, role]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            fio: candidate
                ? [
                      candidate?.second_name,
                      candidate.first_name,
                      candidate.last_name,
                  ].join(' ')
                : '',
            organization_institution: candidate
                ? 'Юридический факультет МГУ имени М.В.Ломоносова'
                : '',
            email: candidate?.email || '',
        },
        onSubmit: (values) => {
            axios
                .post(registerStudentOnEventUrl, formik.values)
                .then(() => {
                    toast.success(
                        'Вы успешно зарегистрировались на мероприятие',
                    );
                    closePopup();
                })
                .catch(() =>
                    toast.error(
                        'При регистрации произошла ошибка. Попробуйте позже',
                    ),
                );
        },
        validationSchema: validationSchema,
    });
    return (
        <form className={styles.EventRegistration__form}>
            <Input
                name="fio"
                fieldClassName={cn(styles.EventRegistration__input, {
                    [styles.EventRegistration__input_valid]:
                        formik.touched.fio && formik.values.fio,
                })}
                placeholder={'ФИО'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.fio}
                isValid={!!formik.values.fio}
            />
            <Input
                fieldClassName={cn(styles.EventRegistration__input, {
                    [styles.EventRegistration__input_valid]:
                        formik.touched.organization_institution &&
                        formik.values.organization_institution,
                })}
                placeholder={'Организация/ВУЗ'}
                name="organization_institution"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.organization_institution}
                isValid={!!formik.values.organization_institution}
            />
            <Input
                fieldClassName={cn(styles.EventRegistration__input, {
                    [styles.EventRegistration__input_valid]:
                        formik.touched.email && formik.values.email,
                })}
                placeholder={'Почта'}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                isValid={!!formik.values.email}
            />
            <RegistrationCheckbox
                onChange={() => setIsChecked(!isChecked)}
                defaultChecked={isChecked}
                text={
                    'Я ознакомлен/а с политикой конфиденциальности и согласен на обработку персональных данных'
                }
                className={styles.EventRegistration__checkbox}
            />
            <Button
                onClick={() => {
                    formik.handleSubmit();
                }}
                isDisabled={!formik.isValid || !isChecked}
                className={cn(styles.EventRegistration__submitbutton, {
                    [styles.EventRegistration__submitbutton_valid]:
                        formik.isValid && isChecked,
                })}
            >
                Зарегистрироваться
            </Button>
        </form>
    );
};
