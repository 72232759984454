import { SetStateAction, useRef } from 'react';
import { AddPinIcon } from '../../assets/icons';
import styles from './AddEventImage.module.scss';

export const AddEventImage = ({
    selectedImages,
    setSelectedImages,
    showImage,
    showUpdateButton = true,
}: AddEventsImageProps) => {
    const filePicker = useRef<HTMLInputElement>(null);
    const handlePick = () => {
        filePicker?.current?.click();
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedImages && setSelectedImages(event.target.files);
    };
    return (
        <div className={styles.addimage}>
            <div className={styles.addimage__image}>
                {selectedImages && selectedImages?.length > 0 ? (
                    <img
                        src={URL.createObjectURL(selectedImages[0])}
                        className={styles.addimage__photo}
                        alt=""
                    />
                ) : showImage ? (
                    <img
                        src={showImage ?? ''}
                        className={styles.addimage__photo}
                        alt=""
                    />
                ) : null}
            </div>
            {showUpdateButton ? (
                <div className={styles.filesuploader}>
                    <div className={styles.filesuploader__container}>
                        <div className={styles.filesuploader__addimage}>
                            <p className={styles.filesuploader__text}>
                                {selectedImages && selectedImages.length > 0
                                    ? 'Заменить на другое изображение'
                                    : 'Загрузить горизонтальное изображение'}
                            </p>
                            <div
                                onClick={handlePick}
                                className={styles.filesuploader__loader}
                            >
                                <AddPinIcon />
                            </div>
                        </div>
                    </div>
                    <input
                        className={styles.addimage__input}
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleChange}
                        ref={filePicker}
                    />
                </div>
            ) : null}
        </div>
    );
};

interface AddEventsImageProps {
    selectedImages?: FileList | null;
    setSelectedImages?: React.Dispatch<SetStateAction<FileList | null>>;
    showImage?: string | null;
    showUpdateButton?: boolean;
}
