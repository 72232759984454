import cn from 'classnames';
import styles from './Tag.module.scss';

interface TagProps {
    type: 'format' | 'date';
    content: string;
}

export const Tag = ({ type, content }: TagProps) => {
    return (
        <div
            className={cn(styles.tag, {
                [styles.tag__date]: type === 'date',
                [styles.tag__format]: type === 'format',
                [styles.tag__blue]: type === 'format' && content === 'Онлайн',
                [styles.tag__red]: type === 'format' && content === 'Офлайн',
            })}
        >
            <span>{content}</span>
        </div>
    );
};
