import axios from 'axios';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../UI/Button/Button';
import { Loader } from '../Loader/Loader';
import styles from './FileDownloader.module.scss';

export const enum FileType {
    PDF = 'pdf',
    EXCEL = 'excel',
}

interface FileDownloaderProps {
    url: string;
    name: string;
    className?: string;
    fileType?: FileType;
    downloadName?: string;
    isDisabled?: boolean;
}

export const FileDownloader = ({
    url,
    className,
    name,
    fileType = FileType.PDF,
    downloadName,
    isDisabled,
}: FileDownloaderProps) => {
    const CVRef = useRef<HTMLAnchorElement>(null);

    const [downloadUrl, setDownloadUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const downloadCV = () => {
        CVRef?.current?.click();
    };

    const downloadFile = () => {
        setIsLoading(true);
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // указываем, что ожидаем получить файловый тип
        })
            .then((response) => {
                //формирую url из файла
                const url = URL.createObjectURL(
                    new Blob([response.data], {
                        type:
                            fileType === FileType.EXCEL
                                ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                : 'application/pdf',
                    }),
                );

                setDownloadUrl(url);
            })
            .then(() => {
                setTimeout(() => {
                    downloadCV();
                    setIsLoading(false);
                }, 1000);
            })
            .catch(async (error) => {
                const errorText = JSON.parse(await error.response?.data.text());
                setIsLoading(false);
                if (errorText.is_open) {
                    toast.error('Резюме кандидата не опубликовано');
                } else {
                    toast.error('Ошибка сервера');
                }
            });
    };

    return (
        <div className={styles.downloader}>
            <Button
                className={styles.downloader__button}
                onClick={downloadFile}
                isDisabled={isDisabled}
            >
                {name}
            </Button>
            <a
                ref={CVRef}
                href={downloadUrl}
                download={downloadName || 'CV_book.pdf'}
                className={styles.downloader__link}
            >
                a
            </a>
            <div className={styles.loader}>{isLoading && <Loader />}</div>
        </div>
    );
};
