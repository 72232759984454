import { LocationMarkIcon } from '../../assets/icons';
import styles from './LocationMark.module.scss';
import cn from 'classnames';

interface LocationMarkProps {
    text: string;
}

export const LocationMark = ({ text }: LocationMarkProps) => {
    return (
        <div className={styles.location}>
            <LocationMarkIcon />
            <span className={cn(styles.organization)}>{text}</span>
        </div>
    );
};
