import { useEffect, useState } from 'react';
import Button from '../../../UI/Button/Button';
import { Input } from '../../../UI/Input/Input';
import { MultiCheckbox } from '../../../UI/MultiCheckbox/MultiCheckbox';
import { RangeInput } from '../../../UI/RangeInput/RangeInput';
import { Loader } from '../../../components/Loader/Loader';
import { EMPLOYMENT_FORM, EXPERIANCE_CONSTANT } from '../../../constants';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    deleteFilter,
    getStaffVacancyList,
    updateCompany,
    updateEmploymentForm,
    updateRequiredExperience,
    updateSalary,
    updateSalaryFrom,
    updateSalaryTo,
    updateStaffVacancyListPage,
} from '../../../store/reducers/StaffVacancySlice';
import { VacancyModal } from '../../CompanyPages/VacancyList/VacancyList';
import Pagination from '../../StaffPages/SupportList/Pagination';
import { StaffVacancyListItem } from './VacancyItem';
import styles from './VacancyItem/index.module.scss';

export const StaffVacancyList = () => {
    const dispatch = useAppDispatch();

    const [modal, setModal] = useState<number | undefined>();

    const {
        vacancyList,
        vacancyListIsLoading,
        company,
        employment_form,
        required_experience,
        salary,
    } = useAppSelector((state) => state.staffVacancyReducer);

    const refetchStaffVacancyList = () => {
        dispatch(
            getStaffVacancyList({
                page: 1,
                size: 10,
                status: 'OP',
                company,
                employment_form,
                required_experience,
                salary_from: salary[0],
                salary_to: salary[1],
            }),
        );
    };

    useEffect(() => {
        refetchStaffVacancyList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.companyVacancyList}>
                {modal ? (
                    <VacancyModal
                        modal={modal}
                        setModal={() => setModal(undefined)}
                        refetch={() => refetchStaffVacancyList()}
                    />
                ) : null}
                <div className={styles.companyVacancyListGrid}>
                    {vacancyListIsLoading ? <Loader /> : null}

                    {!vacancyListIsLoading &&
                    vacancyList?.objects.length === 0 ? (
                        <span className={styles.notificationText}>
                            Вакансий пока нет!
                        </span>
                    ) : null}

                    {!vacancyListIsLoading &&
                        vacancyList &&
                        vacancyList.objects.map((item) => (
                            <StaffVacancyListItem
                                key={`student-vacancy-${item.id}`}
                                vacancy={item}
                                setModal={setModal}
                            />
                        ))}

                    <Pagination
                        list={vacancyList}
                        handlePagination={(p) => {
                            dispatch(updateStaffVacancyListPage(p));
                        }}
                        isLoading={vacancyListIsLoading}
                    />
                </div>
                <FiltersWidget />
            </div>
        </>
    );
};

const FiltersWidget = () => {
    const dispatch = useAppDispatch();

    const { company, required_experience, employment_form, salary } =
        useAppSelector((state) => state.staffVacancyReducer);

    const applyFilters = () => {
        dispatch(
            getStaffVacancyList({
                page: 1,
                size: 10,
                status: 'OP',
                company,
                employment_form,
                required_experience,
                salary_from: salary[0],
                salary_to: salary[1],
            }),
        );
    };

    return (
        <div className={styles.filter}>
            <CompanyField />
            <EmploymentFromMultiCheckbox />
            <ExperienceMultiCheckbox />
            <SalaryRangeInput />
            <Button className={styles.button__accept} onClick={applyFilters}>
                Применить
            </Button>
            <Button
                className={styles.button__reset}
                onClick={() => {
                    dispatch(deleteFilter());
                    getStaffVacancyList({
                        page: 1,
                        size: 20,
                        status: 'OP',
                    });
                }}
            >
                Сбросить фильтры
            </Button>
        </div>
    );
};

const CompanyField = () => {
    const dispatch = useAppDispatch();
    const changeOption = (value: string) => {
        dispatch(updateCompany(value));
    };
    const { company } = useAppSelector((state) => state.staffVacancyReducer);

    return (
        <Input
            placeholder={'Поиск компании'}
            onChange={(e) => changeOption(e.target.value)}
            value={company}
        />
    );
};

const EmploymentFromMultiCheckbox = () => {
    const dispatch = useAppDispatch();
    const changeOptions = (value: string) => {
        dispatch(updateEmploymentForm(value));
    };
    const currentCheckedValues = useAppSelector(
        (state) => state.staffVacancyReducer.employment_form,
    );

    return (
        <MultiCheckbox
            title={'Занятость'}
            options={EMPLOYMENT_FORM}
            changeOptions={changeOptions}
            currentValues={currentCheckedValues || []}
        />
    );
};

const ExperienceMultiCheckbox = () => {
    const dispatch = useAppDispatch();
    const changeOptions = (value: string) => {
        dispatch(updateRequiredExperience(value));
    };
    const currentCheckedValues = useAppSelector(
        (state) => state.staffVacancyReducer.required_experience,
    );
    return (
        <MultiCheckbox
            title={'Опыт работы'}
            options={EXPERIANCE_CONSTANT}
            changeOptions={changeOptions}
            currentValues={currentCheckedValues || []}
        />
    );
};

const SalaryRangeInput = () => {
    const dispatch = useAppDispatch();
    const changeRange = (range: [number, number]) => {
        dispatch(updateSalary(range));
    };
    const salaryRange = useAppSelector(
        (state) => state.staffVacancyReducer.salary,
    );

    const startValue = salaryRange[0];
    const endValue = salaryRange[1];

    const changeStart = (value: string) => {
        dispatch(updateSalaryFrom(Number(value)));
    };
    const changeEnd = (value: string) => {
        dispatch(updateSalaryTo(Number(value)));
    };
    return (
        <RangeInput
            title={'Заработная плата'}
            min={10000}
            max={100000}
            changeRange={changeRange}
            startValue={startValue}
            endValue={endValue}
            changeStart={changeStart}
            changeEnd={changeEnd}
        />
    );
};
