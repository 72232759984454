import { LocationMarkIcon } from '../../assets/icons';
import styles from './Location.module.scss';

interface LocationProps {
    locationName?: string;
}

export const Location = ({ locationName }: LocationProps) => {
    return (
        <div className={styles.location}>
            <LocationMarkIcon />
            <span className={styles.location__text}>{locationName} </span>
        </div>
    );
};
