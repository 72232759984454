import { TgIcon, VkIcon } from '../../assets/icons';
import { Logo, LogoTypes } from '../../components/Logo/Logo';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import styles from './Footer.module.scss';

export const Footer = () => {
    const windowWidth = useWindowWidth();

    return (
        <footer className={styles.footer}>
            <Logo logoType={LogoTypes.FOOTER} />
            <div className={styles.footer__wrapper}>
                <div className={styles.footer__adresses}>
                    <h4 className={styles.footer__header}>
                        Центр карьеры Юридического факультета МГУ
                    </h4>

                    <div className={styles.footer__adress}>
                        Москва, Ленинские горы, д. 1, строение 13 (4-й учебный
                        корпус МГУ), кабинет 659А
                    </div>
                </div>

                {windowWidth && windowWidth > 500 ? (
                    <div className={styles.footer__line}></div>
                ) : (
                    ''
                )}

                <div className={styles.footer__contacts}>
                    <a
                        href="tel:+74997060060"
                        target={'_blank'}
                        className={styles.footer__tel}
                        rel="noreferrer"
                    >
                        +7 (499) 706-00-60 доб. 153
                    </a>
                    <div className={styles.footer__mails}>
                        <a
                            href="mailto:career@law.msu.ru"
                            target={'_blank'}
                            className={styles.footer__mail}
                            rel="noreferrer"
                        >
                            career@law.msu.ru
                        </a>
                        {windowWidth && windowWidth > 920 ? (
                            <div className={styles.footer__separatorline}></div>
                        ) : (
                            ''
                        )}
                        <a
                            href="mailto:career.law.msu@gmail.com"
                            target={'_blank'}
                            className={styles.footer__mail}
                            rel="noreferrer"
                        >
                            career.law.msu@gmail.com
                        </a>
                    </div>
                    <div className={styles.footer__links}>
                        <a
                            className={styles.footer__link}
                            href="https://vk.com/career_law_msu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <VkIcon />
                        </a>
                        <a
                            className={styles.footer__link}
                            href="https://t.me/CareerLawMSU"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TgIcon />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
