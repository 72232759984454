import cn from 'classnames';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
    text: string;
    className?: string;
    onClick?: () => void;
    onChange?: () => void;
    isDisabled?: boolean;
    wrapperOnclick?: () => void;
    isChecked?: boolean;
    fieldClassName?: string;
    checkAreaClassName?: string;
    defaultChecked?: boolean;
    value?: boolean;
    name?: string;
}

export const Checkbox = ({
    text,
    className,
    fieldClassName,
    checkAreaClassName,
    onClick,
    onChange,
    isDisabled,
    wrapperOnclick,
    isChecked,
    defaultChecked,
    value,
    name,
}: CheckboxProps) => {
    return (
        <label
            onClick={() => {
                wrapperOnclick && wrapperOnclick();
            }}
            className={cn(styles.checkbox, className)}
        >
            <input
                disabled={isDisabled}
                className={cn(styles.checkbox__input, checkAreaClassName)}
                type={'checkbox'}
                defaultChecked={defaultChecked}
                onChange={onChange}
            />
            <p className={cn(styles.checkbox__text, fieldClassName)}>{text}</p>
        </label>
    );
};

export const CheckboxResume = ({
    text,
    className,
    fieldClassName,
    checkAreaClassName,
    onChange,
    isDisabled,
    wrapperOnclick,
    value,
}: CheckboxProps) => {
    return (
        <label
            onClick={() => {
                wrapperOnclick && wrapperOnclick();
            }}
            className={cn(styles.checkbox, className)}
        >
            <input
                disabled={isDisabled}
                className={cn(styles.checkbox__input, checkAreaClassName)}
                type="checkbox"
                checked={value}
                onChange={onChange}
            />
            <p className={cn(styles.checkbox__text, fieldClassName)}>{text}</p>
        </label>
    );
};

export const RegistrationCheckbox = ({
    text,
    className,
    fieldClassName,
    checkAreaClassName,
    onClick,
    onChange,
    isDisabled,
    wrapperOnclick,
    isChecked,
    defaultChecked,
    value,
    name,
}: CheckboxProps) => {
    return (
        <div className={cn(styles.checkbox, className)}>
            <input
                disabled={isDisabled}
                className={cn(styles.checkbox__input, checkAreaClassName)}
                type={'checkbox'}
                defaultChecked={defaultChecked}
                onChange={onChange}
            />
            <a
                target="_blank"
                href="https://www.msu.ru/upload/pdf/2017/pr2017_1030_pr4.pdf"
                className={cn(styles.checkbox__text__link, fieldClassName)}
                rel="noreferrer"
            >
                {text}
            </a>
        </div>
    );
};
