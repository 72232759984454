import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IEventMemberCard } from '../../types/types';

export type EventMemberListType = {
    page_count: number;
    current_page: number;
    objects: IEventMemberCard[];
};
const initialState: EventState = {
    eventMemberList: undefined,
    page: 1,
    isLoading: false,
    error: null,
};

type EventState = {
    eventMemberList: EventMemberListType | undefined;
    page: number;
    isLoading: boolean;
    error: any;
};

export const getEventMemberList = createAsyncThunk(
    'event/getEventMemberList',
    async function name(
        { page = 1, size = 20, id }: { page: number; size: number; id: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get<EventMemberListType>(
                `${process.env.REACT_APP_BASE_URL}api/v1/event/${id}/member/`,
                {
                    params: {
                        page: page,
                        size: size,
                    },
                },
            );
            const data = response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        updateEventMemberPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEventMemberList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEventMemberList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.eventMemberList = action.payload;
        });
        builder.addCase(getEventMemberList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.eventMemberList = undefined;
        });
    },
});

export const { updateEventMemberPage } = eventSlice.actions;

export default eventSlice.reducer;
