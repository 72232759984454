import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type StatusType = 'NV' | 'AW' | 'CM';
export type SupportListItemType = {
    id: number;
    email: string;
    fio: string;
    organization_institution: string;
    text: string;
    staff: number;
    status: StatusType;
    created_at: string;
    updated_at: string;
};

export type SupportListType = {
    page_count: number;
    current_page: number;
    objects: SupportListItemType[];
};
const initialState: SupportState = {
    status: 'NV',

    page: 1,
    supportList: undefined,
    isLoading: false,
    error: null,
};

type SupportState = {
    supportList: SupportListType | undefined;
    status: StatusType;
    page: number;
    isLoading: boolean;
    error: any;
};

export const getSupportList = createAsyncThunk(
    'support/getSupportList',
    async function name(
        {
            page = 1,
            size = 20,
            status = 'NV',
        }: { page: number; size: number; status: StatusType },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get<SupportListType>(
                `${process.env.REACT_APP_BASE_URL}api/v1/appeal/`,
                {
                    params: {
                        page: page,
                        size: size,
                        status: status,
                    },
                },
            );
            const data = response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
export const getStaffSupportList = createAsyncThunk(
    'support/getStaffSupportList',
    async function name(
        {
            page = 1,
            size = 20,
            status = 'NV',
        }: { page: number; size: number; status: StatusType },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get<SupportListType>(
                `${process.env.REACT_APP_BASE_URL}api/v1/appeal/current_staff/`,
                {
                    params: {
                        page: page,
                        size: size,
                        status: status,
                    },
                },
            );
            const data = response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const updateSupportList = createAsyncThunk(
    'support/updateSupportList',
    async function name(
        support: {
            id: number;
            email: string;
            fio: string;
            organization_institution: string;
            text: string;
            staff: number;
            status: string;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}api/v1/appeal/${support.id}/`,
                {
                    email: support.email,
                    fio: support.fio,
                    organization_institution: support.organization_institution,
                    text: support.text,
                    staff: support.staff,
                    status: support.status,
                },
            );
            const data = response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const studentsSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        updateStatus(state, action: PayloadAction<StatusType>) {
            state.status = action.payload;
        },
        updatePage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSupportList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSupportList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.supportList = action.payload;
        });
        builder.addCase(getSupportList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getStaffSupportList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStaffSupportList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.supportList = action.payload;
        });
        builder.addCase(getStaffSupportList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(updateSupportList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateSupportList.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(updateSupportList.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export const { updateStatus, updatePage } = studentsSlice.actions;

export default studentsSlice.reducer;
