// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguageFilter_languages__header__VlH\\+c {\n  color: #000;\n  font: var(--font-s);\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 24px;\n}\n.LanguageFilter_languages__wrapper__35\\+V\\+ {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.LanguageFilter_dropdown__LavpZ {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  color: #000;\n}\n.LanguageFilter_dropdown__wrapper__xJKF9 {\n  font: var(--font-s);\n  font-size: 14px;\n}\n.LanguageFilter_dropdown__text__0hQNH {\n  font: var(--font-s);\n  font-size: 14px;\n}\n.LanguageFilter_dropdown__placeholder__8nWKo {\n  font: var(--font-s);\n  font-size: 14px;\n  padding-left: 21px;\n}", "",{"version":3,"sources":["webpack://./src/components/LanguageFilter/LanguageFilter.module.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAAR;AAEI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AADJ;AAEI;EACI,mBAAA;EACA,eAAA;AAAR;AAGI;EACI,mBAAA;EACA,eAAA;AADR;AAGI;EACI,mBAAA;EACA,eAAA;EACA,kBAAA;AADR","sourcesContent":[".languages {\n    &__header {\n        color: #000;\n        font: var(--font-s);\n        font-size: 14px;\n        font-weight: 600;\n        margin-bottom: 24px;\n    }\n    &__wrapper {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n    }\n}\n\n.dropdown {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    color: #000;\n    &__wrapper {\n        font: var(--font-s);\n        font-size: 14px;\n    }\n\n    &__text {\n        font: var(--font-s);\n        font-size: 14px;\n    }\n    &__placeholder {\n        font: var(--font-s);\n        font-size: 14px;\n        padding-left: 21px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languages__header": "LanguageFilter_languages__header__VlH+c",
	"languages__wrapper": "LanguageFilter_languages__wrapper__35+V+",
	"dropdown": "LanguageFilter_dropdown__LavpZ",
	"dropdown__wrapper": "LanguageFilter_dropdown__wrapper__xJKF9",
	"dropdown__text": "LanguageFilter_dropdown__text__0hQNH",
	"dropdown__placeholder": "LanguageFilter_dropdown__placeholder__8nWKo"
};
export default ___CSS_LOADER_EXPORT___;
