import { useEffect } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    LogoCompanyListItemType,
    getLogoCompanyList,
} from '../../../store/reducers/PartnerSlice';

import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import styles from './PartnerPage.module.scss';

const PartnerItem: React.FC<LogoCompanyListItemType> = ({ title, image }) => {
    return (
        <div className={styles.partnerItem}>
            <img src={image} alt={title} />
            <h3>{title}</h3>
        </div>
    );
};

export const PartnerPage = () => {
    const { logoCompanyList, logoCompanyListIsLoading } = useAppSelector(
        (state) => state.partnerReducer,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLogoCompanyList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageLayout>
            <div className={styles.partnerWrapper}>
                <PagePagination linkName={'Партнеры'} />
                <PageHeader title={'Партнеры'} />

                {logoCompanyListIsLoading ? <Loader /> : null}

                {!logoCompanyListIsLoading && logoCompanyList?.length === 0 ? (
                    <span className={styles.notificationText}>
                        Партнеров пока нет!
                    </span>
                ) : null}

                <div className={styles.partnerGrid}>
                    {!logoCompanyListIsLoading &&
                        logoCompanyList?.map((item) => (
                            <PartnerItem
                                key={`partner_logo_${item.id}`}
                                {...item}
                            />
                        ))}
                </div>
            </div>
        </PageLayout>
    );
};
