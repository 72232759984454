import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export type CompanyVacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    company_title: string;
    reaction_count: number;
};

export type CompanyVacancyListType = {
    page_count: number;
    current_page: number;
    objects: CompanyVacancyListItemType[];
};

const initialState: CompanyVacancyState = {
    companyVacancyList: undefined,
    companyVacancyListPage: 1,
    companyVacancyListIsLoading: false,
    companyVacancyListError: null,

    companyVacancyRetrieve: undefined,
    companyVacancyRetrieveIsLoading: false,
    companyVacancyRetrieveError: null,
};

interface CompanyVacancyState {
    companyVacancyList: CompanyVacancyListType | undefined;
    companyVacancyListPage: number;
    companyVacancyListIsLoading: boolean;
    companyVacancyListError: any;

    companyVacancyRetrieve: CompanyVacancyListItemType | undefined;
    companyVacancyRetrieveIsLoading: boolean;
    companyVacancyRetrieveError: any;
}

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getCompanyVacancyList = createAsyncThunk(
    'company/getCompanyVacancyList',
    async function name(
        {
            page,
            size,
            status,
            id,
        }: { page: number; size: number; status: 'OP' | 'CL'; id: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/vacancy/`,
                { params: { page, size, status } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

//асинхронная позгрузка инфы о пользователе по айди
export const updateCompanyVacancy = createAsyncThunk(
    'company/updateCompanyVacancy',
    async function (
        { id, status }: { id: number; status: 'OP' | 'CL' },
        { rejectWithValue },
    ) {
        try {
            await axios
                .put(`${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`, {
                    status: status,
                })
                .catch((e: AxiosError<{ subscription: string }>) => {
                    if (e.response?.data.subscription) {
                        toast.error(
                            <a href="mailto:career.law.msu@gmail.com">
                                Оплатите подписку для публикации новых вакансий:
                                career.law.msu@gmail.com»
                            </a>,
                        );
                    }
                });
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCompanyVacancyRetrieve = createAsyncThunk(
    'company/getCompanyVacancyRetrieve',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const companyVacancySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateCompanyVacancyListPage(state, action: PayloadAction<number>) {
            state.companyVacancyListPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        // фетч неподтвержденных админом студентов
        builder.addCase(getCompanyVacancyList.pending, (state) => {
            state.companyVacancyListIsLoading = true;
        });
        builder.addCase(getCompanyVacancyList.fulfilled, (state, action) => {
            state.companyVacancyListIsLoading = false;
            state.companyVacancyList = action.payload;
        });
        builder.addCase(getCompanyVacancyList.rejected, (state, action) => {
            state.companyVacancyListIsLoading = false;
            state.companyVacancyListError = action.payload;
        });

        builder.addCase(getCompanyVacancyRetrieve.pending, (state) => {
            state.companyVacancyRetrieveIsLoading = true;
        });
        builder.addCase(
            getCompanyVacancyRetrieve.fulfilled,
            (state, action) => {
                state.companyVacancyRetrieveIsLoading = false;
                state.companyVacancyRetrieve = action.payload;
            },
        );
        builder.addCase(getCompanyVacancyRetrieve.rejected, (state, action) => {
            state.companyVacancyRetrieveIsLoading = false;
            state.companyVacancyRetrieveError = action.payload;
        });
    },
});

export const { updateCompanyVacancyListPage } = companyVacancySlice.actions;

export default companyVacancySlice.reducer;
