// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PagePagination_pagetitle__fozvv {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  font: var(--font-s);\n  color: var(--pale-color);\n  margin-bottom: 24px;\n}\n\n.PagePagination_link__element__xgDGS {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}", "",{"version":3,"sources":["webpack://./src/components/PagePagination/PagePagination.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,SAAA;EAEA,mBAAA;EACA,wBAAA;EAEA,mBAAA;AADD;;AAKC;EACC,uBAAA;EACA,gBAAA;EACA,mBAAA;AAFF","sourcesContent":[".pagetitle {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 20px;\n\n\tfont: var(--font-s);\n\tcolor: var(--pale-color);\n\t\n\tmargin-bottom: 24px;\n}\n\n.link{\n\t&__element{\n\t\ttext-overflow: ellipsis;\n\t\toverflow: hidden;\n\t\twhite-space: nowrap;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagetitle": "PagePagination_pagetitle__fozvv",
	"link__element": "PagePagination_link__element__xgDGS"
};
export default ___CSS_LOADER_EXPORT___;
