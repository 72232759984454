import { useEffect } from 'react';
import Button, { ButtonTypes } from '../../../../UI/Button/Button';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import styles from './EditLanguages.module.scss';
import { CrossButton } from '../../../../assets/icons';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { fetchLanguages } from '../../../../store/reducers/LanguagesSlice';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';

const languageOptions = [
    { value: 'Русский', label: 'Русский' },
    { value: 'Английский', label: 'Английский' },
    { value: 'Французский', label: 'Французский' },
    { value: 'Немецкий', label: 'Немецкий' },
    { value: 'Испанский', label: 'Испанский' },
    { value: 'Китайский', label: 'Китайский' },
    { value: 'Итальянский', label: 'Итальянский' },
    { value: 'Армянский', label: 'Армянский' },
    { value: 'Хинди', label: 'Хинди' },
    { value: 'Арабский', label: 'Арабский' },
];

const levelOptions = [
    { value: 'A1', label: 'A1 (начальный)' },
    { value: 'A2', label: 'А2 (ниже среднего)' },
    { value: 'B1', label: 'В1 (средний)' },
    { value: 'B2', label: 'B2 (выше среднего)' },
    { value: 'C1', label: 'C1 (продвинутый)' },
    {
        value: 'C2',
        label: 'C2 (профессиональный уровень владения)',
    },
];

export const EditLanguages = () => {
    const dispatch = useAppDispatch();
    const id = useAppSelector((state) => state.authReducer.id);
    const validationschema = Yup.object().shape({
        languages: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().required('Поле обязательно'),
                level: Yup.string().required('Поле обязательно'),
            }),
        ),
    });
    useEffect(() => {
        id && dispatch(fetchLanguages(id));
    }, [dispatch, id]);

    const languages = useAppSelector(
        (state) => state.languagesReducer.languages,
    );

    return (
        <Formik
            validationSchema={validationschema}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
                languages: languages ? languages : [],
            }}
            onSubmit={(values) => {}}
        >
            {(formik) => (
                <Form
                    className={styles.EditLanguage}
                    onSubmit={formik.handleSubmit}
                >
                    <EditWrapper
                        className={styles.EditLanguages__wrapper}
                        title={'Иностранные языки'}
                        isValid={formik.isValid}
                        saveOnclick={async () => {
                            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/language/many/`;
                            try {
                                await axios.post(url, formik.values);
                                toast.success(
                                    'Данные о языках успешно изменены',
                                );
                            } catch {
                                toast.error(
                                    'При изменении языков произошла ошибка',
                                );
                            }
                        }}
                    >
                        <FieldArray
                            name="languages"
                            render={(arrayHelpers) => {
                                return (
                                    <>
                                        <Button
                                            onClick={() => {
                                                arrayHelpers.insert(
                                                    formik.values.languages
                                                        .length + 1,
                                                    {
                                                        title: '',
                                                        level: '',
                                                    },
                                                );
                                            }}
                                            type={ButtonTypes.AddButton}
                                        ></Button>
                                        <div className={styles.languages}>
                                            {formik.values.languages ? (
                                                formik.values.languages &&
                                                formik.values.languages.map(
                                                    (language, index) => (
                                                        <LanguageForm
                                                            key={index}
                                                            languageOptions={
                                                                languageOptions
                                                            }
                                                            levelOptions={
                                                                levelOptions
                                                            }
                                                            language={language}
                                                            index={index}
                                                            arrayHelpers={
                                                                arrayHelpers
                                                            }
                                                            formik={formik}
                                                            removeLanguages={() =>
                                                                arrayHelpers.remove(
                                                                    index,
                                                                )
                                                            }
                                                        />
                                                    ),
                                                )
                                            ) : (
                                                <p>Здесь еще нет информации</p>
                                            )}
                                        </div>
                                    </>
                                );
                            }}
                        ></FieldArray>
                    </EditWrapper>
                </Form>
            )}
        </Formik>
    );
};

const LanguageForm = ({
    index,
    removeLanguages,
    languageOptions,
}: LanguageFormProps) => {
    return (
        <div className={styles.language}>
            <div className={styles.EditLanguage}>
                <h2 className={styles.EditLanguage__header}>Язык</h2>
                <Field
                    className={styles.select}
                    as="select"
                    name={`languages.${index}.title`}
                >
                    <option value={''} selected disabled>
                        Выберите язык
                    </option>
                    {languageOptions.map((language) => (
                        <option value={language.value}>{language.label}</option>
                    ))}
                </Field>
            </div>

            <div className={styles.EditLanguage}>
                <h2 className={styles.EditLanguage__header}>Уровень</h2>
                <Field
                    className={styles.select}
                    as="select"
                    name={`languages.${index}.level`}
                >
                    <option value={''} selected disabled>
                        Выберите уровень
                    </option>
                    {levelOptions.map((language) => (
                        <option value={language.value}>{language.label}</option>
                    ))}
                </Field>
            </div>
            <CrossButton
                onClick={removeLanguages}
                className={styles.EditLanguage__crossbutton}
            />
        </div>
    );
};

interface LanguageFormProps {
    languageOptions: Option[];
    levelOptions: Option[];
    removeLanguages: () => void;
    index?: number;
    arrayHelpers: any;
    formik: any;
    language: any;
}

interface Option {
    value: string;
    label: string;
}
