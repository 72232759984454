/* eslint-disable @typescript-eslint/no-unused-vars */
import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { convertMonthsToYears } from '../../helpers/convertMonthsToYears';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useGetAge } from '../../hooks/useGetAge';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import {
    getStudentsEducation,
    getStudentsExperience,
    getStudentsLanguages,
    getStudentsResume,
    getStudentsSocialActivities,
    studentsRetrieveSlice,
} from '../../store/reducers/StudentsRetrieveSlice';
import { Role } from '../../types/types';
import { FileDownloader } from '../FileDownloader/FileDownloader';
import { Loader } from '../Loader/Loader';
import { CabinetBanner } from '../ResumeBanner/ResumeBanner';
import styles from './StudentsRetrieve.module.scss';

export const StudentsRetrieve = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(
        (state) => state.studentsRetrieveReducer.isLoading,
    );
    useEffect(() => {
        dispatch(studentsRetrieveSlice.actions.clearCandidate());
        if (id) {
            dispatch(getStudentsResume(Number(id)));
            dispatch(getStudentsSocialActivities(Number(id)));
            dispatch(getStudentsEducation(Number(id)));
            dispatch(getStudentsExperience(Number(id)));
            dispatch(getStudentsLanguages(Number(id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className={styles.CabinetPage}>
            <div className={styles.CabinetPage__wrapper}>
                <button
                    className={styles.backButton}
                    onClick={() => window.history.go(-1)}
                >
                    Назад
                </button>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <ResumeHeader />
                        <Banners />
                    </>
                )}
            </div>
        </div>
    );
};

const Banners = () => {
    const StudentPageBanners = [
        {
            id: 1,
            title: 'О себе',
            link: PATH_DASHBOARD.student.resume.edit.about,
            children: <AboutInformation />,
        },
        {
            id: 2,
            title: 'Образование',
            link: PATH_DASHBOARD.student.resume.edit.education,
            children: <EducationsInformation />,
        },
        {
            id: 3,
            title: 'Иностранные языки',
            link: PATH_DASHBOARD.student.resume.edit.languages,
            children: <LanguagesInformation />,
        },
        {
            id: 4,
            title: 'Опыт работы ',
            link: PATH_DASHBOARD.student.resume.edit.experiance,
            children: <Experiences />,
        },
        {
            id: 5,
            title: 'Общественная деятельность ',
            link: PATH_DASHBOARD.student.resume.edit.socialActivities,
            children: <ActivitiesInformation />,
        },
        {
            id: 6,
            title: 'Ключевые навыки ',
            link: PATH_DASHBOARD.student.resume.edit.skills,
            children: <SkillsInformation />,
        },
    ];
    return (
        <div className={styles.CabinetPage__banners}>
            {StudentPageBanners.map((banner) => (
                <CabinetBanner
                    key={banner.id}
                    title={banner.title}
                    children={banner.children}
                />
            ))}
        </div>
    );
};

//о себе
const AboutInformation = () => {
    const candidate = useAppSelector(
        (state) => state.studentsRetrieveReducer.candidate,
    );

    const isNotEmptyCandidate = useMemo(() => {
        return (
            candidate?.city ||
            candidate?.birthday ||
            candidate?.salary ||
            candidate?.position ||
            candidate?.citizenship
        );
    }, [candidate]);

    const correctFormDate =
        candidate?.birthday &&
        candidate?.birthday.split('-').reverse().join('.');
    const employments: any = {
        FE: 'Полная занятость',
        PE: 'Частичная занятость ',
        RW: 'Удаленная работа',
        IS: 'Стажировка',
    };
    const correctFormEmployments = candidate?.employment_form
        ? candidate?.employment_form
              .map((employment: string) => employments[employment])
              .join(', ')
        : 'Не задано';
    const aboutData = [
        {
            id: 1,
            title: 'Город проживания:',
            content: candidate?.city ? candidate?.city : 'Не задано',
            className: styles.about__element_city,
        },
        {
            id: 2,
            title: 'Дата рождения:',
            content: correctFormDate ? correctFormDate : 'Не задано',
            className: styles.about__element_birthday,
        },
        {
            id: 3,
            title: 'Желаемая должность:',
            content: candidate?.position ? candidate?.position : 'Не задано',
            className: styles.about__element_position,
        },
        {
            id: 4,
            title: 'Зарплатные ожидания:',
            content: candidate?.salary ? candidate?.salary : 'Не задано',
            className: styles.about__element_salary,
        },
        {
            id: 5,
            title: 'Гражданство:',
            content: candidate?.citizenship
                ? candidate?.citizenship
                : 'Не задано',
            className: styles.about__element_citizenship,
        },
        {
            id: 6,
            title: 'Форма занятости:',
            content: correctFormEmployments,
            className: styles.about__element_employment,
        },
    ];
    return (
        <div className={isNotEmptyCandidate ? styles.about : styles.educations}>
            {isNotEmptyCandidate &&
                aboutData.map((aboutElement) => (
                    <div
                        key={aboutElement.id}
                        className={cn(
                            styles.about__element,
                            aboutElement.className,
                        )}
                    >
                        <h3 className={styles.about__header}>
                            {aboutElement.title}
                        </h3>
                        <span className={styles.about__content}>
                            {aboutElement.content
                                ? aboutElement.content
                                : 'Не задано'}
                        </span>
                    </div>
                ))}
            {!isNotEmptyCandidate ? 'Здесь еще нет информации' : null}
        </div>
    );
};

//образования
const EducationsInformation = () => {
    const educations = useAppSelector(
        (state) => state.studentsRetrieveReducer.educationData,
    );

    return (
        <div className={styles.educations}>
            {educations && educations.length
                ? educations?.map((education) => {
                      return (
                          <Education
                              key={education.id}
                              college={education.college}
                              specialization={education.specialization}
                              faculty={education.faculty}
                              grade={education.grade}
                              end={education.end}
                              average_mark={education.average_mark}
                          />
                      );
                  })
                : 'Здесь еще нет информации'}
        </div>
    );
};

const Education = ({
    college,
    specialization,
    faculty,
    grade,
    end,
    average_mark,
}: EducationProps) => {
    const educationData = [
        {
            id: 1,
            title: 'ВУЗ',
            content: college,
            className: styles.education__college,
        },
        {
            id: 2,
            title: 'Специальность',
            content: specialization,
            className: styles.education__specialization,
        },
        {
            id: 3,
            title: 'Факультет',
            content: faculty,
            className: styles.education__faculty,
        },
        {
            id: 4,
            title: 'Уровень обучения',
            content:
                grade === 'BH'
                    ? 'Бакалавриат'
                    : grade === 'MG'
                    ? 'Магистратура'
                    : 'Специалитет',
            className: styles.education__grade,
        },
        {
            id: 5,
            title: 'Год окончания',
            content: end,
            className: styles.education__grade,
        },
        {
            id: 6,
            title: 'Средний бал',
            content: average_mark,
            className: styles.education__average,
        },
    ];
    return (
        <div className={styles.education}>
            {educationData.map((education) => (
                <div
                    key={education.id}
                    className={cn(
                        styles.education__element,
                        education.className,
                    )}
                >
                    <h3 className={styles.education__header}>
                        {education.title}
                    </h3>
                    <span className={styles.education__content}>
                        {education.content}
                    </span>
                </div>
            ))}
        </div>
    );
};

interface EducationProps {
    college: string;
    specialization: string;
    faculty: string;
    grade: string;
    end: string;
    average_mark: number;
}

//опыт работы
const Experiences = () => {
    const isNoExperience = useAppSelector(
        (state) => state.studentsRetrieveReducer.candidate?.is_no_experience,
    );

    const experiences = useAppSelector(
        (state) => state.studentsRetrieveReducer.experiances,
    );

    return (
        <div className={styles.experiances}>
            {isNoExperience || experiences?.length === 0
                ? 'Еще нет опыта работы'
                : experiences &&
                  experiences?.length > 0 &&
                  experiences?.map((exp) => (
                      <Exp
                          key={exp.id}
                          start={exp.start}
                          end={exp.end}
                          site={exp.site}
                          company_title={exp.company_title}
                          position={exp.position}
                          description={exp.description}
                          by_current_time={exp.by_current_time}
                      />
                  ))}
            {}
        </div>
    );
};

const months: any = {
    '01': 'Январь',
    '02': 'Февраль',
    '03': 'Март',
    '04': 'Апрель',
    '05': 'Май',
    '06': 'Июнь',
    '07': 'Июль',
    '08': 'Август',
    '09': 'Сентябрь',
    '10': 'Октябрь',
    '11': 'Ноябрь',
    '12': 'Декабрь',
};

const Exp = ({
    start,
    end,
    site,
    company_title,
    position,
    description,
    by_current_time,
}: ExpProps) => {
    const startMonthIndex = start.split('-')[1];
    const startMonth = months[startMonthIndex];
    const startYear = start.split('-')[0];
    //строка в формате "февраль 2023"
    const startDate = `${startMonth} ${startYear}`;

    const endMonthIndex = end && end.split('-')[1];
    const endMonth = months[endMonthIndex];
    const endYear = end && end.split('-')[0];
    const endDate = `${endMonth} ${endYear}`;

    const workingDate = Math.ceil(
        (Number(endYear) - Number(startYear)) * 12 +
            (Number(endMonthIndex) - Number(startMonthIndex)),
    );

    return (
        <div className={styles.experiance}>
            <div className={styles.experiance__date}>
                <div className={styles.experiance__start}>
                    <h3 className={styles.experiance__header}>Начало</h3>
                    <div className={styles.experiance__month}>
                        <span className={styles.experiance__container}>
                            {startDate}
                        </span>
                    </div>
                </div>
                <div className={styles.experiance__end}>
                    <h3 className={styles.experiance__header}>Конец</h3>
                    <div className={styles.experiance__month}>
                        <span className={styles.experiance__container}>
                            {by_current_time ? 'По настоящее время' : endDate}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.experiance__content}>
                <div className={styles.position}>
                    <h3 className={styles.position__header}>{position}</h3>
                    <span className={styles.position__duration}>
                        {workingDate > 0 ? (
                            <>{convertMonthsToYears(workingDate)} </>
                        ) : (
                            ''
                        )}
                    </span>
                </div>
                <div className={styles.company}>
                    <h3 className={styles.company__title}>{company_title}</h3>
                    <span className={styles.company__site}>{site && site}</span>
                </div>
                <div className={styles.achievements}>
                    <h3 className={styles.achievements__header}>
                        Обязанности и достижения
                    </h3>
                    <span className={styles.achievements__text}>
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

interface ExpProps {
    start: string;
    end: string;
    company_title: string;
    site: string;
    position: string;
    description: string;
    by_current_time: boolean;
}

//общественная деятельность
const ActivitiesInformation = () => {
    const socialActivities = useAppSelector(
        (state) => state.studentsRetrieveReducer.socialActivities,
    );
    const isNoPublicActivity = useAppSelector(
        (state) =>
            state.studentsRetrieveReducer.candidate?.is_no_public_activity,
    );
    return (
        <div className={styles.experiances}>
            {isNoPublicActivity
                ? 'Еще нет опыта общественной деятельности'
                : socialActivities && socialActivities.length > 0
                ? socialActivities?.map((activity, index) => (
                      <Activity
                          key={index}
                          start={activity.start}
                          end={activity.end}
                          company_title={activity.organization}
                          site={activity.site}
                          format={activity.format}
                          description={activity.description}
                      />
                  ))
                : 'Здесь еще нет информации'}
        </div>
    );
};

const Activity = ({
    start,
    end,
    site,
    company_title,
    description,
    format,
}: ActivityProps) => {
    const startMonthIndex = start.split('-')[1];
    const startMonth = months[startMonthIndex];
    const startYear = start.split('-')[0];
    const startDate = `${startMonth} ${startYear}`;

    const endMonthIndex = end.split('-')[1];
    const endMonth = months[endMonthIndex];
    const endYear = end.split('-')[0];
    const endDate = `${endMonth} ${endYear}`;
    const workingDate = Math.ceil(
        (Number(endYear) - Number(startYear)) * 12 +
            (Number(endMonthIndex) - Number(startMonthIndex)),
    );

    return (
        <div className={styles.experiance}>
            <div className={styles.experiance__date}>
                <div className={styles.experiance__start}>
                    <h3 className={styles.experiance__header}>Начало</h3>
                    <div className={styles.experiance__month}>
                        <span className={styles.experiance__container}>
                            {startDate}
                        </span>
                    </div>
                </div>
                <div className={styles.experiance__end}>
                    <h3 className={styles.experiance__header}>Конец</h3>
                    <div className={styles.experiance__month}>
                        <span className={styles.experiance__container}>
                            {endDate}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.experiance__content}>
                <div className={styles.position}>
                    <h3 className={styles.position__header}>{format}</h3>
                    <span className={styles.position__duration}>
                        {workingDate > 0
                            ? convertMonthsToYears(workingDate)
                            : ''}
                    </span>
                </div>
                <div className={styles.company}>
                    <h3 className={styles.company__title}>{company_title}</h3>
                    <span className={styles.company__site}>{site && site}</span>
                </div>
                <div className={styles.achievements}>
                    <h3 className={styles.achievements__header}>
                        Обязанности и достижения
                    </h3>
                    <span className={styles.achievements__text}>
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

interface ActivityProps {
    start: string;
    end: string;
    company_title: string;
    site: string;
    format: string;
    description: string;
}

//языки
const LanguagesInformation = () => {
    const languages = useAppSelector(
        (state) => state.studentsRetrieveReducer.languages,
    );
    return (
        <div className={styles.languages}>
            {languages && languages?.length > 0
                ? languages?.map((language) => (
                      <Language
                          key={language.id}
                          title={language.title}
                          level={language.level}
                      />
                  ))
                : 'Здесь еще нет информации'}
        </div>
    );
};

const Language = ({ title, level }: LanguageProps) => {
    return (
        <div className={styles.languages__element}>
            <h3 className={styles.language__header}>{title}</h3>
            <span className={styles.language__level}>{level}</span>
        </div>
    );
};

interface LanguageProps {
    title: string;
    level: string;
}

//ключевые навыки
const SkillsInformation = () => {
    const skills = useAppSelector(
        (state) => state.studentsRetrieveReducer.candidate?.skills,
    );
    return (
        <div className={styles.skills}>
            {skills && skills.length > 0 ? (
                <div className={styles.skills__text}>
                    {skills &&
                        skills.split('\n').map((paragraph, index) => (
                            <div key={index}>
                                <div>{paragraph}</div>
                                <br />
                            </div>
                        ))}
                </div>
            ) : (
                <div>Здесь еще нет информации</div>
            )}
        </div>
    );
};

//шапка резюме с аватаром
const ResumeHeader = () => {
    const { candidate } = useAppSelector(
        (state) => state.studentsRetrieveReducer,
    );

    const initials = `${candidate?.second_name || ''} ${
        candidate?.first_name || ''
    } ${candidate?.last_name || ''}`;

    const age = useGetAge(candidate?.birthday);

    return (
        <div className={styles.header}>
            {candidate?.photo ? (
                <img src={candidate?.photo} alt="" className={styles.photo} />
            ) : null}
            <div className={styles.header__content}>
                <h3 className={styles.header__name}>
                    {`${candidate?.second_name} ${candidate?.first_name} ${
                        candidate?.last_name ? candidate.last_name : ''
                    }`}
                </h3>
                <span className={styles.header__role}>
                    {candidate?.birthday ? age : 'возраст не указан'}
                </span>
                <Contacts />
            </div>
        </div>
    );
};

const Contacts = () => {
    const candidate = useAppSelector(
        (state) => state.studentsRetrieveReducer.candidate,
    );
    const { role } = useAppSelector((state) => state.authReducer);

    const initials = `${candidate?.second_name || ''} ${
        candidate?.first_name || ''
    } ${candidate?.last_name || ''}`;

    const disabledButton = useMemo(() => {
        if (role === Role.organization && candidate?.is_resume_fill) {
            return false;
        }
        if (role === Role.staff && candidate?.is_resume_fill) {
            return false;
        }
        return true;
    }, [candidate?.is_resume_fill, role]);

    return (
        <div className={styles.contacts}>
            <div className={styles.contacts__inputs}>
                {candidate?.contact_email ? (
                    <span>Email: {candidate?.contact_email}</span>
                ) : null}
                {candidate?.contact_phone ? (
                    <span>Телефон: {candidate?.contact_phone}</span>
                ) : null}
            </div>
            <FileDownloader
                isDisabled={disabledButton}
                name={'Сохранить в PDF'}
                className={styles.card__button}
                url={`${process.env.REACT_APP_BASE_URL}api/v1/candidate/${candidate?.id}/cv/`}
                downloadName={`CV_${initials.replaceAll(' ', '')}`}
            ></FileDownloader>
        </div>
    );
};
