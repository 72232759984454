import cn from 'classnames';
import { useState } from 'react';
import styles from './Input.module.scss';

export const Input = ({
    wrapperClassName,
    placeholder,
    inputHeader,
    fieldClassName,
    headerClassName,
    errorMessage,
    onChange,
    onBlur,
    value,
    name,
    helpMessage,
    icon,
    isValid,
}: InputProps) => {
    const [isHelpHovered, setIsHelpHovered] = useState(false);
    return (
        <div
            className={cn(
                styles.input,
                wrapperClassName ? [...wrapperClassName] : '',
            )}
        >
            {inputHeader || helpMessage ? (
                <div className={styles.input__header_container}>
                    <h3 className={cn(styles.input__header, headerClassName)}>
                        {inputHeader}
                    </h3>

                    {helpMessage && (
                        <div className={styles.help}>
                            <div
                                onMouseEnter={() => setIsHelpHovered(true)}
                                onMouseLeave={() => setIsHelpHovered(false)}
                                className={styles.input__header_help}
                            >
                                <span className={styles.help__text}>!</span>
                            </div>
                            <div
                                className={cn(styles.help__block, {
                                    [styles.help__block_active]: isHelpHovered,
                                })}
                            >
                                {helpMessage}
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
            <div className={styles.input__container}>
                <input
                    onChange={onChange}
                    onBlur={onBlur}
                    // defaultValue={value}
                    value={value}
                    name={name}
                    placeholder={placeholder ? placeholder : 'Поиск'}
                    className={cn(styles.input__field, fieldClassName, {
                        [styles.input__field_valid]: isValid,
                    })}
                ></input>
                {icon ? <div className={styles.input__icon}>{icon}</div> : ''}
            </div>

            <div className={styles.input__error}>
                {errorMessage ? errorMessage : ''}
            </div>
        </div>
    );
};

interface InputProps {
    wrapperClassName?: string[];
    placeholder?: string;
    inputHeader?: string;
    fieldClassName?: string;
    headerClassName?: string;
    icon?: JSX.Element;
    isValid?: boolean;

    errorMessage?: string | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: any;
    name?: string;
    helpMessage?: string;
}
