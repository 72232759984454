import cn from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CheckMarkIcon } from '../../assets/icons';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { VacancyListItemType } from '../../store/reducers/MainVacancySlice';
import { Role } from '../../types/types';
import styles from './Vacancy.module.scss';

export const Vacancy: React.FC<VacancyListItemType> = ({
    position,
    salary_from,
    salary_to,
    company_title,
    description,
    id,
    is_user_reaction,
}) => {
    const { isLogin, role } = useAppSelector((state) => state.authReducer);

    const link = useMemo(() => {
        if (!isLogin) {
            return `${PATH_DASHBOARD.root}?login_vacancy=${id}`;
        }
        return `${PATH_DASHBOARD.student.vacancies.retrieve.replace(
            ':id',
            '',
        )}${id}`;
    }, [id, isLogin]);
    return (
        <div className={styles.vacancy}>
            <div className={styles.vacancy__top}>
                <div className={styles.vacancy__content}>
                    <h3 className={styles.vacancy__header}>{position}</h3>
                    <p className={styles.vacancy__pay}>
                        {salary_from} - {salary_to} руб.
                    </p>
                </div>
                {/* <div className={styles.vacancy__logo}>
                    <img src={vacancyLogo} alt={'логотип вакансии'} />
                </div> */}
            </div>
            <div className={styles.vacancy__middle}>
                <div className={cn(styles.vacancy__company, styles.company)}>
                    <h4 className={styles.company__name}>{company_title}</h4>
                    <CheckMarkIcon />
                </div>
                <p className={styles.company__description}>{description}</p>
            </div>
            <div className={styles.vacancy__bottom}>
                {role !== Role.staff && role !== Role.organization ? (
                    <Link className={styles.vacancy__button_more} to={link}>
                        Подробнее
                    </Link>
                ) : null}
                {/* {role !== Role.staff && role !== Role.organization ? (
                    <Button className={styles.vacancy__button_respond}>
                        <span className={styles.vacancy__button_text}>
                            {is_user_reaction
                                ? 'Вы откликнулись'
                                : 'Откликнуться'}
                        </span>
                        <RightArrowIcon />
                    </Button>
                ) : null} */}
            </div>
        </div>
    );
};
