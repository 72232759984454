/* eslint-disable @typescript-eslint/no-unused-vars */
import { Field, useFormik } from 'formik';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { PATH_DASHBOARD } from '../../../../routes/RouterConfig';
import { Input } from '../../../../UI/Input/Input';
import styles from './EditProfile.module.scss';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ChangeEvent, useEffect, useState } from 'react';
import { validationSchema } from './validationSchema';

export const EditProfile = () => {
    const id = useAppSelector((state) => state.authReducer.id);
    const candidate = useAppSelector(
        (state) => state.candidateReducer.candidate,
    );
    const [conscription, setConscription] = useState<any>(
        candidate?.conscription ? 'Да' : '',
    );
    const [disability, setDisability] = useState<any>(
        candidate?.disability ? 'Да' : '',
    );
    
    const formik = useFormik({
        initialValues: {
            first_name: candidate?.first_name,
            second_name: candidate?.second_name,
            last_name: candidate?.last_name,
            phone: candidate?.phone && candidate?.phone.replace('+', ''),
            conscription: Boolean(conscription),
            disability: Boolean(disability),
        },
        onSubmit: async (values) => {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;
            try {
                if (formik.values.phone === formik.initialValues.phone) {
                    axios
                        .put(url, {
                            first_name: formik.values.first_name,
                            second_name: formik.values.second_name,
                            last_name: formik.values.last_name,
                            conscription: Boolean(conscription),
                            disability: Boolean(disability),
                        })
                        .then(() =>
                            toast.success('Данные профиля успешно изменены'),
                        )
                        .catch(() => toast.error('Произошла ошибка'));
                } else {
                    axios
                        .put(url, {
                            first_name: formik.values.first_name,
                            second_name: formik.values.second_name,
                            last_name: formik.values.last_name,
                            phone: formik.values.phone?.replace('+', ''),
                            conscription: Boolean(conscription),
                            disability: Boolean(disability),
                        })
                        .then(() =>
                            toast.success('Данные профиля успешно изменены'),
                        )
                        .catch(() => toast.error('Произошла ошибка'));
                }
            } catch (e) {
                toast.error('Произошла ошибка. Попробуйте позже');
            }
        },
        validationSchema: validationSchema,
    });
    const options = [
        {
            id: 1,
            value: 'Да',
            label: 'Да',
        },
        {
            id: 2,
            value: '',
            label: 'Нет',
        },
    ];
    return (
        <form onSubmit={formik.handleSubmit} className={styles.EditProfile}>
            <EditWrapper
                saveLink={PATH_DASHBOARD.student.root}
                rejectLink={PATH_DASHBOARD.student.root}
                title={'Мой профиль'}
                isValid={formik.isValid}
                saveOnclick={formik.handleSubmit}
            >
                <div className={styles.EditProfile__form}>
                    <Input
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        inputHeader={'Имя'}
                        placeholder={'Александра'}
                        name="first_name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Input
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        inputHeader={'Фамилия'}
                        placeholder={'Кричевская'}
                        name="second_name"
                        value={formik.values.second_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Input
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        inputHeader={'Отчество'}
                        placeholder={''}
                        name={'last_name'}
                        value={formik.values.last_name}
                        errorMessage={formik.errors.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Input
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        inputHeader={'Телефон'}
                        placeholder={'+7'}
                        name={'phone'}
                        value={formik.values.phone}
                        errorMessage={formik.errors.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />

                    <div className={styles.select}>
                        <h3 className={styles.select__header}>
                            Воинская обязанность
                        </h3>
                        <select
                            defaultValue={conscription}
                            className={styles.select__container}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                setConscription(e.target.value)
                            }
                        >
                            <option disabled selected>
                                Выберите значение
                            </option>
                            {options.map((option) => (
                                <option value={option.value} key={option.id}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={styles.select}>
                        <h3 className={styles.select__header}>Инвалидность</h3>
                        <select
                            defaultValue={disability}
                            className={styles.select__container}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                setDisability(e.target.value)
                            }
                        >
                            <option disabled value="none" selected>
                                Выберите значение
                            </option>
                            {options.map((option) => (
                                <option value={option.value} key={option.id}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </EditWrapper>
        </form>
    );
};
