/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { fetchStudentInfo } from '../../store/reducers/CandidateSlice';
import { getCompanyRetrieve } from '../../store/reducers/CompanySlice';
import { fetchStaffInfo } from '../../store/reducers/StaffSlice';
import { Role } from '../../types/types';

//хок, предназначенный для проверки авторизации, чтобы пользователь не мог войти на приватные страницы минуя вход через окно входа
//к примеру через поисковую строку, подставив /student /staff /organization

export const AuthCheck = ({ children }: AuthCheckProps) => {
    const isLoginFromStore = useAppSelector(
        (state) => state.authReducer.isLogin,
    );
    const isLoginFromLocalStorage = localStorage.getItem('token'); // если есть токен в сторадже , то пользователь залогинен
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoginFromStore && !isLoginFromLocalStorage) {
            navigate(PATH_DASHBOARD.root);
        }
    }, []);

    const userId = localStorage.getItem('id');
    const role = localStorage.getItem('role');

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (userId && role === Role.organization) {
            dispatch(getCompanyRetrieve(Number(userId)));
        }
        if (userId && role === Role.candidate) {
            dispatch(fetchStudentInfo(Number(userId)));
        }
        if (userId && role === Role.staff) {
            dispatch(fetchStaffInfo(Number(userId)));
        }
    }, [role, userId]);

    return <>{children}</>;
};

interface AuthCheckProps {
    children: React.ReactNode;
}
