import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IStudent } from '../../types/types';

const initialState: RegistrationState = {
    error: '',
    isLoading: false,
    data: null,
};

interface RegistrationState {
    error: string;
    isLoading: boolean;
    data: IStudent | null;
}

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        registerStart(state) {
            state.isLoading = true;
        },
        registerSuccess(state) {
            state.isLoading = false;
            state.error = '';
        },
        registerError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


export default registrationSlice.reducer;
