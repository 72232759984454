import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { MyProfileIcon, QuitIcon } from '../../assets/icons';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';

import { roleNavigate } from '../../helpers/roleNavigate';
import { useClickOutside } from '../../hooks/useClickOutside';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { LogoutUser } from '../../services/Logout';
import styles from './SettingsPopup.module.scss';

const DEFAULT_PHOTO_URL =
    'https://png.pngtree.com/png-vector/20220522/ourmid/pngtree-personal-account-avatar-body-boy-png-image_4714973.png';

export const SettingsPopup = ({
    name,
    mail,
    image,
    closeSettingsPopup,
}: SettingsPopupProps) => {
    const clickRef = useRef<HTMLDivElement | null>(null);
    useClickOutside(clickRef, closeSettingsPopup);

    const dispatch = useAppDispatch();
    const userLogOut = () => {
        LogoutUser(dispatch);
    };

    const SettingsLinks = [
        {
            id: 1,
            icon: <MyProfileIcon />,
            title: 'Мой профиль',
            link: roleNavigate(),
            onClick: null,
        },
        {
            id: 2,
            icon: <QuitIcon />,
            title: 'Выйти',
            link: PATH_DASHBOARD.root,
            onClick: () => {
                userLogOut();
            },
        },
    ];

    return (
        <div ref={clickRef} className={styles.SettingsPopup}>
            <div className={styles.SettingsPopup__avatar}>
                <div className={styles.SettingsPopup__container}>
                    <img
                        className={styles.SettingsPopup__image}
                        src={image ? image : DEFAULT_PHOTO_URL}
                        alt={`avatar`}
                    />
                </div>
                <div className={styles.SettingsPopup__headers}>
                    <h2 className={styles.SettingsPopup__title}>{name}</h2>
                    <h3 className={styles.SettingsPopup__subtitle}>{mail}</h3>
                </div>
            </div>
            <div className={styles.SettingsPopup__links}>
                {SettingsLinks.map((setting) => (
                    <SettingLink
                        key={setting.id}
                        icon={setting.icon}
                        link={setting.link}
                        title={setting.title}
                        onClick={() => {
                            closeSettingsPopup();
                            setting.onClick && setting.onClick();
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

interface SettingsPopupProps {
    name: string;
    mail: string;
    image: string;
    closeSettingsPopup: () => void;
}

const SettingLink = ({ icon, link, title, onClick }: SettingLinkProps) => {
    return (
        <Link className={styles.SettingLink} onClick={onClick} to={link}>
            {icon}
            <span className={styles.SettingLink__title}>{title}</span>
        </Link>
    );
};

interface SettingLinkProps {
    icon: JSX.Element;
    link: string;
    title: string;
    onClick: () => void;
}
