import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export type CandidateListItemType = {
    position: string;
    contact_email: string;
    contact_phone: string;
    email: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    photo: string;
    id: number;
};

export type VacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    reaction_count: number;
};

export type VacancyListType = {
    page_count: number;
    current_page: number;
    objects: VacancyListItemType[];
};

export type ReactionListType = {
    page_count: number;
    current_page: number;
    objects: {
        id: number;
        status: string;
        comment: string;
        date_created: string;
        in_user_favorite: boolean;
        candidate: CandidateListItemType;
        vacancy: VacancyListItemType;
    }[];
};

const initialState: CompanyReactionState = {
    reactionList: undefined,
    reactionListPage: 1,
    reactionListIsLoading: false,

    vacancyList: undefined,
    vacancyListIsLoading: false,
};

interface CompanyReactionState {
    reactionList: ReactionListType | undefined;
    reactionListPage: number;
    reactionListIsLoading: boolean;

    vacancyList: VacancyListType | undefined;
    vacancyListIsLoading: boolean;
}

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getCompanyReactionList = createAsyncThunk(
    'company/getCompanyReactionList',
    async function name(
        {
            page,
            size,
            id,
            search,
        }: { page: number; size: number; id: number; search: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/reaction/`,
                { params: { page, size, fio: search } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCompanyVacancyList = createAsyncThunk(
    'company/getCompanyVacancyList',
    async function name(
        { page, size, id }: { page: number; size: number; id: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/vacancy/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCompanyReactionListWithId = createAsyncThunk(
    'company/getCompanyReactionListWithId',
    async function name(
        {
            page,
            size,
            id,
            search,
        }: { page: number; size: number; id: number; search: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/reaction/`,
                { params: { page, size, fio: search } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCompanyFavoriteReactionList = createAsyncThunk(
    'company/getCompanyFavoriteReactionList',
    async function name(
        {
            page,
            size,
            id,
            search,
            vacancy_id,
        }: {
            page: number;
            size: number;
            id: number;
            search: string;
            vacancy_id?: number;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/favorite_reactions/`,
                { params: { page, size, fio: search, vacancy_id } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const updateCompanyFavoriteReaction = createAsyncThunk(
    'company/updateCompanyFavoriteReaction',
    async function (
        { id, vacancy_id }: { id?: number; vacancy_id: number },
        { rejectWithValue },
    ) {
        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/favorite_reactions/${vacancy_id}/`,
            );
            toast.success('Отклик добавлен в Избранное');
        } catch (error) {
            toast.error('Отклик не добавлен в Избранное');
            return rejectWithValue(error);
        }
    },
);

export const deleteCompanyFavoriteReaction = createAsyncThunk(
    'company/deleteCompanyFavoriteReaction',
    async function (
        { id, vacancy_id }: { id?: number; vacancy_id: number },
        { rejectWithValue },
    ) {
        try {
            await axios.delete(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/favorite_reactions/${vacancy_id}/`,
            );
            toast.success('Отклик убран из Избранного');
        } catch (error) {
            toast.error('Отклик не удален из Избранного');
            return rejectWithValue(error);
        }
    },
);

export const companyReactionSlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateCompanyReactionListPage(state, action: PayloadAction<number>) {
            state.reactionListPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyReactionList.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(getCompanyReactionList.fulfilled, (state, action) => {
            state.reactionListIsLoading = false;
            state.reactionList = action.payload;
        });
        builder.addCase(getCompanyReactionList.rejected, (state, action) => {
            state.reactionListIsLoading = false;
        });

        builder.addCase(getCompanyFavoriteReactionList.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(
            getCompanyFavoriteReactionList.fulfilled,
            (state, action) => {
                state.reactionListIsLoading = false;
                state.reactionList = action.payload;
            },
        );
        builder.addCase(
            getCompanyFavoriteReactionList.rejected,
            (state, action) => {
                state.reactionListIsLoading = false;
            },
        );

        builder.addCase(getCompanyReactionListWithId.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(
            getCompanyReactionListWithId.fulfilled,
            (state, action) => {
                state.reactionListIsLoading = false;
                state.reactionList = action.payload;
            },
        );
        builder.addCase(
            getCompanyReactionListWithId.rejected,
            (state, action) => {
                state.reactionListIsLoading = false;
            },
        );

        builder.addCase(getCompanyVacancyList.pending, (state) => {
            state.vacancyListIsLoading = true;
        });
        builder.addCase(getCompanyVacancyList.fulfilled, (state, action) => {
            state.vacancyListIsLoading = false;
            state.vacancyList = action.payload;
        });
        builder.addCase(getCompanyVacancyList.rejected, (state, action) => {
            state.vacancyListIsLoading = false;
        });
    },
});

export const { updateCompanyReactionListPage } = companyReactionSlice.actions;

export default companyReactionSlice.reducer;
