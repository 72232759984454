// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Burger_Burger__4bzUb {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.Burger_Burger__element__C2CUA {\n  width: 31px;\n  height: 3px;\n  background-color: #555454;\n}", "",{"version":3,"sources":["webpack://./src/UI/Burger/Burger.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AAAC;EACC,WAAA;EACA,WAAA;EACA,yBAAA;AAEF","sourcesContent":[".Burger {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n\t&__element {\n\t\twidth: 31px;\n\t\theight: 3px;\n\t\tbackground-color: #555454;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Burger": "Burger_Burger__4bzUb",
	"Burger__element": "Burger_Burger__element__C2CUA"
};
export default ___CSS_LOADER_EXPORT___;
