import { Link } from 'react-router-dom';
import styles from './ResumeBanner.module.scss';

interface CabinetBannerProps {
    title: string;
    children?: React.ReactNode;
    link?: string;
}

export const CabinetBanner = ({
    title,
    children,
    link,
}: CabinetBannerProps) => {
    return (
        <div className={styles.banner}>
            <div className={styles.banner__top}>
                <span className={styles.banner__title}>{title}</span>
                {link ? (
                    <Link
                        to={link}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            });
                        }}
                        className={styles.banner__button}
                    >
                        Редактировать
                    </Link>
                ) : null}
            </div>
            <div className={styles.banner__content}>
                {children === undefined ? 'Здесь еще нет информации' : children}
            </div>
        </div>
    );
};
