import styles from './Header.module.scss';

import { Login } from '../../components/Login/Login';
import { Logo, LogoTypes } from '../../components/Logo/Logo';
import { Navigation } from '../../components/Navigation/Navigation';
import { Register } from '../../components/Register/Register';

import { Link, useLocation } from 'react-router-dom';
import { EventRegistration } from '../../components/EventRegistrationPopup/EventRegistration';
import { roleNavigate } from '../../helpers/roleNavigate';
import { scrollNotAvailable } from '../../helpers/scrollToggler';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { Role } from '../../types/types';
import { CandidateWidgetImage } from '../CabinetHeader/CabinetHeader';

export const Header = () => {
    const location = useLocation();
    return (
        <header className={styles.header}>
            <div className={styles.header__wrapper}>
                <Logo logoType={LogoTypes.HEADER} />
                <Navigation />
                <Authorization />
            </div>
            {location.search.includes('login') ||
            location.search.includes('login_vacancy') ? (
                <Login />
            ) : null}
            {location.search.includes('register') ? <Register /> : null}
            {location.search.includes('event_reg') ? (
                <EventRegistration />
            ) : null}
        </header>
    );
};

const Authorization = () => {
    const { isLogin, role } = useAppSelector((state) => state.authReducer);

    const flag = localStorage.getItem('isVerified');
    const isVerified = JSON.parse(flag !== null ? flag : 'false');
    let isLinkAvailable;

    if (role === Role.candidate) {
        isLinkAvailable = isLogin && isVerified;
    }
    if (role === Role.staff) {
        isLinkAvailable = isLogin;
    }
    if (role === Role.organization) {
        isLinkAvailable = isLogin && isVerified;
    }

    return (
        <div className={styles.authtorization}>
            {isLinkAvailable ? (
                <Link to={roleNavigate()}>
                    <CandidateWidgetImage
                        className={styles.authtorization__widget}
                    />
                </Link>
            ) : (
                <Link
                    className={styles.authtorization__button}
                    to={{ search: 'login=true' }}
                    onClick={scrollNotAvailable}
                >
                    Войти
                </Link>
            )}
        </div>
    );
};

export default Header;
