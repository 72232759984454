import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

export type VacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    company_title: string;
    reaction_count: number;
    is_user_reaction: boolean;
    in_user_favorite: boolean;
};

export type VacancyListType = {
    page_count: number;
    current_page: number;
    objects: VacancyListItemType[];
};

const initialState: StaffVacancyState = {
    company: '',
    employment_form: [],
    salary: [10000, 100000],
    search_query: '',
    required_experience: [],

    vacancyList: undefined,
    vacancyListPage: 1,
    vacancyListIsLoading: false,
    vacancyListError: null,

    vacancyRetrieve: undefined,
    vacancyRetrieveIsLoading: false,
    vacancyRetrieveError: null,
};

interface StaffVacancyState {
    company: string;
    salary: [number, number];
    search_query: string;
    employment_form: string[];
    required_experience: string[];

    vacancyList: VacancyListType | undefined;
    vacancyListPage: number;
    vacancyListIsLoading: boolean;
    vacancyListError: any;

    vacancyRetrieve: VacancyListItemType | undefined;
    vacancyRetrieveIsLoading: boolean;
    vacancyRetrieveError: any;
}

export const getStaffVacancyList = createAsyncThunk(
    'staff/getStaffVacancyList',
    async function name(
        {
            page,
            size,
            status,
            company,
            employment_form,
            salary_from,
            salary_to,
            search_query,
            required_experience,
        }: {
            page: number;
            size: number;
            status: 'OP' | 'CL';
            company?: string;
            employment_form?: string[];
            salary_from?: number;
            salary_to?: number;
            search_query?: string;
            required_experience?: string[];
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/`,
                {
                    params: {
                        page,
                        size,
                        status,
                        company,
                        employment_form,
                        salary_from,
                        salary_to,
                        search_query,
                        required_experience,
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getStaffVacancyRetrieve = createAsyncThunk(
    'staff/getStaffVacancyRetrieve',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const staffVacancySlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateStaffVacancyListPage(state, action: PayloadAction<number>) {
            state.vacancyListPage = action.payload;
        },
        updateCompany(state, action: PayloadAction<string>) {
            state.company = action.payload;
        },
        updateSalary(state, action: PayloadAction<[number, number]>) {
            state.salary = action.payload;
        },
        updateSalaryFrom(state, action: PayloadAction<number>) {
            state.salary[0] = action.payload;
        },
        updateSalaryTo(state, action: PayloadAction<number>) {
            state.salary[1] = action.payload;
        },
        updateSearch(state, action: PayloadAction<string>) {
            state.search_query = action.payload;
        },
        updateRequiredExperience(state, action: PayloadAction<string>) {
            if (
                state.required_experience.find(
                    (item) => item === action.payload,
                )
            ) {
                state.required_experience = state.required_experience.filter(
                    (item) => item !== action.payload,
                );
            } else {
                state.required_experience.push(action.payload);
            }
        },
        updateEmploymentForm(state, action: PayloadAction<string>) {
            if (state.employment_form.find((item) => item === action.payload)) {
                state.employment_form = state.employment_form.filter(
                    (item) => item !== action.payload,
                );
            } else {
                state.employment_form.push(action.payload);
            }
        },
        deleteFilter(state) {
            state.company = '';
            state.employment_form = [];
            state.salary = [10000, 100000];
            state.search_query = '';
            state.required_experience = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStaffVacancyList.pending, (state) => {
            state.vacancyListIsLoading = true;
        });
        builder.addCase(getStaffVacancyList.fulfilled, (state, action) => {
            state.vacancyListIsLoading = false;
            state.vacancyList = action.payload;
        });
        builder.addCase(getStaffVacancyList.rejected, (state, action) => {
            state.vacancyListIsLoading = false;
        });
    },
});

export const {
    updateCompany,
    updateStaffVacancyListPage,
    updateEmploymentForm,
    updateRequiredExperience,
    updateSalaryFrom,
    updateSalaryTo,
    updateSearch,
    updateSalary,
    deleteFilter,
} = staffVacancySlice.actions;

export default staffVacancySlice.reducer;
