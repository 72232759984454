import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    public_activities: Yup.array().of(
        Yup.object().shape({
            start: Yup.date()
                .max(
                    new Date(),
                    'Выбранная дата не может быть позже сегодняшнего дня',
                )
                .nullable()
                .required('Поле обязательно'),
            end: Yup.date()
                .max(
                    new Date(),
                    'Выбранная дата не может быть позже сегодняшнего дня',
                )
                .nullable()
                .min(
                    Yup.ref('start'),
                    'Дата окончания не может быть раньше даты начала',
                )
                .required('Поле обязательно'),
            organization: Yup.string().required('Поле обязательно'),
            site: Yup.string().matches(
                // eslint-disable-next-line no-useless-escape
                /^((http|https|ftp):\/\/)?(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i,
                'Некорректный email',
            ),
            format: Yup.string()
                .required('Поле обязательно')
                .matches(
                    /[а-яА-ЯёЁ]/,
                    'Данное поле может содержать только буквы кириллицы',
                ),
            description: Yup.string().required('Поле обязательно'),
        }),
    ),
});
