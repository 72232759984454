// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__BBmqA {\n  padding: 10px 70px;\n}\n@media (max-width: 1300px) {\n  .Header_header__BBmqA {\n    padding: 23px 40px;\n  }\n}\n.Header_header__wrapper__fhk6h {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.Header_authtorization__1QoPr {\n  display: flex;\n  align-items: center;\n  gap: 31px;\n}\n.Header_authtorization__button__WZR1l {\n  font: var(--font-s);\n  font-weight: var(--font-weight-medium);\n  border: 1px solid #d9d9d9;\n  border-radius: var(--main-border-radius);\n  padding: 5px 15px;\n  color: #11139f;\n}\n.Header_authtorization__widget__\\+Xom2 {\n  width: 45px;\n  height: 45px;\n}", "",{"version":3,"sources":["webpack://./src/layouts/MainHeader/Header.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EAFJ;IAGQ,kBAAA;EAGN;AACF;AAFI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAIR;;AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAGJ;AAFI;EACI,mBAAA;EACA,sCAAA;EACA,yBAAA;EACA,wCAAA;EACA,iBAAA;EACA,cAAA;AAIR;AAFI;EACI,WAAA;EACA,YAAA;AAIR","sourcesContent":[".header {\n    padding: 10px 70px;\n    @media (max-width: 1300px) {\n        padding: 23px 40px;\n    }\n    &__wrapper {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n    }\n}\n\n.authtorization {\n    display: flex;\n    align-items: center;\n    gap: 31px;\n    &__button {\n        font: var(--font-s);\n        font-weight: var(--font-weight-medium);\n        border: 1px solid #d9d9d9;\n        border-radius: var(--main-border-radius);\n        padding: 5px 15px;\n        color: #11139f;\n    }\n    &__widget {\n        width: 45px;\n        height: 45px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__BBmqA",
	"header__wrapper": "Header_header__wrapper__fhk6h",
	"authtorization": "Header_authtorization__1QoPr",
	"authtorization__button": "Header_authtorization__button__WZR1l",
	"authtorization__widget": "Header_authtorization__widget__+Xom2"
};
export default ___CSS_LOADER_EXPORT___;
