import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Burger } from '../../UI/Burger/Burger';
import { CheckMark } from '../../assets/icons';
import avatarImageSrc from '../../assets/images/adminAvatar.png';
import { SettingsPopup } from '../../components/SettingsPopup/SettingsPopup';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { Role } from '../../types/types';
import styles from './CabinetHeader.module.scss';

const DEFAULT_PHOTO_URL =
    'https://png.pngtree.com/png-vector/20220522/ourmid/pngtree-personal-account-avatar-body-boy-png-image_4714973.png';

interface CabinetHeaderProps {
    toggleAside: () => void;
}

export const CabinetHeader = ({ toggleAside }: CabinetHeaderProps) => {
    const windowWidth = useWindowWidth();

    const [isSettingsPopupOpen, setIsSettingsPopupOpen] =
        useState<Boolean>(false);
    const closeSettingsPopup = () => {
        setIsSettingsPopupOpen(false);
    };

    const { candidate } = useAppSelector((state) => state.candidateReducer);
    const { company } = useAppSelector((state) => state.companyReducer);
    const { staff } = useAppSelector((state) => state.staffReducer);
    const { role } = useAppSelector((state) => state.authReducer);

    const name = useMemo(() => {
        if (role === Role.candidate) {
            return (
                [candidate?.second_name, candidate?.first_name]
                    .filter((item) => item !== null)
                    .join(' ') || ''
            );
        }
        if (role === Role.organization) {
            return company?.full_title || '';
        }
        if (role === Role.staff) {
            return (
                [staff?.second_name, staff?.first_name]
                    .filter((item) => item !== null)
                    .join(' ') || ''
            );
        }
        return '';
    }, [
        candidate?.first_name,
        candidate?.second_name,
        company?.full_title,
        role,
        staff?.first_name,
        staff?.second_name,
    ]);

    const mail = useMemo(() => {
        if (role === Role.candidate) {
            return candidate?.email || '';
        }
        if (role === Role.organization) {
            return company?.email || '';
        }
        if (role === Role.staff) {
            return staff?.email || '';
        }
        return '';
    }, [candidate?.email, company?.email, role, staff?.email]);

    const photo = useMemo(() => {
        if (role === Role.candidate) {
            return candidate?.photo || '';
        }
        if (role === Role.organization) {
            return company?.photo || '';
        }
        if (role === Role.staff) {
            return staff?.photo || '';
        }
        return '';
    }, [candidate?.photo, company?.photo, role, staff?.photo]);

    return (
        <header className={styles.header}>
            {windowWidth && windowWidth > 900 ? (
                <HeaderDesctop
                    isSettingsPopupOpen={isSettingsPopupOpen}
                    setIsSettingsPopupOpen={setIsSettingsPopupOpen}
                />
            ) : (
                <HeaderMobile
                    isSettingsPopupOpen={isSettingsPopupOpen}
                    setIsSettingsPopupOpen={setIsSettingsPopupOpen}
                    toggleAside={toggleAside}
                />
            )}
            {isSettingsPopupOpen && (
                <SettingsPopup
                    name={name}
                    mail={mail}
                    image={photo || avatarImageSrc}
                    closeSettingsPopup={closeSettingsPopup}
                />
            )}
        </header>
    );
};

const HeaderDesctop = ({
    setIsSettingsPopupOpen,
    isSettingsPopupOpen,
}: HeaderDesctopProps) => {
    return (
        <>
            <Link
                className={cn(styles.header__link, styles.link__main)}
                to={PATH_DASHBOARD.root}
            >
                Главная
            </Link>
            <Link
                className={cn(styles.header__link, styles.link__help)}
                to={PATH_DASHBOARD.help}
            >
                Поддержка
            </Link>
            <HeaderWidget
                isSettingsPopupOpen={isSettingsPopupOpen}
                setIsSettingsPopupOpen={setIsSettingsPopupOpen}
            />
        </>
    );
};

export const HeaderWidget = ({
    setIsSettingsPopupOpen,
    isSettingsPopupOpen,
}: any) => {
    const { role } = useAppSelector((state) => state.authReducer);
    return (
        <div
            className={styles.header__container}
            onClick={() => {
                setIsSettingsPopupOpen(!isSettingsPopupOpen);
            }}
        >
            <div
                className={cn(styles.header__avatar, {
                    [styles.header__avatar_candidate]: role === Role.candidate,
                    [styles.header__avatar_staff]: role === Role.staff,
                    [styles.header__avatar_organization]:
                        role === Role.organization,
                })}
            >
                <WidgetProxyImage />
            </div>
            <div className={styles.header__button}>
                <CheckMark />
            </div>
        </div>
    );
};

// проксируем фото, разделяя на разные роли для оптимизации запросов
const WidgetProxyImage = () => {
    const { role } = useAppSelector((state) => state.authReducer);
    const { candidate } = useAppSelector((state) => state.candidateReducer);
    const { company } = useAppSelector((state) => state.companyReducer);
    const { staff } = useAppSelector((state) => state.staffReducer);

    const photo = useMemo(() => {
        if (role === Role.candidate) {
            return candidate?.photo || '';
        }
        if (role === Role.organization) {
            return company?.photo || '';
        }
        if (role === Role.staff) {
            return staff?.photo || '';
        }
        return '';
    }, [candidate?.photo, company?.photo, role, staff?.photo]);
    return (
        <img
            className={cn(styles.header__image)}
            src={photo ? photo : DEFAULT_PHOTO_URL}
            alt={'avatar'}
        />
    );
};

interface CandidateWidgetImageProps {
    className?: string;
}

export const CandidateWidgetImage = ({
    className,
}: CandidateWidgetImageProps) => {
    //делаем запрос на фотку кандидата
    const role = localStorage.getItem('role');
    const { candidate } = useAppSelector((state) => state.candidateReducer);
    const { company } = useAppSelector((state) => state.companyReducer);
    const { staff } = useAppSelector((state) => state.staffReducer);

    const photo = useMemo(() => {
        if (role === Role.staff) {
            return staff?.photo;
        }
        if (role === Role.organization) {
            return company?.photo;
        }
        if (role === Role.candidate) {
            return candidate?.photo;
        }
        return '';
    }, [candidate?.photo, company?.photo, role, staff?.photo]);

    return (
        <img
            className={cn(styles.header__image, className)}
            src={photo ? photo : DEFAULT_PHOTO_URL}
            alt={'avatar'}
        />
    );
};

const HeaderMobile = ({
    setIsSettingsPopupOpen,
    isSettingsPopupOpen,
    toggleAside,
}: HeaderMobileProps) => {
    const { candidate } = useAppSelector((state) => state.candidateReducer);
    const { company } = useAppSelector((state) => state.companyReducer);
    const { staff } = useAppSelector((state) => state.staffReducer);
    const { role } = useAppSelector((state) => state.authReducer);

    const photo = useMemo(() => {
        if (role === Role.candidate) {
            return candidate?.photo || '';
        }
        if (role === Role.organization) {
            return company?.photo || '';
        }
        if (role === Role.staff) {
            return staff?.photo || '';
        }
        return '';
    }, [candidate?.photo, company?.photo, role, staff?.photo]);

    return (
        <div className={styles.header__mobile}>
            <Burger onClick={toggleAside} />
            <div
                className={styles.mobile__toggler}
                onClick={() => {
                    setIsSettingsPopupOpen(!isSettingsPopupOpen);
                }}
            >
                <div className={styles.header__avatar}>
                    <img
                        className={styles.header__image}
                        src={photo || avatarImageSrc}
                        alt={'avatar'}
                    />
                </div>
                <div className={styles.header__button}>
                    <CheckMark />
                </div>
            </div>
        </div>
    );
};

interface HeaderDesctopProps {
    setIsSettingsPopupOpen: React.Dispatch<React.SetStateAction<Boolean>>;
    isSettingsPopupOpen: Boolean;
}

interface HeaderMobileProps {
    setIsSettingsPopupOpen: React.Dispatch<React.SetStateAction<Boolean>>;
    isSettingsPopupOpen: Boolean;
    toggleAside: () => void;
}
