// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Overlay_overlay__mpfM- {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  z-index: 100;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.42);\n}", "",{"version":3,"sources":["webpack://./src/components/Overlay/Overlay.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EAEA,kBAAA;EACA,YAAA;EAEA,MAAA;EACA,OAAA;EAEA,+BAAA;AAFJ","sourcesContent":[".overlay{\n    width: 100vw;\n    height: 100vh;\n\n    position: absolute;\n    z-index: 100;\n\n    top:0;\n    left:0;\n\n    background: rgba(0, 0, 0, 0.42);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "Overlay_overlay__mpfM-"
};
export default ___CSS_LOADER_EXPORT___;
