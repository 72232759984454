import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Language } from '../../types/types';

const initialState: LanguageState = {
    languages: null,
    isLoading: false,
    error: null,
};

interface LanguageState {
    languages: Language[] | null;
    isLoading: boolean;
    error: any;
}

export const fetchLanguages = createAsyncThunk(
    'languages/fetchLanguages',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/language/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        clearLanguages(state) {
            state.languages = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLanguages.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchLanguages.fulfilled, (state, action) => {
            state.isLoading = false;
            state.languages = action.payload;
        });
        builder.addCase(fetchLanguages.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default languagesSlice.reducer;
