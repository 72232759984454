/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { EventCard } from '../../../../components/EventCard/EventCard';
import { EventsFilterWidget } from '../../../../components/EventsFilterWidget/EventsFilterWidget';
import { Loader } from '../../../../components/Loader/Loader';
import { DropdownOption, IEventCard } from '../../../../types/types';
import Pagination from '../../../StaffPages/SupportList/Pagination';
import styles from './Events.module.scss';

export const Events = () => {
    const [events, setEvents] = useState<IEventCard[] | null>(null); // стэйт с новостями

    //пагинация
    const [page, setPage] = useState<number>(1); // стэйт - текущая страница в пагинации
    const [paginationArr, setPaginationArr] = useState<number>(1); // максимальное количество страниц

    //индикатор загрузки
    const [isLoading, setIsLoading] = useState<boolean>(false); //изначально нет загрузки

    //фильтры
    const initialState = {
        value: 'all',
        label: 'Все',
    };
    const [format, setFormat] = useState<DropdownOption>(initialState); // стэйт фильтрации по формату
    const [place, setPlace] = useState<DropdownOption>(initialState); // стэйт фильтрации по месту
    const [searchValue, setSearchValue] = useState<string>(''); // стэйт для поисковой строки

    const eventsUrl = `${
        process.env.REACT_APP_BASE_URL
    }api/v1/event/?page=${page}&size=10${
        format.value ? `&event_format=${format.value}` : ''
    }${place.value ? `&location=${place.value}` : ''}${
        searchValue !== '' ? `&search_query=${searchValue}` : ''
    }`;

    useEffect(() => {
        setIsLoading(true); // загрузка началась
        axios
            .get(eventsUrl)
            .then((response) => {
                const pagArray = response.data.page_count;
                setPaginationArr(pagArray); // массив булетов для отрисовки
                setEvents(response.data.objects); // данные по карточкам
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false)); // загрузка закончена
    }, [page, format, place, searchValue, eventsUrl]);

    return (
        <div className={styles.EventsPage}>
            <EventsFilterWidget
                setFormat={setFormat}
                format={format}
                setPlace={setPlace}
                place={place}
                setSearchValue={setSearchValue}
            />
            {isLoading ? (
                <div className={styles.EventsPage__loader}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.EventsPage__wrapper}>
                    <div className={styles.EventsPage__cards}>
                        {events && <EventsList events={events} />}
                    </div>
                </div>
            )}
            <Pagination
                list={{
                    page_count: paginationArr,
                    current_page: page,
                }}
                handlePagination={(p) => {
                    setPage(p);
                }}
                isLoading={isLoading}
            />
        </div>
    );
};

interface EventsListProps {
    events: IEventCard[];
}

export const EventsList = ({ events }: EventsListProps) => {
    return (
        <div className={styles.EventsList}>
            {events.map((event) => (
                <EventCard
                    event={event}
                    key={event.id}
                    containerClassName={styles.personalPage}
                    imageClassName={styles.personalPage__image}
                    titleClassName={styles.personalPage__title}
                    smallBlocksClassName={styles.personalPage__smallBlocks}
                />
            ))}
        </div>
    );
};
