import cn from 'classnames';
import styles from './AddEvent.module.scss';

import axios from 'axios';
import { useFormik } from 'formik';
import { ChangeEvent, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomSelect } from '../../../../UI/CustomSelect/CustomSelect';
import { DatePicker } from '../../../../UI/DatePicker/DatePicker';
import { Input } from '../../../../UI/Input/Input';
import { Textarea } from '../../../../UI/Textarea/Textarea';
import { AddEventImage } from '../../../../components/AddEventImage/AddEventImage';
import { AddNewsHeader } from '../../../../components/AddNewsHeader/AddNewsHeader';
import { PDFUploader } from '../../../../components/PDFUploader/PDFUploader';
import { validationSchema } from './validationSchema';

export const AddEvent = () => {
    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
    const [PDF, setPDF] = useState<File | null>(null);
    const [eventFormat, setEventFormat] = useState<string>('');
    const [adress, setAdress] = useState<string>('');
    const handleFormatChange = (selectedOption: any) => {
        setEventFormat(selectedOption.value);
    };
    const handleAdressChange = (selectedOption: any) => {
        setAdress(selectedOption.value);
    };
    const validImage = useMemo(() => {
        if (selectedImages?.length && selectedImages[0].size < 512000) {
            return true;
        }
        if (!selectedImages?.length) {
            return true;
        }
        return false;
    }, [selectedImages]);

    const validPDF = useMemo(() => {
        if (PDF && PDF.size < 512000) {
            return true;
        }
        if (!PDF) {
            return true;
        }
        return false;
    }, [PDF]);

    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            title: '',
            datetime: '',
            description: '',
            companies: '',
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            if (selectedImages === null) {
                setIsLoading(false);
                toast.error('Необходимо добавить изображение');
                return;
            }

            const formData = new FormData();
            if (selectedImages !== null && selectedImages[0]) {
                formData.append('image', selectedImages[0]);
            }

            const dateArr = formik.values.datetime;
            formData.append('title', formik.values.title);
            formData.append('datetime', dateArr);
            formData.append('format', eventFormat);
            formData.append('address', adress);
            formData.append(
                'description',
                formik.values.description.replace('↵', '\n'),
            );
            formData.append('companies', formik.values.companies);
            if (PDF) {
                formData.append('pdf', PDF);
            }
            await axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}api/v1/event/create_with_image/`,
                    formData,
                )
                .then(() => {
                    toast.success('Мероприятие успешно добавлено!');
                    formik.resetForm();
                    setSelectedImages(null);
                    setPDF(null);
                    setIsLoading(false);
                    window.history.go(-1);
                })
                .catch(() => {
                    toast.error('При загрузке события произошла ошибка');
                    setIsLoading(false);
                });

            setIsLoading(false);
        },
        validationSchema: validationSchema,
    });
    return (
        <form onSubmit={formik.handleSubmit} className={styles.addevent}>
            <AddNewsHeader
                dirty={formik.dirty}
                isValid={formik.isValid && validImage}
                isLoading={isLoading}
                onSubmit={formik.handleSubmit}
                resetForm={() => {
                    formik.resetForm();
                    window.history.go(-1);
                }}
                title={'Добавить мероприятие'}
                extraCondition={
                    selectedImages &&
                    selectedImages.length > 0 &&
                    eventFormat !== ''
                }
            />
            <AddEventImage
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
            />
            {!validImage ? (
                <span className={styles.validImage}>
                    Максимальный размер изображения 500Кб
                </span>
            ) : null}
            <div className={styles.addevent__content}>
                <Input
                    wrapperClassName={[styles.addevent_first]}
                    placeholder="Мероприятие"
                    inputHeader={'Название мероприятия'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name={'title'}
                    errorMessage={formik.errors.title}
                    isValid={
                        formik.touched &&
                        !formik.errors.title &&
                        !!formik.values.title
                    }
                />
                <DatePicker
                    wrapperClassName={[styles.addevent_second]}
                    placeholder="02.03.2023"
                    headerText={'Дата'}
                    headerClassName={styles.input__title}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.datetime}
                    name={'datetime'}
                    errorMessage={formik.errors.datetime}
                    isValid={
                        formik.touched &&
                        !formik.errors.datetime &&
                        !!formik.values.datetime
                    }
                />
                <Input
                    wrapperClassName={[styles.addevent_third]}
                    placeholder="Партнеры"
                    inputHeader={'Партнеры'}
                    headerClassName={styles.input__title}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.companies}
                    name={'companies'}
                    errorMessage={formik.errors.companies}
                    isValid={
                        formik.touched &&
                        !formik.errors.companies &&
                        !!formik.values.companies
                    }
                />
                <CustomSelect
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleAdressChange(e);
                    }}
                    state={adress}
                    className={cn(styles.select, styles.addevent_fourth)}
                    titleClass={styles.select__header}
                    inputFieldClass={styles.select__field}
                    title={'Место проведения'}
                    borderRadius={'17px'}
                    fieldHeight={'55px'}
                    placeholderSize={'15px'}
                    name={'adress'}
                    options={[
                        {
                            value: 'Юридический факультет МГУ',
                            label: 'Юридический факультет МГУ',
                        },
                        {
                            value: 'Сторонняя организация',
                            label: 'Сторонняя организация',
                        },
                    ]}
                    isValid={Boolean(adress)}
                    errorMessage={adress !== '' ? '' : 'Поле обязательное'}
                />
                <CustomSelect
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleFormatChange(e);
                        // formikProps.setFieldValue(`format`, e.target.value);
                    }}
                    state={eventFormat}
                    className={cn(styles.select, styles.addevent_fifth)}
                    titleClass={styles.select__header}
                    inputFieldClass={styles.select__field}
                    title={'Формат мероприятия'}
                    placeholder={'Формат мероприятия'}
                    borderRadius={'17px'}
                    fieldHeight={'55px'}
                    placeholderSize={'15px'}
                    name={'format'}
                    options={[
                        {
                            value: 'ON',
                            label: 'Онлайн',
                        },
                        {
                            value: 'OF',
                            label: 'Офлайн',
                        },
                    ]}
                    isValid={Boolean(eventFormat)}
                    errorMessage={eventFormat !== '' ? '' : 'Поле обязательное'}
                />

                <Textarea
                    wrapperClassName={styles.addevent_sixth}
                    title={'Описание'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    name={'description'}
                    errorMessage={formik.errors.description}
                    isValid={
                        formik.touched &&
                        !formik.errors.description &&
                        !!formik.values.description
                    }
                />
            </div>
            <PDFUploader PDF={PDF} setPDF={setPDF} />
            {!validPDF ? (
                <span className={styles.validImage}>
                    Максимальный размер файла 500Кб
                </span>
            ) : null}
        </form>
    );
};
