import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

export type VacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    company_title: string;
    reaction_count: number;
    is_user_reaction: boolean;
    in_user_favorite: boolean;
};

export type VacancyListType = {
    page_count: number;
    current_page: number;
    objects: VacancyListItemType[];
};

export type VacancyReactionListType = {
    page_count: number;
    current_page: number;
    objects: {
        id: number;
        status: string;
        comment: string;
        vacancy: VacancyListItemType;
    }[];
};

const initialState: CompanyVacancyState = {
    vacancyList: undefined,
};

interface CompanyVacancyState {
    vacancyList: VacancyListType | undefined;
}

export const getVacancyList = createAsyncThunk(
    'main/getVacancyList',
    async function name(_, { rejectWithValue }) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/`,
                {
                    params: {
                        page: 1,
                        size: 10,
                        status: 'OP',
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const mainVacancySlice = createSlice({
    name: 'mainVacancySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVacancyList.fulfilled, (state, action) => {
            state.vacancyList = action.payload;
        });
    },
});

export default mainVacancySlice.reducer;
