import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { updateCompanyReactionListPage } from '../../../store/reducers/CompanyReactionSlice';
import Pagination from '../../StaffPages/SupportList/Pagination';
import styles from './ReactionList.module.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import { CustomSelect } from '../../../UI/CustomSelect/CustomSelect';
import { Input } from '../../../UI/Input/Input';
import { LikeIcon } from '../../../assets/icons';
import useDebounceCallBack from '../../../hooks/useDebounce';
import {
    CandidateListItemType,
    VacancyListItemType,
    deleteCompanyFavoriteReaction,
    getCompanyFavoriteReactionList,
    getCompanyReactionList,
    getCompanyReactionListWithId,
    getCompanyVacancyList,
    updateCompanyFavoriteReaction,
} from '../../../store/reducers/CompanyReactionSlice';

type CompanyReactionItemProps = {
    candidate: CandidateListItemType;
    vacancy: VacancyListItemType;
    comment: string;
    dateCreated: string;
    isFavorite?: boolean;
    refetch?: () => void;
    id: number;
};
export const CompanyReactionItem: React.FC<CompanyReactionItemProps> = ({
    vacancy,
    candidate,
    comment,
    dateCreated,
    isFavorite,
    refetch = () => undefined,
    id,
}) => {
    const { company } = useAppSelector((state) => state.companyReducer);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [showComment, setShowComment] = useState(false);

    const showCommentButton = useMemo(() => {
        if (comment.length > 250) {
            return true;
        }
        return false;
    }, [comment]);

    const modifiedComment = useMemo(() => {
        if (!showCommentButton) {
            return comment;
        }
        return showComment ? comment : `${comment.slice(0, 250)}...`;
    }, [comment, showComment, showCommentButton]);

    return (
        <div className={styles.companyReactionItem}>
            <div className={styles.imageWrapper}>
                {candidate.photo ? (
                    <img src={candidate.photo} alt={candidate.second_name} />
                ) : null}
            </div>
            <div className={styles.content}>
                <div className={styles.contentTitle}>
                    <div className={styles.reactionWrapper}>
                        <h3 className={styles.name}>
                            {candidate.second_name || ''}{' '}
                            {candidate.first_name || ''}{' '}
                            {candidate.last_name || ''}
                        </h3>
                        <span className={styles.reaction}>
                            отклик на вакансию:
                        </span>
                        <span className={styles.position}>
                            {vacancy.position}
                        </span>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div>
                            <button
                                className={styles.buttonFavorite}
                                onClick={async () => {
                                    if (isFavorite) {
                                        await dispatch(
                                            deleteCompanyFavoriteReaction({
                                                id: company?.id,
                                                vacancy_id: id,
                                            }),
                                        );
                                    } else {
                                        await dispatch(
                                            updateCompanyFavoriteReaction({
                                                id: company?.id,
                                                vacancy_id: id,
                                            }),
                                        );
                                    }
                                    refetch();
                                }}
                            >
                                <LikeIcon
                                    className={classNames(
                                        styles.buttonLike,
                                        isFavorite && styles.buttonActiveLike,
                                    )}
                                />
                            </button>
                            <Button
                                className={styles.vacancyButton}
                                onClick={() => {
                                    navigate(
                                        `/company/students_list/${candidate.id}`,
                                    );
                                }}
                            >
                                Подробнее
                            </Button>
                        </div>
                        <span className={styles.updateDate}>
                            {new Date(dateCreated).toLocaleDateString('ru-RU', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                        </span>
                    </div>
                </div>
                {comment ? (
                    <div className={styles.comment}>
                        <span>Сопроводительное письмо</span>
                        <p>{modifiedComment}</p>
                        {showCommentButton ? (
                            <button
                                onClick={() =>
                                    setShowComment((value) => !value)
                                }
                            >
                                {showComment
                                    ? 'Скрыть письмо'
                                    : 'Посмотреть письмо'}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export const CompanyReactionList = () => {
    const [variant, setVariant] = useState<'all' | 'favorite'>('all');
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceCallBack(setSearch, 1000);

    const {
        reactionList,
        reactionListIsLoading,
        reactionListPage,
        vacancyList,
    } = useAppSelector((state) => state.companyReactionReducer);

    const { company } = useAppSelector((state) => state.companyReducer);

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const value = params.get('value');
    const label = params.get('label');

    const [vacancy, setVacancy] = useState<
        { value: string; label: string } | undefined
    >(
        value && label
            ? { value: value, label: label }
            : { value: '0', label: 'Все' },
    );
    const refetchCompanyReactionList = () => {
        if (company?.id && variant === 'all' && vacancy?.value === '0') {
            dispatch(
                getCompanyReactionList({
                    page: reactionListPage,
                    size: 10,
                    id: company?.id,
                    search,
                }),
            );
        }
        if (company?.id && variant === 'all' && vacancy?.value !== '0') {
            dispatch(
                getCompanyReactionListWithId({
                    page: reactionListPage,
                    size: 10,
                    id: Number(vacancy?.value),
                    search,
                }),
            );
        }
        if (company?.id && variant === 'favorite' && vacancy?.value === '0') {
            dispatch(
                getCompanyFavoriteReactionList({
                    page: reactionListPage,
                    size: 10,
                    id: company?.id,
                    search,
                }),
            );
        }
        if (company?.id && variant === 'favorite' && vacancy?.value !== '0') {
            dispatch(
                getCompanyFavoriteReactionList({
                    page: reactionListPage,
                    size: 10,
                    id: company?.id,
                    vacancy_id: Number(vacancy?.value),
                    search,
                }),
            );
        }
    };
    const options = useMemo(() => {
        if (vacancyList) {
            const vacancies = vacancyList?.objects.map((item) => ({
                value: String(item.id),
                label: item.position,
            }));

            return [...vacancies, { value: '0', label: 'Все' }];
        }
        return [];
    }, [vacancyList]);

    useEffect(() => {
        refetchCompanyReactionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant, reactionListPage, company?.id, vacancy, search]);

    useEffect(() => {
        dispatch(updateCompanyReactionListPage(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant, search]);

    useEffect(() => {
        if (company?.id) {
            dispatch(
                getCompanyVacancyList({ page: 1, size: 100, id: company?.id }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company?.id]);

    const error = useMemo(() => {
        if (search) {
            return 'По Вашему запросу ничего не найдено!';
        }
        if (vacancy?.value && vacancy?.value !== '0') {
            return `${
                variant === 'all' ? 'Откликов' : 'Избранных откликов'
            } на вакансию "${vacancy.label}" пока нет!`;
        }
        return `${
            variant === 'all' ? 'Откликов' : 'Избранных откликов'
        } пока нет!`;
    }, [vacancy, search, variant]);

    return (
        <div className={styles.companyVacancyList}>
            <div className={styles.companyVacancyListGrid}>
                {reactionListIsLoading ? <Loader /> : null}

                {!reactionListIsLoading &&
                reactionList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>{error}</span>
                ) : null}

                {!reactionListIsLoading &&
                    reactionList &&
                    reactionList.objects.map((item) => (
                        <CompanyReactionItem
                            key={`vacancy-${item.id}`}
                            vacancy={item.vacancy}
                            dateCreated={item.date_created}
                            candidate={item.candidate}
                            comment={item.comment}
                            isFavorite={item.in_user_favorite}
                            refetch={refetchCompanyReactionList}
                            id={item.id}
                        />
                    ))}

                <Pagination
                    list={reactionList}
                    handlePagination={(p) => {
                        dispatch(updateCompanyReactionListPage(p));
                    }}
                    isLoading={reactionListIsLoading}
                />
            </div>

            <div className={styles.companyVacancyTitle}>
                <div>
                    <button
                        onClick={() => setVariant('all')}
                        className={classNames(
                            styles.vacancyButton,
                            variant === 'all' && styles.vacancyButtonActive,
                        )}
                    >
                        Все
                    </button>
                    <button
                        onClick={() => setVariant('favorite')}
                        className={classNames(
                            styles.vacancyButton,
                            variant === 'favorite' &&
                                styles.vacancyButtonActive,
                        )}
                    >
                        Избранные
                    </button>
                </div>
                <div className={styles.findWrapper}>
                    <Input
                        placeholder={'Поиск студента'}
                        onChange={(e) => debouncedSearch(e.target.value)}
                        // value={search}
                    />
                    <CustomSelect
                        onChange={(e: { value: string; label: string }) => {
                            setVacancy(e);
                        }}
                        value={vacancy}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        placeholder={'Вакансия'}
                        options={options}
                        isSearchable={false}
                    />
                </div>
            </div>
        </div>
    );
};
