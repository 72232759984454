import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../Modal/Modal';
import styles from './DeleteModal.module.scss';

type DeleteModalProps = {
    onClose: () => void;
    url: string;
    title: string;
    successMessage: string;
    errorMessage: string;
    backLink?: string;
};
export const DeleteModal: React.FC<DeleteModalProps> = ({
    onClose,
    url,
    title,
    successMessage,
    errorMessage,
    backLink,
}) => {
    const navigate = useNavigate();
    const deleteNews = () =>
        axios
            .delete(url)
            .then(() => {
                toast.success(successMessage);
                onClose();
                if (backLink) {
                    navigate(backLink);
                } else {
                    window.history.back();
                }
                window.location.reload();
            })
            .catch(() => {
                toast.error(errorMessage);
            });

    return (
        <Modal>
            <div className={styles.Modal}>
                <h2 className={styles.ModalTitle}>{title}</h2>
                <div className={styles.buttonWrapper}>
                    <button
                        type="button"
                        className={styles.ModalButton}
                        onClick={async () => {
                            deleteNews();
                        }}
                    >
                        Да
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className={styles.ModalButton}
                    >
                        Нет
                    </button>
                </div>
            </div>
        </Modal>
    );
};
