import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CompanyListType } from './CompanySlice';

export type CandidateListItemType = {
    position: string;
    contact_email: string;
    contact_phone: string;
    email: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    photo: string;
    id: number;
};

export type VacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    company_title: string;
    reaction_count: number;
};

export type VacancyListType = {
    page_count: number;
    current_page: number;
    objects: VacancyListItemType[];
};

export type ReactionListType = {
    page_count: number;
    current_page: number;
    objects: {
        id: number;
        status: string;
        comment: string;
        in_user_favorite: boolean;
        candidate: CandidateListItemType;
        vacancy: VacancyListItemType;
    }[];
};

const initialState: CompanyReactionState = {
    reactionList: undefined,
    reactionListPage: 1,
    reactionListIsLoading: false,

    vacancyList: undefined,
    vacancyListIsLoading: false,

    companyList: undefined,
    companyListIsLoading: false,
};

interface CompanyReactionState {
    reactionList: ReactionListType | undefined;
    reactionListPage: number;
    reactionListIsLoading: boolean;

    vacancyList: VacancyListType | undefined;
    vacancyListIsLoading: boolean;

    companyList: CompanyListType | undefined;
    companyListIsLoading: boolean;
}

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getReactionList = createAsyncThunk(
    'staff/getReactionList',
    async function name(
        { page, size }: { page: number; size: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/reaction/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getCompanyReactionList = createAsyncThunk(
    'staff/getCompanyReactionList',
    async function name(
        { page, size, id }: { page: number; size: number; id: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/reaction/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getVacancyReactionList = createAsyncThunk(
    'staff/getVacancyReactionList',
    async function name(
        { page, size, id }: { page: number; size: number; id: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/reaction/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getVacancyList = createAsyncThunk(
    'staff/getVacancyList',
    async function name(
        { page, size, id }: { page: number; size: number; id: string },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/vacancy/`,
                { params: { page, size, status: 'OP' } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCompanyList = createAsyncThunk(
    'staff/getCompanyList',
    async function name(
        { page, size }: { page: number; size: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const staffReactionSlice = createSlice({
    name: 'staffReactionSlice',
    initialState,
    reducers: {
        updateReactionListPage(state, action: PayloadAction<number>) {
            state.reactionListPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReactionList.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(getReactionList.fulfilled, (state, action) => {
            state.reactionListIsLoading = false;
            state.reactionList = action.payload;
        });
        builder.addCase(getReactionList.rejected, (state, action) => {
            state.reactionListIsLoading = false;
        });

        builder.addCase(getCompanyReactionList.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(getCompanyReactionList.fulfilled, (state, action) => {
            state.reactionListIsLoading = false;
            state.reactionList = action.payload;
        });
        builder.addCase(getCompanyReactionList.rejected, (state, action) => {
            state.reactionListIsLoading = false;
        });

        builder.addCase(getVacancyReactionList.pending, (state) => {
            state.reactionListIsLoading = true;
        });
        builder.addCase(getVacancyReactionList.fulfilled, (state, action) => {
            state.reactionListIsLoading = false;
            state.reactionList = action.payload;
        });
        builder.addCase(getVacancyReactionList.rejected, (state, action) => {
            state.reactionListIsLoading = false;
        });

        builder.addCase(getVacancyList.pending, (state) => {
            state.vacancyListIsLoading = true;
        });
        builder.addCase(getVacancyList.fulfilled, (state, action) => {
            state.vacancyListIsLoading = false;
            state.vacancyList = action.payload;
        });
        builder.addCase(getVacancyList.rejected, (state, action) => {
            state.vacancyListIsLoading = false;
        });

        builder.addCase(getCompanyList.pending, (state) => {
            state.companyListIsLoading = true;
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {
            state.companyListIsLoading = false;
            state.companyList = action.payload;
        });
        builder.addCase(getCompanyList.rejected, (state, action) => {
            state.companyListIsLoading = false;
        });
    },
});

export const { updateReactionListPage } = staffReactionSlice.actions;

export default staffReactionSlice.reducer;
