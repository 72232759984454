import { EventRegistration } from '../components/EventRegistrationPopup/EventRegistration';
import { Login } from '../components/Login/Login';
import { Register } from '../components/Register/Register';
import { VacancyRetrieve } from '../components/VacancyRetrieve/VacancyRetrieve';

import { EditProfile as EditCompanyProfile } from '../pages/CompanyPages/MainProfile/EditProfile/EditProfile';

import { StudentsRetrieve } from '../components/StudentsRetrieve/StudentsRetrieve';
import { EditContactProfile } from '../pages/CompanyPages/MainProfile/EditProfile/EditContactProfile';
import { CompanyProfile } from '../pages/CompanyPages/MainProfile/Profile/CompanyProfile';
import { CompanyReactionList } from '../pages/CompanyPages/ReactionList/ReactionList';
import { CompanyVacancy } from '../pages/CompanyPages/Vacancy/Vacancy';
import { CompanyVacancyEdit } from '../pages/CompanyPages/VacancyEdit/VacancyEdit';
import { CompanyVacancyList } from '../pages/CompanyPages/VacancyList/VacancyList';
import { AboutPage } from '../pages/MainPages/About/AboutPage';
import { EventsPage } from '../pages/MainPages/Events/EventsPage';
import { EventsItemPage } from '../pages/MainPages/EventsItem/EventsItemPage';
import { HelpPage } from '../pages/MainPages/Help/HelpPage';
import { MainPage } from '../pages/MainPages/Main/MainPage';
import { NewsPage } from '../pages/MainPages/News/NewsPage';
import { NewsItemPage } from '../pages/MainPages/NewsItem/NewsItemPage';
import { PartnerPage } from '../pages/MainPages/Partner/PartnerPage';
import { AddEvent } from '../pages/StaffPages/Events/AddEvent/AddEvent';
import { EditEvent } from '../pages/StaffPages/Events/EditEvent/EditEvent';
import { AddNews } from '../pages/StaffPages/News/AddNews/AddNews';
import { EditNews } from '../pages/StaffPages/News/EditNews/EditNews';
import { NewsList } from '../pages/StaffPages/News/NewsList/NewsList';
import { PartnerList } from '../pages/StaffPages/PartnerList/PartnerList';
import { StaffReactionList } from '../pages/StaffPages/ReactionList/ReactionList';
import { AddShow } from '../pages/StaffPages/ShowList/AddShow/AddShow';
import { ShowList } from '../pages/StaffPages/ShowList/ShowList';
import { EditProfile as EditStaffProfile } from '../pages/StaffPages/StaffProfile/EditProfile/EditProfile';
import { StaffProfile } from '../pages/StaffPages/StaffProfile/StaffProfile';
import { StudentsList } from '../pages/StaffPages/StudentsList/StudentsList';
import { SupportList } from '../pages/StaffPages/SupportList/SupportList';
import { StaffVacancyList } from '../pages/StaffPages/VacancyList/VacancyList';
import { UsersVerification } from '../pages/StaffPages/Verification/Registration/Registration';
import { Events } from '../pages/StudentPages/Events/Events/Events';
import { FavouritesEvents } from '../pages/StudentPages/Events/FavouritesEvents/FavouritesEvents';
import { ParticipateEvents } from '../pages/StudentPages/Events/ParticipateEvents/ParticipateEvents';
import { EditProfile } from '../pages/StudentPages/MainProfile/EditProfile/EditProfile';
import { StudentProfile } from '../pages/StudentPages/MainProfile/Profile/StudentProfile';
import { EditAbout } from '../pages/StudentPages/Resume/EditAbout/EditAbout';
import { EditEducation } from '../pages/StudentPages/Resume/EditEducation/EditEducation';
import { EditExperiance } from '../pages/StudentPages/Resume/EditExperiance/EditExperiance';
import { EditLanguages } from '../pages/StudentPages/Resume/EditLanguages/EditLanguages';
import { EditSkills } from '../pages/StudentPages/Resume/EditSkills/EditSkills';
import { EditSocActivity } from '../pages/StudentPages/Resume/EditSocActivity/EditSocActivity';
import { StudentResume } from '../pages/StudentPages/Resume/Resume/Resume';
import { StudentVacancyFavoriteList } from '../pages/StudentPages/VacancyList/VacancyFavoriteList';
import { StudentVacancyList } from '../pages/StudentPages/VacancyList/VacancyList';
import { StudentVacancyReactionList } from '../pages/StudentPages/VacancyList/VacancyReactionList';

export const PATH_DASHBOARD = {
    root: '/',
    register: (pathname: string) => `${pathname}/register`,
    login: (pathname: string) => `${pathname}/login`,
    eventreg: (pathname: string) => `${pathname}/event_reg`,
    news: '/news',
    about: '/about',
    events: '/events',
    help: '/help',
    partners: '/partners',
    newsItem: (id: number) => `/news/:id`,
    eventsItem: (id: number) => `/events/:id`,
    student: {
        root: '/candidate',
        profile: {
            root: '/candidate/profile',
            edit: '/candidate/profile/edit',
        },
        resume: {
            root: '/candidate/resume',
            edit: {
                about: '/candidate/edit_about',
                education: '/candidate/edit_edu',
                languages: '/candidate/edit_languages',
                experiance: '/candidate/edit_exp',
                skills: '/candidate/edit_skills',
                socialActivities: '/candidate/edit_socialactivities',
            },
        },
        events: {
            root: '/candidate/events',
            participate: '/candidate/events/participate',
            favourites: '/candidate/events/favourites',
        },
        vacancies: {
            root: '/candidate/vacancies',
            modal: '/candidate/vacancies?reaction=true',
            reaction: '/candidate/vacancies-reaction',
            favorite: '/candidate/vacancies-favorite',
            retrieve: '/candidate/vacancies/:id',
        },
    },
    staff: {
        root: '/staff',
        edit: '/staff/edit',
        support: {
            root: '/staff/support',
            all: '/staff/support/all',
            my: '/staff/support/my',
        },
        about: '/staff/about',
        verification: {
            root: '/staff/verification',
            events: '/staff/events',
            registration: '/staff/registration',
        },
        studentsList: '/staff/students_list',
        studentsRetrieve: '/staff/students_list/:id',
        partnerList: '/staff/partner_list',
        show: {
            root: '/staff/show-list',
            addShow: '/staff/show-add',
        },
        reactionList: '/staff/reaction_list',
        vacancies: {
            root: '/staff/vacancies',
            retrieve: '/staff/vacancies/:id',
        },
        feedbackList: '/staff/feedback_list',
        events: {
            root: '/staff/events',
            addEvent: '/staff/events_add',
            editEvent: '/staff/events_edit/:id',
        },
        coursesList: '/staff/courses_list',
        tests: '/staff/courses_list',
        news: {
            root: '/staff/news_list',
            addNews: '/staff/add_news',
            editNews: '/staff/news_edit/:id',
        },
    },
    company: {
        root: '/company',
        edit: '/company/edit',
        contactEdit: '/company/contact/edit',
        vacancies: {
            root: '/company/vacancies',
            create: '/company/vacancies/create',
            retrieve: '/company/vacancies/:id',
            edit: '/company/vacancies/edit/:id',
        },
        studentsList: '/company/students_list',
        studentsRetrieve: '/company/students_list/:id',
        events: {
            root: '/company/events',
            participate: '/company/events/participate',
            favourites: '/company/events/favourites',
        },
        reaction: '/company/reaction',
    },
};

export const RouterConfig = [
    //роутинг главных страниц
    {
        path: PATH_DASHBOARD.root,
        element: <MainPage />,
    },
    {
        path: String(PATH_DASHBOARD.register),
        element: <Register />,
    },
    {
        path: String(PATH_DASHBOARD.login),
        element: <Login />,
    },
    {
        path: String(PATH_DASHBOARD.eventreg),
        element: <EventRegistration />,
    },
    {
        path: PATH_DASHBOARD.news,
        element: <NewsPage />,
    },
    {
        path: PATH_DASHBOARD.events,
        element: <EventsPage />,
    },
    {
        path: PATH_DASHBOARD.about,
        element: <AboutPage />,
    },
    {
        path: PATH_DASHBOARD.partners,
        element: <PartnerPage />,
    },
    {
        path: PATH_DASHBOARD.help,
        element: <HelpPage />,
    },
    {
        path: PATH_DASHBOARD.newsItem(1),
        element: <NewsItemPage />,
    },
    {
        path: PATH_DASHBOARD.eventsItem(1),
        element: <EventsItemPage />,
    },
    //роутинг страниц личного кабинета студента
    {
        path: PATH_DASHBOARD.student.root,
        element: <StudentProfile />,
    },
    {
        path: PATH_DASHBOARD.student.profile.edit,
        element: <EditProfile />,
    },
    {
        path: PATH_DASHBOARD.student.resume.root,
        element: <StudentResume />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.about,
        element: <EditAbout />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.education,
        element: <EditEducation />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.languages,
        element: <EditLanguages />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.experiance,
        element: <EditExperiance />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.skills,
        element: <EditSkills />,
    },
    {
        path: PATH_DASHBOARD.student.resume.edit.socialActivities,
        element: <EditSocActivity />,
    },

    {
        path: PATH_DASHBOARD.student.events.root,
        element: <Events />,
    },
    {
        path: PATH_DASHBOARD.student.events.favourites,
        element: <FavouritesEvents />,
    },
    {
        path: PATH_DASHBOARD.student.events.participate,
        element: <ParticipateEvents />,
    },
    {
        path: PATH_DASHBOARD.student.vacancies.root,
        element: <StudentVacancyList />,
    },
    {
        path: PATH_DASHBOARD.student.vacancies.reaction,
        element: <StudentVacancyReactionList />,
    },
    {
        path: PATH_DASHBOARD.student.vacancies.favorite,
        element: <StudentVacancyFavoriteList />,
    },
    {
        path: PATH_DASHBOARD.student.vacancies.retrieve,
        element: <VacancyRetrieve />,
    },
    {
        path: PATH_DASHBOARD.student.vacancies.modal,
        element: <Login />,
    },
    //роутинг страниц личного кабинета сотрудника(админа)
    {
        path: PATH_DASHBOARD.staff.root,
        element: <StaffProfile />,
    },
    {
        path: PATH_DASHBOARD.staff.edit,
        element: <EditStaffProfile />,
    },
    {
        path: PATH_DASHBOARD.staff.verification.registration,
        element: <UsersVerification />,
    },
    {
        path: PATH_DASHBOARD.staff.news.root,
        element: <NewsList />,
    },
    {
        path: PATH_DASHBOARD.staff.news.addNews,
        element: <AddNews />,
    },
    {
        path: PATH_DASHBOARD.staff.news.editNews,
        element: <EditNews />,
    },
    {
        path: PATH_DASHBOARD.staff.events.root,
        element: <Events />,
    },
    {
        path: PATH_DASHBOARD.staff.events.addEvent,
        element: <AddEvent />,
    },
    {
        path: PATH_DASHBOARD.staff.events.editEvent,
        element: <EditEvent />,
    },
    {
        path: PATH_DASHBOARD.staff.show.root,
        element: <ShowList />,
    },
    {
        path: PATH_DASHBOARD.staff.show.addShow,
        element: <AddShow />,
    },
    {
        path: PATH_DASHBOARD.staff.partnerList,
        element: <PartnerList />,
    },
    {
        path: PATH_DASHBOARD.staff.studentsList,
        element: <StudentsList />,
    },
    {
        path: PATH_DASHBOARD.staff.studentsRetrieve,
        element: <StudentsRetrieve />,
    },
    {
        path: PATH_DASHBOARD.staff.support.all,
        element: <SupportList />,
    },
    {
        path: PATH_DASHBOARD.staff.reactionList,
        element: <StaffReactionList />,
    },
    //роутинг страниц личного кабинета компании
    {
        path: PATH_DASHBOARD.company.root,
        element: <CompanyProfile />,
    },
    {
        path: PATH_DASHBOARD.company.edit,
        element: <EditCompanyProfile />,
    },
    {
        path: PATH_DASHBOARD.company.contactEdit,
        element: <EditContactProfile />,
    },
    {
        path: PATH_DASHBOARD.company.vacancies.root,
        element: <CompanyVacancyList />,
    },
    {
        path: PATH_DASHBOARD.company.vacancies.create,
        element: <CompanyVacancy />,
    },
    {
        path: PATH_DASHBOARD.company.vacancies.edit,
        element: <CompanyVacancyEdit />,
    },
    {
        path: PATH_DASHBOARD.company.vacancies.retrieve,
        element: <VacancyRetrieve />,
    },
    {
        path: PATH_DASHBOARD.company.studentsList,
        element: <StudentsList />,
    },
    {
        path: PATH_DASHBOARD.company.studentsRetrieve,
        element: <StudentsRetrieve />,
    },

    {
        path: PATH_DASHBOARD.company.events.root,
        element: <Events />,
    },
    {
        path: PATH_DASHBOARD.company.events.favourites,
        element: <FavouritesEvents />,
    },
    {
        path: PATH_DASHBOARD.company.events.participate,
        element: <ParticipateEvents />,
    },
    {
        path: PATH_DASHBOARD.company.reaction,
        element: <CompanyReactionList />,
    },
    {
        path: PATH_DASHBOARD.staff.vacancies.root,
        element: <StaffVacancyList />,
    },
    {
        path: PATH_DASHBOARD.staff.vacancies.retrieve,
        element: <VacancyRetrieve />,
    },
];

export default RouterConfig;
