/* eslint-disable max-len */
import styles from './AboutPage.module.scss';

import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PageHeader } from '../../../components/PageHeader/PageHeader';

import goalImageSrc from '../../../assets/images/Goal.png';
import BannerImage from '../../../assets/images/AboutPhoto.png';


export const AboutPage = () => {
    return (
        <PageLayout>
            <div className={styles.about}>
                <div className={styles.about__wrapper}>
                    <PagePagination linkName={'О нас'} />
                    <PageHeader
                        title={'О нас'}
                        description={
                            'С 2009 г. функционирует Центр карьеры Юридического факультета МГУ,который объединяет студентов, выпускников и работодателей, оказывает помощь в профессиональной ориентации студентов и выпускников и способствует построению ими успешной карьеры.'
                        }
                        className={styles.AboutHeader}
                    />
                    <Goals />
                    <Banners />
                    <Offers />
                </div>
            </div>
        </PageLayout>
    );
};

const goalsData = [
    {
        id: 1,
        title: 'Содействие занятости',
        description:
            'и трудоустройству студентов и выпускников Юридического факультета МГУ',
    },
    {
        id: 2,
        title: 'Поддержка студентам',
        description:
            'Оказание информационно-консультационной поддержки студентам и выпускникам МГУ в построении успешной карьеры, профессионального роста и развития',
    },
    {
        id: 3,
        title: 'Развитие связей',
        description:
            'Развитие деловых и иных связей с компаниями-работодателями',
    },
];


const Goals = () => {
    return (
        <div className={styles.goals}>
            <div className={styles.goals__wrapper}>
                <h3 className={styles.goals__header}>Цели Центра карьеры:</h3>
                <div className={styles.goals__content}>
                    <div className={styles.goals__container}>
                        {goalsData.map((goal) => (
                            <AboutPoint goal={goal} />
                        ))}
                    </div>
                    <div className={styles.goals__photo}>
                        <img
                            className={styles.goals__image}
                            src={goalImageSrc}
                            alt={'Career center goals'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const AboutPoint = ({ goal }: AboutPointProps) => {
    return (
        <div className={styles.goal}>
            <div className={styles.goal__top}>
                <div className={styles.goal__number}>
                    <span className={styles.goal__number_text}>{goal.id}</span>
                </div>
                <h3 className={styles.goal__header}>{goal.title}</h3>
            </div>
            <div className={styles.goal__description}>{goal.description}</div>
        </div>
    );
};

interface AboutPointProps {
    goal: {
        id: number;
        title: string;
        description: string;
    };
}

interface AboutBannerProps {
    header: string;
    description?: string[];
}


export const Banners = () => {
    return (
        <div className={styles.banners}>
            <div className={styles.banners__container}>
                <AboutBanner
                    description={[
                        'размещение информации о вакансиях на сайте юридического факультета, на сайте Центра карьеры и на стендах юридического факультета в 4-м учебном корпусе',
                        'проведение презентации компании перед целевой аудиторией (целенаправленный поиск юристов из числа выпускников)',
                        'проведение информационных и образовательных программ (семинары, лекции, круглые столы, конкурсы и т.д.) с предварительным отбором студентов в соответствии с требованиями компании',
                        'содействие в поиске и подборе персонала: первичный отбор по резюме, сбор рекомендаций и характеристик на отобранных студентов или выпускников, проведение первичных собеседований с кандидатами, разработка индивидуальных программ отбора кандидатов',
                        'информационное сопровождение мероприятий компании на юридическом факультете МГУ',
                        'участие в конференциях, организуемых Центром карьеры для студентов и выпускников',
                        'участие в Днях карьеры и ярмарках вакансий, организуемых Юридическим факультетом МГУ',
                    ]}
                    header="В целях организации взаимодействия Центра карьеры и работодателей на разных уровнях Центр карьеры предлагает:"
                />
            </div>
            <div className={styles.banners__container}>
                <AboutBanner
                    description={[
                        'консультирование по вопросам профессионального и личностного роста: подготовка к прохождению профессионального отбора в компаниях, советы по написанию резюме, психологическое ориентирование на ту или иную сферу деятельности',
                        'информацию о вакансиях и стажировках на начальные позиции в ведущих компаниях от работодателей, кадровых агентств и выпускников юридического факультета МГУ',
                        'участие в презентациях компаний и в Днях карьеры, ярмарках вакансий на Юридическом факультете МГУ',
                        'участие во встречах с партнерами, руководителями HR-департаментов',
                        'участие в информационных и образовательных мероприятиях, раскрывающих способы успешного построения карьеры: бизнес-кейсы, деловые игры, семинары, тренинги, круглые столы и т.д.',
                        'полезная информация и постоянная помощь в Центре карьеры и на сайте',
                    ]}
                    header="Студентам Центр карьеры предлагает:"
                />
                <div className={styles.banners__images}>
                    <img src={BannerImage} className={styles.banners__image} />
                </div>
            </div>
        </div>
    );
};

const AboutBanner = ({ header, description }: AboutBannerProps) => {
    return (
        <div className={styles.banner}>
            <h4 className={styles.banner__header}>{header}</h4>
            <ul className={styles.banner__description}>
                {description &&
                    description.map((desc) => (
                        <li className={styles.banner__description_element}>
                            {desc}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

const offersData = [
    {
        id: 1,
        title: 'Топ вакансии',
        description:
            'Вакансии и стажировки на средних и топовых (требующих наличия опыта) позициях в ведущих компаниях',
    },
    {
        id: 2,
        title: 'Вовлеченность выпускников',
        description: 'Участие во встречах выпускников МГУ',
    },
    {
        id: 3,
        title: 'Проведение презентаций',
        description: 'Проведение презентаций их компаний',
    },
    {
        id: 4,
        title: 'Нетворкинг',
        description:
            'Возможность поделиться опытом со студентами посредством участия в информационных и образовательных программах Центра',
    },
];

const Offers = () => {
    return (
        <div className={styles.offers}>
            <h1 className={styles.offers__header}>
                Выпускникам Центр карьеры предлагает:
            </h1>
            <div className={styles.offers__wrapper}>
                {offersData.map((offer) => (
                    <AboutPoint goal={offer} />
                ))}
            </div>
        </div>
    );
};
