// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tag_tag__date__2Ftbo {\n  background-color: var(--accented-color);\n  font-size: 15px;\n  padding: 7px 10px;\n  font: var(--font-s);\n  border: none;\n  border-radius: 17px;\n  margin-right: auto;\n}\n.Tag_tag__format__C7-Zi {\n  background-color: #f5f8ff;\n  padding: 7px 10px;\n  font: var(--font-s);\n  font-size: 15px;\n  border: none;\n  border-radius: 17px;\n}\n.Tag_tag__blue__d4L1X {\n  background-color: #11139F;\n  color: white;\n}\n.Tag_tag__red__gsAU1 {\n  background-color: red;\n  color: white;\n}", "",{"version":3,"sources":["webpack://./src/components/Tag/Tag.module.scss"],"names":[],"mappings":"AACI;EACI,uCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EAEA,YAAA;EACA,mBAAA;EAEA,kBAAA;AAFR;AAII;EACI,yBAAA;EACA,iBAAA;EAEA,mBAAA;EACA,eAAA;EAEA,YAAA;EACA,mBAAA;AAJR;AAMI;EACI,yBAAA;EACA,YAAA;AAJR;AAMI;EACI,qBAAA;EACA,YAAA;AAJR","sourcesContent":[".tag {\n    &__date {\n        background-color: var(--accented-color);\n        font-size: 15px;\n        padding: 7px 10px;\n        font: var(--font-s);\n\n        border: none;\n        border-radius: 17px;\n\n        margin-right: auto;\n    }\n    &__format {\n        background-color: #f5f8ff;\n        padding: 7px 10px;\n\n        font: var(--font-s);\n        font-size: 15px;\n\n        border: none;\n        border-radius: 17px;\n    }\n    &__blue {\n        background-color: #11139F;\n        color: white;\n    }\n    &__red {\n        background-color: red;\n        color: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag__date": "Tag_tag__date__2Ftbo",
	"tag__format": "Tag_tag__format__C7-Zi",
	"tag__blue": "Tag_tag__blue__d4L1X",
	"tag__red": "Tag_tag__red__gsAU1"
};
export default ___CSS_LOADER_EXPORT___;
