import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LeftArrow } from '../../assets/icons';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { getCompanyVacancyRetrieve } from '../../store/reducers/CompanyVacancySlice';
import { Role } from '../../types/types';
import { Loader } from '../Loader/Loader';
import styles from './VacancyRetrieve.module.scss';

export const VacancyRetrieve = () => {
    const { id } = useParams();

    const { companyVacancyRetrieve, companyVacancyRetrieveIsLoading } =
        useAppSelector((state) => state.companyVacancyReducer);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (id) {
            dispatch(getCompanyVacancyRetrieve(Number(id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const required_experience: { value: string; label: string }[] = [
        { value: 'NO', label: 'Нет опыта' },
        { value: 'L1', label: 'До 1 года' },
        { value: '13', label: 'От 1 до 3 лет' },
        { value: 'M3', label: 'Более 3 лет' },
    ];

    const employment_form: { value: string; label: string }[] = [
        { value: 'FE', label: 'Полная занятость' },
        { value: 'PE', label: 'Частичная занятость' },
        { value: 'RW', label: 'Удаленная работа' },
        { value: 'IS', label: 'Стажировка' },
        { value: 'SH', label: 'Вахта' },
        { value: 'AL', label: 'Все' },
    ];

    const { role } = useAppSelector((state) => state.authReducer);

    const linkBack = useMemo(() => {
        if (role === Role.candidate) {
            return PATH_DASHBOARD.student.vacancies.root;
        }
        if (role === Role.staff) {
            return PATH_DASHBOARD.staff.vacancies.root;
        }
        return PATH_DASHBOARD.company.vacancies.root;
    }, [role]);

    if (companyVacancyRetrieveIsLoading) {
        return (
            <div className={styles.vacancyRetrieve}>
                <Loader />
            </div>
        );
    }
    return (
        <div className={styles.vacancyRetrieve}>
            <Link className={styles.backLink} to={linkBack}>
                <LeftArrow />
                <span className={styles.backLink}>К списку вакансий</span>
            </Link>
            <div className={styles.vacancy}>
                <div className={styles.company}>
                    <h1 className={styles.position}>
                        {companyVacancyRetrieve?.position}
                    </h1>
                    <div>
                        {companyVacancyRetrieve?.salary_from &&
                        companyVacancyRetrieve.salary_to ? (
                            <span className={styles.salary}>
                                {companyVacancyRetrieve?.salary_from} -{' '}
                                {companyVacancyRetrieve?.salary_to} руб.
                            </span>
                        ) : null}
                        {companyVacancyRetrieve?.date_update ? (
                            <span className={styles.updateDate}>
                                Дата публикации:{' '}
                                {new Date(
                                    companyVacancyRetrieve?.date_update,
                                ).toLocaleDateString('ru-RU', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}
                            </span>
                        ) : null}
                    </div>
                </div>
                <div className={styles.about}>
                    <div className={styles.column}>
                        <div className={styles.experience}>
                            <h3 className={styles.aboutTitle}>
                                Требуемый опыт работы
                            </h3>
                            <span className={styles.aboutText}>
                                {required_experience.find(
                                    (it) =>
                                        it.value ===
                                        companyVacancyRetrieve?.required_experience,
                                )?.label ||
                                    companyVacancyRetrieve?.required_experience}
                            </span>
                        </div>
                        <div>
                            <h3 className={styles.aboutTitle}>Занятость</h3>
                            <span className={styles.aboutText}>
                                {companyVacancyRetrieve?.employment_form
                                    ? companyVacancyRetrieve?.employment_form
                                          .map((item) => {
                                              return (
                                                  employment_form.find(
                                                      (it) => it.value === item,
                                                  )?.label || item
                                              );
                                          })
                                          .join(', ')
                                    : ''}
                            </span>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.employment}>
                            <h3 className={styles.aboutTitle}>
                                Ключевые навыки
                            </h3>
                            <div className={styles.employmentForm}>
                                {companyVacancyRetrieve?.skills
                                    .split(',')
                                    .map((item) => (
                                        <span
                                            className={
                                                styles.employmentFormItem
                                            }
                                        >
                                            {item}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.description}>
                    {companyVacancyRetrieve?.description
                        .split('\n')
                        .map((item) => {
                            return <p>{item}</p>;
                        })}
                </div>
            </div>
        </div>
    );
};
