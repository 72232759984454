// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResumeBanner_banner__WCWhy {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #DFDFDF;\n  border-radius: 17px;\n  font: var(--font-s);\n}\n.ResumeBanner_banner__top__tHDCo {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 17px 25px;\n  width: 100%;\n  border-bottom: 1px solid #DFDFDF;\n}\n.ResumeBanner_banner__content__EDDFx {\n  padding: 15px 25px;\n}\n.ResumeBanner_banner__button__lh-V3 {\n  border: none;\n  font: var(--font-s);\n  color: #11139F;\n}", "",{"version":3,"sources":["webpack://./src/components/ResumeBanner/ResumeBanner.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EAEA,yBAAA;EACA,mBAAA;EAEA,mBAAA;AADJ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EAEA,kBAAA;EACA,WAAA;EAEA,gCAAA;AAFR;AAII;EACI,kBAAA;AAFR;AAII;EACI,YAAA;EACA,mBAAA;EACA,cAAA;AAFR","sourcesContent":[".banner{\n    display: flex;\n    flex-direction: column;\n\n    border: 1px solid #DFDFDF;\n    border-radius: 17px;\n\n    font: var(--font-s);\n    &__top{\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        padding: 17px 25px;\n        width: 100%;\n        \n        border-bottom: 1px solid #DFDFDF;\n    }\n    &__content{\n        padding: 15px 25px;  \n    }\n    &__button{\n        border: none;\n        font: var(--font-s);\n        color: #11139F;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "ResumeBanner_banner__WCWhy",
	"banner__top": "ResumeBanner_banner__top__tHDCo",
	"banner__content": "ResumeBanner_banner__content__EDDFx",
	"banner__button": "ResumeBanner_banner__button__lh-V3"
};
export default ___CSS_LOADER_EXPORT___;
