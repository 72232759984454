/* eslint-disable @typescript-eslint/no-unused-vars */
import cn from 'classnames';
import { CabinetBanner } from '../../../../components/ResumeBanner/ResumeBanner';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../../routes/RouterConfig';
import styles from './About.module.scss';

export const CompanyAbout = () => {
    return (
        <div className={styles.companyProfile}>
            <div className={styles.CabinetPage}>
                <div className={styles.CabinetPage__wrapper}>
                    <Banners />
                </div>
            </div>
        </div>
    );
};

const Banners = () => {
    const StudentPageBanners = [
        {
            id: 1,
            title: 'Контактное лицо',
            children: <ContactInformation />,
        },
    ];
    return (
        <div className={styles.CabinetPage__banners}>
            {StudentPageBanners.map((banner) => (
                <CabinetBanner
                    key={banner.id}
                    title={banner.title}
                    children={banner.children}
                    link={PATH_DASHBOARD.company.contactEdit}
                />
            ))}
        </div>
    );
};

//о себе
const ContactInformation = () => {
    const company = useAppSelector((state) => state.companyReducer.company);

    const aboutData = [
        {
            id: 1,
            title: 'ФИО Сотрудника',
            content: company
                ? [
                      company.second_name,
                      company.first_name,
                      company.last_name,
                  ].join(' ')
                : 'Не задано',
        },
        {
            id: 2,
            title: 'Email',
            content: company?.contact_email
                ? company.contact_email
                : 'Не задано',
        },
        {
            id: 3,
            title: 'Телефон',
            content: company?.contact_phone
                ? company.contact_phone
                : 'Не задано',
        },
        {
            id: 4,
            title: 'Должность',
            content: company?.contact_person_position
                ? company.contact_person_position
                : 'Не задано',
        },
    ];
    return (
        <div className={styles.about}>
            {aboutData.map((aboutElement) => (
                <div
                    key={aboutElement.id}
                    className={cn(styles.about__element)}
                >
                    <h3 className={styles.about__header}>
                        {aboutElement.title}
                    </h3>
                    <span className={styles.about__content}>
                        {aboutElement.content
                            ? aboutElement.content
                            : 'Не задано'}
                    </span>
                </div>
            ))}
        </div>
    );
};
