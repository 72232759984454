import { default as classNames, default as cn } from 'classnames';
import { Dispatch, useEffect, useState } from 'react';
import Button from '../../../../UI/Button/Button';
import { Loader } from '../../../../components/Loader/Loader';
import { Modal } from '../../../../components/Modal/Modal';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import {
    CompanyListItemType,
    confirmCompanyList,
    deleteCompany,
    getUnverifiedCompanyList,
    updateUnverifiedCompanyListPage,
} from '../../../../store/reducers/CompanySlice';
import {
    confirmStudent,
    deleteStudent,
    getUnverifiedStudentList,
    updateUnverifiedStudentListPage,
} from '../../../../store/reducers/StudentsSlice';
import { Candidate } from '../../../../types/types';
import Pagination from '../../SupportList/Pagination';
import styles from './Registration.module.scss';

type VerificationModalProps = {
    modal: { id: number; variant: 'student' | 'company' } | undefined;
    setModal: Dispatch<undefined>;
    refetch: () => void;
};
export const VerificationModal: React.FC<VerificationModalProps> = ({
    modal,
    setModal,
    refetch,
}) => {
    const dispatch = useAppDispatch();

    const closePopup = () => {
        setModal(undefined);
    };
    return (
        <Modal>
            <div className={styles.vacancyModal}>
                <h2 className={styles.vacancyModalTitle}>
                    Вы уверены, что хотите удалить заявку?
                </h2>
                <div className={styles.buttonWrapper}>
                    <button
                        type="button"
                        className={styles.vacancyModalButton}
                        onClick={async () => {
                            if (modal?.variant === 'student') {
                                await dispatch(deleteStudent(modal.id));
                            }
                            if (modal?.variant === 'company') {
                                await dispatch(deleteCompany(modal.id));
                            }
                            refetch();
                            closePopup();
                        }}
                    >
                        Да
                    </button>
                    <button
                        type="button"
                        onClick={closePopup}
                        className={styles.vacancyModalButton}
                    >
                        Нет
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export const UsersVerification = () => {
    const [variant, setVariant] = useState<'student' | 'company'>('student');
    const {
        unverifiedCompanyList,
        unverifiedCompanyListPage,
        unverifiedCompanyListIsLoading,
    } = useAppSelector((state) => state.companyReducer);

    const {
        unverifiedStudentList,
        unverifiedStudentListPage,
        unverifiedStudentListIsLoading,
    } = useAppSelector((state) => state.studentsReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (variant === 'student') {
            dispatch(
                getUnverifiedStudentList({
                    page: unverifiedStudentListPage,
                    size: 20,
                }),
            );
        } else {
            dispatch(
                getUnverifiedCompanyList({
                    page: unverifiedCompanyListPage,
                    size: 20,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unverifiedStudentListPage, variant, unverifiedCompanyListPage]);

    return (
        <div className={styles.UsersVerification}>
            <div className={styles.UsersVerification__wrapper}>
                <div className={styles.UserToggler}>
                    <button
                        className={classNames(
                            styles.UserToggler__element,
                            variant === 'student' && styles.UserToggler__active,
                        )}
                        onClick={() => setVariant('student')}
                    >
                        Студент
                    </button>
                    <button
                        className={classNames(
                            styles.UserToggler__element,
                            variant === 'company' && styles.UserToggler__active,
                        )}
                        onClick={() => setVariant('company')}
                    >
                        Партнер
                    </button>
                </div>
                {!unverifiedStudentListIsLoading &&
                variant === 'student' &&
                unverifiedStudentList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        Заявок пока нет!
                    </span>
                ) : null}
                {unverifiedStudentListIsLoading && variant === 'student' && (
                    <Loader />
                )}
                {variant === 'student' ? (
                    <>
                        <StudentVerification />
                        <Pagination
                            list={unverifiedStudentList}
                            handlePagination={(p) => {
                                dispatch(updateUnverifiedStudentListPage(p));
                            }}
                            isLoading={unverifiedStudentListIsLoading}
                        />
                    </>
                ) : null}

                {!unverifiedCompanyListIsLoading &&
                variant === 'company' &&
                unverifiedCompanyList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        Заявок пока нет!
                    </span>
                ) : null}
                {unverifiedCompanyListIsLoading && variant === 'company' && (
                    <Loader />
                )}
                {variant === 'company' ? (
                    <>
                        <CompanyVerification />
                        <Pagination
                            list={unverifiedCompanyList}
                            handlePagination={(p) => {
                                dispatch(updateUnverifiedCompanyListPage(p));
                            }}
                            isLoading={unverifiedCompanyListIsLoading}
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
};

const StudentVerification = () => {
    const { unverifiedStudentList, unverifiedStudentListPage } = useAppSelector(
        (state) => state.studentsReducer,
    );
    const [modal, setModal] = useState<
        { id: number; variant: 'student' | 'company' } | undefined
    >(undefined);

    const dispatch = useAppDispatch();

    const refetch = () =>
        dispatch(
            getUnverifiedStudentList({
                page: unverifiedStudentListPage,
                size: 20,
            }),
        );

    return unverifiedStudentList?.objects &&
        unverifiedStudentList?.objects.length !== 0 ? (
        <div className={styles.cards}>
            {modal ? (
                <VerificationModal
                    modal={modal}
                    setModal={setModal}
                    refetch={refetch}
                />
            ) : null}
            {unverifiedStudentList.objects.map((student) => (
                <StudentVerificationCard
                    key={`company-item-${student.id}`}
                    candidate={student}
                    setModal={setModal}
                ></StudentVerificationCard>
            ))}
        </div>
    ) : null;
};

type StudentVerificationCardProps = {
    candidate: Candidate;
    setModal: Dispatch<
        { id: number; variant: 'student' | 'company' } | undefined
    >;
};
const StudentVerificationCard: React.FC<StudentVerificationCardProps> = ({
    candidate,
    setModal,
}) => {
    const dispatch = useAppDispatch();
    const [isVerificated] = useState<boolean>(false);
    const { unverifiedStudentListPage } = useAppSelector(
        (state) => state.studentsReducer,
    );
    return (
        <div className={styles.card}>
            <div className={styles.card__wrapper}>
                <h2 className={styles.card__header}>
                    {[
                        candidate.second_name,
                        candidate.first_name,
                        candidate.last_name,
                    ].join(' ')}
                </h2>
                <span className={styles.card__email}>{candidate.email}</span>
                <span className={styles.card__number}>
                    {candidate.document_number}
                </span>
                <div className={styles.card__buttonWrapper}>
                    <Button
                        onClick={async () => {
                            await dispatch(confirmStudent(candidate.id));
                            dispatch(
                                getUnverifiedStudentList({
                                    page: unverifiedStudentListPage,
                                    size: 20,
                                }),
                            );
                        }}
                        className={cn(styles.card__button, {
                            [styles.card__button_active]: isVerificated,
                        })}
                    >
                        {isVerificated
                            ? 'Профиль подтвержден'
                            : 'Подтвердить профиль'}
                    </Button>
                    <Button
                        onClick={() => {
                            setModal({ id: candidate.id, variant: 'student' });
                        }}
                        className={cn(styles.card__button)}
                    >
                        Удалить заявку
                    </Button>
                </div>
            </div>
        </div>
    );
};

const CompanyVerification = () => {
    const { unverifiedCompanyList, unverifiedCompanyListPage } = useAppSelector(
        (state) => state.companyReducer,
    );

    const [modal, setModal] = useState<
        { id: number; variant: 'student' | 'company' } | undefined
    >(undefined);

    const dispatch = useAppDispatch();

    const refetch = () =>
        dispatch(
            getUnverifiedCompanyList({
                page: unverifiedCompanyListPage,
                size: 20,
            }),
        );

    return unverifiedCompanyList?.objects &&
        unverifiedCompanyList?.objects.length !== 0 ? (
        <div className={styles.cards}>
            {modal ? (
                <VerificationModal
                    modal={modal}
                    setModal={setModal}
                    refetch={refetch}
                />
            ) : null}
            {unverifiedCompanyList.objects.map((company) => (
                <CompanyVerificationCard
                    key={`company-item-${company.id}`}
                    company={company}
                    setModal={setModal}
                ></CompanyVerificationCard>
            ))}
        </div>
    ) : null;
};

type CompanyVerificationCardProps = {
    company: CompanyListItemType;
    setModal: Dispatch<
        { id: number; variant: 'student' | 'company' } | undefined
    >;
};
const CompanyVerificationCard: React.FC<CompanyVerificationCardProps> = ({
    company,
    setModal,
}) => {
    const dispatch = useAppDispatch();
    const [isVerificated] = useState<boolean>(false);

    const { unverifiedCompanyListPage } = useAppSelector(
        (state) => state.companyReducer,
    );

    return (
        <div className={styles.card}>
            <div className={styles.card__wrapper}>
                <h2 className={styles.card__header}>{company.full_title}</h2>
                <span className={styles.card__email}>
                    {company.short_title}
                </span>
                <span className={styles.card__email}>{company.email}</span>
                <div className={styles.card__buttonWrapper}>
                    <Button
                        onClick={async () => {
                            await dispatch(confirmCompanyList(company.id));
                            dispatch(
                                getUnverifiedCompanyList({
                                    page: unverifiedCompanyListPage,
                                    size: 20,
                                }),
                            );
                        }}
                        className={cn(styles.card__button, {
                            [styles.card__button_active]: isVerificated,
                        })}
                    >
                        {isVerificated
                            ? 'Профиль подтвержден'
                            : 'Подтвердить профиль'}
                    </Button>
                    <Button
                        onClick={() => {
                            setModal({ id: company.id, variant: 'company' });
                        }}
                        className={cn(styles.card__button)}
                    >
                        Удалить заявку
                    </Button>
                </div>
            </div>
        </div>
    );
};
