import cn from 'classnames';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { LikeIcon, RightArrowIcon } from '../../assets/icons';
import photoSrc from '../../assets/images/EventImage.png';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useChangeLike } from '../../hooks/useChangeLike';
import { IEventCard, Role } from '../../types/types';
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { LocationMark } from '../LocationMark/LocationMark';
import { Tag } from '../Tag/Tag';
import styles from './EventCard.module.scss';

export interface EventCardProps {
    event: IEventCard;
    containerClassName?: string;
    titleClassName?: string;
    imageClassName?: string;
    smallBlocksClassName?: string;
}

export const EventCard = ({
    event,
    containerClassName,
    titleClassName,
    imageClassName,
    smallBlocksClassName,
}: EventCardProps) => {
    const navigate = useNavigate();
    const [isCardLiked, setIsCardLiked] = useState(
        event.in_user_favorite_events,
    );

    const { isLogin, role } = useAppSelector((state) => state.authReducer);

    const { likeEvent, cancelLikeEvent } = useChangeLike(event.id);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const eventUrl = `${process.env.REACT_APP_BASE_URL}api/v1/event/${event.id}/`;

    return (
        <div className={styles.eventcard}>
            <Link
                to={`/events/${event.id}`}
                className={cn(styles.eventcard__imagecontainer, imageClassName)}
            >
                <img
                    className={styles.eventcard__image}
                    src={event.image ? event.image : photoSrc}
                    alt="событие"
                />
            </Link>
            <div className={cn(styles.eventcard__wrapper, containerClassName)}>
                <div className={styles.eventcard__top}>
                    <Tag
                        type="format"
                        content={event.format === 'ON' ? 'Онлайн' : 'Офлайн'}
                    />
                    <Tag
                        type="date"
                        content={new Date(event.datetime).toLocaleDateString()}
                    />
                    {role !== Role.staff ? (
                        <LikeIcon
                            onClick={() => {
                                if (!isLogin) {
                                    return navigate({
                                        search: '?login=true',
                                    });
                                }
                                isCardLiked ? cancelLikeEvent() : likeEvent();
                                setIsCardLiked(!isCardLiked);
                            }}
                            className={
                                isCardLiked
                                    ? styles.eventcard__like_active
                                    : styles.eventcard__like
                            }
                        />
                    ) : null}
                </div>
                <Link
                    to={`/events/${event.id}`}
                    className={styles.eventcard__middle}
                >
                    <h3
                        className={cn(styles.eventcard__header, titleClassName)}
                    >
                        {event.title}
                    </h3>
                </Link>
                <div className={styles.eventcard__bottom}>
                    <Button className={styles.eventcard__moreinfo}>
                        <Link
                            onClick={() => {
                                window.scroll({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                            }}
                            className={cn(
                                styles.eventcard__moreinfo_link,
                                smallBlocksClassName,
                            )}
                            to={`/events/${event.id}`}
                        >
                            <span className={styles.eventcard__moreinfo_text}>
                                Подробнее
                            </span>
                            <RightArrowIcon />
                        </Link>
                    </Button>
                    <LocationMark text={event.address} />
                </div>
                {role === 'staff' ? (
                    <div className={styles.buttons}>
                        <Button
                            onClick={() => {
                                navigate(`/staff/events_edit/${event.id}/`);
                            }}
                        >
                            Редактировать
                        </Button>
                        <Button
                            onClick={() => {
                                setShowDeleteModal(true);
                            }}
                        >
                            Удалить
                        </Button>
                    </div>
                ) : null}
            </div>
            {showDeleteModal ? (
                <DeleteModal
                    backLink="/staff/events"
                    url={eventUrl}
                    title="Вы действительно хотите удалить мероприятие?"
                    successMessage="Мероприятие удалено!"
                    errorMessage="Мероприятие не удалено!"
                    onClose={() => setShowDeleteModal(false)}
                />
            ) : null}
        </div>
    );
};
