import { useFormik } from 'formik';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { CustomSelect } from '../../../../UI/CustomSelect/CustomSelect';
import { DatePicker } from '../../../../UI/DatePicker/DatePicker';
import { Input } from '../../../../UI/Input/Input';
import styles from './EditAbout.module.scss';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { fetchStudentInfo } from '../../../../store/reducers/CandidateSlice';
import { SelectOption } from '../../../../types/types';
import { validationSchema } from './validationSchema';

export const EditAbout = () => {
    const employmentData = [
        {
            value: 'FE',
            label: 'Полная занятость',
        },
        {
            value: 'PE',
            label: 'Частичная занятость',
        },
        { value: 'RW', label: 'Удаленная работа' },
        { value: 'IS', label: 'Стажировка' },
    ];

    const { id } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    useEffect(() => {
        id && dispatch(fetchStudentInfo(id));
    }, [dispatch, id]);
    const candidate = useAppSelector(
        (state) => state.candidateReducer.candidate,
    );
    const emplObj = candidate?.employment_form.map((el) => ({
        value: el,
        // eslint-disable-next-line array-callback-return
        label: employmentData.find((dataElement: SelectOption) => {
            if (dataElement.value === el) {
                return dataElement.label;
            }
        })?.label,
    }));
    const [employment, setEmployment] = useState<Array<SelectOption>>([]);
    const formik = useFormik({
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        initialValues: {
            city: candidate?.city,
            birthday: candidate?.birthday,
            citizenship: candidate?.citizenship,
            employment: employment.filter(
                (el) => el.value !== '' && el.label !== '',
            ),
            salary: candidate?.salary,
            position: candidate?.position,
        },
        onSubmit: () => {},
        validationSchema: validationSchema,
    });
    const onSubmit = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;
            const candidateData = {
                city: formik.values.city,
                birthday: formik.values.birthday,
                citizenship: formik.values.citizenship,
                employment_form: employment
                    .map((el: SelectOption) => el.value)
                    .filter((el) => el !== ''),
                salary: formik.values.salary,
                position: formik.values.position,
            };
            await axios.put(url, candidateData);
            toast.success('Данные успешно изменены');
        } catch (e) {
            toast.error('Произошла ошибка');
        }
    };
    return (
        <EditWrapper
            isValid={formik.isValid}
            dirty={formik.dirty}
            saveOnclick={onSubmit}
            title={'О себе'}
        >
            <form
                onSubmit={formik.handleSubmit}
                className={styles.EditAbout__wrapper}
            >
                <Input
                    wrapperClassName={[styles.EditAbout__input]}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    inputHeader={'Город проживания'}
                    placeholder={'Москва'}
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.city}
                    isValid={Boolean(formik.values.city) && !formik.errors.city}
                />
                <DatePicker
                    wrapperClassName={[styles.EditAbout__input]}
                    headerText={'Дата рождения'}
                    placeholder={'12.12.1987'}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    name="birthday"
                    value={formik.values.birthday}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.birthday}
                    isValid={
                        Boolean(formik.values.birthday) &&
                        !formik.errors.birthday
                    }
                />
                <Input
                    wrapperClassName={[styles.EditAbout__input]}
                    inputHeader={'Гражданство'}
                    placeholder={'Российская Федерация'}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    name="citizenship"
                    value={formik.values.citizenship}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.citizenship}
                    isValid={
                        Boolean(formik.values.citizenship) &&
                        !formik.errors.citizenship
                    }
                />
                <CustomSelect
                    onChange={(event: any) => {
                        setEmployment(event);
                    }}
                    defaultValue={emplObj?.filter((el) => el.value !== '')}
                    title={'Форма занятости'}
                    className={styles.select}
                    titleClass={styles.select__header}
                    inputFieldClass={styles.select__field}
                    borderRadius={'17px'}
                    fieldHeight={'45px'}
                    placeholderSize={'15px'}
                    isValid={
                        candidate?.employment_form &&
                        candidate?.employment_form.length > 0 &&
                        employment.length > 0
                    }
                    isMulti={true}
                    options={employmentData}
                />
                <Input
                    wrapperClassName={[styles.EditAbout__input]}
                    inputHeader={'Зарплатные ожидания (руб)'}
                    placeholder={'60000'}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    value={formik.values.salary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={formik.errors.salary}
                    name="salary"
                    isValid={
                        Boolean(formik.values.salary) && !formik.errors.salary
                    }
                />
                <Input
                    wrapperClassName={[styles.EditAbout__input]}
                    inputHeader={'Желаемая позиция'}
                    placeholder={'Помощник юриста'}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    name="position"
                    value={formik.values.position}
                    errorMessage={formik.errors.position}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={
                        Boolean(formik.values.position) &&
                        !formik.errors.position
                    }
                />
            </form>
        </EditWrapper>
    );
};
