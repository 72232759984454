import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    fio: yup
        .string()
        .required('Поле обязательно')
        .max(100, 'Заголовок не должен содержать более 100 символов'),
    organization_institution: yup
        .string()
        .required('Поле обязательно')
        .max(100, 'Заголовок не должен содержать более 100 символов'),
    email: yup
        .string()
        .typeError('')
        .max(50, 'Поле не должно содержать более 50 символов')
        .required()
        .matches(
            /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
        ),
});
