import { useFormik } from 'formik';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { Textarea } from '../../../../UI/Textarea/Textarea';
import styles from './EditSkills.module.scss';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import axios from 'axios';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { fetchStudentInfo } from '../../../../store/reducers/CandidateSlice';

export const EditSkills = () => {
    const dispatch = useAppDispatch();
    const id = useAppSelector((state) => state.authReducer.id);
    useEffect(() => {
        id && dispatch(fetchStudentInfo(id));
    }, [dispatch, id]);
    const candidate = useAppSelector(
        (state) => state.candidateReducer.candidate,
    );
    const formik = useFormik({
        initialValues: {
            skills: candidate?.skills ? candidate?.skills : '',
        },
        onSubmit: async () => {},
        validationSchema: yup.object().shape({
            skills: yup
                .string()
                .required('Поле обязательно')
                .matches(
                    /^[а-яА-ЯёЁ0-9]/,
                    'Данное поле может содержать только символы кириллицы и цифры',
                )
                .max(20000, 'Текст не может быть длиннее 20 000 символов'),
        }),
    });
    const sendSkills = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;
            
            await axios.put(url, {
                skills: formik.values.skills.replace('↵', '\n'),
            });
            toast.success('Ключевые навыки успешно изменены');
        } catch (e) {
            toast.error('При загрузке ключевых навыков произошла ошибка');
        }
    };
    return (
        <EditWrapper
            saveOnclick={sendSkills}
            dirty={formik.dirty}
            isValid={formik.isValid && formik.dirty}
            title={'Ключевые навыки'}
        >
            <form className={styles.EditSkills__form}>
                <Textarea
                    areaClassName={styles.SkillArea}
                    title={
                        'Опишите свои навыки и достижения, в том числе курсовые и дипломы'
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.skills}
                    name={'skills'}
                    errorMessage={formik.errors.skills}
                    isValid={!formik.errors.skills}
                />
            </form>
        </EditWrapper>
    );
};
