import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Layout } from './hocs/Layout/Layout';
import { AppRouter } from './routes/AppRouter';

import './assets/scss/main.scss';

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAppSelector } from './hooks/reduxHooks/useAppSelector';
import { useAuthCheck } from './hooks/useAuthCheck';

const App = () => {
    useAuthCheck();

    const { isLogin, token } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        if (isLogin && token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    }, [isLogin, token]);

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => setIsMounted(true), []);

    return (
        <div className="app">
            <Layout>
                {isMounted ? <AppRouter /> : null}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    className={'tosts'}
                />
            </Layout>
        </div>
    );
};

export default App;
// fix
