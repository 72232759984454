import HeaderImageSrc from "../../assets/images/CabinetLogo.png";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";
import { PATH_DASHBOARD } from "../../routes/RouterConfig";

export enum LogoTypes {
	FOOTER = "footer",
	HEADER = "header",
}

interface LogoProps {
	logoType: LogoTypes;
}

export const Logo = ({ logoType }: LogoProps) => {
	return (
		<>
			{logoType === LogoTypes.HEADER ? (
				<Link to={PATH_DASHBOARD.root} className={styles.logo}>
					<img
						className={styles.logo__image}
						src={HeaderImageSrc}
						alt={"header logo"}
					/>
					<div className={styles.logo__headers}>
						<span className={styles.logo__title}>ЮРИДИЧЕСКИЙ ФАКУЛЬТЕТ</span>
						<span className={styles.logo__subtitle}>
							МГУ ИМЕНИ М.В.ЛОМОНОСОВА
						</span>
					</div>
				</Link>
			) : (
				<div className={styles.logo}>
					<img
						className={styles.logo__image}
						src={HeaderImageSrc}
						alt={"header logo"}
					/>
					<div className={styles.logo__headers}>
						<span className={styles.logo__title}>ЮРИДИЧЕСКИЙ ФАКУЛЬТЕТ</span>
						<span className={styles.logo__subtitle}>
							МГУ ИМЕНИ М.В.ЛОМОНОСОВА
						</span>
					</div>
				</div>
			)}
		</>
	);
};
