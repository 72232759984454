import { Dropdown } from '../../UI/Dropdown/Dropdown';
import styles from './EventsFilterWidget.module.scss';
import { DropdownOption } from '../../types/types';
import { Dispatch, SetStateAction, memo } from 'react';
import { SearchInput } from '../../UI/SearchInput/SearchInput';
import useDebounceCallBack from '../../hooks/useDebounce';

// виджет, содержащий фильтры (формат, место, значение в поисковом инпуте)
export const EventsFilterWidget = memo(
    ({ setPlace, place, setFormat, format, setSearchValue }: any) => {
        const debouncedSearch = useDebounceCallBack(setSearchValue, 1000);
        return (
            <div className={styles.widget}>
                <FormatFilter setFormat={setFormat} format={format} />
                <PlaceFilter setPlace={setPlace} place={place} />
                <SearchInput
                    className={styles.search}
                    placeholder={'Поиск'}
                    onChange={debouncedSearch}
                />
            </div>
        );
    },
);

interface FormatFilterProps {
    setFormat: Dispatch<SetStateAction<DropdownOption | undefined>>;
    format: DropdownOption;
}

const FormatFilter = ({ setFormat, format }: FormatFilterProps) => {
    const formatOptions = [
        { value: 'OF', label: 'Офлайн' },
        { value: 'ON', label: 'Онлайн' },
        { value: 'ALL', label: 'Все' },
    ];
    return (
        <div className={styles.format}>
            <h2 className={styles.format__header}>Формат</h2>
            <Dropdown
                options={formatOptions}
                setCurrentValue={setFormat}
                currentValue={format}
            />
        </div>
    );
};

const PlaceFilter = ({ setPlace, place }: any) => {
    const placeOptions = [
        {
            value: 'Юридический факультет МГУ',
            label: 'Юридический факультет МГУ',
        },
        { value: 'Сторонняя организация', label: 'Сторонняя организация' },
        { value: 'all', label: 'Все' },
    ];
    return (
        <div className={styles.place}>
            <h2 className={styles.format__header}>Место</h2>
            <Dropdown
                options={placeOptions}
                setCurrentValue={setPlace}
                currentValue={place}
            />
        </div>
    );
};
