import cn from 'classnames';
import { useFormik } from 'formik';
import { SetStateAction, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../UI/Button/Button';
import { Checkbox, RegistrationCheckbox } from '../../UI/Checkbox/Checkbox';
import { HidePasswordIcon, VisiblePasswordIcon } from '../../assets/icons';
import { scrollAvailable } from '../../helpers/scrollToggler';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { registerOrganization, registerStudent } from '../../services/Register';
import { Role } from '../../types/types';
import { Modal } from '../Modal/Modal';
import styles from './Register.module.scss';

export const Register = () => {
    return (
        <Modal>
            <RegisterFormLayout />
        </Modal>
    );
};

const RegisterFormLayout = () => {
    const [role, setRole] = useState<Role>(Role.candidate);
    const location = useLocation();
    const isLoginPopupOpen = location.search.includes('login');
    return (
        <div className={styles.formlayout}>
            <div className={styles.formlayout__wrapper}>
                <h4 className={styles.formlayout__header}>
                    {isLoginPopupOpen ? 'Авторизоваться' : 'Создать профиль'}
                </h4>
                <p className={styles.formlayout__description}>
                    Для использования всех функций сайта выберите, к какой
                    категории пользователей вы относитесь
                </p>
                <div className={styles.formlayout__chooserole}>
                    <Button
                        isDisabled={false}
                        onClick={() => setRole(Role.candidate)}
                        className={cn(styles.formlayout__student, {
                            [styles.formlayout__active]:
                                role === Role.candidate,
                        })}
                    >
                        Студент
                    </Button>
                    <Button
                        isDisabled={false}
                        onClick={() => setRole(Role.organization)}
                        className={cn(styles.formlayout__organization, {
                            [styles.formlayout__active]:
                                role === Role.organization,
                        })}
                    >
                        Организация
                    </Button>
                </div>

                <RegisterForm role={role} setRole={setRole} />

                <div className={styles.formlayout__footer}>
                    {!isLoginPopupOpen ? (
                        <>
                            <span className={styles.formlayout__footer_text}>
                                Уже есть профиль?
                            </span>
                            <Link
                                onClick={() => {}}
                                className={styles.formlayout__footer_button}
                                to={{ search: '?login=true' }}
                            >
                                Войти
                            </Link>
                        </>
                    ) : (
                        <>
                            <span className={styles.formlayout__footer_text}>
                                Еще нет профиля?
                            </span>
                            <Link
                                onClick={() => {}}
                                className={styles.formlayout__footer_button}
                                to={{ search: '?register=true' }}
                            >
                                Зарегистрироваться
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export const RegisterForm = ({ role, setRole }: RegisterFormProps) => {
    return (
        <div className={styles.RegisterForm}>
            <div className={styles.inputs}>
                {role === Role.candidate || role === Role.staff ? (
                    <StudentRegisterForm />
                ) : null}
                {role === Role.organization ? (
                    <OrganizationRegisterForm />
                ) : null}
            </div>
        </div>
    );
};

interface RegisterFormProps {
    role?: Role;
    setRole?: React.Dispatch<SetStateAction<Role>>;
}

const StudentRegisterForm = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [isAgreeWith, setIsAgreeWith] = useState(false);

    const formik = useFormik({
        initialValues: {
            first_name: '',
            second_name: '',
            document_number: '',
            email: '',
            password: '',
            confirm_password: '',
        },

        onSubmit: async (values) => {
            try {
                await dispatch(registerStudent(values));
                navigate(PATH_DASHBOARD.root);
                scrollAvailable();
            } catch (error) {
                toast.error('Произошла ошибка сервера');
            }
        },

        validationSchema: yup.object().shape({
            first_name: yup
                .string()
                .typeError('Должно быть строкой')
                .required('Поле обязательно')
                .max(50, 'Поле не должно содержать более 50 символов')
                .matches(
                    // eslint-disable-next-line no-useless-escape
                    /([А-ЯЁ][а-яё]+[\-\s]?)/,
                    'Пожалуйста, введите имя на русском языке. Первая буква заглавная',
                ),
            second_name: yup
                .string()
                .typeError('Должно быть строкой')
                .required('Данное поле обязательно')
                .max(50, 'Поле не должно содержать более 50 символов')
                .matches(
                    // eslint-disable-next-line no-useless-escape
                    /([А-ЯЁ][а-яё]+[\-\s]?)/,
                    'Пожалуйста, введите фамилию на русском языке. Первая буква заглавная',
                ),
            document_number: yup
                .string()
                .typeError('Должно быть числом')
                .matches(
                    /^\d{8}$|^[А-Я]{3}\d{7}$/,
                    'Номер студенческого билета в формате 00000000 или диплома в формате ААА0000000',
                )
                .required(
                    'Номер студенческого билета в формате 00000000 или диплома в формате ААА0000000',
                ),
            email: yup
                .string()
                .typeError('')
                .max(50, 'Поле не должно содержать более 50 символов')
                .required('')
                .matches(
                    /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
                    'Введите валидный email',
                ),
            password: yup
                .string()
                .matches(
                    /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
                    'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
                )
                .required(
                    'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
                )
                .max(35, 'Пароль не может быть больше 35 символов'),
            confirm_password: yup
                .string()
                .required(
                    'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
                )
                .max(35, 'Пароль не может быть больше 35 символов')
                .oneOf([yup.ref('password')], 'Пароли не совпадают'),
        }),
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
        useState(false);
    return (
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className={styles.inputs__wrapper}>
                <input
                    type={'text'}
                    className={styles.input}
                    placeholder={'Имя'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    name={'first_name'}
                    style={{
                        borderColor: formik.errors.first_name
                            ? 'red'
                            : formik.values.first_name
                            ? 'green'
                            : 'grey',
                        background: 'inherit',
                    }}
                />
                <input
                    type={'text'}
                    className={styles.input}
                    placeholder={'Фамилия'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.second_name}
                    name={'second_name'}
                    style={{
                        borderColor: formik.errors.second_name
                            ? 'red'
                            : formik.values.second_name
                            ? 'green'
                            : 'grey',
                        background: 'inherit',
                    }}
                />
                <label className={styles.input__label}>
                    <input
                        type={'text'}
                        className={styles.input}
                        placeholder={'Номер студенческого билета или диплома'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.document_number}
                        name={'document_number'}
                        style={{
                            borderColor: formik.errors.document_number
                                ? 'red'
                                : formik.values.document_number
                                ? 'green'
                                : 'grey',
                            background: 'inherit',
                        }}
                    />
                    {formik.touched.document_number &&
                        formik.errors.document_number && (
                            <p className={styles.input__error}>
                                {formik.errors.document_number}
                            </p>
                        )}
                </label>
                <input
                    type={'email'}
                    className={styles.input}
                    placeholder={'Email'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    name={'email'}
                    style={{
                        borderColor: formik.errors.email
                            ? 'red'
                            : formik.values.email
                            ? 'green'
                            : 'grey',
                        background: 'inherit',
                    }}
                />

                <label className={styles.input__label}>
                    <div className={styles.input__password}>
                        <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            className={cn(styles.input, styles.password__input)}
                            placeholder={'Пароль'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            name={'password'}
                            style={{
                                borderColor: formik.errors.password
                                    ? 'red'
                                    : formik.values.password
                                    ? 'green'
                                    : 'grey',
                                background: 'inherit',
                            }}
                        />
                        <div
                            className={styles.password__icon}
                            onClick={() => {
                                setIsPasswordVisible(!isPasswordVisible);
                            }}
                        >
                            {isPasswordVisible ? (
                                <VisiblePasswordIcon />
                            ) : (
                                <HidePasswordIcon />
                            )}
                        </div>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                        <p className={styles.input__error}>
                            {formik.errors.password}
                        </p>
                    )}
                </label>

                <label className={styles.input__label}>
                    <div className={styles.input__password}>
                        <input
                            type={
                                isConfirmPasswordVisible ? 'text' : 'password'
                            }
                            className={styles.input}
                            placeholder={'Повторите пароль'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            name={'confirm_password'}
                            style={{
                                borderColor: formik.errors.confirm_password
                                    ? 'red'
                                    : formik.values.confirm_password
                                    ? 'green'
                                    : 'grey',
                                background: 'inherit',
                            }}
                        />
                        <div
                            className={styles.password__icon}
                            onClick={() => {
                                setIsConfirmPasswordVisible(
                                    !isConfirmPasswordVisible,
                                );
                            }}
                        >
                            {isConfirmPasswordVisible ? (
                                <VisiblePasswordIcon />
                            ) : (
                                <HidePasswordIcon />
                            )}
                        </div>
                    </div>
                    {formik.touched.confirm_password &&
                        formik.errors.confirm_password && (
                            <p className={styles.input__error}>
                                {formik.errors.confirm_password}
                            </p>
                        )}
                </label>
            </div>
            <RegistrationCheckbox
                text={
                    'Я ознакомлен/а с политикой конфиденциальности и согласен на обработку персональных данных'
                }
                className={styles.inputs__checkbox}
                isChecked={isAgreeWith}
                onChange={() => setIsAgreeWith(!isAgreeWith)}
            />
            <Checkbox
                text={'Запомнить пароль'}
                className={styles.inputs__checkbox}
            />
            <div className={styles.inputs__buttons}>
                <Button
                    isDisabled={
                        !formik.isValid && !formik.dirty && !isAgreeWith
                    }
                    buttonType={'submit'}
                    className={cn(styles.inputs__button, {
                        [styles.inputs__button_valid]:
                            formik.dirty && formik.isValid && isAgreeWith,
                    })}
                >
                    Создать профиль
                </Button>
            </div>
        </form>
    );
};

const OrganizationRegisterForm = () => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            full_title: '',
            short_title: '',
            email: '',
            password: '',
            confirm_password: '',
        },
        onSubmit: async (values) => {
            try {
                await dispatch(registerOrganization(values));
                navigate(PATH_DASHBOARD.root);
                scrollAvailable();
            } catch (error) {
                toast.error('Произошла ошибка сервера');
            }
        },
        validationSchema: yup.object().shape({
            full_title: yup
                .string()
                .typeError('Должно быть строкой')
                .required('Поле обязательно')
                .max(50, 'Поле не должно содержать более 50 символов')
                .matches(
                    // eslint-disable-next-line no-useless-escape
                    /([А-ЯЁ][а-яё]+[\-\s]?)/,
                    'Пожалуйста, введите название на русском языке. Первая буква заглавная',
                ),
            short_title: yup
                .string()
                .typeError('Должно быть строкой')
                .required('Данное поле обязательно')
                .max(50, 'Поле не должно содержать более 50 символов')
                .matches(
                    // eslint-disable-next-line no-useless-escape
                    /([А-ЯЁ][а-яё]+[\-\s]?)/,
                    'Пожалуйста, введите название на русском языке. Первая буква заглавная',
                ),
            email: yup
                .string()
                .typeError('')
                .max(50, 'Поле не должно содержать более 50 символов')
                .required()
                .matches(
                    /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
                ),
            password: yup
                .string()
                .matches(
                    /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
                    'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
                )
                .required('Поле обязательно')
                .max(35, 'Пароль не может быть больше 30 символов'),
            confirm_password: yup
                .string()
                .required('Поле обязательно')
                .max(35, 'Пароль не может быть больше 30 символов')
                .oneOf([yup.ref('password')], 'Пароли не совпадают'),
        }),
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
        useState(false);
    return (
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className={styles.inputs__wrapper}>
                <label className={styles.input__label}>
                    <input
                        className={styles.input}
                        placeholder={'Полное наименование организации'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.full_title}
                        name={'full_title'}
                        style={{
                            borderColor: formik.errors.full_title
                                ? 'red'
                                : formik.values.full_title
                                ? 'green'
                                : 'grey',
                            background: 'inherit',
                        }}
                    />
                    {formik.touched.full_title && formik.errors.full_title && (
                        <p className={styles.input__error}>
                            {formik.errors.full_title}
                        </p>
                    )}
                </label>
                <label className={styles.input__label}>
                    <input
                        className={styles.input}
                        placeholder={'Краткое наименование организации'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.short_title}
                        name={'short_title'}
                        style={{
                            borderColor: formik.errors.short_title
                                ? 'red'
                                : formik.values.short_title
                                ? 'green'
                                : 'grey',
                            background: 'inherit',
                        }}
                    />
                    {formik.touched.short_title &&
                        formik.errors.short_title && (
                            <p className={styles.input__error}>
                                {formik.errors.short_title}
                            </p>
                        )}
                </label>
                <input
                    className={styles.input}
                    placeholder={'Email'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    name={'email'}
                    style={{
                        borderColor: formik.errors.email
                            ? 'red'
                            : formik.values.email
                            ? 'green'
                            : 'grey',
                        background: 'inherit',
                    }}
                />
                <label className={styles.input__label}>
                    <div className={styles.input__password}>
                        <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            className={styles.input}
                            placeholder={'Пароль'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            name={'password'}
                            style={{
                                borderColor: formik.errors.password
                                    ? 'red'
                                    : formik.values.password
                                    ? 'green'
                                    : 'grey',
                                background: 'inherit',
                            }}
                        />
                        <div
                            className={styles.password__icon}
                            onClick={() => {
                                setIsPasswordVisible(!isPasswordVisible);
                            }}
                        >
                            {isPasswordVisible ? (
                                <VisiblePasswordIcon />
                            ) : (
                                <HidePasswordIcon />
                            )}
                        </div>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                        <p className={styles.input__error}>
                            {formik.errors.password}
                        </p>
                    )}
                </label>
                <label className={styles.input__label}>
                    <div className={styles.input__password}>
                        <input
                            type={
                                isConfirmPasswordVisible ? 'text' : 'password'
                            }
                            className={styles.input}
                            placeholder={'Повторите пароль'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            name={'confirm_password'}
                            style={{
                                borderColor: formik.errors.confirm_password
                                    ? 'red'
                                    : formik.values.confirm_password
                                    ? 'green'
                                    : 'grey',
                                background: 'inherit',
                            }}
                        />
                        <div
                            className={styles.password__icon}
                            onClick={() => {
                                setIsConfirmPasswordVisible(
                                    !isConfirmPasswordVisible,
                                );
                            }}
                        >
                            {isConfirmPasswordVisible ? (
                                <VisiblePasswordIcon />
                            ) : (
                                <HidePasswordIcon />
                            )}
                        </div>
                    </div>
                    {formik.touched.confirm_password &&
                        formik.errors.confirm_password && (
                            <p className={styles.input__error}>
                                {formik.errors.confirm_password}
                            </p>
                        )}
                </label>
            </div>
            <Checkbox
                text={'Запомнить пароль'}
                className={styles.inputs__checkbox}
            />
            <div className={styles.inputs__buttons}>
                <Button
                    isDisabled={!formik.isValid && !formik.dirty}
                    buttonType={'submit'}
                    className={cn(styles.inputs__button, {
                        [styles.inputs__button_valid]:
                            formik.dirty && formik.isValid,
                    })}
                >
                    Создать профиль
                </Button>
            </div>
        </form>
    );
};
