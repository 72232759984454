import { useEffect, useState } from 'react';
import { Checkbox } from '../../../../UI/Checkbox/Checkbox';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import styles from './EditSocActivity.module.scss';
import { fetchSocialActivities } from '../../../../store/reducers/SocialActivitiesSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { FieldArray, Form, Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button, { ButtonTypes } from '../../../../UI/Button/Button';
import { CrossButton } from '../../../../assets/icons';
import {
    FormikInput,
    FormikTextarea,
} from '../../../../UI/FormikFields/FormikFields';
import { validationSchema } from './validationSchema';
import { getFormattedDate } from '../../../../helpers/getFormattedDate';

export const EditSocActivity = () => {
    const dispatch = useAppDispatch();
    const id = useAppSelector((state) => state.authReducer.id);
    const is_no_public_activity = useAppSelector(
        (state) => state.candidateReducer.candidate?.is_no_public_activity,
    );
    const [isChecked, setIsCheked] = useState(
        is_no_public_activity ? is_no_public_activity : false,
    );
    useEffect(() => {
        id && dispatch(fetchSocialActivities(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const socialActivities = useAppSelector(
        (state) => state.socialActivitiesReducer.socialActivities,
    );
    const initialdata = socialActivities?.map((public_activities) => {
        return {
            start: public_activities.start,
            end: public_activities.end,
            organization: public_activities.organization,
            site: public_activities.site,
            format: public_activities.format,
            description: public_activities.description,
            by_current_time: public_activities.by_current_time,
        };
    });
    const NEW_EXPERIANCE = {
        start: '',
        end: '',
        organization: '',
        site: '',
        format: '',
        description: '',
    };
    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
                public_activities: initialdata ? initialdata : [],
            }}
            onSubmit={(values) => {}}
        >
            {(formik) => (
                <Form className={styles.form} onSubmit={formik.handleSubmit}>
                    <EditWrapper
                        className={styles.EditEducation__wrapper}
                        title={'Опыт общественной деятельности'}
                        saveOnclick={async () => {
                            const experienceUrl = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/public_activity/many/`;
                            const candidateUrl = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;
                            await axios
                                .post(
                                    experienceUrl,
                                    isChecked
                                        ? { public_activities: [] }
                                        : formik.values,
                                )
                                .then(async () => {
                                    await axios
                                        .put(candidateUrl, {
                                            is_no_public_activity: isChecked,
                                        })
                                        .then(() => {
                                            toast.success(
                                                'Данные об общественной деятельности успешно изменены',
                                            );
                                        })
                                        .catch(() => {
                                            toast.error(
                                                'Призошла ошибка! Попробуйте позже',
                                            );
                                        });
                                })
                                .catch(() => {
                                    toast.error(
                                        'Призошла ошибка! Попробуйте позже',
                                    );
                                });
                        }}
                        isValid={
                            (formik.isValid &&
                                formik.values.public_activities.length > 0) ||
                            isChecked
                        }
                    >
                        <FieldArray
                            name="public_activities"
                            render={(arrayHelpers) => {
                                return (
                                    <div
                                        className={
                                            styles.SocialActivitiesForms__container
                                        }
                                    >
                                        <Checkbox
                                            className={
                                                styles.EditExperiance__checkbox
                                            }
                                            onChange={() => {
                                                setIsCheked(!isChecked);
                                            }}
                                            text={
                                                'Нет опыта общественной деятельности'
                                            }
                                            defaultChecked={isChecked}
                                            fieldClassName={
                                                styles.EditExperiance__checkbox_text
                                            }
                                            checkAreaClassName={
                                                styles.EditExperiance__checkbox_checkarea
                                            }
                                        />
                                        <CandidateExperiances
                                            formik={formik}
                                            arrayHelpers={arrayHelpers}
                                            isChecked={isChecked}
                                        />
                                        <Button
                                            onClick={() => {
                                                setIsCheked(false);
                                                arrayHelpers.insert(
                                                    formik.values
                                                        .public_activities
                                                        .length + 1,
                                                    NEW_EXPERIANCE,
                                                );
                                            }}
                                            type={ButtonTypes.AddButton}
                                        ></Button>
                                    </div>
                                );
                            }}
                        ></FieldArray>
                    </EditWrapper>
                </Form>
            )}
        </Formik>
    );
};

const CandidateExperiances = ({
    formik,
    arrayHelpers,
    isChecked,
}: CandidateSocialActivitiesProps) => {
    const NEW_SOC_ACTIVITIES = {
        start: '',
        end: '',
        organization: '',
        site: '',
        format: '',
        description: '',
        by_current_time: false,
    };
    return (
        <div className={styles.SocialActivitiesForms__container}>
            {formik.values.public_activities &&
                formik.values.public_activities.map(
                    (public_activities: any, index: number) => (
                        <SocialActivityForm
                            key={index}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            errors={
                                formik?.errors?.public_activities &&
                                formik?.errors?.public_activities[index]
                                    ? formik?.errors?.public_activities[index]
                                    : NEW_SOC_ACTIVITIES
                            }
                            formik={formik}
                            formType={''}
                            isChecked={isChecked}
                        />
                    ),
                )}
        </div>
    );
};

interface CandidateSocialActivitiesProps {
    formik?: any;
    arrayHelpers?: any;
    isChecked: boolean;
}

const SocialActivityForm = ({
    index,
    formik,
    errors,
    arrayHelpers,
    isChecked,
}: SocialActivityFormProps) => {
    const [isNow, setIsNow] = useState<boolean>(
        Boolean(formik.values.public_activities[index].by_current_time),
    );
    return (
        <div className={styles.EditForm}>
            <CrossButton
                className={styles.EditForm__crossbutton}
                onClick={() => {
                    arrayHelpers.remove(index);
                }}
            />
            <div className={styles.EditForm__start}>
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.college}
                    name={`public_activities.${index}.start`}
                    title={'Дата начала'}
                    isValid={
                        errors &&
                        !errors.start &&
                        Boolean(formik.values.public_activities[index].start)
                    }
                    placeholder={'01.01.2023'}
                    type={'date'}
                />
            </div>
            <div className={styles.EditForm__end}>
                <FormikInput
                    wrapperClassName={styles.end}
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    disabled={Boolean(
                        formik.values.public_activities[index].by_current_time,
                    )}
                    error={formik.errors.end}
                    name={`public_activities.${index}.end`}
                    title={'Дата окончания'}
                    isValid={
                        errors &&
                        !errors.end &&
                        Boolean(formik.values.public_activities[index].end)
                    }
                    placeholder={'01.01.2023'}
                    type={'date'}
                />
                <Checkbox
                    className={styles.EditForm__checkbox}
                    fieldClassName={styles.EditForm__checkbox_text}
                    checkAreaClassName={styles.EditForm__checkbox_checkarea}
                    text={'По настоящее время'}
                    onChange={() => {
                        setIsNow(!isNow);
                        setIsNow(!isNow);
                        if (!isNow) {
                            formik.setFieldValue(
                                `public_activities.${index}.end`,
                                getFormattedDate(),
                            );
                        }
                        formik.setFieldValue(
                            `public_activities.${index}.by_current_time`,
                            !isNow,
                        );
                    }}
                    defaultChecked={isNow}
                    name={`public_activities.${index}.by_current_time`}
                />
            </div>
            <div className={styles.EditForm__organization}>
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.organization}
                    name={`public_activities.${index}.organization`}
                    title={'Организация'}
                    isValid={
                        errors &&
                        !errors.organization &&
                        Boolean(
                            formik.values.public_activities[index].organization,
                        )
                    }
                    placeholder={'Научно-студенческое общество'}
                />
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.cite}
                    name={`public_activities.${index}.site`}
                    title={'Сайт организации'}
                    isValid={
                        errors &&
                        !errors.site &&
                        Boolean(formik?.values?.public_activities[index]?.site)
                    }
                    placeholder={'https://www.urist.ru'}
                />
            </div>
            <FormikInput
                index={index}
                fieldClassName={styles.EditForm__datepicker}
                error={formik.errors.format}
                name={`public_activities.${index}.format`}
                title={'Вид мероприятия'}
                isValid={
                    errors &&
                    !errors.format &&
                    Boolean(formik.values.public_activities[index].format)
                }
                placeholder={'Конференция'}
            />
            <FormikTextarea
                title="Обязанности и достижения"
                error={formik.errors.description}
                isValid={
                    formik.errors &&
                    !errors.description &&
                    Boolean(formik.values.public_activities[index].description)
                }
                name={`public_activities.${index}.description`}
            />
            {isChecked && <div className={styles.overlay}></div>}
        </div>
    );
};

interface SocialActivityFormProps {
    formType: string;
    experience?: any;
    index: number;
    arrayHelpers: any;
    errors: any;
    formik: any;
    isChecked: boolean;
}
