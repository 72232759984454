import React from 'react';
import { Overlay } from '../Overlay/Overlay';
import styles from './Modal.module.scss';
import ReactDOM from 'react-dom';

const portal = document.getElementById('portal') as HTMLElement;

export const Modal = ({ children }: { children: React.ReactNode }) => {
    return ReactDOM.createPortal(
        <div className={styles.modal}>
            <Overlay />
            {children}
        </div>,
        portal,
    );
};
