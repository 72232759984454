// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation_header__nav__5rLk9 {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  font: var(--font-m);\n  font-weight: 700;\n  font-weight: var(--font-weight-medium);\n  margin-right: auto;\n}\n@media (max-width: 1300px) {\n  .Navigation_header__nav__5rLk9 {\n    gap: 5px;\n  }\n}\n@media (max-width: 900px) {\n  .Navigation_header__nav__5rLk9 {\n    display: none;\n  }\n}\n.Navigation_header__nav__5rLk9 a.Navigation_active__8X7xS {\n  color: red;\n}", "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EAEA,mBAAA;EACA,gBAAA;EACA,sCAAA;EAEA,kBAAA;AADJ;AAEI;EAVJ;IAWQ,QAAA;EACN;AACF;AAAI;EAbJ;IAcQ,aAAA;EAGN;AACF;AAFI;EACI,UAAA;AAIR","sourcesContent":[".header__nav{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n\n    font: var(--font-m);\n    font-weight: 700;\n    font-weight: var(--font-weight-medium);\n\n    margin-right: auto;\n    @media (max-width:1300px) {\n        gap: 5px;\n    }\n    @media (max-width:900px) {\n        display: none;\n    }\n    & a.active{\n        color: red;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__nav": "Navigation_header__nav__5rLk9",
	"active": "Navigation_active__8X7xS"
};
export default ___CSS_LOADER_EXPORT___;
