/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useRef, useState } from 'react';
import SwiperCore, {
    Grid,
    Navigation,
    Scrollbar,
    Swiper as SwiperType,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
    LeftArrow,
    LikeIcon,
    RightArrow,
    RightArrowIcon,
} from '../../assets/icons';

import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import Button from '../../UI/Button/Button';
import { dateConverter } from '../../helpers/dateConverter';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useChangeLike } from '../../hooks/useChangeLike';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { IEventCard } from '../../types/types';
import { LocationMark } from '../LocationMark/LocationMark';
import { Tag } from '../Tag/Tag';
import styles from './EventsSlider.module.scss';
import './styles.css';

interface EventsSliderProps {
    eventData: null | IEventCard[];
}

SwiperCore.use([Navigation]);

const EventsSlider = ({ eventData }: EventsSliderProps) => {
    const swiperRef = useRef<SwiperType>();
    const windowWidth = useWindowWidth() || 900;

    const showNavButtons = useMemo(() => {
        if (eventData && eventData.length > 4 && windowWidth >= 1000) {
            return true;
        }
        if (eventData && eventData.length > 2 && windowWidth < 1000) {
            return true;
        }
        return false;
    }, [windowWidth, eventData]);

    return (
        <div className={styles.EventsSlider}>
            <h3 className={styles.EventsSlider__header}>
                Ближайшие мероприятия{' '}
            </h3>
            {eventData && (
                <Swiper
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    slidesPerView={windowWidth && windowWidth < 1400 ? 1 : 2}
                    grid={{
                        rows: 2,
                    }}
                    spaceBetween={0}
                    scrollbar={{
                        hide: false,
                        horizontalClass: styles.EventsSlider__scrollbar,
                    }}
                    modules={[Grid, Navigation, Scrollbar]}
                    className="mySwiper"
                >
                    {eventData.map((event: IEventCard, index: number) => (
                        <SwiperSlide key={index}>
                            <EventSlide
                                id={String(event.id)}
                                key={event.id}
                                title={event.title}
                                datetime={event.datetime}
                                format={event.format}
                                address={event.address}
                                in_user_favorite_events={
                                    event.in_user_favorite_events
                                }
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            {showNavButtons ? (
                <div className={styles.EventsSlider__buttons}>
                    <button
                        className={styles.EventsSlider__button}
                        onClick={() => swiperRef.current?.slidePrev()}
                    >
                        <LeftArrow />
                    </button>
                    <button
                        className={styles.EventsSlider__button}
                        onClick={() => swiperRef.current?.slideNext()}
                    >
                        <RightArrow />
                    </button>
                </div>
            ) : null}
        </div>
    );
};

interface EventSlideProps {
    id: string;
    title: string;
    datetime: string;
    format: string;
    address: string;
    in_user_favorite_events: boolean;
}

const EventSlide = ({
    title,
    datetime,
    format,
    address,
    id,
    in_user_favorite_events,
}: EventSlideProps) => {
    const [isCardLiked, setIsCardLiked] = useState(in_user_favorite_events);
    const { isLogin } = useAppSelector((state) => state.authReducer);

    const navigate = useNavigate();
    const navigateToCurrentEvent = () => {
        navigate(`/events/${id}`);
    };
    const { likeEvent, cancelLikeEvent } = useChangeLike(Number(id));

    return (
        <div className={styles.EventSlide}>
            <div className={styles.EventSlide__top}>
                <Tag
                    type="format"
                    content={format === 'ON' ? 'Онлайн' : 'Офлайн'}
                />
                <Tag type="date" content={dateConverter(new Date(datetime))} />
                <LikeIcon
                    onClick={() => {
                        if (!isLogin) {
                            return navigate({ search: '?login=true' });
                        }
                        isCardLiked ? cancelLikeEvent() : likeEvent();
                        setIsCardLiked(!isCardLiked);
                    }}
                    className={
                        isCardLiked
                            ? styles.EventSlide__like_active
                            : styles.EventSlide__like
                    }
                />
            </div>
            <h3
                onClick={navigateToCurrentEvent}
                className={styles.EventSlide__header}
            >
                {title}
            </h3>
            <div className={styles.EventSlide__bottom}>
                <Button
                    className={styles.EventSlide__moreinfo}
                    onClick={navigateToCurrentEvent}
                >
                    <span className={styles.EventSlide__moreinfo_text}>
                        Подробнее
                    </span>
                    <div className={styles.EventSlide__moreinfo_icon}>
                        <RightArrowIcon />
                    </div>
                </Button>
                <LocationMark text={address} />
            </div>
        </div>
    );
};

export default EventsSlider;
