import { Dispatch, SetStateAction, useRef } from 'react';
import styles from './PDFUploader.module.scss';
import cn from 'classnames';
import { AddPinIcon, CrossButton } from '../../assets/icons';
import PDFImage from '../../assets/images/PDF.png';

interface PDFUploaderProps {
    className?: string;
    PDF: File | null;
    setPDF: Dispatch<SetStateAction<File | null>>;
}

export const PDFUploader = ({ className, setPDF, PDF }: PDFUploaderProps) => {
    const pdfRef = useRef<HTMLInputElement>(null);

    const handlePick = () => {
        pdfRef?.current?.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            const file = event?.target?.files[0];
            setPDF(file);
        }
    };

    const handleClear = () => {
        setPDF(null);
        const inputField = pdfRef.current;
        if (inputField) {
            inputField.value = '';
        }
    };

    return (
        <div className={cn(styles.PDFUploader, className)}>
            <div className={styles.PDFUploader__wrapper}>
                <div className={styles.PDFUploader__title}>
                    <div
                        onClick={handlePick}
                        className={styles.PDFUploader__load}
                    >
                        <span>Загрузить PDF</span>
                        <AddPinIcon />
                    </div>
                </div>

                <div className={styles.PDFUploader__area}>
                    {PDF !== null ? (
                        <PDFCard PDF={PDF} handleClear={handleClear}></PDFCard>
                    ) : (
                        'Файл не загружен'
                    )}
                </div>
                <input
                    className={styles.PDFUploader__input_hidden}
                    type="file"
                    onChange={handleChange}
                    ref={pdfRef}
                    accept=".pdf"
                />
            </div>
        </div>
    );
};

const PDFCard = ({ PDF, handleClear }: PDFCardProps) => {
    return (
        <div className={styles.PDF}>
            <CrossButton className={styles.PDF__button} onClick={handleClear} />
            <div className={styles.PDF__wrapper}>
                <img
                    className={styles.PDF__image}
                    src={PDFImage}
                    alt="PDFimage"
                />
                <span className={styles.PDF__name}>{PDF && PDF.name}</span>
            </div>
        </div>
    );
};

interface PDFCardProps {
    PDF: File;
    handleClear: () => void;
}
