import { useRef, useState } from 'react';
import styles from './Dropdown.module.scss';
import cn from 'classnames';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { DropdownOption } from '../../types/types';

interface DropdownProps {
    icon?: React.ReactNode;
    options: DropdownOption[];

    setCurrentValue?: (value: DropdownOption) => void; // функция позволяющая управлять дропдауном через стэйт
    currentValue?: DropdownOption;

    className?: string;
    dropdownElementClassname?: string;
    placeholderClassname?: string;

    changeOption?: (value: string) => void; // функция озволяющая связать дропдаун и стор
    option?: string;
}

export const Dropdown = ({
    icon,
    options,
    setCurrentValue,
    currentValue,
    className,
    dropdownElementClassname,
    placeholderClassname,
    changeOption,
    option,
}: DropdownProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const toggleActivity = () => {
        setIsActive(!isActive);
    };

    const ref = useRef(null);
    const handleClickOutside = () => {
        setIsActive(false);
    };
    useClickOutside(ref, handleClickOutside);

    return (
        <div
            ref={ref}
            className={cn(styles.dropdown, className)}
            onClick={toggleActivity}
        >
            <div className={styles.dropdown__icon_outside}>{icon}</div>
            <div
                className={cn(styles.dropdown__btn, placeholderClassname, {
                    [styles.dropdown__btn_active]: isActive,
                })}
            >
                {option
                    ? option
                    : !currentValue
                    ? 'Выберите вариант'
                    : currentValue?.label}
            </div>
            <FontAwesomeIcon
                className={cn(styles.dropdown__icon, {
                    [styles.dropdown__icon_active]: isActive,
                })}
                icon={faCaretDown}
            />
            {isActive && (
                <ul className={styles.dropdown__content}>
                    {options &&
                        options.map((option: DropdownOption, index: number) => (
                            <li
                                key={index}
                                onClick={() => {
                                    if (setCurrentValue) {
                                        setCurrentValue(option);
                                    }
                                    if (changeOption) {
                                        changeOption(option.value);
                                    }
                                }}
                                className={cn(
                                    styles.dropdown__item,
                                    dropdownElementClassname,
                                )}
                            >
                                {option.label}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};
