// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EventsFilterWidget_widget__dzVRp {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  grid-column-gap: 50px;\n  grid-row-gap: 34px;\n  width: 100%;\n}\n\n.EventsFilterWidget_format__Gk-wt {\n  grid-area: 1/1/2/2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 15px;\n  width: 100%;\n}\n.EventsFilterWidget_format__header__QSTpG {\n  font: var(--font-m);\n}\n\n.EventsFilterWidget_place__Ro7-L {\n  grid-area: 1/2/2/3;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 15px;\n  width: 100%;\n}\n.EventsFilterWidget_place__header__ZAnUy {\n  font: var(--font-m);\n}\n\n.EventsFilterWidget_search__vlvVK {\n  grid-area: 2/1/3/3;\n}", "",{"version":3,"sources":["webpack://./src/components/EventsFilterWidget/EventsFilterWidget.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,qBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AACJ;AAAI;EACI,mBAAA;AAER;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,WAAA;AACJ;AAAI;EACI,mBAAA;AAER;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".widget {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n    grid-column-gap: 50px;\n    grid-row-gap: 34px;\n    width: 100%;\n}\n\n.format {\n    grid-area: 1 / 1 / 2 / 2;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n    width: 100%;\n    &__header {\n        font: var(--font-m);\n    }\n}\n\n.place {\n    grid-area: 1 / 2 / 2 / 3;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n    width: 100%;\n    &__header {\n        font: var(--font-m);\n    }\n}\n\n.search {\n    grid-area: 2 / 1 / 3 / 3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": "EventsFilterWidget_widget__dzVRp",
	"format": "EventsFilterWidget_format__Gk-wt",
	"format__header": "EventsFilterWidget_format__header__QSTpG",
	"place": "EventsFilterWidget_place__Ro7-L",
	"place__header": "EventsFilterWidget_place__header__ZAnUy",
	"search": "EventsFilterWidget_search__vlvVK"
};
export default ___CSS_LOADER_EXPORT___;
