import axios, { AxiosError } from 'axios';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import Button from '../../UI/Button/Button';
import { PasswordInput } from '../../UI/PasswordInput/PasswordInput';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { Role } from '../../types/types';
import styles from './ChangePassword.module.scss';
import { validationSchema } from './validationSchema';

export const ChangePassword = () => {
    const id = useAppSelector((state) => state.authReducer.id);

    const role = localStorage.getItem('role');

    const url = useMemo(() => {
        if (role === Role.candidate) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/change_password/candidate/${id}/`;
        }
        if (role === Role.staff) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/change_password/staff/${id}/`;
        }
        return `${process.env.REACT_APP_BASE_URL}api/v1/change_password/company/${id}/`;
    }, [id, role]);

    const formik = useFormik({
        initialValues: {
            current_password: '',
            new_password: '',
            repeat_new_password: '',
        },
        onSubmit: async (e) => {
            await axios
                .post(url, {
                    current_password: formik.values.current_password,
                    new_password: formik.values.new_password,
                })
                .then(() => {
                    toast.success('Пароль успешно изменен');
                    // eslint-disable-next-line no-restricted-globals
                    formik.resetForm();
                })
                .catch((e: AxiosError) => {
                    const status = e.response?.request.status;
                    if (status >= 500) {
                        toast.error('Ошибка сервера, попробуйте позже');
                    } else if (status && status >= 400 && status < 500) {
                        toast.error('Неверный старый пароль');
                        return;
                    }
                    toast.error('Произошла ошибка');
                });
        },
        validationSchema: validationSchema,
    });
    return (
        <div className={styles.passwords}>
            <h2 className={styles.passwords__header}>Изменить пароль</h2>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.password__container}
            >
                <div className={styles.passwords__inputs}>
                    <PasswordInput
                        wrapperClassName={[styles.input_first]}
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        headerText="Cтарый пароль"
                        name={`current_password`}
                        errorMessage={formik.errors.current_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.current_password || ''}
                    />
                    <PasswordInput
                        wrapperClassName={[styles.input_second]}
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        placeholder="Пароль"
                        headerText="Новый пароль"
                        name={`new_password`}
                        errorMessage={formik.errors.new_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.new_password || ''}
                    />
                    <PasswordInput
                        wrapperClassName={[styles.input_third]}
                        headerClassName={styles.input__header}
                        placeholder="Пароль"
                        headerText="Повторите новый пароль"
                        fieldClassName={styles.input__field}
                        name={`repeat_new_password`}
                        errorMessage={formik.errors.repeat_new_password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.repeat_new_password || ''}
                    />
                </div>
                <div className={styles.passwords__buttons}>
                    <Button
                        buttonType="reset"
                        isDisabled={!formik.isValid && formik.dirty}
                        onClick={() => {
                            formik.handleSubmit();
                        }}
                        className={cn(styles.passwords__button, {
                            [styles.passwords__button_valid]:
                                formik.isValid && formik.dirty,
                        })}
                    >
                        Сохранить
                    </Button>
                </div>
            </form>
        </div>
    );
};
