import { ChangeEvent } from 'react';
import { DropdownOption } from '../../types/types';
import styles from './MultiCheckbox.module.scss';

interface MultiCheckboxProps {
    title: string;
    options: DropdownOption[];
    changeOptions: (value: string) => void;
    currentValues: string[];
}

export const MultiCheckbox = ({
    title,
    options,
    changeOptions,
    currentValues,
}: MultiCheckboxProps) => {
    return (
        <div className={styles.checkboxes}>
            <h3 className={styles.checkboxes__header}>{title}</h3>
            <div className={styles.checkboxes__container}>
                {options.length > 0 &&
                    options.map((checkbox, index) => (
                        <Option
                            value={checkbox.value}
                            key={index}
                            label={checkbox.label}
                            changeOptions={() => changeOptions(checkbox.value)}
                            isChecked={currentValues.includes(checkbox.value)}
                        />
                    ))}
            </div>
        </div>
    );
};

interface OptionProps {
    value: string;
    label: string;
    changeOptions: (value: string) => void;
    isChecked: boolean;
}

const Option = ({ value, label, changeOptions, isChecked }: OptionProps) => {
    return (
        <label className={styles.option}>
            <input
                className={styles.option__input}
                type="checkbox"
                checked={isChecked}
                onChange={(e: ChangeEvent<HTMLElement>) => {
                    changeOptions(value);
                }}
            />
            <span className={styles.option__text}>{label}</span>
        </label>
    );
};
