import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { EventCard } from '../../../../components/EventCard/EventCard';
import { Loader } from '../../../../components/Loader/Loader';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { IEventCard, Role } from '../../../../types/types';
import Pagination from '../../../StaffPages/SupportList/Pagination';
import styles from './FavouritesEvents.module.scss';

export const FavouritesEvents = () => {
    const [events, setEvents] = useState<IEventCard[] | null>(null); // стэйт с новостями

    //пагинация
    const [page, setPage] = useState<number>(1); // стэйт - текущая страница в пагинации
    const [paginationArr, setPaginationArr] = useState<number>(1); // максимальное количество страниц

    //индикатор загрузки
    const [isLoading, setIsLoading] = useState<boolean>(false); //изначально нет загрузки

    const { id, role } = useAppSelector((state) => state.authReducer);

    const eventsUrl = useMemo(() => {
        if (role === Role.staff) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/staff/${id}/favorite_event/?page=${page}&size=10`;
        }
        if (role === Role.organization) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/favorite_event/?page=${page}&size=10`;
        }
        return `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/favorite_event/?page=${page}&size=10`;
    }, [id, page, role]);

    useEffect(() => {
        setIsLoading(true); // загрузка началась
        axios
            .get(eventsUrl)
            .then((response) => {
                const pagArray = response.data.page_count;
                setPaginationArr(pagArray); // массив булетов для отрисовки
                setEvents(response.data.objects); // данные по карточкам
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false)); // загрузка закончена
    }, [eventsUrl]);

    return (
        <div className={styles.FavouritesEvents}>
            {isLoading ? (
                <div className={styles.FavouritesEvents__loader}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.FavouritesEvents__wrapper}>
                    <div className={styles.FavouritesEvents__cards}>
                        {events && events.length > 0 ? (
                            <FavouritesList events={events} />
                        ) : (
                            <p className={styles.FavouritesEvents__text}>
                                Вы еще не добавили мероприятия в избранное
                            </p>
                        )}
                    </div>
                </div>
            )}
            <Pagination
                list={{
                    page_count: paginationArr,
                    current_page: page,
                }}
                handlePagination={(p) => {
                    setPage(p);
                }}
                isLoading={isLoading}
            />
        </div>
    );
};

interface FavouritesListProps {
    events: IEventCard[];
}

const FavouritesList = ({ events }: FavouritesListProps) => {
    return (
        <div className={styles.FavouritesList}>
            {events.map((event) => (
                <EventCard
                    event={event}
                    key={event.id}
                    containerClassName={styles.personalPage}
                    imageClassName={styles.personalPage__image}
                    titleClassName={styles.personalPage__title}
                    smallBlocksClassName={styles.personalPage__smallBlocks}
                />
            ))}
        </div>
    );
};
