// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsSection_newssection__XGtbR {\n  padding: 0px 46px 30px;\n  background-color: var(--bg-color);\n}", "",{"version":3,"sources":["webpack://./src/pages/MainPages/Main/MainPageSections/NewsSection/NewsSection.module.scss"],"names":[],"mappings":"AAAA;EACC,sBAAA;EACA,iCAAA;AACD","sourcesContent":[".newssection {\n\tpadding: 0px 46px 30px ;\n\tbackground-color: var(--bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newssection": "NewsSection_newssection__XGtbR"
};
export default ___CSS_LOADER_EXPORT___;
