// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayout_pagelayout__JpyOr {\n  padding: 0px 70px 40px;\n}\n@media (max-width: 768px) {\n  .PageLayout_pagelayout__JpyOr {\n    padding: 0px 20px 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/PageLayout/PageLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;AAAI;EAFJ;IAGQ,sBAAA;EAGN;AACF","sourcesContent":[".pagelayout{\n    padding: 0px 70px 40px;\n    @media (max-width: 768px) {\n        padding: 0px 20px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagelayout": "PageLayout_pagelayout__JpyOr"
};
export default ___CSS_LOADER_EXPORT___;
