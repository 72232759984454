// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppLink_AppLink__DBDj3 {\n  font: var(--font-s);\n  text-decoration: none;\n  padding: 12px 21px;\n  border-radius: 17px;\n  font-weight: var(--font-weight-medium);\n}\n@media (max-width: 1300px) {\n  .AppLink_AppLink__DBDj3 {\n    padding: 7px 15px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/AppLink/AppLink.module.scss"],"names":[],"mappings":"AAAA;EACC,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sCAAA;AACD;AAAC;EAND;IAOE,iBAAA;EAGA;AACF","sourcesContent":[".AppLink {\n\tfont: var(--font-s);\n\ttext-decoration: none;\n\tpadding: 12px 21px;\n\tborder-radius: 17px;\n\tfont-weight: var(--font-weight-medium);\n\t@media (max-width: 1300px) {\n\t\tpadding: 7px 15px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AppLink": "AppLink_AppLink__DBDj3"
};
export default ___CSS_LOADER_EXPORT___;
