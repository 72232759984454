import { Link, useLocation } from 'react-router-dom';
import { scrollAvailable } from '../../helpers/scrollToggler';
import styles from './Overlay.module.scss';

export const Overlay = () => {
    const { pathname } = useLocation();
    return (
        <Link
            to={`${pathname}`}
            onClick={() => {
                scrollAvailable();
            }}
            className={styles.overlay}
        ></Link>
    );
};
