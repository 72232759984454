import { FieldArray, Form, Formik } from 'formik';
import Button, { ButtonTypes } from '../../../../UI/Button/Button';
import { Checkbox } from '../../../../UI/Checkbox/Checkbox';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import styles from './EditExperiance.module.scss';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { fetchExperience } from '../../../../store/reducers/ExperianceSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { validationschema } from './validationSchema';
import {
    FormikInput,
    FormikTextarea,
} from '../../../../UI/FormikFields/FormikFields';
import { CrossButton } from '../../../../assets/icons';
import { getFormattedDate } from '../../../../helpers/getFormattedDate';

export const EditExperiance = () => {
    const { id } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    useEffect(() => {
        id && dispatch(fetchExperience(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const experience = useAppSelector(
        (state) => state.experianceReducer.experiances,
    );
    const is_no_experience = useAppSelector(
        (state) => state.candidateReducer.candidate?.is_no_experience,
    );
    const [isChecked, setIsCheked] = useState(
        is_no_experience ? is_no_experience : false,
    );
    const initialdata = experience?.map((exp: any) => {
        return {
            start: exp.start,
            end: exp.end,
            company_title: exp.company_title,
            site: exp.site,
            position: exp.position,
            description: exp.description,
            by_current_time: exp.by_current_time,
        };
    });
    const NEW_SOC_ACTIVITY = {
        start: '',
        end: '',
        company_title: '',
        site: '',
        position: '',
        description: '',
        by_current_time: false,
    };
    return (
        <Formik
            validationSchema={validationschema}
            validateOnMount={false}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
                experience: initialdata ? initialdata : [],
            }}
            onSubmit={(values) => {}}
        >
            {(formik) => (
                <Form className={styles.form} onSubmit={formik.handleSubmit}>
                    <EditWrapper
                        className={styles.EditEducation__wrapper}
                        title={'Опыт работы'}
                        saveOnclick={async () => {
                            const experienceUrl = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/experience/many/`;
                            const candidateUrl = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/`;
                            await axios
                                .post(
                                    experienceUrl,
                                    isChecked
                                        ? { experience: [] }
                                        : formik.values,
                                )
                                .then(async () => {
                                    await axios
                                        .put(candidateUrl, {
                                            is_no_experience: isChecked,
                                        })
                                        .then(() => {
                                            toast.success(
                                                'Данные об опыте работы успешно изменены',
                                            );
                                        })
                                        .catch(() => {
                                            toast.error(
                                                'Призошла ошибка! Попробуйте позже',
                                            );
                                        });
                                })
                                .catch(() => {
                                    toast.error(
                                        'Призошла ошибка! Попробуйте позже',
                                    );
                                });
                        }}
                        isValid={
                            (formik.isValid &&
                                formik.values.experience.length > 0) ||
                            isChecked
                        }
                    >
                        <FieldArray
                            name="experience"
                            render={(arrayHelpers) => {
                                return (
                                    <div className={styles.ExperianceForms}>
                                        <Checkbox
                                            onChange={() => {
                                                setIsCheked(!isChecked);
                                            }}
                                            defaultChecked={isChecked}
                                            text={'Нет опыта работы'}
                                            className={
                                                styles.EditExperiance__checkbox
                                            }
                                            fieldClassName={
                                                styles.EditExperiance__checkbox_text
                                            }
                                            checkAreaClassName={
                                                styles.EditExperiance__checkbox_checkarea
                                            }
                                        />
                                        <CandidateExperiances
                                            formik={formik}
                                            arrayHelpers={arrayHelpers}
                                            isChecked={isChecked}
                                        />
                                        <Button
                                            onClick={() => {
                                                setIsCheked(false);
                                                arrayHelpers.insert(
                                                    formik.values.experience
                                                        .length + 1,
                                                    NEW_SOC_ACTIVITY,
                                                );
                                            }}
                                            type={ButtonTypes.AddButton}
                                        ></Button>
                                    </div>
                                );
                            }}
                        ></FieldArray>
                    </EditWrapper>
                </Form>
            )}
        </Formik>
    );
};

const CandidateExperiances = ({
    formik,
    arrayHelpers,
    isChecked,
}: CandidateExperiancesProps) => {
    const NEW_SOC_ACTIVITY = {
        start: '',
        end: '',
        company_title: '',
        site: '',
        position: '',
        description: '',
        by_current_time: false,
    };
    return (
        <div className={styles.ExperianceForms__container}>
            {formik.values.experience &&
                formik.values.experience.map(
                    (experience: any, index: number) => (
                        <EditForm
                            key={index}
                            experience={experience}
                            index={index}
                            arrayHelpers={arrayHelpers}
                            errors={
                                formik?.errors?.experience &&
                                formik?.errors?.experience[index]
                                    ? formik?.errors?.experience[index]
                                    : NEW_SOC_ACTIVITY
                            }
                            formik={formik}
                            formType={'skills'}
                            isChecked={isChecked}
                        />
                    ),
                )}
        </div>
    );
};

interface CandidateExperiancesProps {
    formik?: any;
    arrayHelpers?: any;
    isChecked: boolean;
}

const EditForm = ({
    index,
    formik,
    errors,
    arrayHelpers,
    isChecked,
}: EditFormProps) => {
    const [isNow, setIsNow] = useState<boolean>(
        Boolean(formik.values.experience[index].by_current_time),
    );
    return (
        <div className={styles.EditForm}>
            <CrossButton
                className={styles.EditForm__crossbutton}
                onClick={() => {
                    arrayHelpers.remove(index);
                }}
            />
            <div className={styles.EditForm__start}>
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.college}
                    name={`experience.${index}.start`}
                    title={'Дата начала'}
                    isValid={errors && !errors.start}
                    placeholder={'01.01.2023'}
                    type={'date'}
                />
            </div>
            <div className={styles.EditForm__end}>
                <FormikInput
                    index={index}
                    wrapperClassName={styles.end}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.end}
                    name={`experience.${index}.end`}
                    title={'Дата окончания'}
                    isValid={errors && !errors.end}
                    placeholder={'01.01.2023'}
                    type={'date'}
                    disabled={formik.values.experience[index].by_current_time}
                />
                <Checkbox
                    className={styles.end__checkbox}
                    fieldClassName={styles.EditExperiance__checkbox_text}
                    checkAreaClassName={
                        styles.EditExperiance__checkbox_checkarea
                    }
                    text={'По настоящее время'}
                    onChange={() => {
                        setIsNow(!isNow);
                        if (!isNow) {
                            formik.setFieldValue(
                                `experience.${index}.end`,
                                getFormattedDate(),
                            );
                        }
                        formik.setFieldValue(
                            `experience.${index}.by_current_time`,
                            !isNow,
                        );
                    }}
                    defaultChecked={isNow}
                    name={`experience.${index}.by_current_time`}
                />
            </div>
            <div className={styles.EditForm__organization}>
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.company_title}
                    name={`experience.${index}.company_title`}
                    title={'Организация'}
                    isValid={errors && !errors.company_title}
                    placeholder={'Юридическая компания'}
                />
                <FormikInput
                    index={index}
                    fieldClassName={styles.EditForm__datepicker}
                    error={formik.errors.cite}
                    name={`experience.${index}.site`}
                    title={'Сайт организации'}
                    isValid={
                        errors &&
                        !errors.site &&
                        formik?.values?.experience[index]?.site !== ''
                    }
                    placeholder={'https://www.urist.ru'}
                />
            </div>
            <FormikInput
                index={index}
                fieldClassName={styles.EditForm__datepicker}
                error={formik.errors.position}
                name={`experience.${index}.position`}
                title={'Должность'}
                isValid={errors && !errors.position}
                placeholder={'Юрист'}
            />
            <FormikTextarea
                title="Обязанности и достижения"
                error={formik.errors.description}
                isValid={formik.errors && !errors.description}
                name={`experience.${index}.description`}
            />
            {isChecked && <div className={styles.overlay}></div>}
        </div>
    );
};

interface EditFormProps {
    formType: string;
    experience?: any;
    index: number;
    arrayHelpers: any;
    errors: any;
    formik: any;
    isChecked: boolean;
}
