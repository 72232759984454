import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './VacanciesSection.module.scss';

import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import { RightArrowIcon } from '../../../../../assets/icons';
import { Vacancy } from '../../../../../components/Vacancy/Vacancy';
import { slideCounter } from '../../../../../helpers/slideCounter';
import { useAppDispatch } from '../../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/reduxHooks/useAppSelector';
import { useWindowWidth } from '../../../../../hooks/useWindowWidth';
import { PATH_DASHBOARD } from '../../../../../routes/RouterConfig';
import { getVacancyList } from '../../../../../store/reducers/MainVacancySlice';
import { Role } from '../../../../../types/types';
import './styles.css';

export const VacanciesSection = () => {
    const { isLogin, role } = useAppSelector((state) => state.authReducer);
    const { vacancyList } = useAppSelector((state) => state.mainVacancyReducer);

    const link = useMemo(() => {
        if (!isLogin) {
            return `${PATH_DASHBOARD.root}?login_vacancy=all`;
        }
        return PATH_DASHBOARD.student.vacancies.root;
    }, [isLogin]);

    const windowWidth = useWindowWidth();
    const slides = slideCounter(windowWidth ? windowWidth : 1700);

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getVacancyList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className={styles.vacancies} id="vacancies">
            <div className={styles.vacancies__wrapper}>
                <h3 className={styles.vacancies__header}>Свежие вакансии</h3>
                {vacancyList?.objects.length ? (
                    <Swiper
                        slidesPerView={slides}
                        spaceBetween={0}
                        pagination={{
                            clickable: true,
                        }}
                        className="mySwiper"
                    >
                        {vacancyList?.objects.map((vacancy) => (
                            <SwiperSlide key={vacancy.id}>
                                <Vacancy
                                    {...vacancy}
                                    key={`vacancy_${vacancy.id}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className={styles.message}>Свежих вакансий нет!</div>
                )}
                {vacancyList?.objects.length &&
                role !== Role.staff &&
                role !== Role.organization ? (
                    <div className={styles.vacancies__link}>
                        <Link to={link} className={styles.vacancies__link_text}>
                            Все вакансии
                        </Link>

                        <RightArrowIcon />
                    </div>
                ) : null}
            </div>
        </section>
    );
};
