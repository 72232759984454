import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    city: yup
        .string()
        .required('Поле обязательно')
        .max(35, 'Название города не может быть больше 35 символов')
        .matches(
            /^[а-яА-ЯёЁ]/,
            'Название города должно быть введено на русском языке',
        ),
    birthday: yup
        .date()
        .typeError('Поле обязательно')
        .max(new Date(), 'Выбранная дата не может быть позже сегодняшнего дня')
        .nullable('Укажите поле даты верно')
        .required('Поле обязательно'),
    citizenship: yup
        .string()
        .required('Поле обязательно')
        .max(35, 'Название города не может быть больше 35 символов')
        .matches(
            /^[-/,.а-яА-ЯёЁ \s]+$/,
            'Гражданство должно быть введено на русском языке',
        ),
    salary: yup
        .number()
        .typeError('Зарплатные ожидания в формате числа')
        .required('Поле обязательно'),
    position: yup
        .string()
        .required('Поле обязательно')
        .max(35, 'Название желаемой позиции не может быть больше 35 символов')
        .matches(
            /^[-/,.а-яА-ЯёЁ \s]+$/,
            'Желаемая позиция должна быть введена на русском языке',
        ),
});
