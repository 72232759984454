import AppLink from "../AppLink/AppLink";
import styles from "./Navigation.module.scss";
import { navigationData } from "./NavigationData";

export const Navigation = () => {
  return (
    <nav className={styles.header__nav}>
      {navigationData.map((item) => (
        <AppLink to={item.path ? item.path : "/"} key={item.id}>
          {item.name}
        </AppLink>
      ))}
    </nav>
  );
};
