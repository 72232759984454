// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageHeader_pageheader__4wXuO {\n  display: flex;\n  align-items: center;\n  font: var(--font-l);\n  font-weight: var(--font-weight-bold);\n  border-radius: 17px;\n  background-color: var(--bg-second-color);\n  padding: 28px 36px;\n  margin-bottom: 30px;\n}\n.PageHeader_pageheader__header__2Kn1t {\n  font: var(--font-m);\n  font-weight: var(--font-weight-bold);\n}\n.PageHeader_pageheader__title__dwDb0 {\n  margin-right: 129px;\n  font-weight: var(--font-weight-bold);\n  white-space: nowrap;\n}\n@media (max-width: 1600px) {\n  .PageHeader_pageheader__title__dwDb0 {\n    margin-right: 35px;\n  }\n}\n.PageHeader_pageheader__description__6LYhw {\n  font: var(--font-s);\n  font-weight: var(--font-weight-medium);\n  max-width: 1070px;\n}", "",{"version":3,"sources":["webpack://./src/components/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,oCAAA;EAEA,mBAAA;EACA,wCAAA;EACA,kBAAA;EAEA,mBAAA;AAFD;AAGC;EACC,mBAAA;EACA,oCAAA;AADF;AAGC;EACC,mBAAA;EACA,oCAAA;EACA,mBAAA;AADF;AAEE;EAJD;IAKE,kBAAA;EACD;AACF;AACC;EACC,mBAAA;EACA,sCAAA;EAEA,iBAAA;AAAF","sourcesContent":[".pageheader {\n\tdisplay: flex;\n\talign-items: center;\n\t\n\tfont: var(--font-l);\n\tfont-weight: var(--font-weight-bold);\n\n\tborder-radius: 17px;\n\tbackground-color: var(--bg-second-color);\n\tpadding: 28px 36px;\n\n\tmargin-bottom: 30px;\n\t&__header {\n\t\tfont: var(--font-m);\n\t\tfont-weight: var(--font-weight-bold);\n\t}\n\t&__title {\n\t\tmargin-right: 129px;\n\t\tfont-weight: var(--font-weight-bold);\n\t\twhite-space: nowrap;\n\t\t@media (max-width: 1600px) {\n\t\t\tmargin-right: 35px;\n\t\t}\n\t}\n\t&__description {\n\t\tfont: var(--font-s);\n\t\tfont-weight: var(--font-weight-medium);\n\t\t\n\t\tmax-width: 1070px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageheader": "PageHeader_pageheader__4wXuO",
	"pageheader__header": "PageHeader_pageheader__header__2Kn1t",
	"pageheader__title": "PageHeader_pageheader__title__dwDb0",
	"pageheader__description": "PageHeader_pageheader__description__6LYhw"
};
export default ___CSS_LOADER_EXPORT___;
