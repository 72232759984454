import { useState, useEffect } from 'react';

// хук, считывающий текущую ширину экрана
const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState<number | undefined>(
        undefined,
    );

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

export { useWindowWidth };
