import { PATH_DASHBOARD } from "../../routes/RouterConfig";

export const navigationData = [
	{
		name: "Новости",
		path: PATH_DASHBOARD.news,
		id: 1,
	},
	{
		name: "Мероприятия",
		path: PATH_DASHBOARD.events,
		id: 2,
	},
	{
		name: "Партнеры",
		path: PATH_DASHBOARD.partners,
		id: 3,
	},
	{
		name: "О нас",
		path: PATH_DASHBOARD.about,
		id: 4,
	},
	{
		name: "Поддержка",
		path: PATH_DASHBOARD.help,
		id: 5,
	},
];
