import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    first_name: yup
        .string()
        .typeError('Должно быть строкой')
        .required('Поле обязательно')
        .max(50, 'Поле не должно содержать более 50 символов')
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^[a-яё]+(?:[ -][a-яё]+)*$/i,
            'Введите имя на русском языке.',
        ),
    second_name: yup
        .string()
        .typeError('Должно быть строкой')
        .required('Поле обязательно')
        .max(50, 'Поле не должно содержать более 50 символов')
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^[a-яё]+(?:[ -][a-яё]+)*$/i,
            'Введите имя на русском языке.',
        ),
    last_name: yup
        .string()
        .nullable()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^[a-яё]+(?:[ -][a-яё]+)*$/i,
            'Введите имя на русском языке.',
        ),
    phone: yup
        .string()
        .typeError('Должно быть строкой')
        .nullable()
        .matches(
            // eslint-disable-next-line no-useless-escape
            /^(?:\d{11}|\+\d{11})$/,
            'Некорректный номер телефона',
        ),
});
