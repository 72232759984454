import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { authSlice } from './AuthSlice';
export type CompanyListItemType = {
    is_verified: boolean;
    full_title: string;
    short_title: string;
    status: string;
    city: string;
    address: string;
    about: string;
    tg: string;
    contact_email: string;
    contact_phone: string;
    contact_person: string;
    contact_person_position: string;
    site: string;
    favorite_events: number[];
    email: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    photo: string;
    id: number;
    has_subscription: boolean;
    subscription_from: string;
    subscription_to: string;
};

export type CompanyListType = {
    page_count: number;
    current_page: number;
    objects: CompanyListItemType[];
};

const initialState: CompanyState = {
    unverifiedCompanyList: undefined,
    unverifiedCompanyListPage: 1,
    unverifiedCompanyListIsLoading: false,
    unverifiedCompanyListError: null,

    companyList: undefined,
    companyListPage: 1,
    companyListIsLoading: false,
    companyListError: null,

    company: undefined,
    isLoading: false,
    error: null,
};

interface CompanyState {
    unverifiedCompanyList: CompanyListType | undefined;
    unverifiedCompanyListPage: number;
    unverifiedCompanyListIsLoading: boolean;
    unverifiedCompanyListError: any;

    companyList: CompanyListType | undefined;
    companyListPage: number;
    companyListIsLoading: boolean;
    companyListError: any;

    company: CompanyListItemType | undefined;
    isLoading: boolean;
    error: any;
}

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getCompanyList = createAsyncThunk(
    'company/getCompanyList',
    async function name(
        { page, size }: { page: number; size: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

//асинхронная позгрузка инфы о пользователе по айди
export const getCompanyRetrieve = createAsyncThunk(
    'company/getCompanyRetrieve',
    async function (id: number, { rejectWithValue, dispatch }) {
        const token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/`;

        const data = await axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((data) => data.data)
            .catch(async (error: AxiosError<{ code: string }>) => {
                if (error.response?.data.code === 'token_not_valid') {
                    const refresh = localStorage.getItem('refresh');
                    const urlRefresh = `${process.env.REACT_APP_BASE_URL}api/v1/token/refresh/`;

                    await axios
                        .post<{
                            access: string;
                            refresh: string;
                        }>(urlRefresh, {
                            refresh: refresh,
                        })
                        .then((data) => {
                            if (data.data.access) {
                                dispatch(
                                    authSlice.actions.refreshToken({
                                        token: data.data.access,
                                    }),
                                );
                                localStorage.setItem('token', data.data.access);
                            }
                        });

                    const dataRefresh = await axios
                        .get(url)
                        .then((data) => data.data);

                    return dataRefresh;
                }
            });

        return data;
    },
);

// подгружаем студентов в фильтрационный стэйт на основании фильтров, которые будем прокидывать извне
export const getUnverifiedCompanyList = createAsyncThunk(
    'company/getUnverifiedCompanyList',
    async function name(
        { page, size }: { page: number; size: number },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/company/unverified/`,
                { params: { page, size } },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const confirmCompanyList = createAsyncThunk(
    'company/confirmCompanyList',
    async function (id: number, { rejectWithValue }) {
        try {
            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/verified/`,
                    { is_verified: true },
                )
                .then(() => {
                    toast.success('Партнер успешно верифицирован');
                })
                .catch(() => toast.error('Партнер не верифицирован'));
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const deleteCompany = createAsyncThunk(
    'company/deleteCompany',
    async function (id: number, { rejectWithValue }) {
        try {
            await axios
                .delete(
                    `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/`,
                )
                .then(() => {
                    toast.success('Заявка удалена');
                })
                .catch(() => toast.error('Заявка не удалена'));
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateCompanyListPage(state, action: PayloadAction<number>) {
            state.companyListPage = action.payload;
        },
        updateUnverifiedCompanyListPage(state, action: PayloadAction<number>) {
            state.unverifiedCompanyListPage = action.payload;
        },
        clearCompany(state) {
            state.company = undefined;
        },
    },
    extraReducers: (builder) => {
        // фетч неподтвержденных админом студентов
        builder.addCase(getCompanyRetrieve.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCompanyRetrieve.fulfilled, (state, action) => {
            state.isLoading = false;
            state.company = action.payload;
        });
        builder.addCase(getCompanyRetrieve.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        // фетч неподтвержденных админом студентов
        builder.addCase(getCompanyList.pending, (state) => {
            state.companyListIsLoading = true;
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {
            state.companyListIsLoading = false;
            state.companyList = action.payload;
        });
        builder.addCase(getCompanyList.rejected, (state, action) => {
            state.companyListIsLoading = false;
            state.companyListError = action.payload;
        });

        // фетч подтвержденных студентов
        builder.addCase(getUnverifiedCompanyList.pending, (state) => {
            state.unverifiedCompanyListIsLoading = true;
        });
        builder.addCase(getUnverifiedCompanyList.fulfilled, (state, action) => {
            state.unverifiedCompanyListIsLoading = false;
            state.unverifiedCompanyList = action.payload;
        });
        builder.addCase(getUnverifiedCompanyList.rejected, (state, action) => {
            state.unverifiedCompanyListIsLoading = false;
            state.unverifiedCompanyListError = action.payload;
        });
    },
});

export const {
    updateCompanyListPage,
    updateUnverifiedCompanyListPage,
    clearCompany,
} = companySlice.actions;

export default companySlice.reducer;
