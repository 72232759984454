import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import Button from '../../../UI/Button/Button';
import { Input } from '../../../UI/Input/Input';
import { MultiCheckbox } from '../../../UI/MultiCheckbox/MultiCheckbox';
import { RangeInput } from '../../../UI/RangeInput/RangeInput';
import { Textarea } from '../../../UI/Textarea/Textarea';
import { CloseIcon } from '../../../assets/icons';
import { Loader } from '../../../components/Loader/Loader';
import { Modal } from '../../../components/Modal/Modal';
import { EMPLOYMENT_FORM, EXPERIANCE_CONSTANT } from '../../../constants';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    deleteFilter,
    getCandidateVacancyList,
    updateCandidateVacancyListPage,
    updateCandidateVacancyReaction,
    updateCompany,
    updateEmploymentForm,
    updateRequiredExperience,
    updateSalary,
    updateSalaryFrom,
    updateSalaryTo,
} from '../../../store/reducers/CandidateVacancySlice';
import Pagination from '../../StaffPages/SupportList/Pagination';
import { StudentVacancyListItem } from './VacancyItem';
import styles from './VacancyItem/index.module.scss';

export const StudentVacancyList = () => {
    const dispatch = useAppDispatch();

    const [modalId, setModalId] = useState<
        { id: number; name: string } | undefined
    >(undefined);

    const {
        candidateVacancyList,
        candidateVacancyListIsLoading,
        candidateVacancyListPage,
        company,
        employment_form,
        required_experience,
        salary,
    } = useAppSelector((state) => state.candidateVacancyReducer);

    const { candidate } = useAppSelector((state) => state.candidateReducer);

    const refetchCompanyVacancyList = () => {
        dispatch(
            getCandidateVacancyList({
                page: candidateVacancyListPage,
                size: 10,
                status: 'OP',
                company,
                employment_form,
                required_experience,
                salary_from: salary[0],
                salary_to: salary[1],
            }),
        );
    };

    useEffect(() => {
        dispatch(
            getCandidateVacancyList({
                page: candidateVacancyListPage,
                size: 10,
                status: 'OP',
                company,
                employment_form,
                required_experience,
                salary_from: salary[0],
                salary_to: salary[1],
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateVacancyListPage]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            text: '',
        },
        onSubmit: async () => {
            if (candidate?.id && modalId?.id) {
                await dispatch(
                    updateCandidateVacancyReaction({
                        id: candidate?.id,
                        vacancy_id: modalId?.id,
                        comment: formik.values.text,
                    }),
                ).then(() => setModalId(undefined));
                refetchCompanyVacancyList();
            }
        },
        validationSchema: yup.object().shape({
            text: yup
                .string()
                .required('Поле обязательно')
                .max(1000, 'Достигнута максимальная длина письма'),
        }),
    });

    useEffect(() => {
        if (candidate?.id) {
            refetchCompanyVacancyList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidate?.id]);

    return (
        <>
            {modalId ? (
                <Modal>
                    <form
                        onSubmit={formik.handleSubmit}
                        className={styles.modal}
                    >
                        <h3>Отклик на вакансию</h3>
                        <span>{modalId.name}</span>
                        <Textarea
                            name="text"
                            placeholder="Сопроводительное письмо"
                            value={formik.values.text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.errors.text}
                            isValid={
                                Boolean(formik.values.text) &&
                                !formik.errors.text
                            }
                        />
                        <button
                            type="submit"
                            className={styles.vacancyAddButtonAccept}
                            disabled={!formik.isValid || !formik.dirty}
                        >
                            Отправить
                        </button>
                        <button
                            className={styles.closeButton}
                            onClick={() => setModalId(undefined)}
                        >
                            <CloseIcon />
                        </button>
                    </form>
                </Modal>
            ) : null}
            <div className={styles.companyVacancyList}>
                <div className={styles.companyVacancyListGrid}>
                    {candidateVacancyListIsLoading ? <Loader /> : null}

                    {!candidateVacancyListIsLoading &&
                    candidateVacancyList?.objects.length === 0 ? (
                        <span className={styles.notificationText}>
                            Вакансий пока нет!
                        </span>
                    ) : null}

                    {!candidateVacancyListIsLoading &&
                        candidateVacancyList &&
                        candidateVacancyList.objects.map((item) => (
                            <StudentVacancyListItem
                                key={`student-vacancy-${item.id}`}
                                vacancy={item}
                                refetch={() => refetchCompanyVacancyList()}
                                setModal={() =>
                                    setModalId({
                                        id: item.id,
                                        name: item.position,
                                    })
                                }
                            />
                        ))}

                    <Pagination
                        list={candidateVacancyList}
                        handlePagination={(p) => {
                            dispatch(updateCandidateVacancyListPage(p));
                        }}
                        isLoading={candidateVacancyListIsLoading}
                    />
                </div>
                <FiltersWidget />
            </div>
        </>
    );
};

const FiltersWidget = () => {
    const dispatch = useAppDispatch();

    const { company, required_experience, employment_form, salary } =
        useAppSelector((state) => state.candidateVacancyReducer);

    const applyFilters = () => {
        dispatch(
            getCandidateVacancyList({
                page: 1,
                size: 10,
                status: 'OP',
                company,
                employment_form,
                required_experience,
                salary_from: salary[0],
                salary_to: salary[1],
            }),
        );
    };

    return (
        <div className={styles.filter}>
            <CompanyField />
            <EmploymentFromMultiCheckbox />
            <ExperienceMultiCheckbox />
            <SalaryRangeInput />
            <Button className={styles.button__accept} onClick={applyFilters}>
                Применить
            </Button>
            <Button
                className={styles.button__reset}
                onClick={() => {
                    dispatch(deleteFilter());
                    getCandidateVacancyList({
                        page: 1,
                        size: 10,
                        status: 'OP',
                    });
                }}
            >
                Сбросить фильтры
            </Button>
        </div>
    );
};

const CompanyField = () => {
    const dispatch = useAppDispatch();
    const changeOption = (value: string) => {
        dispatch(updateCompany(value));
    };
    const { company } = useAppSelector(
        (state) => state.candidateVacancyReducer,
    );

    return (
        <Input
            placeholder={'Поиск компании'}
            onChange={(e) => changeOption(e.target.value)}
            value={company}
        />
    );
};

const EmploymentFromMultiCheckbox = () => {
    const dispatch = useAppDispatch();
    const changeOptions = (value: string) => {
        dispatch(updateEmploymentForm(value));
    };
    const currentCheckedValues = useAppSelector(
        (state) => state.candidateVacancyReducer.employment_form,
    );

    return (
        <MultiCheckbox
            title={'Занятость'}
            options={EMPLOYMENT_FORM}
            changeOptions={changeOptions}
            currentValues={currentCheckedValues || []}
        />
    );
};

const ExperienceMultiCheckbox = () => {
    const dispatch = useAppDispatch();
    const changeOptions = (value: string) => {
        dispatch(updateRequiredExperience(value));
    };
    const currentCheckedValues = useAppSelector(
        (state) => state.candidateVacancyReducer.required_experience,
    );
    return (
        <MultiCheckbox
            title={'Опыт работы'}
            options={EXPERIANCE_CONSTANT}
            changeOptions={changeOptions}
            currentValues={currentCheckedValues || []}
        />
    );
};

const SalaryRangeInput = () => {
    const dispatch = useAppDispatch();
    const changeRange = (range: [number, number]) => {
        dispatch(updateSalary(range));
    };
    const salaryRange = useAppSelector(
        (state) => state.candidateVacancyReducer.salary,
    );

    const startValue = salaryRange[0];
    const endValue = salaryRange[1];

    const changeStart = (value: string) => {
        dispatch(updateSalaryFrom(Number(value)));
    };
    const changeEnd = (value: string) => {
        dispatch(updateSalaryTo(Number(value)));
    };
    return (
        <RangeInput
            title={'Заработная плата'}
            min={10000}
            max={1000000}
            changeRange={changeRange}
            startValue={startValue}
            endValue={endValue}
            changeStart={changeStart}
            changeEnd={changeEnd}
        />
    );
};
