import classNames from 'classnames';
import { Dispatch, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../../components/Loader/Loader';
import { Modal } from '../../../components/Modal/Modal';
import { scrollAvailable } from '../../../helpers/scrollToggler';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../routes/RouterConfig';
import {
    CompanyVacancyListItemType,
    getCompanyVacancyList,
    updateCompanyVacancy,
    updateCompanyVacancyListPage,
} from '../../../store/reducers/CompanyVacancySlice';
import Pagination from '../../StaffPages/SupportList/Pagination';
import styles from './VacancyList.module.scss';

type VacancyModalProps = {
    modal: number;
    setModal: () => void;
    refetch: () => void;
};
export const VacancyModal: React.FC<VacancyModalProps> = ({
    modal,
    setModal,
    refetch,
}) => {
    const dispatch = useAppDispatch();

    const closePopup = () => {
        setModal();
        scrollAvailable();
    };
    return (
        <Modal>
            <div className={styles.vacancyModal}>
                <h2 className={styles.vacancyModalTitle}>
                    Вы уверены, что хотите снять эту вакансию с публикации?
                </h2>
                <div className={styles.buttonWrapper}>
                    <button
                        type="button"
                        className={styles.vacancyModalButton}
                        onClick={async () => {
                            await dispatch(
                                updateCompanyVacancy({
                                    id: modal,
                                    status: 'CL',
                                }),
                            );
                            refetch();
                            closePopup();
                        }}
                    >
                        Да
                    </button>
                    <button
                        type="button"
                        onClick={closePopup}
                        className={styles.vacancyModalButton}
                    >
                        Нет
                    </button>
                </div>
            </div>
        </Modal>
    );
};

type CompanyVacancyListItemProps = {
    vacancy: CompanyVacancyListItemType;
    refetch: () => void;
    setModal: Dispatch<number | undefined>;
};
export const CompanyVacancyListItem: React.FC<CompanyVacancyListItemProps> = ({
    vacancy,
    refetch,
    setModal,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div className={styles.companyVacancyListItem}>
            <div className={styles.title}>
                <Link
                    to={`/company/vacancies/${vacancy.id}`}
                    className={styles.position}
                >
                    {vacancy.position}
                </Link>
                <span className={styles.updateDate}>
                    {new Date(vacancy.date_update).toLocaleDateString('ru-RU', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}
                </span>
            </div>

            <h3 className={styles.sallary}>
                {vacancy.salary_from} - {vacancy.salary_to} руб.
            </h3>
            <span className={styles.company}>{vacancy.company_title}</span>
            <span className={styles.description}>
                {vacancy.description.length >= 200
                    ? vacancy.description.slice(0, 200) + '...'
                    : vacancy.description}
            </span>
            {vacancy.status === 'OP' && (
                <Link
                    className={styles.reaction}
                    to={`${PATH_DASHBOARD.company.reaction}/?value=${vacancy.id}&label=${vacancy.position}`}
                >
                    Количество откликов: {vacancy.reaction_count}
                </Link>
            )}
            <div className={styles.buttons}>
                <button
                    onClick={() => navigate(`/company/vacancies/${vacancy.id}`)}
                    className={styles.vacancyAddButton}
                >
                    Подробнее
                </button>
                {vacancy.status === 'CL' ? (
                    <button
                        onClick={async () => {
                            await dispatch(
                                updateCompanyVacancy({
                                    id: vacancy.id,
                                    status: 'OP',
                                }),
                            );
                            refetch();
                        }}
                        className={styles.vacancyAddButton}
                    >
                        Опубликовать
                    </button>
                ) : (
                    <button
                        // onClick={async () => {
                        //     await dispatch(
                        //         updateCompanyVacancy({
                        //             id: vacancy.id,
                        //             status: 'CL',
                        //         }),
                        //     );
                        //     refetch();
                        // }}
                        onClick={() => {
                            setModal(vacancy.id);
                        }}
                        className={styles.vacancyAddButton}
                    >
                        Снять с публикации
                    </button>
                )}
                <button
                    onClick={() =>
                        navigate(`/company/vacancies/edit/${vacancy.id}`)
                    }
                    className={styles.vacancyAddButton}
                >
                    Редактировать
                </button>
            </div>
        </div>
    );
};
export const CompanyVacancyList = () => {
    const navigate = useNavigate();
    const [variant, setVariant] = useState(false);
    const dispatch = useAppDispatch();

    const [modal, setModal] = useState<number | undefined>();

    const {
        companyVacancyList,
        companyVacancyListIsLoading,
        companyVacancyListPage,
    } = useAppSelector((state) => state.companyVacancyReducer);

    const { company } = useAppSelector((state) => state.companyReducer);

    const refetchCompanyVacancyList = () => {
        if (company?.id) {
            dispatch(
                getCompanyVacancyList({
                    page: companyVacancyListPage,
                    size: 10,
                    status: variant ? 'CL' : 'OP',
                    id: company?.id,
                }),
            );
        }
    };

    useLayoutEffect(() => {
        refetchCompanyVacancyList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant, companyVacancyListPage, company]);

    useEffect(() => {
        dispatch(updateCompanyVacancyListPage(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variant, company]);

    return (
        <div className={styles.companyVacancyList}>
            {modal ? (
                <VacancyModal
                    modal={modal}
                    setModal={() => setModal(undefined)}
                    refetch={() => refetchCompanyVacancyList()}
                />
            ) : null}
            <div className={styles.companyVacancyTitle}>
                <div>
                    <button
                        onClick={() => setVariant(false)}
                        className={classNames(
                            styles.vacancyButton,
                            variant === false && styles.vacancyButtonActive,
                        )}
                    >
                        Опубликованные
                    </button>
                    <button
                        onClick={() => setVariant(true)}
                        className={classNames(
                            styles.vacancyButton,
                            variant === true && styles.vacancyButtonActive,
                        )}
                    >
                        Неопубликованные
                    </button>
                </div>

                <div>
                    <button
                        onClick={() =>
                            navigate(PATH_DASHBOARD.company.vacancies.create)
                        }
                        className={styles.vacancyAddButton}
                    >
                        Создать вакансию
                    </button>
                </div>
            </div>
            <div className={styles.companyVacancyListGrid}>
                {companyVacancyListIsLoading ? <Loader /> : null}

                {!companyVacancyListIsLoading &&
                companyVacancyList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        Вакансий пока нет!
                    </span>
                ) : null}

                {!companyVacancyListIsLoading &&
                    companyVacancyList &&
                    companyVacancyList.objects.map((item) => (
                        <CompanyVacancyListItem
                            key={`vacancy-${item.id}`}
                            vacancy={item}
                            refetch={() => refetchCompanyVacancyList()}
                            setModal={setModal}
                        />
                    ))}

                <Pagination
                    list={companyVacancyList}
                    handlePagination={(p) => {
                        dispatch(updateCompanyVacancyListPage(p));
                    }}
                    isLoading={companyVacancyListIsLoading}
                />
            </div>
        </div>
    );
};
