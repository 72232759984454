// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput_Input__AJ7XX {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 43px;\n  position: relative;\n  z-index: 0;\n  cursor: pointer;\n  font: var(--font-s);\n}\n.SearchInput_Input__AJ7XX ::placeholder {\n  color: #333;\n}\n.SearchInput_Input__container__VK3ac {\n  width: 100%;\n  padding: 0 20px 0 40px;\n  border-radius: 17px;\n  outline: none;\n  border: 1px solid #c6c6c6;\n  cursor: pointer;\n  padding: 10px 10px 10px 50px;\n}\n.SearchInput_Input__icon__S89d9 {\n  position: absolute;\n  right: 15px;\n  display: flex;\n  align-items: center;\n}\n\ninput:focus {\n  border-color: #3498db;\n}", "",{"version":3,"sources":["webpack://./src/UI/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EAEA,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,mBAAA;AADJ;AAEI;EACI,WAAA;AAAR;AAGI;EACI,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,4BAAA;AADR;AAGI;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AADR;;AAKA;EACI,qBAAA;AAFJ","sourcesContent":[".Input {\n    display: flex;\n    align-items: center;\n\n    width: 100%;\n    height: 43px;\n\n    position: relative;\n    z-index: 0;\n    cursor: pointer;\n    font: var(--font-s);\n    ::placeholder {\n        color: #333;\n    }\n\n    &__container {\n        width: 100%;\n        padding: 0 20px 0 40px;\n        border-radius: 17px;\n        outline: none;\n        border: 1px solid #c6c6c6;\n        cursor: pointer;\n        padding: 10px 10px 10px 50px;\n    }\n    &__icon {\n        position: absolute;\n        right: 15px;\n        display: flex;\n        align-items: center;\n    }\n}\n\ninput:focus {\n    border-color: #3498db;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "SearchInput_Input__AJ7XX",
	"Input__container": "SearchInput_Input__container__VK3ac",
	"Input__icon": "SearchInput_Input__icon__S89d9"
};
export default ___CSS_LOADER_EXPORT___;
