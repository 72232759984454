// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__-VVF9 {\n  display: flex;\n  flex-direction: column;\n}\n\n.Layout_cabinet__dDjFs {\n  display: grid;\n  grid-template-columns: 0.35fr 1fr;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n}\n@media (max-width: 1000px) {\n  .Layout_cabinet__dDjFs {\n    grid-template-columns: 1fr;\n  }\n}\n.Layout_cabinet__wrapper__sMIVI {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/hocs/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;AACD;;AAEA;EACC,aAAA;EACA,iCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACD;AAAC;EAND;IAOE,0BAAA;EAGA;AACF;AAFC;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAIF","sourcesContent":[".main {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.cabinet {\n\tdisplay: grid;\n\tgrid-template-columns: 0.35fr 1fr;\n\twidth: 100vw;\n\theight: 100vh;\n\talign-items: center;\n\t@media (max-width: 1000px) {\n\t\tgrid-template-columns: 1fr;\n\t}\n\t&__wrapper {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\theight: 100%;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__-VVF9",
	"cabinet": "Layout_cabinet__dDjFs",
	"cabinet__wrapper": "Layout_cabinet__wrapper__sMIVI"
};
export default ___CSS_LOADER_EXPORT___;
