import cn from 'classnames';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import styles from './EditWrapper.module.scss';

interface EditWrapperProps {
    children: React.ReactNode;
    title: string;
    className?: string;
    saveLink?: string;
    rejectLink?: string;
    saveOnclick?: () => void;
    rejectOnclick?: () => void;
    dirty?: boolean;
    isValid?: boolean;
    isSubmitButton?: boolean;
}

export const EditWrapper = ({
    children,
    title,
    className,
    saveLink,
    rejectLink,
    dirty,
    isValid,
    saveOnclick,
    isSubmitButton = false,
}: EditWrapperProps) => {
    const navigate = useNavigate();
    return (
        <div className={cn(styles.EditWrapper, className)}>
            <div className={styles.EditWrapper__top}>
                <h2 className={styles.EditWrapper__header}>{title}</h2>
                {!isSubmitButton ? (
                    <div className={styles.EditWrapper__buttons}>
                        <Button
                            onClick={() => {
                                if (!isValid || !saveOnclick) {
                                    return;
                                }
                                saveOnclick();
                                setTimeout(() => {
                                    navigate(
                                        saveLink
                                            ? saveLink
                                            : PATH_DASHBOARD.student.resume
                                                  .root,
                                    );
                                }, 700);
                            }}
                            isDisabled={!isValid}
                            className={cn(styles.EditWrapper__buttons_save, {
                                [styles.EditWrapper__buttons_valid]: isValid,
                            })}
                        >
                            Сохранить
                        </Button>
                        <Link
                            className={styles.EditWrapper__buttons_reject}
                            to={
                                rejectLink
                                    ? rejectLink
                                    : PATH_DASHBOARD.student.resume.root
                            }
                        >
                            Отмена
                        </Link>
                    </div>
                ) : null}
            </div>
            {children}
        </div>
    );
};
