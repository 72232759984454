import cn from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CheckMark } from '../../assets/icons';
import logoSrc from '../../assets/images/CabinetLogo.png';
import { getAsideElements } from '../../helpers/getAsideElements';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { DropdownElements, Role } from '../../types/types';
import styles from './CabinetAside.module.scss';

interface CabinetAsideProps {
    isAsideOpen: boolean;
}

export const CabinetAside = ({ isAsideOpen }: CabinetAsideProps) => {
    return (
        <aside
            className={cn(styles.aside, {
                [styles.aside__opened]: isAsideOpen,
            })}
        >
            <AsideLogo />
            <AsideNavigation />
        </aside>
    );
};

const AsideLogo = () => {
    const { role } = useAppSelector((state) => state.authReducer);

    return (
        <Link to={PATH_DASHBOARD.root} className={styles.logo}>
            <div className={styles.logo__photo}>
                <img src={logoSrc} className={styles.logo__image} alt="logo" />
            </div>
            <h2 className={styles.logo__header}>
                Личный кабинет
                {role === Role.candidate && ' студента/выпускника'}{' '}
                {role === Role.organization && 'партнера'}{' '}
                {role === Role.staff && 'сотрудника Центра карьеры'}{' '}
                Юридического факультета МГУ им. М.В.Ломоносова
            </h2>
        </Link>
    );
};

const AsideNavigation = () => {
    const { role } = useAppSelector((state) => state.authReducer);
    const asideElements = role && getAsideElements(role);
    const [activeElement, setActiveElement] = useState<string>('');
    return (
        <nav className={styles.navigation}>
            {asideElements &&
                asideElements.length > 0 &&
                asideElements.map((el: any) => (
                    <AsideNavigationElement
                        key={el.id}
                        name={el.name}
                        icon={el.icon}
                        dropdownElements={el.dropdownElements}
                        link={el.link}
                        activeElement={activeElement}
                        setActiveElement={setActiveElement}
                    />
                ))}
        </nav>
    );
};

export interface AsideElement {
    id?: number;
    icon: JSX.Element;
    name: string;
    link?: string | null;
    dropdownElements?: Array<DropdownElements>;
    onClick?: () => void;
    activeElement: string;
    setActiveElement: Dispatch<SetStateAction<string>>;
}

const AsideNavigationElement = ({
    name,
    icon,
    dropdownElements,
    link,
    activeElement,
    setActiveElement,
}: AsideElement) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedElement, setSelectedElement] = useState<string>('');
    return (
        <div
            onClick={() => link && navigate(link)}
            className={cn(styles.navigationElement, {
                [styles.navigationElement__active]: activeElement === name,
            })}
        >
            <div
                className={styles.navigationElement__wrapper}
                onClick={() => setActiveElement(name)}
            >
                <div className={styles.navigationElement__icon}>{icon}</div>
                <h3 className={styles.navigationElement__name}>{name}</h3>
                {dropdownElements?.length !== 0 && (
                    <div className={styles.navigationElement__checkmark}>
                        <CheckMark />
                    </div>
                )}
            </div>
            {dropdownElements && (
                <Dropdown
                    selectedElement={location.pathname}
                    isDropdownOpened={activeElement === name}
                    dropdownElements={dropdownElements}
                    setSelectedElement={setSelectedElement}
                />
            )}
        </div>
    );
};

interface DropdownProps {
    isDropdownOpened: boolean;
    dropdownElements: Array<DropdownElements>;
    selectedElement: string;
    setSelectedElement: Dispatch<SetStateAction<string>>;
}

const Dropdown = ({
    isDropdownOpened,
    dropdownElements,
    selectedElement,
    setSelectedElement,
}: DropdownProps) => {
    return (
        <div
            className={cn(styles.navigationElement__dropdown, {
                [styles.navigationElement__active]: isDropdownOpened,
            })}
        >
            {dropdownElements?.map((el) => (
                <Link
                    key={el.dropdownId}
                    to={el.src}
                    className={cn(styles.dropdownElement, {
                        [styles.dropdownElement_opened]:
                            selectedElement === el.src,
                    })}
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                        setSelectedElement(el.title);
                    }}
                >
                    {el.title}
                </Link>
            ))}
        </div>
    );
};
