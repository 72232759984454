import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './PartnersSection.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

import { useEffect } from 'react';
import { Autoplay, Navigation } from 'swiper';
import { useAppDispatch } from '../../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../../hooks/reduxHooks/useAppSelector';
import { useWindowWidth } from '../../../../../hooks/useWindowWidth';
import { getLogoCompanyList } from '../../../../../store/reducers/PartnerSlice';

export const PartnersSection = () => {
    const { logoCompanyList } = useAppSelector((state) => state.partnerReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLogoCompanyList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const windowWidth = useWindowWidth();

    const BIG_SCREEN_SIZE = 2000;
    const MEDIUM_SCREEN_SIZE = 1000;
    const LITTLE_SCREEN_SIZE = 700;

    const slideCounter = (width: number): number => {
        let count;
        if (width > BIG_SCREEN_SIZE) {
            return (count = 5);
        } else if (width < BIG_SCREEN_SIZE && width > MEDIUM_SCREEN_SIZE) {
            return (count = 4);
        } else if (width < LITTLE_SCREEN_SIZE) {
            return (count = 2);
        } else {
            return (count = 3);
        }
    };

    const slides = slideCounter(windowWidth ? windowWidth : BIG_SCREEN_SIZE);

    return logoCompanyList && logoCompanyList.length !== 0 ? (
        <section className={styles.partners}>
            <h4 className={styles.partners__header}>
                <span>Партнеры</span>
            </h4>
            <div className={styles.partners__slider}>
                <Swiper
                    slidesPerView={slides}
                    spaceBetween={0}
                    pagination={{
                        clickable: true,
                    }}
                    speed={10000}
                    autoplay={{
                        delay: 500,
                        disableOnInteraction: false,
                    }}
                    className="mySwiper"
                    modules={[Navigation, Autoplay]}
                >
                    {logoCompanyList.map((partner) => (
                        <SwiperSlide key={partner.id}>
                            <PartnerSlide partnerSrc={partner.image} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    ) : null;
};

interface PartnerProps {
    partnerSrc: string;
}

const PartnerSlide = ({ partnerSrc }: PartnerProps) => {
    return (
        <div className={styles.partnerslide}>
            <img
                className={styles.partnerslide__image}
                src={partnerSrc}
                alt=""
            />
        </div>
    );
};
