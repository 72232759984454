import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './reducers/AuthSlice';
import candidateReducer from './reducers/CandidateSlice';
import candidateVacancyReducer from './reducers/CandidateVacancySlice';
import companyReactionReducer from './reducers/CompanyReactionSlice';
import companyReducer from './reducers/CompanySlice';
import companyVacancyReducer from './reducers/CompanyVacancySlice';
import educationReducer from './reducers/EducationSlice';
import eventReducer from './reducers/EventSlice';
import experianceReducer from './reducers/ExperianceSlice';
import languagesReducer from './reducers/LanguagesSlice';
import mainVacancyReducer from './reducers/MainVacancySlice';
import partnerReducer from './reducers/PartnerSlice';
import registerReducer from './reducers/RegistrationSlice';
import socialActivitiesReducer from './reducers/SocialActivitiesSlice';
import newsReducer from './reducers/StaffNewsSlice';
import staffReactionReducer from './reducers/StaffReactionSlice';
import staffReducer from './reducers/StaffSlice';
import staffVacancyReducer from './reducers/StaffVacancySlice';
import studentsRetrieveReducer from './reducers/StudentsRetrieveSlice';
import studentsReducer from './reducers/StudentsSlice';
import supportReducer from './reducers/SupportSlice';

const rootReducer = combineReducers({
    registerReducer,
    authReducer,
    studentsReducer,
    candidateReducer,
    educationReducer,
    languagesReducer,
    experianceReducer,
    socialActivitiesReducer,
    supportReducer,
    companyReducer,
    companyVacancyReducer,
    companyReactionReducer,
    candidateVacancyReducer,
    staffReducer,
    staffReactionReducer,
    staffVacancyReducer,
    newsReducer,
    mainVacancyReducer,
    eventReducer,
    studentsRetrieveReducer,
    partnerReducer,
});

const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// added by backend
// нужно для восстановления состояний редюсеров. используем в корневом index.tsx
// иначе при перезагрузке стейты редюсеров будут заполнены значениями по умолчанию (null и пр)
const persistConfig = {
    key: 'root',
    storage,
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);
const persistedReducer = rootReducer;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});
export const persistor = persistStore(store);
