export const GRADE_CONSTANT: Record<string, string> = {
    BH: 'Бакалавриат',
    MG: 'Магистратура',
    SP: 'Специалитет',
};

export const GRADE_DROPDOWN_OPTIONS = [
    {
        value: 'BH',
        label: 'Бакалавриат',
    },
    {
        value: 'MG',
        label: 'Магистратура',
    },
    {
        value: 'SP',
        label: 'Специалитет',
    },
];

export const EXPERIANCE_CONSTANT = [
    {
        value: 'NO',
        label: 'Нет опыта работы',
    },
    {
        value: 'L1',
        label: 'Менее 1 года работы',
    },
    {
        value: '13',
        label: 'От 1 до 3 лет',
    },
    {
        value: 'M3',
        label: 'Более трех лет',
    },
];

export const EMPLOYMENT_FORM = [
    { value: 'FE', label: 'Полная занятость' },
    { value: 'PE', label: 'Частичная занятость' },
    { value: 'RW', label: 'Удаленная работа' },
    { value: 'IS', label: 'Стажировка' },
    { value: 'SH', label: 'Вахта' },
];

export const languageOptions = [
    { value: 'Русский', label: 'Русский' },
    { value: 'Английский', label: 'Английский' },
    { value: 'Французский', label: 'Французский' },
    { value: 'Немецкий', label: 'Немецкий' },
    { value: 'Испанский', label: 'Испанский' },
    { value: 'Китайский', label: 'Китайский' },
    { value: 'Итальянский', label: 'Итальянский' },
    { value: 'Армянский', label: 'Армянский' },
    { value: 'Хинди', label: 'Хинди' },
    { value: 'Арабский', label: 'Арабский' },
];

export const levelOptions = [
    { value: 'A1', label: 'A1' },
    { value: 'A2', label: 'А2' },
    { value: 'B1', label: 'В1' },
    { value: 'B2', label: 'B2' },
    { value: 'C1', label: 'C1' },
    {
        value: 'C2',
        label: 'C2',
    },
];
