/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    Candidate,
    EducationData,
    Experience,
    Language,
    SocialActivities,
} from '../../types/types';

const initialState: CandidateState = {
    candidate: null,
    isLoading: false,
    socialActivities: null,
    educationData: null,
    languages: null,
    experiances: null,
};

interface CandidateState {
    candidate: Candidate | null;
    isLoading: boolean;
    socialActivities: SocialActivities[] | null;
    educationData: EducationData[] | null;
    languages: Language[] | null;
    experiances: Experience[] | null;
}

export const getStudentsExperience = createAsyncThunk(
    'candidate/getStudentsExperience',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/experience/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
export const getStudentsLanguages = createAsyncThunk(
    'candidate/getStudentsLanguages',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/language/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getStudentsSocialActivities = createAsyncThunk(
    'candidate/getStudentsSocialActivities',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/public_activity/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getStudentsEducation = createAsyncThunk(
    'candidate/getStudentsEducation',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/education/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getStudentsResume = createAsyncThunk(
    'candidate/getStudentsResume',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/resume/`;
            const data = await axios.get(url);
            return data.data;
        } catch {
            try {
                const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/resume_always/`;
                const data = await axios.get(url);
                return data.data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    },
);

export const studentsRetrieveSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        clearCandidate(state) {
            state.candidate = null;
            state.educationData = null;
            state.languages = null;
            state.socialActivities = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStudentsResume.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStudentsResume.fulfilled, (state, action) => {
            state.isLoading = false;
            state.candidate = action.payload;
        });
        builder.addCase(getStudentsResume.rejected, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(
            getStudentsSocialActivities.fulfilled,
            (state, action) => {
                state.socialActivities = action.payload;
            },
        );

        builder.addCase(getStudentsEducation.fulfilled, (state, action) => {
            state.educationData = action.payload.objects;
        });
        builder.addCase(getStudentsLanguages.fulfilled, (state, action) => {
            state.languages = action.payload;
        });
        builder.addCase(getStudentsExperience.fulfilled, (state, action) => {
            state.experiances = action.payload;
        });
    },
});

export default studentsRetrieveSlice.reducer;
