import cn from 'classnames';
import styles from './EventsItemPage.module.scss';

import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import { LeftArrowIcon, LikeIcon, RightArrowIcon } from '../../../assets/icons';
import { Location } from '../../../components/Location/Location';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { Tag } from '../../../components/Tag/Tag';
import { dateConverter } from '../../../helpers/dateConverter';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { useChangeLike } from '../../../hooks/useChangeLike';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { PATH_DASHBOARD } from '../../../routes/RouterConfig';
import { IEventCard, Role } from '../../../types/types';

import axios from 'axios';
import {
    FileDownloader,
    FileType,
} from '../../../components/FileDownloader/FileDownloader';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import {
    getEventMemberList,
    updateEventMemberPage,
} from '../../../store/reducers/EventSlice';
import Pagination from '../../StaffPages/SupportList/Pagination';

export const EventsItemPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const windowWidth = useWindowWidth();
    const pathname = useLocation();
    const { id } = useParams();
    const { isLogin, role } = useAppSelector((state) => state.authReducer);
    const { eventMemberList, page, isLoading } = useAppSelector(
        (state) => state.eventReducer,
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [eventInfo, setEventInfo] = useState<IEventCard | null>(null);
    const eventId = pathname.pathname.split('/')[2];
    const { likeEvent, cancelLikeEvent } = useChangeLike(Number(eventId));

    const eventUrl = `${process.env.REACT_APP_BASE_URL}api/v1/event/${id}/`;

    useEffect(() => {
        axios
            .get(eventUrl)
            .then((response) => {
                setEventInfo(response?.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [eventUrl]);

    useEffect(() => {
        if (id && page) {
            dispatch(getEventMemberList({ id: id, page: page, size: 20 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, page]);

    useEffect(() => {
        dispatch(updateEventMemberPage(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const [isCardLiked, setIsCardLiked] = useState<boolean>(
        !!eventInfo?.in_user_favorite_events,
    );

    // added by back
    // инфа о мероприятии приходит не сразу, но как придет обновим состояние на новое
    useEffect(() => {
        setIsCardLiked(eventInfo?.in_user_favorite_events ? true : false);
    }, [eventInfo?.in_user_favorite_events]);

    return (
        <PageLayout>
            <div className={styles.EventsItem}>
                <PagePagination
                    linkName={'Мероприятия'}
                    event={eventInfo?.title}
                    route={PATH_DASHBOARD.events}
                />
                <div className={styles.EventsItem__wrapper}>
                    <div className={styles.EventsItem__headers}>
                        <h3 className={styles.EventsItem__header}>
                            {eventInfo?.title}
                        </h3>
                        <div className={styles.EventsItem__banners}>
                            <div className={styles.EventsItem__banners_buttons}>
                                <Tag
                                    type={'format'}
                                    content={
                                        eventInfo?.format === 'ON'
                                            ? 'Онлайн'
                                            : 'Офлайн'
                                    }
                                />
                                <Tag
                                    type={'date'}
                                    content={dateConverter(
                                        new Date(String(eventInfo?.datetime)),
                                    )}
                                />
                            </div>
                            <Location locationName={eventInfo?.address} />
                            <div className={styles.EventsItem__tools}>
                                <LikeIcon
                                    onClick={() => {
                                        if (!isLogin) {
                                            return navigate({
                                                search: '?login=true',
                                            });
                                        }
                                        isCardLiked
                                            ? cancelLikeEvent()
                                            : likeEvent();
                                        setIsCardLiked(!isCardLiked);
                                    }}
                                    className={
                                        isCardLiked
                                            ? styles.EventsItem__like_active
                                            : styles.EventsItem__like
                                    }
                                />
                                {/* <LinkIcon /> */}
                            </div>
                            <Button
                                className={cn(styles.EventsItem__engage, {
                                    [styles.EventsItem__engage_disabled]:
                                        eventInfo?.is_user_member,
                                })}
                            >
                                {eventInfo?.is_user_member ? (
                                    'Участвую'
                                ) : (
                                    <Link to={{ search: '?event_reg=true' }}>
                                        Участвовать
                                    </Link>
                                )}

                                {!eventInfo?.is_user_member && (
                                    <RightArrowIcon />
                                )}
                            </Button>
                        </div>
                        <div className={styles.EventsItem__container}>
                            <img
                                className={styles.EventsItem__image}
                                src={eventInfo?.image ? eventInfo?.image : ''}
                                alt="event"
                            />
                        </div>
                        {windowWidth && windowWidth <= 1200 && (
                            <div className={styles.EventsItem__content}>
                                {eventInfo?.description
                                    .split('\n')
                                    .map((paragraph, idx) => (
                                        <p key={idx}>{paragraph}</p>
                                    ))}
                            </div>
                        )}
                    </div>
                    {windowWidth && windowWidth > 1200 && (
                        <div className={styles.EventsItem__content}>
                            {eventInfo?.description
                                .split('\n')
                                .map((paragraph) => (
                                    <p>{paragraph}</p>
                                ))}
                        </div>
                    )}
                    {eventInfo?.is_pdf ? (
                        <FileDownloader
                            downloadName={eventInfo.title}
                            name={'Скачать PDF'}
                            className={styles.card__button}
                            url={`${process.env.REACT_APP_BASE_URL}api/v1/event/${id}/pdf/`}
                        ></FileDownloader>
                    ) : null}
                    {role &&
                    role === Role.staff &&
                    eventMemberList &&
                    eventMemberList.objects.length ? (
                        <div className={styles.member__wrapper}>
                            <div className={styles.title__wrapper}>
                                <h3 className={styles.member__title}>
                                    Регистрация на мероприятия
                                </h3>
                                <FileDownloader
                                    downloadName={`${eventInfo?.title}`}
                                    fileType={FileType.EXCEL}
                                    name={'Скачать XLSX'}
                                    className={styles.card__button}
                                    url={`${process.env.REACT_APP_BASE_URL}api/v1/event/${id}/excel/`}
                                ></FileDownloader>
                            </div>

                            <div className={styles.member__table}>
                                <div className={styles.member__top}>
                                    <span>ФИО</span>
                                    <span>Организация/ВУЗ</span>
                                    <span>Почта</span>
                                    <span>Дата регистрации</span>
                                </div>
                                {eventMemberList.objects.map((item) => (
                                    <div
                                        key={`event_${item.id}`}
                                        className={styles.member__item}
                                    >
                                        <span>{item.fio}</span>
                                        <span>
                                            {item.organization_institution}
                                        </span>
                                        <span>{item.email}</span>
                                        <span>
                                            {new Date(
                                                item.registrated_date,
                                            ).toLocaleDateString('ru-RU', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <Pagination
                                list={eventMemberList}
                                handlePagination={(p) => {
                                    dispatch(updateEventMemberPage(p));
                                }}
                                isLoading={isLoading}
                            />
                        </div>
                    ) : null}
                </div>
                <div className={styles.EventsItem__buttons}>
                    {eventInfo?.previous_fresh_event ? (
                        <Button
                            onClick={() => {
                                navigate(
                                    `/events/${eventInfo?.previous_fresh_event}`,
                                );
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }}
                            className={styles.NewsItem__button}
                        >
                            {windowWidth && windowWidth > 650 ? (
                                <div
                                    className={
                                        styles.EventsItem__button_wrapper
                                    }
                                >
                                    <p>К предыдущему мероприятию</p>
                                </div>
                            ) : (
                                <LeftArrowIcon
                                    className={styles.EventsItem__arrow}
                                />
                            )}
                        </Button>
                    ) : null}
                    {eventInfo?.next_fresh_event ? (
                        <Button
                            onClick={() => {
                                navigate(
                                    `/events/${eventInfo?.next_fresh_event}`,
                                );
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }}
                            className={styles.EventsItem__button}
                        >
                            {windowWidth && windowWidth > 650 ? (
                                <div
                                    className={
                                        styles.EventsItem__button_wrapper
                                    }
                                >
                                    <p>К следующему мероприятию</p>
                                </div>
                            ) : (
                                <RightArrowIcon
                                    className={styles.EventsItem__arrow}
                                />
                            )}
                        </Button>
                    ) : null}
                </div>
            </div>
        </PageLayout>
    );
};
