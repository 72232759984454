import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SocialActivities } from '../../types/types';
import axios from 'axios';

const initialState: InitialState = {
    isLoading: false,
    error: null,
    socialActivities: null,
};

interface InitialState {
    isLoading: boolean;
    error: any;
    socialActivities: SocialActivities[] | null;
}

export const fetchSocialActivities = createAsyncThunk(
    'socialActivities/fetchSocialActivities',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/public_activity/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const socialActivitiesSlice = createSlice({
    name: 'socialActivities',
    initialState,
    reducers: {
        clearSocialActivities(state) {
            state.socialActivities = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSocialActivities.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchSocialActivities.fulfilled, (state, action) => {
            state.isLoading = false;
            state.socialActivities = action.payload;
        });
        builder.addCase(fetchSocialActivities.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default socialActivitiesSlice.reducer;
