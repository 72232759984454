import styles from './ShowList.module.scss';

import { Dispatch, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    LogoCompanyListItemType,
    getLogoCompanyList,
} from '../../../store/reducers/PartnerSlice';

import axios from 'axios';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../UI/Button/Button';
import { Loader } from '../../../components/Loader/Loader';
import { Modal } from '../../../components/Modal/Modal';

type ShowListItemProps = {
    item: LogoCompanyListItemType;
    setPartner: Dispatch<{ id: number; status: 'add' | 'delete' }>;
};
const ShowListItem: React.FC<ShowListItemProps> = ({ item, setPartner }) => {
    return (
        <div className={styles.partnerListItem}>
            <div className={styles.content}>
                <div className={styles.logoWrapper}>
                    {item.image ? (
                        <img
                            className={styles.logo}
                            src={item.image}
                            alt={item.title}
                        />
                    ) : null}
                    <h3 className={styles.title}>{item.title}</h3>
                </div>
            </div>
            <div className={styles.status}>
                <div className={styles.statusToggler}>
                    <button
                        className={classNames(styles.onButton)}
                        onClick={() =>
                            setPartner({ id: item.id, status: 'delete' })
                        }
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    );
};

export const ShowList: React.FC = () => {
    const { logoCompanyList, logoCompanyListIsLoading } = useAppSelector(
        (state) => state.partnerReducer,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getLogoCompanyList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const [partner, setPartner] = useState<
        { id: number; status: 'add' | 'delete' } | undefined
    >(undefined);

    const [isLoading, setIsLoading] = useState(false);

    return logoCompanyList ? (
        <div className={styles.container}>
            {partner ? (
                <Modal>
                    <div className={styles.partnerModal}>
                        <h3> Вы уверены, что хотите удалить партнера?</h3>

                        <div className={styles.buttonWrapper}>
                            <button
                                onClick={async () => {
                                    setIsLoading(true);
                                    await axios
                                        .delete(
                                            `${process.env.REACT_APP_BASE_URL}api/v1/logo/${partner.id}/`,
                                        )
                                        .then(() => {
                                            toast.success('Партнер удален!');
                                            setPartner(undefined);
                                            setIsLoading(false);
                                            dispatch(getLogoCompanyList());
                                        })
                                        .catch(() => {
                                            toast.error(
                                                'При удалении партнера произошла ошибка',
                                            );
                                            setIsLoading(false);
                                        });
                                }}
                                disabled={isLoading}
                            >
                                Подтвердить
                            </button>

                            <button onClick={() => setPartner(undefined)}>
                                Отмена
                            </button>
                        </div>
                    </div>
                </Modal>
            ) : null}
            <div className={styles.content}>
                <div className={styles.buttons}>
                    <Button onClick={() => navigate('/staff/show-add')}>
                        Добавить партнера
                    </Button>
                </div>
                {logoCompanyListIsLoading ? <Loader /> : null}

                {!logoCompanyListIsLoading && logoCompanyList.length === 0 ? (
                    <span className={styles.notificationText}>
                        Партнеров пока нет!
                    </span>
                ) : null}

                <div className={styles.contentGrid}>
                    {!logoCompanyListIsLoading &&
                        logoCompanyList?.map((item) => (
                            <ShowListItem
                                key={`partner_item_${item.id}`}
                                item={item}
                                setPartner={setPartner}
                            />
                        ))}
                </div>
            </div>
        </div>
    ) : null;
};
