import { useRef, useState } from 'react';
import { DropdownOption } from '../../types/types';
import styles from './MultiDropdown.module.scss';
import cn from 'classnames';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { CrossButton } from '../../assets/icons';

interface MultiDropdownProps {
    options: DropdownOption[];
    className?: string;

    changeCurrentValues: (value: string) => void; // функция озволяющая связать дропдаун и стор
    currentValues: string[]; // текущие выбранные значения
}

export const MultiDropdown = ({
    options,
    className,
    changeCurrentValues,
    currentValues,
}: MultiDropdownProps) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const toggleActivity = () => {
        setIsActive(!isActive);
    };
    const handleClickOutside = () => {
        setIsActive(false);
    };

    const ref = useRef(null);
    useClickOutside(ref, handleClickOutside);

    return (
        <div
            ref={ref}
            className={cn(styles.dropdown, className)}
            onClick={toggleActivity}
        >
            <div
                className={cn(styles.dropdown__btn, {
                    [styles.dropdown__btn_active]: isActive,
                })}
            >
                {!currentValues?.length
                    ? 'Выберите вариант'
                    : currentValues.map((element) => (
                          <Point
                              
                              value={element}
                              removePoint={changeCurrentValues}
                          />
                      ))}
            </div>
            <FontAwesomeIcon
                className={cn(styles.dropdown__icon, {
                    [styles.dropdown__icon_active]: isActive,
                })}
                icon={faCaretDown}
            />
            {isActive && (
                <ul className={styles.dropdown__content}>
                    {options &&
                        options.map((option: DropdownOption, index: number) => (
                            <li
                                key={index}
                                onClick={() => {
                                    changeCurrentValues(option.value);
                                }}
                                className={cn(styles.dropdown__item)}
                            >
                                {option.label}
                            </li>
                        ))}
                </ul>
            )}
        </div>
    );
};

interface PointProps {
    value: string;

    removePoint: (value: string) => void;
}

const Point = ({ value, removePoint }: PointProps) => {
    return (
        <div className={styles.point}>
            <span className={styles.point__value}>{value}</span>
            <CrossButton
                className={styles.point__button}
                onClick={(event) => {
                    event.stopPropagation();
                    removePoint(value);
                }}
            />
        </div>
    );
};
