import { EventsSection } from './MainPageSections/EventsSection/EventsSection';
import { IntroSection } from './MainPageSections/IntroSection/IntroSection';
import { NewsSection } from './MainPageSections/NewsSection/NewsSection';
import { PartnersSection } from './MainPageSections/PartnersSection/PartnersSection';
import { VacanciesSection } from './MainPageSections/VacanciesSection/VacanciesSection';

export const MainPage = () => {
    return (
        <>
            <IntroSection />
            <NewsSection />
            <EventsSection />
            <VacanciesSection />
            <PartnersSection />
        </>
    );
};
