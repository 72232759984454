import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { LikeIcon } from '../../../../assets/icons';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import {
    VacancyListItemType,
    deleteCandidateVacancyFavorite,
    updateCandidateVacancyFavorite,
} from '../../../../store/reducers/CandidateVacancySlice';
import styles from './index.module.scss';

type StudentVacancyListItemProps = {
    vacancy: VacancyListItemType;
    refetch: () => void;
    setModal?: () => void;
};
export const StudentVacancyListItem: React.FC<StudentVacancyListItemProps> = ({
    vacancy,
    refetch,
    setModal = () => undefined,
}) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { candidate } = useAppSelector((state) => state.candidateReducer);

    return (
        <div className={styles.companyVacancyListItem}>
            <div className={styles.title}>
                <Link
                    to={`/candidate/vacancies/${vacancy.id}`}
                    className={styles.position}
                >
                    {vacancy.position}
                </Link>
                <span className={styles.updateDate}>
                    {new Date(vacancy.date_update).toLocaleDateString('ru-RU', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}
                </span>
            </div>

            <h3 className={styles.sallary}>
                {vacancy.salary_from} - {vacancy.salary_to} руб.
            </h3>
            <span className={styles.company}>{vacancy.company_title}</span>
            <span className={styles.description}>
                {vacancy.description.length >= 200
                    ? vacancy.description.slice(0, 200) + '...'
                    : vacancy.description}
            </span>
            {vacancy.status === 'OP' && (
                <span className={styles.reaction}>
                    Откликнулось {vacancy.reaction_count} человек
                </span>
            )}
            <div className={styles.buttons}>
                <button
                    onClick={() =>
                        navigate(`/candidate/vacancies/${vacancy.id}`)
                    }
                    className={styles.vacancyAddButton}
                >
                    Подробнее
                </button>
                <button
                    onClick={async () => {
                        setModal();
                    }}
                    disabled={
                        vacancy.is_user_reaction ||
                        !candidate?.is_resume_fill ||
                        !candidate.is_open
                    }
                    className={styles.vacancyAddButtonAccept}
                >
                    {vacancy.is_user_reaction
                        ? 'Вы откликнулись'
                        : 'Откликнуться'}
                </button>
                <button
                    onClick={async () => {
                        if (vacancy.in_user_favorite) {
                            await dispatch(
                                deleteCandidateVacancyFavorite({
                                    id: candidate?.id,
                                    vacancy_id: vacancy.id,
                                }),
                            );
                            refetch();
                        } else {
                            await dispatch(
                                updateCandidateVacancyFavorite({
                                    id: candidate?.id,
                                    vacancy_id: vacancy.id,
                                }),
                            );
                            refetch();
                        }
                    }}
                    className={classNames(
                        styles.vacancyButtonLike,
                        vacancy.in_user_favorite &&
                            styles.vacancyButtonActiveLike,
                    )}
                >
                    <LikeIcon />
                </button>
            </div>
            {(!candidate?.is_resume_fill || !candidate?.is_open) &&
            !vacancy.is_user_reaction ? (
                <span className={styles.vacancyText}>
                    Для отклика на вакансию заполните и опубликуйте ваше резюме!
                </span>
            ) : null}
        </div>
    );
};
