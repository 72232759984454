import axios, { AxiosError } from 'axios';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Button, { ButtonTypes } from '../../../UI/Button/Button';
import { CustomSelect } from '../../../UI/CustomSelect/CustomSelect';
import { Input } from '../../../UI/Input/Input';
import { Textarea } from '../../../UI/Textarea/Textarea';
import { CloseIcon } from '../../../assets/icons';
import { Loader } from '../../../components/Loader/Loader';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { PATH_DASHBOARD } from '../../../routes/RouterConfig';
import { getCompanyVacancyRetrieve } from '../../../store/reducers/CompanyVacancySlice';
import styles from './VacancyEdit.module.scss';
import { validationSchema } from './validationSchema';

type InitialValuesType = {
    position: string;
    required_experience: string;
    employment_form: { value: string; label: string }[];
    salaryFrom: string;
    salaryTo: string;
    description: string;
    skill: string;
};
export const CompanyVacancyEdit = () => {
    const { id } = useParams();
    const [skills, setSkilss] = useState<{ id: string; name: string }[]>([]);

    const navigate = useNavigate();

    const { companyVacancyRetrieve, companyVacancyRetrieveIsLoading } =
        useAppSelector((state) => state.companyVacancyReducer);
    const dispatch = useAppDispatch();

    const initialValues: InitialValuesType = {
        position: '',
        required_experience: '',
        employment_form: [],
        salaryFrom: '',
        salaryTo: '',
        description: '',
        skill: '',
    };

    useEffect(() => {
        if (id) {
            dispatch(getCompanyVacancyRetrieve(Number(id)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        initialValues,
        onSubmit: async () => {},
        validationSchema: validationSchema,
    });

    const employment_form: { value: string; label: string }[] = [
        { value: 'FE', label: 'Полная занятость' },
        { value: 'PE', label: 'Частичная занятость' },
        { value: 'RW', label: 'Удаленная работа' },
        { value: 'IS', label: 'Стажировка' },
        { value: 'SH', label: 'Вахта' },
    ];

    const [mount, setMount] = useState(false);
    useEffect(() => {
        formik.setFieldValue('position', companyVacancyRetrieve?.position);
        formik.setFieldValue(
            'required_experience',
            companyVacancyRetrieve?.required_experience,
        );
        formik.setFieldValue(
            'employment_form',
            companyVacancyRetrieve?.employment_form.map((item) => ({
                value: item,
                label: employment_form.find((it) => it.value === item)?.label,
            })),
        );
        formik.setFieldValue('salaryFrom', companyVacancyRetrieve?.salary_from);
        formik.setFieldValue('salaryTo', companyVacancyRetrieve?.salary_to);
        formik.setFieldValue(
            'description',
            companyVacancyRetrieve?.description,
        );
        if (companyVacancyRetrieve?.skills) {
            const sk = companyVacancyRetrieve?.skills
                .split(',')
                .map((item) => ({
                    id: uuidv4(),
                    name: item,
                }));
            setSkilss(sk);
        }
        if (companyVacancyRetrieve) {
            setMount(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyVacancyRetrieve]);

    useEffect(() => {
        formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mount]);

    const onSubmit = async (status: 'OP' | 'CL') => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`;
            const vacancyData = {
                status: status,
                position: formik.values.position,
                salary_from: formik.values.salaryFrom,
                salary_to: formik.values.salaryTo,
                required_experience: formik.values.required_experience,
                description: formik.values.description,
                skills: skills.length
                    ? skills.map((item) => item.name).join(', ')
                    : 'Нет навыков',
                employment_form: formik.values.employment_form.map(
                    (item) => item.value,
                ),
                company: id,
            };
            await axios.put(url, vacancyData);
            toast.success('Данные успешно изменены');
            navigate(PATH_DASHBOARD.company.vacancies.root);
        } catch (e: unknown) {
            if (e instanceof AxiosError && e.response?.data.subscription) {
                toast.error(
                    <a href="mailto:career.law.msu@gmail.com">
                        Оплатите подписку для публикации новых вакансий:
                        career.law.msu@gmail.com»
                    </a>,
                );
            } else {
                toast.error('Произошла ошибка');
            }
        }
    };
    const deleteVacancy = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`;
            await axios.delete(url);
            toast.success('Данные успешно удалены');
            navigate(PATH_DASHBOARD.company.vacancies.root);
        } catch (e) {
            toast.error('Произошла ошибка');
        }
    };
    const required_experience: { value: string; label: string }[] = [
        { value: 'NO', label: 'Нет опыта' },
        { value: 'L1', label: 'До 1 года' },
        { value: '12', label: 'От 1 до 3 лет' },
        { value: 'M3', label: 'Более 3 лет' },
    ];

    if (companyVacancyRetrieveIsLoading) {
        return (
            <div className={styles.companyVacancy}>
                <Loader />
            </div>
        );
    }
    return (
        <form className={styles.companyVacancy}>
            <div className={styles.companyVacancyTitle}>
                <div>
                    <h1>Вакансия</h1>
                    <span>
                        {new Date().toLocaleDateString('ru-Ru', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}
                    </span>
                </div>

                <div>
                    <Button
                        onClick={() => onSubmit('OP')}
                        isDisabled={
                            !formik.isValid ||
                            !formik.dirty ||
                            skills.length === 0
                        }
                        className={classNames(
                            styles.companyVacancyButton_save,
                            formik.isValid &&
                                formik.dirty &&
                                skills.length !== 0 &&
                                styles.companyVacancyButton_valid,
                        )}
                    >
                        Опубликовать
                    </Button>
                    <Button
                        onClick={() => onSubmit('CL')}
                        isDisabled={
                            !formik.isValid ||
                            !formik.dirty ||
                            skills.length === 0
                        }
                        className={classNames(
                            styles.companyVacancyButton_save,
                            formik.isValid &&
                                formik.dirty &&
                                skills.length !== 0 &&
                                styles.companyVacancyButton_valid,
                        )}
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={() => deleteVacancy()}
                        className={classNames(
                            styles.companyVacancyButton_save,
                            styles.companyVacancyButton_delete,
                        )}
                    >
                        Удалить
                    </Button>
                </div>
            </div>
            <div className={styles.companyVacancyGrid}>
                <div>
                    <span>Должность</span>
                    <Input
                        wrapperClassName={[styles.EditAbout__input]}
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        placeholder={'Должность'}
                        name="position"
                        value={formik.values.position || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.errors.position}
                        isValid={
                            Boolean(formik.values.position) &&
                            !formik.errors.position
                        }
                    />
                </div>
                <div>
                    <span>Требуемый опыт</span>
                    <CustomSelect
                        onChange={(e: { value: string; label: string }) =>
                            formik.setFieldValue('required_experience', e.value)
                        }
                        value={required_experience.find(
                            (item) =>
                                item.value ===
                                formik.values.required_experience,
                        )}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        placeholder={'Выберите опыт'}
                        options={required_experience}
                        isValid={
                            Boolean(formik.values.required_experience) &&
                            !formik.errors.required_experience
                        }
                    />
                </div>
                <div className={styles.companyVacancyGridSalary}>
                    <span>Заработная плата</span>
                    <Input
                        wrapperClassName={[styles.EditAbout__input]}
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        placeholder={'От 30000'}
                        name="salaryFrom"
                        value={formik.values.salaryFrom || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.errors.salaryFrom}
                        isValid={
                            Boolean(formik.values.salaryFrom) &&
                            !formik.errors.salaryFrom
                        }
                    />
                    <Input
                        wrapperClassName={[styles.EditAbout__input]}
                        headerClassName={styles.input__header}
                        fieldClassName={styles.input__field}
                        placeholder={'До 50000'}
                        name="salaryTo"
                        value={formik.values.salaryTo || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.errors.salaryTo}
                        isValid={
                            Boolean(formik.values.salaryTo) &&
                            !formik.errors.salaryTo
                        }
                    />
                </div>
                <div>
                    <span>Занятость</span>
                    <CustomSelect
                        onChange={(e: { value: string; label: string }[]) => {
                            formik.setFieldValue('employment_form', e);
                        }}
                        value={formik.values.employment_form}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        placeholder={'Выберите занятость'}
                        options={employment_form}
                        isValid={
                            Boolean(formik.values.employment_form) &&
                            !formik.errors.employment_form
                        }
                        isMulti
                    />
                </div>
                <div className={styles.companyVacancyGridModifiedField}>
                    <span>Ключевые навыки</span>
                    <div>
                        <Input
                            wrapperClassName={[styles.EditAbout__input]}
                            headerClassName={styles.input__header}
                            fieldClassName={styles.input__field}
                            placeholder={'Добавьте навык'}
                            name="skill"
                            value={formik.values.skill || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isValid={!!skills.length}
                        />
                        <Button
                            type={ButtonTypes.AddButton}
                            isDisabled={formik.values.skill === ''}
                            onClick={() => {
                                if (formik.values.skill !== '') {
                                    setSkilss([
                                        ...skills,
                                        {
                                            id: uuidv4(),
                                            name: formik.values.skill,
                                        },
                                    ]);
                                }
                                formik.setFieldValue('skill', '');
                            }}
                        >
                            Добавить
                        </Button>
                        <div className={styles.companyVacancyExperience}>
                            {skills.map((item) => (
                                <div key={`${item.id}`}>
                                    <span>{item.name}</span>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setSkilss([
                                                ...skills.filter(
                                                    (it) => it.id !== item.id,
                                                ),
                                            ])
                                        }
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.companyVacancyGridModifiedField}>
                    <span>Описание вакансии</span>
                    <Textarea
                        headerClassName={styles.input__header}
                        placeholder={'Описание вакансии'}
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errorMessage={formik.errors.description}
                        isValid={
                            Boolean(formik.values.description) &&
                            !formik.errors.description
                        }
                    />
                </div>
            </div>
        </form>
    );
};
