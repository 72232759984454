import {
    AsideEventsIcon,
    AsideFeedbackIcon,
    AsideNewsIcon,
    AsidePartnersIcon,
    AsideResumeIcon,
    AsideStudentsIcon,
    AsideSupportIcon,
    AsideVacancyIcon,
    AsideVerificationIcon,
} from '../assets/icons';
import { PATH_DASHBOARD } from '../routes/RouterConfig';
import { AsideElement, Role } from '../types/types';

const candidateAsideElements: Array<AsideElement> = [
    {
        id: 1,
        icon: <AsideResumeIcon />,
        name: 'Резюме',
        link: PATH_DASHBOARD.student.resume.root,
        dropdownElements: [],
    },
    {
        id: 2,
        icon: <AsideVacancyIcon />,
        name: 'Вакансии',
        link: null,
        dropdownElements: [
            {
                title: 'Все вакансии',
                dropdownId: 3,
                src: PATH_DASHBOARD.student.vacancies.root,
            },
            {
                title: 'Избранные ',
                dropdownId: 4,
                src: PATH_DASHBOARD.student.vacancies.favorite,
            },
            {
                title: 'Отклики ',
                dropdownId: 5,
                src: PATH_DASHBOARD.student.vacancies.reaction,
            },
        ],
    },
    {
        id: 6,
        icon: <AsideEventsIcon />,
        name: 'Мероприятия',
        link: null,
        dropdownElements: [
            {
                title: 'Список мероприятий',
                dropdownId: 7,
                src: PATH_DASHBOARD.student.events.root,
            },
            {
                title: 'Избранные',
                dropdownId: 8,
                src: PATH_DASHBOARD.student.events.favourites,
            },
            {
                title: 'Участвую',
                dropdownId: 9,
                src: PATH_DASHBOARD.student.events.participate,
            },
        ],
    },
];

const staffAsideElements: Array<AsideElement> = [
    {
        id: 1,
        icon: <AsideSupportIcon />,
        name: 'Техподдержка',
        link: PATH_DASHBOARD.staff.support.all,
        dropdownElements: undefined,
    },
    // {
    //     id: 2,
    //     icon: <AsideAboutIcon />,
    //     name: 'О нас',
    //     link: null,
    //     dropdownElements: undefined,
    // },
    {
        id: 4,
        icon: <AsideVerificationIcon />,
        name: 'Верификация',
        link: PATH_DASHBOARD.staff.verification.registration,
        dropdownElements: undefined,
    },
    {
        id: 7,
        icon: <AsideStudentsIcon />,
        name: 'Студенты',
        link: PATH_DASHBOARD.staff.studentsList,
        dropdownElements: undefined,
    },

    {
        id: 8,
        icon: <AsidePartnersIcon />,
        name: 'Партнеры',
        link: null,
        dropdownElements: [
            {
                title: 'Отображаемые партнеры',
                dropdownId: 15,
                src: PATH_DASHBOARD.staff.show.root,
            },
            {
                title: 'Все партнеры',
                dropdownId: 16,
                src: PATH_DASHBOARD.staff.partnerList,
            },
        ],
    },
    {
        id: 9,
        icon: <AsideVacancyIcon />,
        name: 'Вакансии',
        link: PATH_DASHBOARD.staff.vacancies.root,
        dropdownElements: undefined,
    },
    {
        id: 10,
        icon: <AsideFeedbackIcon />,
        name: 'Отклики',
        link: PATH_DASHBOARD.staff.reactionList,
        dropdownElements: undefined,
    },
    {
        id: 11,
        icon: <AsideEventsIcon />,
        name: 'Мероприятия',
        link: null,
        dropdownElements: [
            {
                title: 'Все мероприятия',
                dropdownId: 12,
                src: PATH_DASHBOARD.staff.events.root,
            },
            {
                title: 'Добавить мероприятие ',
                dropdownId: 13,
                src: PATH_DASHBOARD.staff.events.addEvent,
            },
        ],
    },
    {
        id: 14,
        icon: <AsideNewsIcon />,
        name: 'Новости',
        link: null,
        dropdownElements: [
            {
                title: 'Все новости',
                dropdownId: 15,
                src: PATH_DASHBOARD.staff.news.root,
            },
            {
                title: 'Добавить новость ',
                dropdownId: 16,
                src: PATH_DASHBOARD.staff.news.addNews,
            },
        ],
    },
];

const organizationAsideElements: Array<AsideElement> = [
    {
        id: 1,
        icon: <AsideVacancyIcon />,
        name: 'Вакансии',
        link: PATH_DASHBOARD.company.vacancies.root,
        dropdownElements: [],
    },
    {
        id: 2,
        icon: <AsideStudentsIcon />,
        name: 'Соискатели',
        link: PATH_DASHBOARD.company.studentsList,
        dropdownElements: [],
    },
    {
        id: 3,
        icon: <AsideEventsIcon />,
        name: 'Мероприятия',
        link: null,
        dropdownElements: [
            {
                title: 'Список мероприятий',
                dropdownId: 7,
                src: PATH_DASHBOARD.company.events.root,
            },
            {
                title: 'Избранные',
                dropdownId: 8,
                src: PATH_DASHBOARD.company.events.favourites,
            },
            {
                title: 'Участвую',
                dropdownId: 9,
                src: PATH_DASHBOARD.company.events.participate,
            },
        ],
    },
    {
        id: 4,
        icon: <AsideFeedbackIcon />,
        name: 'Отклики',
        link: PATH_DASHBOARD.company.reaction,
        dropdownElements: [],
    },
];

const AsideElementsData = {
    candidate: candidateAsideElements,
    staff: staffAsideElements,
    organization: organizationAsideElements,
};

export const getAsideElements = (role: Role) => {
    if (role === Role.candidate) {
        return AsideElementsData.candidate;
    }
    if (role === Role.organization) {
        return AsideElementsData.organization;
    }
    if (role === Role.staff) {
        return AsideElementsData.staff;
    }
};
