import styles from './PartnerList.module.scss';

import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { updateCompanyListPage } from '../../../store/reducers/PartnerSlice';

import axios from 'axios';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { DatePicker } from '../../../UI/DatePicker/DatePicker';
import { Input } from '../../../UI/Input/Input';
import { Loader } from '../../../components/Loader/Loader';
import { Modal } from '../../../components/Modal/Modal';
import useDebounceCallBack from '../../../hooks/useDebounce';
import {
    CompanyListItemType,
    getCompanyList,
} from '../../../store/reducers/PartnerSlice';
import Pagination from '../SupportList/Pagination';
import { validationSchema } from './validationSchema';

type PartnerListItemProps = {
    item: CompanyListItemType;
    setPartner: Dispatch<{ id: number; status: 'add' | 'delete' }>;
};
const PartnerListItem: React.FC<PartnerListItemProps> = ({
    item,
    setPartner,
}) => {
    return (
        <div className={styles.partnerListItem}>
            <div className={styles.content}>
                <div className={styles.logoWrapper}>
                    {item.photo ? (
                        <img
                            className={styles.logo}
                            src={item.photo}
                            alt={item.full_title}
                        />
                    ) : null}
                    <h3 className={styles.title}>{item.full_title}</h3>
                </div>
                <div className={styles.contact}>
                    {item.second_name || item.first_name ? (
                        <>
                            <span style={{ opacity: '60%' }}>
                                Контактное лицо
                            </span>
                            <span>{`${[item.second_name, item.first_name].join(
                                ' ',
                            )}`}</span>
                        </>
                    ) : null}
                    {item.contact_email ? (
                        <>
                            <span style={{ opacity: '60%' }}>Email</span>
                            <span>{item.contact_email}</span>
                        </>
                    ) : null}
                    {item.contact_phone ? (
                        <>
                            <span style={{ opacity: '60%' }}>Телефон</span>
                            <span>{item.contact_phone}</span>
                        </>
                    ) : null}
                </div>
            </div>
            <div className={styles.status}>
                <div className={styles.subscription}>
                    <span>Статус платных услуг:</span>
                    {item.subscription_from && item.subscription_to ? (
                        <div>
                            <span>
                                от:{' '}
                                {new Date(
                                    item.subscription_from,
                                ).toLocaleString('ru-RU', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}
                            </span>
                            <span>
                                до:{' '}
                                {new Date(item.subscription_to).toLocaleString(
                                    'ru-RU',
                                    {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    },
                                )}
                            </span>
                        </div>
                    ) : null}
                </div>
                <div className={styles.statusToggler}>
                    <button
                        className={classNames(
                            styles.onButton,
                            item.has_subscription && styles.onButtonActive,
                        )}
                        disabled={item.has_subscription}
                        onClick={() =>
                            setPartner({ id: item.id, status: 'add' })
                        }
                    >
                        Вкл
                    </button>
                    <button
                        className={classNames(
                            styles.offButton,
                            !item.has_subscription && styles.offButtonActive,
                        )}
                        disabled={
                            !item.subscription_from && !item.subscription_to
                        }
                        onClick={() =>
                            setPartner({ id: item.id, status: 'delete' })
                        }
                    >
                        Выкл
                    </button>
                </div>
            </div>
        </div>
    );
};

export const PartnerList: React.FC = () => {
    const [search, setSearch] = useState('');

    const { companyList, companyListIsLoading, companyListPage } =
        useAppSelector((state) => state.partnerReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCompanyList({ page: companyListPage, size: 10, search }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyListPage]);

    useEffect(() => {
        dispatch(getCompanyList({ page: 1, size: 10, search }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const [partner, setPartner] = useState<
        { id: number; status: 'add' | 'delete' } | undefined
    >(undefined);

    const debouncedSearch = useDebounceCallBack(setSearch, 1000);

    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {
            subscription_from: '',
            subscription_to: '',
        },
        onSubmit: async (values) => {
            setIsLoading(true);
            const formData: {
                subscription_from: string | null;
                subscription_to: string | null;
            } = {
                subscription_from: null,
                subscription_to: null,
            };

            if (partner?.status === 'add') {
                formData.subscription_from = formik.values.subscription_from;
                formData.subscription_to = formik.values.subscription_to;
            }

            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}api/v1/subscribe/${partner?.id}/`,
                    formData,
                )
                .then(() => {
                    if (partner?.status === 'add') {
                        toast.success('Подписка успешно активирована!');
                    } else {
                        toast.success('Подписка успешно отменена!');
                    }
                    formik.resetForm();
                    setIsLoading(false);
                    setPartner(undefined);
                    dispatch(
                        getCompanyList({ page: companyListPage, size: 10 }),
                    );
                })
                .catch(() => {
                    if (partner?.status === 'add') {
                        toast.error('При активации подписки произошла ошибка!');
                    } else {
                        toast.error('При отмене подписки произошла ошибка!');
                    }
                    setIsLoading(false);
                });
        },
        validationSchema: partner?.status === 'add' ? validationSchema : null,
    });

    const isValid = useMemo(() => {
        if (
            !formik.values.subscription_from ||
            !formik.values.subscription_to
        ) {
            return '';
        }
        if (
            new Date(formik.values.subscription_from).getTime() >
            new Date(formik.values.subscription_to).getTime()
        ) {
            return 'Дата начала подписки не должна быть позже даты окончания подписки';
        }
        if (
            new Date(formik.values.subscription_from).getTime() <=
            new Date(new Date().setHours(0, 0, 0, 0)).getTime()
        ) {
            return 'Дата начала подписки не должна быть раньше текущего дня';
        }
        return '';
    }, [formik.values.subscription_from, formik.values.subscription_to]);

    useEffect(() => {
        formik.setFieldValue('subscription_from', '');
        formik.setFieldValue('subscription_to', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partner]);

    return companyList ? (
        <div className={styles.container}>
            {partner ? (
                <Modal>
                    <form
                        className={styles.partnerModal}
                        onSubmit={formik.handleSubmit}
                    >
                        <h3>
                            {partner.status === 'add'
                                ? 'Активация подписки'
                                : 'Вы уверены, что требуется отключить подписку?'}
                        </h3>
                        {partner.status === 'add' ? (
                            <div className={styles.period}>
                                <DatePicker
                                    placeholder="02.03.2023"
                                    headerText={'Дата начала подписки'}
                                    headerClassName={styles.input__title}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.subscription_from}
                                    name={'subscription_from'}
                                    errorMessage={
                                        formik.errors.subscription_from
                                    }
                                    isValid={
                                        formik.dirty &&
                                        !formik.errors.subscription_from &&
                                        !!formik.values.subscription_from
                                    }
                                />
                                <DatePicker
                                    placeholder="02.03.2023"
                                    headerText={'Дата завершения подписки'}
                                    headerClassName={styles.input__title}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.subscription_to}
                                    name={'subscription_to'}
                                    errorMessage={
                                        formik.errors.subscription_to || isValid
                                    }
                                    isValid={
                                        formik.dirty &&
                                        !formik.errors.subscription_to &&
                                        !!formik.values.subscription_to
                                    }
                                />
                            </div>
                        ) : null}
                        <div className={styles.buttonWrapper}>
                            {partner.status === 'add' ? (
                                <button
                                    type="submit"
                                    disabled={
                                        !formik.isValid ||
                                        Boolean(isValid) ||
                                        isLoading
                                    }
                                >
                                    Подтвердить
                                </button>
                            ) : null}
                            {partner.status === 'delete' ? (
                                <button type="submit" disabled={isLoading}>
                                    Подтвердить
                                </button>
                            ) : null}
                            <button onClick={() => setPartner(undefined)}>
                                Отмена
                            </button>
                        </div>
                    </form>
                </Modal>
            ) : null}
            <div className={styles.content}>
                <Input
                    placeholder={'Поиск партнера'}
                    onChange={(e) => debouncedSearch(e.target.value)}
                    // value={search}
                />

                {companyListIsLoading ? <Loader /> : null}

                {!companyListIsLoading && companyList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        Партнеров пока нет!
                    </span>
                ) : null}

                <div className={styles.contentGrid}>
                    {!companyListIsLoading &&
                        companyList?.objects.map((item) => (
                            <PartnerListItem
                                key={`partner_item_${item.id}`}
                                item={item}
                                setPartner={setPartner}
                            />
                        ))}
                </div>

                <Pagination
                    list={companyList}
                    handlePagination={(p) => {
                        dispatch(updateCompanyListPage(p));
                    }}
                    isLoading={companyListIsLoading}
                />
            </div>
        </div>
    ) : null;
};
