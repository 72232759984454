import cn from 'classnames';
import styles from './PasswordInput.module.scss';
import { useState } from 'react';
import { HidePasswordIcon, VisiblePasswordIcon } from '../../assets/icons';

export const PasswordInput = ({
    wrapperClassName,
    placeholder,
    headerText,
    fieldClassName,
    headerClassName,
    errorMessage,
    onChange,
    onBlur,
    value,
    name,
    helpMessage,
    isValid,
}: PasswordInputProps) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const visibleToggler = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div
            className={cn(
                styles.PasswordInput,
                wrapperClassName ? [...wrapperClassName] : '',
            )}
        >
            {headerText && (
                <h3
                    className={cn(
                        styles.PasswordInput__header,
                        headerClassName,
                    )}
                >
                    {headerText}
                </h3>
            )}
            <div className={styles.PasswordInput__wrapper}>
                <input
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    placeholder={placeholder ? placeholder : 'Пароль'}
                    className={cn(styles.PasswordInput__field, fieldClassName, {
                        [styles.PasswordInput__field_valid]: isValid,
                    })}
                    type={isPasswordVisible ? 'text' : 'password'}
                ></input>
                <div
                    className={styles.PasswordInput__icon}
                    onClick={visibleToggler}
                >
                    {isPasswordVisible ? (
                        <VisiblePasswordIcon />
                    ) : (
                        <HidePasswordIcon />
                    )}
                </div>
            </div>
            <div className={styles.PasswordInput__error}>
                {errorMessage ? errorMessage : ''}
            </div>
        </div>
    );
};

interface PasswordInputProps {
    wrapperClassName?: string[];
    placeholder?: string;
    headerText?: string;
    fieldClassName?: string;
    headerClassName?: string;
    errorMessage?: string | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: any;
    name?: string;
    helpMessage?: string;
    isValid?: boolean;
}
