import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import { RightArrowIcon } from '../../../assets/icons';
import cardSrc from '../../../assets/images/newsCard.png';
import { DeleteModal } from '../../../components/DeleteModal/DeleteModal';
import { Loader } from '../../../components/Loader/Loader';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import { INewsCard } from '../../../types/types';
import Pagination from '../../StaffPages/SupportList/Pagination';
import styles from './NewsPage.module.scss';

export const NewsPage = () => {
    // стэйт для пагинации
    const [page, setPage] = useState<number>(1); // стэйт - текущая страница в пагинации
    const [news, setNews] = useState<INewsCard[] | null>(null); // стэйт с новостями
    const [paginationArr, setPaginationArr] = useState<number>(1); // максимальное количество страниц
    const newsUrl = `${process.env.REACT_APP_BASE_URL}api/v1/news/?page=${page}&size=10`;
    const [isLoading, setIsLoading] = useState<boolean>(false); //изначально нет загрузки
    useEffect(() => {
        setIsLoading(true);
        axios
            .get(newsUrl)
            .then((response) => {
                const pagArray = response.data.page_count;
                setPaginationArr(pagArray); // массив булетов для отрисовки
                setNews(response.data.objects); // данные по карточкам
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    }, [page]);
    return (
        <PageLayout>
            <div className={styles.newspage}>
                <PagePagination linkName={'Новости'} />
                <PageHeader title={'Новости'} />
                <div className={styles.newspage__container}>
                    {isLoading ? (
                        <div className={styles.newspage__loader}>
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <NewsCardsContainer news={news} />
                        </>
                    )}
                    <Pagination
                        list={{
                            page_count: paginationArr,
                            current_page: page,
                        }}
                        handlePagination={(p) => {
                            setPage(p);
                        }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </PageLayout>
    );
};

const NewsCardsContainer = ({ news }: NewsCardsContainerProps) => {
    return (
        <div className={styles.newspage__cards}>
            {news &&
                news.map((card) => (
                    <Card
                        key={card.id}
                        id={card.id}
                        title={card.title}
                        create_date={card.create_date}
                        publish_date={card.publish_date}
                        text={card.text}
                        images={card.images}
                    />
                ))}
        </div>
    );
};

interface NewsCardsContainerProps {
    news: INewsCard[] | null;
}

export const Card = ({
    id,
    title,
    create_date,
    publish_date,
    text,
    images,
}: INewsCard) => {
    const { role } = useAppSelector((state) => state.authReducer);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const navigate = useNavigate();

    const url = `${process.env.REACT_APP_BASE_URL}api/v1/news/${id}/`;

    return (
        <div className={styles.newscard}>
            <div
                onClick={() => {
                    navigate(`/news/${id}`);
                    window.scroll({
                        top: 0,
                        behavior: 'smooth',
                    });
                }}
                className={styles.newscard__container}
            >
                <img
                    className={styles.newscard__image}
                    src={images[0]?.path ? images[0]?.path : cardSrc}
                    alt="новость"
                />
            </div>
            <div className={styles.newscard__wrapper}>
                <div
                    onClick={() => {
                        navigate(`/news/${id}`);
                        window.scroll({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                    className={styles.newscard__header_src}
                >
                    <h3 className={styles.newscard__header}>{title}</h3>
                </div>
                <p className={styles.newscard__description}>{text}</p>
                <div className={styles.newscard__buttons}>
                    <Button
                        onClick={() => {
                            window.scroll({
                                top: 0,
                                behavior: 'smooth',
                            });
                            navigate(`/news/${id}`);
                        }}
                        className={styles.newscard__moreinfo}
                    >
                        <span className={styles.newscard__moreinfo_text}>
                            Подробнее
                        </span>
                        <div className={styles.newscard__moreinfo_icon}>
                            <RightArrowIcon />
                        </div>
                    </Button>
                    <p className={styles.newscard__date}>
                        Дата публикации{' '}
                        {new Date(publish_date).toLocaleDateString('ru-RU', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}
                    </p>
                </div>
                {role === 'staff' ? (
                    <div className={styles.buttons}>
                        <Button
                            onClick={() => {
                                navigate(`/staff/news_edit/${id}/`);
                            }}
                        >
                            Редактировать
                        </Button>
                        <Button
                            onClick={() => {
                                setShowDeleteModal(true);
                            }}
                        >
                            Удалить
                        </Button>
                    </div>
                ) : null}
            </div>
            {showDeleteModal ? (
                <DeleteModal
                    backLink="/staff/news_list"
                    url={url}
                    title="Вы действительно хотите удалить новость?"
                    successMessage="Новость удалена!"
                    errorMessage="Новость не удалена!"
                    onClose={() => setShowDeleteModal(false)}
                />
            ) : null}
        </div>
    );
};
