export const convertMonthsToYears = (months: number): string => {
    const years = Math.trunc(months / 12);
    const month = months % 12;
    return `${years > 0 ? years + ' ' + getYearSuffix(years) : ''} ${
        month > 0 ? month + ' ' + getMonthSuffix(month) : ''
    }`;
};

export const getMonthSuffix = (month: number): string => {
    let suffix;

    if (month % 10 === 1 && month % 100 !== 11) {
        suffix = 'месяц';
    } else if (
        month % 10 >= 2 &&
        month % 10 <= 4 &&
        !(month % 100 >= 12 && month % 100 <= 14)
    ) {
        suffix = 'месяца';
    } else {
        suffix = 'месяцев';
    }

    return suffix;
};

export const getYearSuffix = (years: number): string => {
    let suffix;

    if (years % 10 === 1 && years % 100 !== 11) {
        suffix = 'год';
    } else if (
        years % 10 >= 2 &&
        years % 10 <= 4 &&
        !(years % 100 >= 12 && years % 100 <= 14)
    ) {
        suffix = 'года';
    } else {
        suffix = 'лет';
    }

    return suffix;
};
