// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiCheckbox_checkboxes__sUm0V {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.MultiCheckbox_checkboxes__container__22XzP {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.MultiCheckbox_checkboxes__header__\\+ZVQC {\n  color: #000;\n  font: var(--font-s);\n  font-size: 14px;\n  font-weight: 600;\n}\n\n.MultiCheckbox_option__ErJIW {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  cursor: pointer;\n}\n.MultiCheckbox_option__text__gADQy {\n  color: #000;\n  font: var(--font-s);\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/UI/MultiCheckbox/MultiCheckbox.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAER;AAAI;EACI,WAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AAAJ;AACI;EACI,WAAA;EAEA,mBAAA;EACA,eAAA;AAAR","sourcesContent":[".checkboxes {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n    &__container {\n        display: flex;\n        flex-direction: column;\n        gap: 5px;\n    }\n    &__header {\n        color: #000;\n\n        font: var(--font-s);\n        font-size: 14px;\n        font-weight: 600;\n    }\n}\n\n.option {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    cursor: pointer;\n    &__text {\n        color: #000;\n\n        font: var(--font-s);\n        font-size: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxes": "MultiCheckbox_checkboxes__sUm0V",
	"checkboxes__container": "MultiCheckbox_checkboxes__container__22XzP",
	"checkboxes__header": "MultiCheckbox_checkboxes__header__+ZVQC",
	"option": "MultiCheckbox_option__ErJIW",
	"option__text": "MultiCheckbox_option__text__gADQy"
};
export default ___CSS_LOADER_EXPORT___;
