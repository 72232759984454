import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { DatePicker } from '../../../../UI/DatePicker/DatePicker';
import { Input } from '../../../../UI/Input/Input';
import { Textarea } from '../../../../UI/Textarea/Textarea';
import { AddEventImage } from '../../../../components/AddEventImage/AddEventImage';
import { AddNewsHeader } from '../../../../components/AddNewsHeader/AddNewsHeader';
import { INewsCard } from '../../../../types/types';
import styles from './EditNews.module.scss';

export const EditNews = () => {
    const pathname = useLocation();
    const [newsInfo, setNewsInfo] = useState<INewsCard | null>(null);
    const newsId = pathname.pathname.split('/')[3];
    const newsUrl = `${process.env.REACT_APP_BASE_URL}api/v1/news/${newsId}/`;

    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);

    const validImage = useMemo(() => {
        if (selectedImages?.length && selectedImages[0].size < 512000) {
            return true;
        }
        if (!selectedImages?.length) {
            return true;
        }
        return false;
    }, [selectedImages]);

    const initialValues = useMemo(() => {
        let datetime = '';
        if (newsInfo?.publish_date) {
            const now = new Date(newsInfo?.publish_date);

            const day = ('0' + now.getDate()).slice(-2);
            const month = ('0' + (now.getMonth() + 1)).slice(-2);

            datetime = now.getFullYear() + '-' + month + '-' + day;
        }
        return {
            title: newsInfo?.title ?? '',
            publish_date: datetime ?? '',
            text: newsInfo?.text ?? '',
        };
    }, [newsInfo]);

    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues,
        onSubmit: async () => {
            setIsLoading(true);
            const dateArr = formik.values.publish_date;

            const formData = new FormData();

            formData.append('title', formik.values.title);
            formData.append('publish_date', dateArr);
            formData.append('text', formik.values.text.replace('↵', '\n'));

            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}api/v1/news/${newsId}/`,
                    formData,
                )
                .then(() => {
                    toast.success('Новость успешно обновлена!');
                    formik.resetForm();
                    setSelectedImages(null);
                    setIsLoading(false);
                    window.history.go(-1);
                })
                .catch(() => {
                    toast.error('При обновлении новости произошла ошибка');
                    setIsLoading(false);
                });

            setIsLoading(false);
        },
        validationSchema: yup.object().shape({
            title: yup
                .string()
                .required('Поле обязательно')
                .max(100, 'Заголовок не должен содержать более 100 символов'),
            publish_date: yup.string().required('Поле обязательно'),
            text: yup
                .string()
                .required('Поле обязательно')
                .max(4096, ' Превышен лимит символов'),
        }),
    });

    useEffect(() => {
        axios
            .get(newsUrl)
            .then((response) => {
                setNewsInfo(response?.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [newsUrl]);
    return (
        <form onSubmit={formik.handleSubmit} className={styles.addnews}>
            <AddNewsHeader
                dirty={formik.dirty}
                isValid={formik.isValid && validImage}
                isLoading={isLoading}
                onSubmit={formik.handleSubmit}
                resetForm={() => {
                    formik.resetForm();
                    window.history.go(-1);
                }}
                title={'Обновить новость'}
            />
            <AddEventImage
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                showImage={newsInfo?.images[0].path ?? null}
                showUpdateButton={false}
            />
            {!validImage ? (
                <span className={styles.validImage}>
                    Максимальный размер изображения 500Кб
                </span>
            ) : null}
            <div className={styles.addnews__content}>
                <Input
                    wrapperClassName={[styles.addnews_first]}
                    placeholder="Новость"
                    fieldClassName={styles.input__field}
                    inputHeader={'Название новости'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name={'title'}
                    errorMessage={formik.errors.title}
                    isValid={
                        formik.touched &&
                        !formik.errors.title &&
                        !!formik.values.title
                    }
                />
                <DatePicker
                    wrapperClassName={[styles.addnews_second]}
                    placeholder="02.03.2023"
                    headerText={'Дата'}
                    headerClassName={styles.input__title}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.publish_date}
                    name={'publish_date'}
                    errorMessage={formik.errors.publish_date}
                    isValid={
                        formik.touched &&
                        !formik.errors.publish_date &&
                        !!formik.values.publish_date
                    }
                />

                <Textarea
                    wrapperClassName={styles.addnews_third}
                    title={'Описание'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.text}
                    name={'text'}
                    errorMessage={formik.errors.text}
                    isValid={
                        formik.touched &&
                        !formik.errors.text &&
                        !!formik.values.text
                    }
                />
            </div>
        </form>
    );
};
