import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role } from '../../types/types';

const initialState: AuthState = {
    loginError: '',
    isLoading: false,
    isLogin: false,
    token: null,
    refresh: null, // added by backend
    role: null,
    id: null,
    isVerified: null,
};

interface AuthState {
    loginError: string;
    isLoading: boolean;
    isLogin: boolean;
    token: string | null;
    refresh: string | null; // added by backend
    role: Role | null;
    id: number | null;
    isVerified: boolean | null;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginStart(state) {
            state.isLoading = true;
        },
        loginSuccess(state, action: PayloadAction<any>) {
            state.isLoading = false;
            state.loginError = '';
            state.token = action.payload.access;
            state.refresh = action.payload.refresh; // added by backend
            state.role = action.payload.user_type;
            state.id = action.payload.user_type_id;
            state.isVerified = action.payload.is_verified;
            state.isLogin = true;
        },
        loginError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.loginError = action.payload;
            state.isLogin = false;
        },
        login(state) {
            state.isLogin = true;
        },
        logOut(state) {
            state.isLogin = false;
            state.token = null;
            state.refresh = null; // added by backend
            state.id = null; // added by backend
            state.role = null;
            state.isVerified = null;
        },
        authCheck(state, action) {
            state.token = action.payload.token;
            state.refresh = action.payload.refresh; // added by backend
            state.role = action.payload.role;
            state.id = action.payload.id;
            state.isLogin = true;
            state.isVerified = action.payload.isVerified;
        },
        refreshToken(state, action) {
            state.token = action.payload.token;
        },
    },
});

export default authSlice.reducer;
