import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CabinetAside } from '../../layouts/CabinetAside/CabinetAside';
import { CabinetHeader } from '../../layouts/CabinetHeader/CabinetHeader';
import { Footer } from '../../layouts/MainFooter/Footer';
import Header from '../../layouts/MainHeader/Header';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { AuthCheck } from '../AuthCheck/AuthCheck';
import { RoleCheck } from '../RoleCheck/RoleCheck';
import styles from './Layout.module.scss';

// хок предназначенный для отрисовки разных лэйаутов в зависимости от роли, чтобы не писать много кода и отрисовывать разные
//лэйауты для главной страницы и для личного кабинета

export const Layout = ({ children }: LayoutProps) => {
    const { pathname } = useLocation();

    const isStudentPage = pathname.includes(PATH_DASHBOARD.student.root);
    const isStaffPage = pathname.includes(PATH_DASHBOARD.staff.root);
    const isOrganizationPage = pathname.includes(PATH_DASHBOARD.company.root);

    const [isAsideOpen, setIsAsideOpen] = useState<boolean>(false);
    const toggleAside = () => {
        setIsAsideOpen(!isAsideOpen);
    };
    return isStudentPage || isStaffPage || isOrganizationPage ? (
        // страницы личного кабинета дополнительно прогоняются через хок, проверяющий регистрацию
        <AuthCheck>
            <RoleCheck>
                <div className={styles.cabinet}>
                    <CabinetAside isAsideOpen={isAsideOpen} />
                    <div className={styles.cabinet__wrapper}>
                        <CabinetHeader toggleAside={toggleAside} />
                        {children}
                    </div>
                </div>
            </RoleCheck>
        </AuthCheck>
    ) : (
        <AuthCheck>
            <div className={styles.main}>
                <Header />
                {children}
                <Footer />
            </div>
        </AuthCheck>
    );
};

interface LayoutProps {
    children: React.ReactNode;
}
