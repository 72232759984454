import styles from './CompanyProfile.module.scss';

import { ChangePassword } from '../../../../components/ChangePassword/ChangePassword';
import { UserProfileHeader } from '../../../../components/UserProfileHeader/UserProfileHeader';
import { CompanyAbout } from '../About/About';

export const CompanyProfile = () => {
    return (
        <div className={styles.companyProfile}>
            <UserProfileHeader />
            <CompanyAbout />
            <ChangePassword />
        </div>
    );
};
