/* eslint-disable no-const-assign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import cn from 'classnames';
import {
    ErrorMessage,
    Field,
    FieldArray,
    Form,
    Formik,
    useFormikContext,
} from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Button, { ButtonTypes } from '../../../../UI/Button/Button';
import {
    FormikInput,
    FormikSelect,
} from '../../../../UI/FormikFields/FormikFields';
import { CrossButton } from '../../../../assets/icons';
import { EditWrapper } from '../../../../components/EditWrapper/EditWrapper';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { fetchEducationInfo } from '../../../../store/reducers/EducationSlice';
import { Grade } from '../../../../types/types';
import styles from './EditEducation.module.scss';
import { validationschema } from './validationSchema';

const specializationData = {
    master: [
        {
            value: 'Кафедра административного права',
            label: 'Кафедра административного права',
        },
        {
            value: 'Кафедра гражданского права',
            label: 'Кафедра гражданского права',
        },
        {
            value: 'Кафедра гражданского процесса',
            label: 'Кафедра гражданского процесса',
        },
        {
            value: 'Кафедра истории государства и права',
            label: 'Кафедра истории государства и права',
        },
        {
            value: 'Кафедра коммерческого права и основ правоведения',
            label: 'Кафедра коммерческого права и основ правоведения',
        },
        {
            value: 'Кафедра конституционного и муниципального права',
            label: 'Кафедра конституционного и муниципального права',
        },
        {
            value: 'Кафедра криминалистики',
            label: 'Кафедра криминалистики',
        },
        {
            value: 'Кафедра международного права',
            label: 'Кафедра международного права',
        },
        {
            value: 'Кафедра предпринимательского права',
            label: 'Кафедра предпринимательского права',
        },
        {
            value: 'Кафедра теории государства и права и политологии',
            label: 'Кафедра теории государства и права и политологии',
        },
        {
            value: 'Кафедра трудового права',
            label: 'Кафедра трудового права',
        },
        {
            value: 'Кафедра уголовного процесса, правосудия и прокурорского надзора',
            label: 'Кафедра уголовного процесса, правосудия и прокурорского надзора',
        },
        {
            value: 'Кафедра уголовного права и криминологии',
            label: 'Кафедра уголовного права и криминологии',
        },
        {
            value: 'Кафедра финансового права',
            label: 'Кафедра финансового права',
        },
        {
            value: 'Кафедра экологического и земельного права',
            label: 'Кафедра экологического и земельного права',
        },
        {
            value: 'Иное',
            label: 'Иное',
        },
    ],
    specialistAndBacheloor: [
        {
            value: 'Гражданско-правовая',
            label: 'Гражданско-правовая',
        },
        {
            value: 'Государственно-правовая',
            label: 'Государственно-правовая',
        },
        {
            value: 'Уголовно-правовая',
            label: 'Уголовно-правовая',
        },
        {
            value: 'Иное',
            label: 'Иное',
        },
    ],
};

export const EditEducation = () => {
    const dispatch = useAppDispatch();
    const { id } = useAppSelector((state) => state.authReducer);
    useEffect(() => {
        id && dispatch(fetchEducationInfo(id));
    }, [id, dispatch]);
    const educationData = useAppSelector(
        (state) => state.educationReducer.educationData,
    );
    const initialdata = educationData?.map((initialForm) => {
        return {
            average_mark: initialForm.average_mark,
            college: initialForm.college,
            end: initialForm.end,
            faculty: initialForm.faculty,
            grade: initialForm.grade,
            specialization: initialForm.specialization
                ? initialForm.specialization
                : 'Не задано',
            specialty: initialForm.specialty
                ? initialForm.specialty
                : 'Не задано',
        };
    });
    return (
        <Formik
            validationSchema={validationschema}
            validateOnBlur={true}
            enableReinitialize={true}
            initialValues={{
                educations: initialdata ? initialdata : [],
            }}
            onSubmit={(values) => {}}
        >
            {(formik) => (
                <Form className={styles.form} onSubmit={formik.handleSubmit}>
                    <EditWrapper
                        className={styles.EditEducation__wrapper}
                        title={'Образование'}
                        saveOnclick={async () => {
                            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/education/many/`;
                            await axios
                                .post(url, formik.values)
                                .then(() => {
                                    toast.success(
                                        'Данные об образовании успешно изменены',
                                    );
                                })
                                .catch(() => {
                                    toast.error('Призошла ошибка!');
                                });
                        }}
                        isValid={
                            formik.isValid &&
                            formik.values.educations.length > 0 &&
                            formik.dirty
                        }
                    >
                        <FieldArray
                            name="educations"
                            render={(arrayHelpers) => {
                                return (
                                    <div className={styles.EducationForms}>
                                        {formik.values.educations &&
                                            formik.values.educations.map(
                                                (education, index) => (
                                                    <EducationForm
                                                        key={index}
                                                        education={education}
                                                        index={index}
                                                        arrayHelpers={
                                                            arrayHelpers
                                                        }
                                                        errors={
                                                            formik?.errors
                                                                ?.educations &&
                                                            formik?.errors
                                                                ?.educations[
                                                                index
                                                            ]
                                                                ? formik?.errors
                                                                      ?.educations[
                                                                      index
                                                                  ]
                                                                : {
                                                                      average_mark:
                                                                          '',
                                                                      college:
                                                                          '',
                                                                      end: '',
                                                                      faculty:
                                                                          '',
                                                                      grade: '',
                                                                      specialization:
                                                                          '',
                                                                      specialty:
                                                                          '',
                                                                  }
                                                        }
                                                        formik={formik}
                                                    />
                                                ),
                                            )}

                                        <Button
                                            onClick={() => {
                                                arrayHelpers.insert(
                                                    formik.values.educations
                                                        .length + 1,
                                                    {
                                                        average_mark: '',
                                                        college: '',
                                                        end: '',
                                                        faculty: '',
                                                        grade: 'BH',
                                                        specialization: '',
                                                        specialty: '',
                                                    },
                                                );
                                            }}
                                            type={ButtonTypes.AddButton}
                                        ></Button>
                                    </div>
                                );
                            }}
                        ></FieldArray>
                    </EditWrapper>
                </Form>
            )}
        </Formik>
    );
};

interface EducationFormProps {
    education: initialData;
    index: number;
    arrayHelpers?: any;
    errors?: any;
    formik: any;
}

interface initialData {
    grade?: Grade;
    college?: string;
    faculty?: string;
    specialization?: string;
    end?: string;
    average_mark?: number;
    specialty?: string;
}

const EducationForm = ({
    index,
    education,
    arrayHelpers,
    errors,
    formik,
}: EducationFormProps) => {
    const [grade, setGrade] = useState<string | null>(
        education.grade ? education?.grade : null,
    );
    const formikProps = useFormikContext();
    useEffect(() => {
        formikProps.setFieldValue(`educations.${index}.grade`, grade);
    }, [grade]);
    return (
        <div className={styles.EditEducation}>
            <CrossButton
                className={styles.CrossButton}
                onClick={() => {
                    arrayHelpers.remove(index);
                }}
            />
            <div className={styles.EditEducation__container}>
                <FormikInput
                    index={index}
                    error={formik.errors.college}
                    name={`educations.${index}.college`}
                    title={'ВУЗ'}
                    isValid={errors && !errors.college}
                />
                <FormikSelect
                    options={[
                        { value: 'BH', label: 'Бакалавриат' },
                        { value: 'MG', label: 'Магистратура' },
                        { value: 'SP', label: 'Специалитет' },
                    ]}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setGrade(e.target.value);
                    }}
                    value={grade ? grade : 'BH'}
                    index={index}
                    name={`educations.${index}.grade`}
                    title={'Уровень обучения'}
                    error={formik.errors.grade}
                    isValid={Boolean(grade) && formik.touched}
                />
                <FormikInput
                    index={index}
                    error={formik.errors.faculty}
                    name={`educations.${index}.faculty`}
                    title={'Факультет'}
                    isValid={errors && !errors.faculty}
                />
                <SpecializationSelect
                    index={index}
                    errors={errors}
                    grade={grade}
                    value={formik.values.educations[index].specialization}
                />
                <FormikInput
                    index={index}
                    error={formik.errors.average_mark}
                    name={`educations.${index}.average_mark`}
                    title={'Средний балл'}
                    isValid={errors && !errors.average_mark}
                />
                <FormikInput
                    index={index}
                    error={formik.errors.end}
                    name={`educations.${index}.end`}
                    title={'Год выпуска'}
                    isValid={errors && !errors.end}
                    fieldClassName={styles.field__datepicker}
                />
                <SpecialitySelect
                    index={index}
                    errors={errors}
                    grade={grade}
                    value={formik.values.educations[index].specialty}
                />
            </div>
        </div>
    );
};

interface SpecializationSelectProps {
    index: number;
    errors: any;
    grade: string | null;
    value: string;
}

const SpecializationSelect = ({
    index,
    errors,
    grade,
    value,
}: SpecializationSelectProps) => {
    return (
        <div className={styles.field}>
            <h3 className={styles.field__header}>Специализация</h3>
            {value === 'Иное' ? (
                <input
                    id={`educations.${index}.specialization`}
                    className={cn(styles.select, {
                        [styles.select_valid]: errors && !errors.specialization,
                    })}
                    name={`educations.${index}.specialization`}
                />
            ) : (
                <Field
                    id={`educations.${index}.specialization`}
                    as="select"
                    className={cn(styles.select, {
                        [styles.select_valid]: errors && !errors.specialization,
                    })}
                    name={`educations.${index}.specialization`}
                    value={value}
                >
                    {grade === 'MG' ? (
                        <>
                            <option value={''} selected disabled>
                                Выберите специальность
                            </option>
                            {specializationData.master.map(
                                (specialization, index) => (
                                    <option
                                        key={index}
                                        value={specialization.value}
                                    >
                                        {specialization.label}
                                    </option>
                                ),
                            )}
                        </>
                    ) : (
                        <>
                            <option value={''} selected disabled>
                                Выберите специальность
                            </option>
                            {specializationData.specialistAndBacheloor.map(
                                (specialization, index) => (
                                    <option
                                        key={index}
                                        value={specialization.value}
                                    >
                                        {specialization.label}
                                    </option>
                                ),
                            )}
                        </>
                    )}
                </Field>
            )}
        </div>
    );
};

interface SpecialitySelectProps {
    index: number;
    errors: any;
    grade: string | null;
    value: string;
}

const SpecialitySelect = ({
    index,
    errors,
    grade,
    value,
}: SpecialitySelectProps) => {
    return (
        <div className={styles.field}>
            <h3 className={styles.field__header}>Специальность</h3>
            {value === 'Иное' ? (
                <input
                    className={cn(styles.select, {
                        [styles.select_valid]: !errors.specialty,
                    })}
                    name={`educations.${index}.specialty`}
                    id={`educations.${index}.specialty`}
                />
            ) : (
                <Field
                    as="select"
                    className={cn(styles.select, {
                        [styles.select_valid]: !errors.specialty,
                    })}
                    type="text"
                    name={`educations.${index}.specialty`}
                    id={`educations.${index}.specialty`}
                    placeholder={'Юрист'}
                >
                    {grade === 'MG' ? (
                        <>
                            <option value="" selected disabled>
                                Выберите специальность
                            </option>
                            <option value="Криминалист">
                                Магистр юриспруденции
                            </option>
                            <option value="Иное">Иное</option>
                        </>
                    ) : (
                        <>
                            <option value="" selected disabled>
                                Выберите специальность
                            </option>
                            <option value="Все">Юриспруденция</option>
                            <option value="Иное">Иное</option>
                        </>
                    )}
                </Field>
            )}

            <div className={styles.field__error}>
                <ErrorMessage
                    component={'span'}
                    name={`educations.${index}.specialty`}
                    className={styles.field__error}
                />
            </div>
        </div>
    );
};
