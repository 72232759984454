import cn from 'classnames';
import React from 'react';
import { SelectOption } from '../../types/types';
import styles from './CustomSelect.module.scss';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Select from 'react-select';

export interface option {
    value: string;
    label: string | number;
}

export interface OptionProps {
    errorMessage?: string | undefined;
    isMulti?: boolean;
    onChange?: any;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: any;
    name?: string;
    state?: any;
    isValid?: boolean;

    options?: SelectOption[];
    defaultValue?: any;
    placeholder?: string | number;
    className?: string;
    inputFieldClass?: string;
    titleClass?: string;

    title?: string;
    borderRadius?: string;
    placeholderSize?: string;
    fieldHeight?: string;
    isSearchable?: boolean;
    isDisabled?: boolean;
}

export const CustomSelect = ({
    errorMessage,
    onChange,
    onBlur,
    value,
    name,
    options,
    placeholder,
    title,
    defaultValue,
    className,
    inputFieldClass,
    titleClass,
    borderRadius,
    placeholderSize,
    isMulti,
    isValid,
    isSearchable = true,
    isDisabled = false,
}: OptionProps) => {
    return (
        <div className={cn(styles.Select, className)}>
            {title && <Title title={title} titleClass={titleClass} />}
            <Select
                isDisabled={isDisabled}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                defaultValue={defaultValue}
                name={name}
                className={cn(styles.Select__dropdown, inputFieldClass)}
                placeholder={placeholder ? placeholder : 'Все'}
                options={options}
                isMulti={isMulti}
                isSearchable={isSearchable}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: borderRadius ? borderRadius : '25px',
                        border: isValid
                            ? '2px solid green'
                            : '2px solid #cccccc',
                        padding: '0 5px',
                        textAlign: 'justify',
                        color: 'black ',
                        fontSize: placeholderSize,
                        outline: 'none',
                        '&:hover': {
                            border: isValid
                                ? '2px solid green'
                                : '2px solid #007aff',
                        },
                    }),
                    valueContainer: (defaultStyles: any) => {
                        return {
                            ...defaultStyles,
                        };
                    },
                    placeholder: (defaultStyles: any) => {
                        return {
                            ...defaultStyles,
                            color: '#808080',
                            fontSize: placeholderSize
                                ? placeholderSize
                                : '20px',
                            verticalAlign: 'center',
                        };
                    },
                    menuPortal: (base) => ({
                        ...base,
                        fontSize: placeholderSize ? placeholderSize : '20',
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize: placeholderSize ? placeholderSize : '20',
                    }),
                    multiValue: (base) => ({
                        ...base,
                        backgroundColor: '#F5F8FF',
                        color: 'black',
                        borderRadius: '11px',
                    }),
                }}
            />
            <div className={styles.Select__error}>
                {errorMessage ? errorMessage : ''}
            </div>
        </div>
    );
};

const Title = ({
    title,
    titleClass,
}: {
    title: string;
    titleClass: string | undefined;
}) => {
    return <h2 className={cn(styles.Select__header, titleClass)}>{title}</h2>;
};
