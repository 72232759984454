import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(
            /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
            'Некорректный email',
        )
        .required('Поле обязательно')
        .max(35, 'Логин не может быть больше 35 символов'),
    fio: yup
        .string()
        .required('Поле обязательно')
        .max(100, 'Заголовок не должен содержать более 100 символов')
        .matches(
            // eslint-disable-next-line no-useless-escape
            /([А-ЯЁ][а-яё]+[\-\s]?)/,
            'Пожалуйста, введите ФИО на русском языке. Первая буква заглавная',
        ),
    organization_institution: yup
        .string()
        .typeError('')
        .max(100, 'Поле не должно содержать более 50 символов')
        .required('Поле обязательно'),
    text: yup
        .string()
        .typeError('')
        .max(400, 'Поле не должно содержать более 50 символов')
        .required('Поле обязательно'),
});
