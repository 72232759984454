import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { Role } from '../../types/types';
import { AvatarUploader } from '../AvatarUploader/AvatarUploader';
import styles from './UserProfileHeader.module.scss';

export const UserProfileHeader = () => {
    const { role } = useAppSelector((state) => state.authReducer);
    return (
        <>
            {role === Role.candidate && <StudentProfileHeader />}
            {role === Role.staff && <StaffProfileHeader />}
            {role === Role.organization && <OrganizationProfileHeader />}
        </>
    );
};

const StudentProfileHeader = () => {
    const { candidate } = useAppSelector((state) => state.candidateReducer);

    useEffect(() => {
        const photo = candidate?.photo;
        if (!photo) {
            return;
        }
    }, [candidate]);

    return (
        <div className={styles.header}>
            <AvatarUploader />
            <div className={styles.header__content}>
                <MainInfo
                    firstName={candidate?.first_name}
                    secondName={candidate?.second_name}
                    lastName={candidate?.last_name}
                />
                <Contacts phone={candidate?.phone} mail={candidate?.email} />
                <ExtraInfo />
            </div>
        </div>
    );
};

const StaffProfileHeader = () => {
    const staff = useAppSelector((state) => state.staffReducer.staff);

    useEffect(() => {
        const photo = staff?.photo;
        if (!photo) {
            return;
        }
    }, [staff]);

    return (
        <div className={styles.header}>
            <AvatarUploader />
            <div className={styles.header__content}>
                <MainInfo
                    firstName={staff?.first_name}
                    secondName={staff?.second_name}
                    lastName={staff?.last_name}
                />
                <div className={styles.ExtraInfo}>
                    <div className={styles.ExtraInfo__wrapper}>
                        <span className={styles.ExtraInfo__point}>Email:</span>
                        <span className={styles.ExtraInfo__info}>
                            {staff?.email || '---'}
                        </span>
                    </div>
                    <div className={styles.ExtraInfo__wrapper}>
                        <span className={styles.ExtraInfo__point}>
                            Телефон:{' '}
                        </span>
                        <span className={styles.ExtraInfo__info}>
                            {staff?.phone || '---'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const OrganizationProfileHeader = () => {
    const company = useAppSelector((state) => state.companyReducer.company);

    useEffect(() => {
        const photo = company?.photo;
        if (!photo) {
            return;
        }
    }, [company]);

    return (
        <div className={styles.header}>
            <AvatarUploader />
            <div className={styles.header__content}>
                <MainInfo firstName={company?.full_title} secondName={''} />
                <div className={styles.ExtraInfo}>
                    <div className={styles.ExtraInfo__wrapper}>
                        <span className={styles.ExtraInfo__point}>Email:</span>
                        <span className={styles.ExtraInfo__info}>
                            {company?.email || '---'}
                        </span>
                    </div>
                    <div className={styles.ExtraInfo__wrapper}>
                        <span className={styles.ExtraInfo__point}>
                            Телефон:{' '}
                        </span>
                        <span className={styles.ExtraInfo__info}>
                            {company?.phone || '---'}
                        </span>
                    </div>
                </div>
                {company?.has_subscription ? (
                    <span className={styles.tariffActive}>
                        Статус подписки: <b>активна</b> с{' '}
                        {new Date(company.subscription_from).toLocaleString(
                            'ru-RU',
                            {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            },
                        )}{' '}
                        по{' '}
                        {new Date(company.subscription_to).toLocaleString(
                            'ru-RU',
                            {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            },
                        )}{' '}
                    </span>
                ) : (
                    <span className={styles.tariffNotActive}>
                        Статус подписки: <b>не активна</b>. Узнать подробнее и
                        оплатить подписку:{' '}
                        <a href="mailto:career.law.msu@gmail.com">
                            career.law.msu@gmail.com»
                        </a>
                    </span>
                )}
            </div>
        </div>
    );
};

const MainInfo = ({ firstName, secondName, lastName }: MainInfoProps) => {
    const { role } = useAppSelector((state) => state.authReducer);
    return (
        <div className={styles.header__maininfo}>
            <div className={styles.header_left}>
                <h3 className={styles.header__name}>
                    {secondName} {firstName} {lastName ? lastName : ''}
                </h3>
                <span className={styles.header__role}>
                    {role === Role.candidate && 'Студент'}
                    {role === Role.staff && 'Сотрудник Центра карьеры'}
                    {role === Role.organization && 'Партнер'}
                </span>
            </div>
            {role === Role.candidate && (
                <div className={styles.header_right}>
                    <Link
                        className={styles.header__button}
                        to={PATH_DASHBOARD.student.profile.edit}
                    >
                        Редактировать профиль
                    </Link>
                </div>
            )}
            {role === Role.organization && (
                <div className={styles.header_right}>
                    <Link
                        className={styles.header__button}
                        to={PATH_DASHBOARD.company.edit}
                    >
                        Редактировать профиль
                    </Link>
                </div>
            )}
            {role === Role.staff && (
                <div className={styles.header_right}>
                    <Link
                        className={styles.header__button}
                        to={PATH_DASHBOARD.staff.edit}
                    >
                        Редактировать профиль
                    </Link>
                </div>
            )}
        </div>
    );
};

interface MainInfoProps {
    firstName: string | undefined | null;
    secondName: string | undefined | null;
    lastName?: string | undefined | null;
}

const ExtraInfo = () => {
    const conscription = useAppSelector(
        (state) => state.candidateReducer.candidate?.conscription,
    );
    const disability = useAppSelector(
        (state) => state.candidateReducer.candidate?.disability,
    );
    return (
        <div className={styles.ExtraInfo}>
            <div className={styles.ExtraInfo__wrapper}>
                <span className={styles.ExtraInfo__point}>
                    Воинская обязанность:
                </span>
                <span className={styles.ExtraInfo__info}>
                    {conscription ? 'да' : 'нет'}
                </span>
            </div>
            <div className={styles.ExtraInfo__wrapper}>
                <span className={styles.ExtraInfo__point}>Инвалидность: </span>
                <span className={styles.ExtraInfo__info}>
                    {disability ? 'да' : 'нет'}
                </span>
            </div>
        </div>
    );
};

const Contacts = ({
    phone,
    mail,
}: {
    phone: string | null | undefined;
    mail: string | null | undefined;
}) => {
    const windowWidth = useWindowWidth();
    return (
        <div className={styles.contacts}>
            <div className={styles.contacts__wrapper}>
                <span className={styles.contacts__title}>Email: </span>
                <span className={styles.contacts__subtitle}> {mail}</span>
            </div>
            {windowWidth && windowWidth > 1000 && (
                <div className={styles.header__stripe}>|</div>
            )}
            <div className={styles.header__tel}>
                <span className={styles.contacts__title}>Телефон:</span>
                <span className={styles.contacts__subtitle}>
                    {' '}
                    {phone ? phone : ' Не указан'}
                </span>
            </div>
        </div>
    );
};
