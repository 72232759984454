// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileDownloader_downloader__3j\\+TC {\n  font: var(--font-s) !important;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  max-height: 50px;\n}\n.FileDownloader_downloader__link__lzxxG {\n  width: 0;\n  height: 0;\n  opacity: 0;\n}\n.FileDownloader_downloader__button__iiS2X {\n  font: var(--font-s);\n  border: none;\n  outline: 1px solid #b2b2b2;\n  white-space: nowrap;\n}\n.FileDownloader_downloader__button__iiS2X:hover {\n  background-color: var(--accented-color);\n  outline: none;\n  color: #000;\n}\n\n.FileDownloader_loader__kl-gI {\n  transform: scale(0.4);\n}", "",{"version":3,"sources":["webpack://./src/components/FileDownloader/FileDownloader.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EAEA,aAAA;EACA,mBAAA;EACA,QAAA;EAEA,gBAAA;AADJ;AAGI;EACI,QAAA;EACA,SAAA;EACA,UAAA;AADR;AAGI;EACI,mBAAA;EACA,YAAA;EACA,0BAAA;EACA,mBAAA;AADR;AAEQ;EACI,uCAAA;EACA,aAAA;EACA,WAAA;AAAZ;;AAKA;EACI,qBAAA;AAFJ","sourcesContent":[".downloader {\n    font: var(--font-s) !important;\n\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    max-height: 50px;\n\n    &__link {\n        width: 0;\n        height: 0;\n        opacity: 0;\n    }\n    &__button {\n        font: var(--font-s);\n        border: none;\n        outline: 1px solid #b2b2b2;\n        white-space: nowrap;\n        &:hover {\n            background-color: var(--accented-color);\n            outline: none;\n            color: #000;\n        }\n    }\n}\n\n.loader {\n    transform: scale(0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloader": "FileDownloader_downloader__3j+TC",
	"downloader__link": "FileDownloader_downloader__link__lzxxG",
	"downloader__button": "FileDownloader_downloader__button__iiS2X",
	"loader": "FileDownloader_loader__kl-gI"
};
export default ___CSS_LOADER_EXPORT___;
