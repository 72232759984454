import { FC } from 'react';
import ReactPaginate from 'react-paginate';

import classNames from 'classnames';
import { PaginationLeftVector } from '../../../assets/icons';
import style from './Pagination.module.scss';
type PaginationProps = {
    list?: { page_count: number; current_page: number };
    handlePagination: (page: number) => void;
    isLoading?: boolean;
};

const Pagination: FC<PaginationProps> = (props) => {
    const { list, handlePagination, isLoading } = props;

    const pagesCount = list?.page_count || 1;

    if (pagesCount === 1) {
        return null;
    }
    return pagesCount ? (
        <ReactPaginate
            activeClassName={style.paginationItemActive}
            pageClassName={style.paginationItem}
            pageLabelBuilder={(page) => <p>{page}</p>}
            className={classNames(
                style.paginationWrapper,
                isLoading && style.disabled,
            )}
            disabledClassName={style.disabled}
            breakClassName={style.paginationEllipsis}
            breakLabel="..."
            nextLabel={
                <button
                    className={style.paginationNext}
                    onClick={() => undefined}
                >
                    <PaginationLeftVector />
                </button>
            }
            onPageChange={(e) => handlePagination(e.selected + 1)}
            pageRangeDisplayed={3}
            pageCount={pagesCount}
            previousLabel={
                <button
                    className={style.paginationPrev}
                    onClick={() => undefined}
                >
                    <PaginationLeftVector />
                </button>
            }
        />
    ) : null;
};

export default Pagination;
