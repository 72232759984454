import * as Yup from 'yup';

export const validationschema = Yup.object().shape({
    educations: Yup.array().of(
        Yup.object().shape({
            average_mark: Yup.number()
                .positive('Введите число от 0 до 5.0')
                .typeError('Введите число от 0 до 5.0')
                .test(
                    'maxDigits',
                    'Максимальное количество знаков после запятой - 3',
                    (number) => String(number).length <= 5,
                )
                .test(
                    'Введите число от 0 до 5.0',
                    'Введите число от 0 до 5.0',
                    (value) => {
                        if (value === undefined && value === null) {
                            return false;
                        }
                        if (Number(value) >= 0 && Number(value) <= 5) {
                            return true;
                        }
                    },
                )
                .required('Поле обязательно'),
            college: Yup.string()
                .required('Поле обязательно')
                .matches(
                    /^[?!,.а-яА-ЯёЁ0-9 \s]+$/,
                    'Данное поле может содержать только буквы кириллицы или латинницы',
                )
                .max(100, 'Максимальное количество символов 100'),
            end: Yup.number()
                .typeError('Укажите корректное значение')
                .test(
                    'Укажите корректное значение',
                    'Укажите корректное значение',
                    (value) => {
                        if (!value) {
                            return false;
                        }
                        if (
                            value >= 1950 &&
                            value <= new Date().getFullYear() + 5
                        ) {
                            return true;
                        }
                    },
                )
                .required('Поле обязательно'),
            faculty: Yup.string()
                .required('Поле обязательно')
                .matches(
                    /^[?!,.а-яА-ЯёЁ0-9- \s]+$/,
                    'Факультет должен быть веден на русском языке',
                )
                .max(100, 'Максимальное количество символов 100'),
            grade: Yup.string().required('Поле обязательно'),
            specialization: Yup.string().required('Поле обязательно'),
            specialty: Yup.string().required('Поле обязательно'),
        }),
    ),
});
