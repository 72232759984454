import cn from 'classnames';
import { AddButton } from '../../assets/icons';
import styles from './Button.module.scss';

export enum ButtonTypes {
    AddButton = 'AddButton',
}

interface ButtonProps {
    children?: React.ReactNode;
    className?: string;
    onClick?: any;
    type?: ButtonTypes;
    isDisabled?: boolean;
    buttonType?: any;
}

const Button = ({
    children,
    className,
    onClick,
    type,
    buttonType,
    isDisabled = false,
}: ButtonProps) => {
    if (type === ButtonTypes.AddButton) {
        return (
            <div
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
                className={cn(styles.addbutton, className)}
            >
                <span className={styles.addbutton__text}>
                    {children ? children : 'Добавить'}
                </span>
                <AddButton />
            </div>
        );
    }
    return (
        <button
            onClick={onClick}
            className={cn(styles.button, className ? className : '')}
            disabled={isDisabled ? true : false}
            type={buttonType ? buttonType : 'button'}
        >
            {children}
        </button>
    );
};

export default Button;
