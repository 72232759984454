import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import { LeftArrowIcon, RightArrowIcon } from '../../../assets/icons';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PagePagination } from '../../../components/PagePagination/PagePagination';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { PATH_DASHBOARD } from '../../../routes/RouterConfig';
import { INewsCard } from '../../../types/types';
import styles from './NewsItemPage.module.scss';

export const NewsItemPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [newsInfo, setNewsInfo] = useState<INewsCard | null>();

    const url = `${process.env.REACT_APP_BASE_URL}api/v1/news/${id}/`;
    useEffect(() => {
        axios
            .get(url)
            .then((response) => {
                setNewsInfo(response?.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id, url]);

    const windowWidth = useWindowWidth();

    return (
        <PageLayout>
            <section className={styles.NewsItem}>
                <PagePagination
                    linkName={'Новости'}
                    event={newsInfo?.title}
                    route={PATH_DASHBOARD.news}
                />
                <div className={styles.NewsItem__title}>
                    <h2 className={styles.NewsItem__header}>
                        {newsInfo?.title}
                    </h2>
                    {newsInfo?.publish_date ? (
                        <p className={styles.NewsItem__date}>
                            Дата публикации:{' '}
                            {new Date(
                                newsInfo?.publish_date,
                            ).toLocaleDateString('ru-RU', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            })}
                        </p>
                    ) : null}
                </div>
                <div className={styles.NewsItem__container}>
                    <img
                        className={styles.NewsItem__image}
                        alt={'картинка новости'}
                        src={newsInfo?.images[0] && newsInfo?.images[0].path}
                    />
                </div>
                <div className={styles.NewsItem__description}>
                    {newsInfo?.text.split('\n').map((item, idx) => {
                        return <p key={idx}>{item}</p>;
                    })}
                </div>
                <div className={styles.NewsItem__buttons}>
                    {newsInfo?.previous_news ? (
                        <Button
                            className={styles.NewsItem__button}
                            onClick={() => {
                                navigate(`/news/${newsInfo?.previous_news}`);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }}
                        >
                            {windowWidth && windowWidth > 650 ? (
                                <p>К предыдущей новости</p>
                            ) : (
                                <LeftArrowIcon
                                    className={styles.NewsItem__arrow}
                                />
                            )}
                        </Button>
                    ) : null}
                    {newsInfo?.next_news ? (
                        <Button
                            className={styles.NewsItem__button}
                            onClick={() => {
                                navigate(`/news/${newsInfo?.next_news}`);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                            }}
                        >
                            {windowWidth && windowWidth > 650 ? (
                                <>
                                    <p>К следующей новости</p>
                                </>
                            ) : (
                                <RightArrowIcon
                                    className={styles.NewsItem__arrow}
                                />
                            )}
                        </Button>
                    ) : null}
                </div>
            </section>
        </PageLayout>
    );
};
