import styles from './DatePicker.module.scss';
import cn from 'classnames';

export const DatePicker = ({
    wrapperClassName,
    headerText,
    fieldClassName,
    headerClassName,
    errorMessage,
    onChange,
    onBlur,
    value,
    name,
    isValid,
}: DatePickerProps) => {
    return (
        <div
            className={cn(
                styles.datepicker,
                wrapperClassName ? [...wrapperClassName] : '',
            )}
        >
            <h3 className={cn(styles.datepicker__header, headerClassName)}>
                {headerText}
            </h3>
            <input
                type="date"
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                name={name}
                placeholder={'dd / mm / yyyy'}
                className={cn(styles.datepicker__field, fieldClassName, {
                    [styles.datepicker__field_valid]: isValid,
                })}
            ></input>
            <div className={styles.datepicker__error}>
                {errorMessage ? errorMessage : ''}
            </div>
        </div>
    );
};

interface DatePickerProps {
    headerText?: string;
    placeholder?: string;

    wrapperClassName?: string[];
    fieldClassName?: string;
    headerClassName?: string;

    errorMessage?: string | undefined;
    isValid?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: any;
    name?: string;
    helpMessage?: string;
}
