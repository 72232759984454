import * as yup from 'yup';
export const validationSchema = yup.object().shape({
    title: yup
        .string()
        .required('Поле обязательно')
        .max(100, 'Заголовок не должен содержать более 100 символов'),
    datetime: yup.string().required('Поле обязательно'),
    companies: yup
        .string()
        .max(100, 'Поле партнеров не должно содержать более 100 символов'),
    description: yup
        .string()
        .required('Поле обязательно')
        .max(4096, ' Превышен лимит символов'),
});
