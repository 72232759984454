import styles from './StudentCard.module.scss';

import { useNavigate } from 'react-router-dom';
import Button from '../../UI/Button/Button';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { useGetAge } from '../../hooks/useGetAge';
import { EducationData, Role } from '../../types/types';

interface StudentCardProps {
    initials: string;
    birthday: string;
    photo: string;
    education: EducationData[];
    id: number;
    disabled?: boolean;
}

const DEFAULT_PHOTO_URL =
    'https://png.pngtree.com/png-vector/20220522/ourmid/pngtree-personal-account-avatar-body-boy-png-image_4714973.png';

const grades = {
    BH: 'Бакалавриат',
    MG: 'Магистратура',
    SP: 'Специалитет',
};

export const StudentCard = ({
    initials,
    birthday,
    photo,
    education,
    id,
    disabled,
}: StudentCardProps) => {
    const { role } = useAppSelector((state) => state.authReducer);

    const navigate = useNavigate();

    const age = useGetAge(birthday);

    return (
        <div className={styles.card}>
            <div className={styles.card__top}>
                <div className={styles.card__content}>
                    <div className={styles.image}>
                        <img
                            src={photo ? photo : DEFAULT_PHOTO_URL}
                            className={styles.image_inner}
                            alt={'student avatar'}
                        ></img>
                    </div>
                    <div className={styles.card__info}>
                        <h3 className={styles.card__header}>{initials}</h3>
                        <span className={styles.card__role}>{age}</span>
                    </div>
                    <Button
                        className={styles.card__button}
                        onClick={() => {
                            if (role === Role.staff) {
                                navigate(`/staff/students_list/${id}`);
                            }
                            if (role === Role.organization) {
                                navigate(`/company/students_list/${id}`);
                            }
                        }}
                    >
                        Подробнее
                    </Button>
                </div>
            </div>
            <div className={styles.card__educations}>
                {education.length > 0 ? (
                    education.map((education, index) => (
                        <EducationPoint key={index} education={education} />
                    ))
                ) : (
                    <p className={styles.card__noinfo}>
                        Резюме не опубликовано
                    </p>
                )}
            </div>
        </div>
    );
};

interface EducationPointProps {
    education: EducationData;
}

const EducationPoint = ({ education }: EducationPointProps) => {
    return (
        <div className={styles.education}>
            <div className={styles.education__element}>
                <h4>ВУЗ: </h4>
                <span>{education.college}</span>
            </div>
            <div className={styles.education__element}>
                <h4>Факультет: </h4>
                <span>{education.faculty}</span>
            </div>
            <div className={styles.education__element}>
                <h4>Уровень обучения: </h4>
                <span>{grades[education.grade]}</span>
            </div>
            <div className={styles.education__element}>
                <h4>Специальность: </h4>
                <span>
                    {education.specialty === 'Все' ? 'Юриспруденция' : 'Иное'}
                </span>
            </div>
            <div className={styles.education__element}>
                <h4>Год окончания: </h4>
                <span>{education.end}</span>
            </div>
            <div className={styles.education__element}>
                <h4>Средний балл : </h4>
                <span>{Number(education.average_mark).toFixed(1)}</span>
            </div>
        </div>
    );
};
