import styles from './LanguageFilter.module.scss';

import { useEffect } from 'react';
import { Dropdown } from '../../UI/Dropdown/Dropdown';
import { MultiDropdown } from '../../UI/MultiDropdown/MultiDropdown';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import {
    changeLanguage,
    changeLanguageLevels,
} from '../../store/reducers/StudentsSlice';
import { useAppSelector } from '../../hooks/reduxHooks/useAppSelector';
import { languageOptions, levelOptions } from '../../constants';

export const LanguagesFilters = () => {
    const dispatch = useAppDispatch();
    const { language, languageLevels } = useAppSelector(
        (state) => state.studentsReducer,
    );

    const handleChangeLanguage = (value: string) => {
        dispatch(changeLanguage(value));
    };
    const handleChangeLanguageLevels = (value: string) => {
        dispatch(changeLanguageLevels(value));
    };
    return (
        <div className={styles.languages}>
            <h3 className={styles.languages__header}>Выберите язык</h3>
            <div className={styles.languages__wrapper}>
                <Dropdown
                    options={languageOptions}
                    changeOption={handleChangeLanguage}
                    className={styles.dropdown__wrapper}
                    dropdownElementClassname={styles.dropdown__text}
                    placeholderClassname={styles.dropdown__placeholder}
                    option={language}
                />
                <MultiDropdown
                    options={levelOptions}
                    changeCurrentValues={handleChangeLanguageLevels}
                    currentValues={languageLevels}
                />
            </div>
        </div>
    );
};
