import axios from 'axios';
import { toast } from 'react-toastify';
import { registrationSlice } from '../store/reducers/RegistrationSlice';
import { AppDispatch } from '../store/store';
import { IOrganization, IStudent } from '../types/types';

export const registerStudent =
    (studentData: IStudent) => async (dispatch: AppDispatch) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/`;
            dispatch(registrationSlice.actions.registerStart());
            const response = await axios.post(url, studentData);
            dispatch(registrationSlice.actions.registerSuccess(response.data));
            toast.success('Заявка отправлена, дождитесь верификации');
        } catch (e: any) {
            dispatch(
                registrationSlice.actions.registerError(e.request.response),
            );

            const status = e.request.status;
            if (status >= 400 && status < 500) {
                toast.error('Такой пользователь уже существует');
            } else {
                toast.error('Ошибка сервера. Попробуйте позже');
            }
        }
    };

export const registerOrganization =
    (organizationData: IOrganization) => async (dispatch: AppDispatch) => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/company/`;
            dispatch(registrationSlice.actions.registerStart());
            const response = await axios.post(url, organizationData);
            dispatch(registrationSlice.actions.registerSuccess(response.data));
            toast.success('Заявка отправлена, дождитесь верификации');
        } catch (e: any) {
            dispatch(
                registrationSlice.actions.registerError(e.request.response),
            );

            const status = e.request.status;
            if (status >= 400 && status < 500) {
                toast.error('Такой пользователь уже существует');
            } else {
                toast.error('Ошибка сервера. Попробуйте позже');
            }
        }
    };
