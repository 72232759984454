import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';

export type VacancyListItemType = {
    id: number;
    status: string;
    position: string;
    salary_from: number;
    salary_to: number;
    required_experience: string;
    description: string;
    city: string;
    address: string;
    skills: string;
    employment_form: string[];
    date_created: string;
    date_update: string;
    company: number;
    company_title: string;
    reaction_count: number;
    is_user_reaction: boolean;
    in_user_favorite: boolean;
};

export type VacancyListType = {
    page_count: number;
    current_page: number;
    objects: VacancyListItemType[];
};

export type VacancyReactionListType = {
    page_count: number;
    current_page: number;
    objects: {
        id: number;
        status: string;
        comment: string;
        vacancy: VacancyListItemType;
    }[];
};

const initialState: CompanyVacancyState = {
    company: '',
    employment_form: [],
    salary: [10000, 1000000],
    search_query: '',
    required_experience: [],

    candidateVacancyList: undefined,
    candidateVacancyListPage: 1,
    candidateVacancyListIsLoading: false,
    candidateVacancyListError: null,

    candidateVacancyFavoriteList: undefined,
    candidateVacancyFavoriteListPage: 1,
    candidateVacancyFavoriteListIsLoading: false,
    candidateVacancyFavoriteListError: null,

    candidateVacancyReactionList: undefined,
    candidateVacancyReactionListPage: 1,
    candidateVacancyReactionListIsLoading: false,
    candidateVacancyReactionListError: null,

    candidateVacancyRetrieve: undefined,
    candidateVacancyRetrieveIsLoading: false,
    candidateVacancyRetrieveError: null,
};

interface CompanyVacancyState {
    company: string;
    salary: [number, number];
    search_query: string;
    employment_form: string[];
    required_experience: string[];

    candidateVacancyList: VacancyListType | undefined;
    candidateVacancyListPage: number;
    candidateVacancyListIsLoading: boolean;
    candidateVacancyListError: any;

    candidateVacancyFavoriteList: VacancyListType | undefined;
    candidateVacancyFavoriteListPage: number;
    candidateVacancyFavoriteListIsLoading: boolean;
    candidateVacancyFavoriteListError: any;

    candidateVacancyReactionList: VacancyReactionListType | undefined;
    candidateVacancyReactionListPage: number;
    candidateVacancyReactionListIsLoading: boolean;
    candidateVacancyReactionListError: any;

    candidateVacancyRetrieve: VacancyListItemType | undefined;
    candidateVacancyRetrieveIsLoading: boolean;
    candidateVacancyRetrieveError: any;
}

export const getCandidateVacancyList = createAsyncThunk(
    'candidate/getCandidateVacancyList',
    async function name(
        {
            page,
            size,
            status,
            company,
            employment_form,
            salary_from,
            salary_to,
            search_query,
            required_experience,
        }: {
            page: number;
            size: number;
            status: 'OP' | 'CL';
            company?: string;
            employment_form?: string[];
            salary_from?: number;
            salary_to?: number;
            search_query?: string;
            required_experience?: string[];
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/`,
                {
                    params: {
                        page,
                        size,
                        status,
                        company,
                        employment_form,
                        salary_from,
                        salary_to,
                        search_query,
                        required_experience,
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCandidateVacancyFavoriteList = createAsyncThunk(
    'candidate/getCandidateVacancyFavoriteList',
    async function name(
        {
            page,
            size,
            id,
        }: {
            page: number;
            size: number;
            id?: number;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/favorite_vacancies/`,
                {
                    params: {
                        page,
                        size,
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCandidateVacancyReactionList = createAsyncThunk(
    'candidate/getCandidateVacancyReactionList',
    async function name(
        {
            page,
            size,
            id,
        }: {
            page: number;
            size: number;
            id?: number;
        },
        { rejectWithValue },
    ) {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/reaction/`,
                {
                    params: {
                        page,
                        size,
                    },
                    paramsSerializer: {
                        serialize: (params) => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                            });
                        },
                    },
                },
            );
            const data = await response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getCandidateVacancyRetrieve = createAsyncThunk(
    'candidate/getCandidateVacancyRetrieve',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/vacancy/${id}/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const updateCandidateVacancyFavorite = createAsyncThunk(
    'candidate/updateCompanyVacancyFavorite',
    async function (
        { id, vacancy_id }: { id?: number; vacancy_id: number },
        { rejectWithValue },
    ) {
        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/favorite_vacancies/${vacancy_id}/`,
            );
            toast.success('Вакансия добавлена в Избранное');
        } catch (error) {
            toast.error('Вакансия не добавлена');
            return rejectWithValue(error);
        }
    },
);
export const deleteCandidateVacancyFavorite = createAsyncThunk(
    'candidate/deleteCompanyVacancyFavorite',
    async function (
        { id, vacancy_id }: { id?: number; vacancy_id: number },
        { rejectWithValue },
    ) {
        try {
            await axios.delete(
                `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/favorite_vacancies/${vacancy_id}/`,
            );
            toast.success('Вакансия убрана из Избранного');
        } catch (error) {
            toast.error('Вакансия не удалена');
            return rejectWithValue(error);
        }
    },
);

export const updateCandidateVacancyReaction = createAsyncThunk(
    'candidate/updateCompanyVacancyReaction',
    async function (
        {
            id,
            vacancy_id,
            comment,
        }: { id?: number; vacancy_id: number; comment: string },
        { rejectWithValue },
    ) {
        try {
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/v1/reaction/`,
                {
                    candidate: id,
                    vacancy: vacancy_id,
                    status: 'NV',
                    comment: comment,
                },
            );
            toast.success('Вы успешно откликнулись на вакансию');
        } catch (error) {
            toast.error('Отклие не отправлен');
            return rejectWithValue(error);
        }
    },
);

export const candidateVacancySlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        //смена страницы в пагинации
        updateCandidateVacancyListPage(state, action: PayloadAction<number>) {
            state.candidateVacancyListPage = action.payload;
        },
        updateCandidateVacancyFavoriteListPage(
            state,
            action: PayloadAction<number>,
        ) {
            state.candidateVacancyListPage = action.payload;
        },
        updateCandidateVacancyReactionListPage(
            state,
            action: PayloadAction<number>,
        ) {
            state.candidateVacancyListPage = action.payload;
        },
        updateCompany(state, action: PayloadAction<string>) {
            state.company = action.payload;
        },
        updateSalary(state, action: PayloadAction<[number, number]>) {
            state.salary = action.payload;
        },
        updateSalaryFrom(state, action: PayloadAction<number>) {
            state.salary[0] = action.payload;
        },
        updateSalaryTo(state, action: PayloadAction<number>) {
            state.salary[1] = action.payload;
        },
        updateSearch(state, action: PayloadAction<string>) {
            state.search_query = action.payload;
        },
        updateRequiredExperience(state, action: PayloadAction<string>) {
            if (
                state.required_experience.find(
                    (item) => item === action.payload,
                )
            ) {
                state.required_experience = state.required_experience.filter(
                    (item) => item !== action.payload,
                );
            } else {
                state.required_experience.push(action.payload);
            }
        },
        updateEmploymentForm(state, action: PayloadAction<string>) {
            if (state.employment_form.find((item) => item === action.payload)) {
                state.employment_form = state.employment_form.filter(
                    (item) => item !== action.payload,
                );
            } else {
                state.employment_form.push(action.payload);
            }
        },
        deleteFilter(state) {
            state.company = '';
            state.employment_form = [];
            state.salary = [10000, 100000];
            state.search_query = '';
            state.required_experience = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCandidateVacancyList.pending, (state) => {
            state.candidateVacancyListIsLoading = true;
        });
        builder.addCase(getCandidateVacancyList.fulfilled, (state, action) => {
            state.candidateVacancyListIsLoading = false;
            state.candidateVacancyList = action.payload;
        });
        builder.addCase(getCandidateVacancyList.rejected, (state, action) => {
            state.candidateVacancyListIsLoading = false;
        });

        builder.addCase(getCandidateVacancyFavoriteList.pending, (state) => {
            state.candidateVacancyFavoriteListIsLoading = true;
        });
        builder.addCase(
            getCandidateVacancyFavoriteList.fulfilled,
            (state, action) => {
                state.candidateVacancyFavoriteListIsLoading = false;
                state.candidateVacancyFavoriteList = action.payload;
            },
        );
        builder.addCase(
            getCandidateVacancyFavoriteList.rejected,
            (state, action) => {
                state.candidateVacancyFavoriteListIsLoading = false;
            },
        );

        builder.addCase(getCandidateVacancyReactionList.pending, (state) => {
            state.candidateVacancyReactionListIsLoading = true;
        });
        builder.addCase(
            getCandidateVacancyReactionList.fulfilled,
            (state, action) => {
                state.candidateVacancyReactionListIsLoading = false;
                state.candidateVacancyReactionList = action.payload;
            },
        );
        builder.addCase(
            getCandidateVacancyReactionList.rejected,
            (state, action) => {
                state.candidateVacancyReactionListIsLoading = false;
            },
        );
    },
});

export const {
    updateCandidateVacancyListPage,
    updateCandidateVacancyFavoriteListPage,
    updateCandidateVacancyReactionListPage,
    updateCompany,
    updateEmploymentForm,
    updateRequiredExperience,
    updateSalaryFrom,
    updateSalaryTo,
    updateSearch,
    updateSalary,
    deleteFilter,
} = candidateVacancySlice.actions;

export default candidateVacancySlice.reducer;
