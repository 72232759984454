import { Calendar } from 'react-calendar';
import styles from './Calendar.module.scss';
import './calendar.scss';
import { useState } from 'react';

interface EventCalendarProps {
    dates?: null | Date[];
}

export const EventCalendar = ({ dates }: EventCalendarProps) => {
    const [value, onChange] = useState(new Date());

    const tileClassName = ({ date }: any) => {
        if (dates?.some((d: Date) => isSameDay(d, date))) {
            return 'tileClassName';
        }
        return null;
    };

    return (
        <div className={styles.calendar}>
            <Calendar
                onChange={() => onChange(value)}
                value={value}
                tileClassName={tileClassName}
            />
        </div>
    );
};

function isSameDay(d1: Date, d2: Date) {
    return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
    );
}
