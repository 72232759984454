import { ChangePassword } from '../../../components/ChangePassword/ChangePassword';
import { UserProfileHeader } from '../../../components/UserProfileHeader/UserProfileHeader';
import styles from './StaffProfile.module.scss';

export const StaffProfile = () => {
    return (
        <div className={styles.StudentProfile}>
            <UserProfileHeader />
            <ChangePassword />
        </div>
    );
};
