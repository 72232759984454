import axios from 'axios';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { Role } from '../types/types';
import { useAppSelector } from './reduxHooks/useAppSelector';

//хук, который будет либо добавлять лайк, либо удалять, извне будем прокидывать айдишник карточки и состояние ее лайка
//зашитое внутри сущности мероприятия

export const useChangeLike = (eventId: number) => {
    const { id, role } = useAppSelector((state) => state.authReducer);

    const changeEventLikeStatusUrl = useMemo(() => {
        if (role === Role.staff) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/staff/${id}/favorite_event/${eventId}/`;
        }
        if (role === Role.organization) {
            return `${process.env.REACT_APP_BASE_URL}api/v1/company/${id}/favorite_event/${eventId}/`;
        }
        return `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/favorite_event/${eventId}/`;
    }, [id, role, eventId]);

    //лайкаем карточку
    const likeEvent = () => {
        axios
            .post(changeEventLikeStatusUrl)
            .then(() => toast.success('Добавлено в избранное'))
            .catch(() => {});
    };

    //снимаем лайк с карточки
    const cancelLikeEvent = () => {
        axios
            .delete(changeEventLikeStatusUrl)
            .then(() => toast.success('Удалено из избранного'))
            .catch(() => toast.error('Ошибка при удалении из избранного'));
    };

    return { likeEvent, cancelLikeEvent };
};
