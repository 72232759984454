import { Link, To } from 'react-router-dom';
import { RightVector } from '../../assets/icons';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import cn from 'classnames';
import styles from './PagePagination.module.scss';

interface PageTitleProps {
    linkName: string;
    route?: To;
    event?: string;
}

export const PagePagination = ({ linkName, route, event }: PageTitleProps) => {
    return (
        <div className={styles.pagetitle}>
            <Link className={styles.link} to={String(PATH_DASHBOARD.root)}>
                Главная
            </Link>
            <RightVector />
            <Link
                to={route ? route : PATH_DASHBOARD.root}
                className={styles.link}
            >
                {linkName}
            </Link>
            {event && (
                <>
                    <RightVector />
                    <p className={cn(styles.link, styles.link__element)}>
                        {event}
                    </p>
                </>
            )}
        </div>
    );
};
