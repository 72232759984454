import { ChangeEvent } from 'react';
import { SearchIcon } from '../../assets/icons';
import styles from './SearchInput.module.scss';
import cn from 'classnames';

export const SearchInput = ({
    className,
    icon,
    onChange,
    placeholder,
}: SearchInputProps) => {
    return (
        <div className={cn(styles.Input, className)}>
            <input
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                className={styles.Input__container}
                type="text"
            />
            <div className={styles.Input__icon}>
                <SearchIcon />
            </div>
        </div>
    );
};

interface SearchInputProps {
    icon?: React.ReactNode;
    className?: string;
    onChange: (value: string) => void;
    placeholder?: string;
}
