import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { EducationData } from '../../types/types';

const initialState: EducationState = {
    error: null,
    isLoading: false,
    educationData: null,
};

interface EducationState {
    error: string | null | any;
    isLoading: boolean;
    educationData: EducationData[] | null;
}

export const fetchEducationInfo = createAsyncThunk(
    'education/fetchEducationInfo',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/education/`;
            const data = await axios.get(url);
            return data.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const educationSlice = createSlice({
    name: 'education',
    initialState,
    reducers: {
        clearEducations(state) {
            state.educationData = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEducationInfo.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchEducationInfo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.educationData = action.payload.objects;
        });
        builder.addCase(fetchEducationInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default educationSlice.reducer;
