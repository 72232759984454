import cn from 'classnames';
import styles from './EditEvent.module.scss';

import axios from 'axios';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomSelect } from '../../../../UI/CustomSelect/CustomSelect';
import { DatePicker } from '../../../../UI/DatePicker/DatePicker';
import { Input } from '../../../../UI/Input/Input';
import { Textarea } from '../../../../UI/Textarea/Textarea';
import { AddEventImage } from '../../../../components/AddEventImage/AddEventImage';
import { AddNewsHeader } from '../../../../components/AddNewsHeader/AddNewsHeader';
// import { PDFUploader } from '../../../../components/PDFUploader/PDFUploader';
import { IEventCard } from '../../../../types/types';
import { validationSchema } from './validationSchema';

export const EditEvent = () => {
    const pathname = useLocation();
    const [eventInfo, setEventInfo] = useState<IEventCard | null>(null);
    const eventId = pathname.pathname.split('/')[3];
    const eventUrl = `${process.env.REACT_APP_BASE_URL}api/v1/event/${eventId}/`;

    const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
    const [PDF, setPDF] = useState<File | null>(null);
    const [eventFormat, setEventFormat] = useState<
        { value: string; label: string } | undefined
    >();
    const [adress, setAdress] = useState<
        { value: string; label: string } | undefined
    >();
    const handleFormatChange = (selectedOption: any) => {
        setEventFormat({
            value: selectedOption.value,
            label: selectedOption.label,
        });
    };
    const handleAdressChange = (selectedOption: any) => {
        setAdress({ value: selectedOption.value, label: selectedOption.label });
    };
    const validImage = useMemo(() => {
        if (selectedImages?.length && selectedImages[0].size < 512000) {
            return true;
        }
        if (!selectedImages?.length) {
            return true;
        }
        return false;
    }, [selectedImages]);

    // const validPDF = useMemo(() => {
    //     if (PDF && PDF.size < 512000) {
    //         return true;
    //     }
    //     if (!PDF) {
    //         return true;
    //     }
    //     return false;
    // }, [PDF]);

    const initialValues = useMemo(() => {
        let datetime = '';
        if (eventInfo?.datetime) {
            const now = new Date(eventInfo?.datetime);

            const day = ('0' + now.getDate()).slice(-2);
            const month = ('0' + (now.getMonth() + 1)).slice(-2);

            datetime = now.getFullYear() + '-' + month + '-' + day;
        }
        return {
            title: eventInfo?.title ?? '',
            datetime: datetime ?? '',
            description: eventInfo?.description ?? '',
            companies: eventInfo?.companies ?? '',
        };
    }, [eventInfo]);

    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues,
        onSubmit: async (values) => {
            setIsLoading(true);
            // if (selectedImages === null) {
            //     setIsLoading(false);
            //     return;
            // }
            const formData = new FormData();
            if (selectedImages !== null && selectedImages[0]) {
                formData.append('image', selectedImages[0]);
            }

            const dateArr = formik.values.datetime;
            formData.append('title', formik.values.title);
            formData.append('datetime', dateArr);
            formData.append('format', eventFormat?.value || '');
            formData.append('address', adress?.value || '');
            formData.append(
                'description',
                formik.values.description.replace('↵', '\n'),
            );
            formData.append('companies', formik.values.companies);
            if (PDF) {
                formData.append('pdf', PDF);
            }
            await axios
                .put(
                    `${process.env.REACT_APP_BASE_URL}api/v1/event/${eventId}/`,
                    formData,
                )
                .then(() => {
                    toast.success('Мероприятие успешно обновлено!');
                    formik.resetForm();
                    setSelectedImages(null);
                    setPDF(null);
                    setIsLoading(false);
                    window.history.go(-1);
                })
                .catch(() => {
                    toast.error('При обновлении события произошла ошибка');
                    setIsLoading(false);
                });

            setIsLoading(false);
        },
        validationSchema: validationSchema,
    });

    useEffect(() => {
        axios
            .get(eventUrl)
            .then((response) => {
                setEventInfo(response?.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [eventUrl]);

    useEffect(() => {
        if (eventInfo) {
            setAdress({ value: eventInfo.address, label: eventInfo.address });
            setEventFormat({
                value: eventInfo.format === 'ON' ? 'ON' : 'OF',
                label: eventInfo.format === 'ON' ? 'Онлайн' : 'Офлайн',
            });
        }
    }, [eventInfo]);

    return (
        <form onSubmit={formik.handleSubmit} className={styles.addevent}>
            <AddNewsHeader
                dirty={formik.dirty}
                isValid={formik.isValid && validImage}
                isLoading={isLoading}
                onSubmit={formik.handleSubmit}
                resetForm={() => {
                    formik.resetForm();
                    window.history.go(-1);
                }}
                title={'Обновить мероприятие'}
            />
            <AddEventImage
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                showImage={eventInfo?.image}
                showUpdateButton={false}
            />
            {!validImage ? (
                <span className={styles.validImage}>
                    Максимальный размер изображения 500Кб
                </span>
            ) : null}
            <div className={styles.addevent__content}>
                <Input
                    wrapperClassName={[styles.addevent_first]}
                    placeholder="Мероприятие"
                    inputHeader={'Название мероприятия'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    name={'title'}
                    errorMessage={formik.errors.title}
                    isValid={
                        formik.touched &&
                        !formik.errors.title &&
                        !!formik.values.title
                    }
                />
                <DatePicker
                    wrapperClassName={[styles.addevent_second]}
                    placeholder="02.03.2023"
                    headerText={`Дата`}
                    headerClassName={styles.input__title}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.datetime}
                    name={'datetime'}
                    errorMessage={formik.errors.datetime}
                    isValid={
                        formik.touched &&
                        !formik.errors.datetime &&
                        !!formik.values.datetime
                    }
                />
                <Input
                    wrapperClassName={[styles.addevent_third]}
                    placeholder="Партнеры"
                    inputHeader={'Партнеры'}
                    headerClassName={styles.input__title}
                    onChange={(e) => {
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.companies}
                    name={'companies'}
                    errorMessage={formik.errors.companies}
                    isValid={
                        formik.touched &&
                        !formik.errors.companies &&
                        !!formik.values.companies
                    }
                />
                <CustomSelect
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleAdressChange(e);
                    }}
                    value={adress}
                    state={adress}
                    className={cn(styles.select, styles.addevent_fourth)}
                    titleClass={styles.select__header}
                    inputFieldClass={styles.select__field}
                    title={`Место проведения`}
                    borderRadius={'17px'}
                    fieldHeight={'55px'}
                    placeholderSize={'15px'}
                    name={'adress'}
                    options={[
                        {
                            value: 'Юридический факультет МГУ',
                            label: 'Юридический факультет МГУ',
                        },
                        {
                            value: 'Сторонняя организация',
                            label: 'Сторонняя организация',
                        },
                    ]}
                    isValid={Boolean(adress)}
                    errorMessage={adress ? '' : 'Поле обязательное'}
                />
                <CustomSelect
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleFormatChange(e);
                        // formikProps.setFieldValue(`format`, e.target.value);
                    }}
                    value={eventFormat}
                    state={eventFormat}
                    className={cn(styles.select, styles.addevent_fifth)}
                    titleClass={styles.select__header}
                    inputFieldClass={styles.select__field}
                    title={`Формат мероприятия`}
                    placeholder={'Формат мероприятия'}
                    borderRadius={'17px'}
                    fieldHeight={'55px'}
                    placeholderSize={'15px'}
                    name={'format'}
                    options={[
                        {
                            value: 'ON',
                            label: 'Онлайн',
                        },
                        {
                            value: 'OF',
                            label: 'Офлайн',
                        },
                    ]}
                    isValid={Boolean(eventFormat)}
                    errorMessage={eventFormat ? '' : 'Поле обязательное'}
                />

                <Textarea
                    wrapperClassName={styles.addevent_sixth}
                    title={'Описание'}
                    headerClassName={styles.input__title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    name={'description'}
                    errorMessage={formik.errors.description}
                    isValid={
                        formik.touched &&
                        !formik.errors.description &&
                        !!formik.values.description
                    }
                />
            </div>
            {/* <PDFUploader PDF={PDF} setPDF={setPDF} />
            {!validPDF ? (
                <span className={styles.validImage}>
                    Максимальный размер файла 500Кб
                </span>
            ) : null} */}
        </form>
    );
};
