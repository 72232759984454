import cn from 'classnames';
import { useFormik } from 'formik';
import { SetStateAction, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Button from '../../UI/Button/Button';
import { Checkbox } from '../../UI/Checkbox/Checkbox';
import { Input } from '../../UI/Input/Input';
import { PasswordInput } from '../../UI/PasswordInput/PasswordInput';
import { roleNavigate } from '../../helpers/roleNavigate';
import { scrollAvailable } from '../../helpers/scrollToggler';
import { useAppDispatch } from '../../hooks/reduxHooks/useAppDispatch';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';
import { authCompany, authStudentStaff } from '../../services/Auth';
import { LogoutUser } from '../../services/Logout';
import { Role } from '../../types/types';
import { Modal } from '../Modal/Modal';
import styles from './Login.module.scss';

export const Login = () => {
    return (
        <Modal>
            <LoginFormLayout />
        </Modal>
    );
};

const LoginFormLayout = () => {
    const [character, setСharacter] = useState<Role>(Role.candidate);
    const location = useLocation();
    const isLoginPopupOpen = location.search.includes('login');
    return (
        <div className={styles.formlayout}>
            <div className={styles.formlayout__wrapper}>
                <h4 className={styles.formlayout__header}>
                    {isLoginPopupOpen ? 'Авторизоваться' : 'Создать профиль'}
                </h4>
                <p className={styles.formlayout__description}>
                    Для использования всех функций сайта выберите, к какой
                    категории пользователей вы относитесь
                </p>

                <CharacterToggler
                    character={character}
                    setСharacter={setСharacter}
                />

                <div className={styles.form}>
                    <LoginForm
                        character={character}
                        setCharacter={setСharacter}
                    />
                </div>
                <div className={styles.formlayout__footer}>
                    {!isLoginPopupOpen ? (
                        <>
                            <span className={styles.formlayout__footer_text}>
                                Уже есть профиль?
                            </span>
                            <Link
                                className={styles.formlayout__footer_button}
                                to={{ search: '?login=true' }}
                            >
                                Войти
                            </Link>
                        </>
                    ) : (
                        <>
                            <span className={styles.formlayout__footer_text}>
                                Еще нет профиля?
                            </span>
                            <Link
                                className={styles.formlayout__footer_button}
                                to={{ search: '?register=true' }}
                            >
                                Зарегистрироваться
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const CharacterToggler = ({
    character,
    setСharacter,
}: CharacterTogglerProps) => {
    return (
        <div className={styles.formlayout__chooserole}>
            <Button
                isDisabled={false}
                onClick={() => setСharacter(Role.candidate)}
                className={cn(styles.formlayout__student, {
                    [styles.formlayout__active]: character === Role.candidate,
                })}
            >
                Студент
            </Button>
            <Button
                isDisabled={false}
                onClick={() => setСharacter(Role.organization)}
                className={cn(styles.formlayout__organization, {
                    [styles.formlayout__active]:
                        character === Role.organization,
                })}
            >
                Организация
            </Button>
        </div>
    );
};

interface CharacterTogglerProps {
    character: Role;
    setСharacter: any;
}

const LoginForm = ({ character, setCharacter }: LoginFormProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values) => {
            try {
                if (character === Role.candidate || character === Role.staff) {
                    await dispatch(authStudentStaff(formik.values));
                } else {
                    await dispatch(authCompany(formik.values));
                }
                const localStorageRole = localStorage.getItem('role');
                if (localStorageRole === Role.candidate) {
                    const flag = localStorage.getItem('isVerified');
                    const isVerified = JSON.parse(flag !== null ? flag : '');
                    if (isVerified) {
                        if (location.search.includes('login_vacancy=all')) {
                            navigate(PATH_DASHBOARD.student.vacancies.root);
                            scrollAvailable();
                            return 0;
                        }
                        if (location.search.includes('login_vacancy')) {
                            navigate(
                                `${PATH_DASHBOARD.student.vacancies.retrieve.replace(
                                    ':id',
                                    '',
                                )}${location.search.replace(
                                    '?login_vacancy=',
                                    '',
                                )}`,
                            );
                            scrollAvailable();
                            return 0;
                        }
                        // редирект на личный кабинет
                        navigate(roleNavigate());
                        scrollAvailable();
                    } else {
                        // выход из личного кабинета
                        LogoutUser(dispatch);
                        toast.warn('Дождитесь подтверждения учетной записи');
                    }
                }
                if (localStorageRole === Role.staff) {
                    navigate(roleNavigate());
                    scrollAvailable();
                }
                if (localStorageRole === Role.organization) {
                    const flag = localStorage.getItem('isVerified');
                    const isVerified = JSON.parse(flag !== null ? flag : '');
                    if (isVerified) {
                        // редирект на личный кабинет
                        navigate(roleNavigate());
                        scrollAvailable();
                    } else {
                        // выход из личного кабинета
                        LogoutUser(dispatch);
                        toast.warn('Дождитесь подтверждения учетной записи');
                    }
                }
            } catch {
                toast.error('Неправильный логин/пароль');
            }
        },

        validationSchema: yup.object().shape({
            email: yup
                .string()
                .matches(
                    /([A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[A-Za-z0-9-]+(\.[A-Z|a-z]{2,})+/,
                    'Некорректный email',
                )
                .required('Поле обязательно')
                .max(35, 'Логин не может быть больше 35 символов'),
            password: yup
                .string()
                .required('Поле обязательно')
                .max(35, 'Пароль не может быть больше 35 символов')
                .oneOf([yup.ref('password')], 'Пароли не совпадают')
                .matches(
                    /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
                    'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
                ),
        }),
    });

    return (
        <form onSubmit={formik.handleSubmit} className={styles.inputs__form}>
            <div className={styles.inputs__wrapper}>
                <Input
                    placeholder={'Логин'}
                    wrapperClassName={[styles.input]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={'email'}
                    value={formik.values.email}
                    errorMessage={formik.errors.email}
                />
                <PasswordInput
                    wrapperClassName={[styles.input]}
                    headerClassName={styles.input__header}
                    fieldClassName={styles.input__field}
                    placeholder={'Пароль'}
                    name={'password'}
                    errorMessage={formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                />
            </div>
            <Checkbox text={'Запомнить пароль'} />
            <div className={styles.inputs__buttons}>
                <button
                    className={cn(styles.inputs__button, {
                        [styles.inputs__button_valid]:
                            formik.dirty && formik.isValid,
                    })}
                    disabled={!formik.isValid}
                    type="submit"
                >
                    Войти
                </button>
            </div>
        </form>
    );
};

interface LoginFormProps {
    character?: Role;
    setCharacter?: React.Dispatch<SetStateAction<Role>>;
}
