// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsList_news__FobwW {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 60px;\n  padding: 60px;\n}\n@media (max-width: 800px) {\n  .NewsList_news__FobwW {\n    padding: 20px;\n  }\n}\n\n.NewsList_newsList__0XVSb {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 20px;\n}\n@media (max-width: 1000px) {\n  .NewsList_newsList__0XVSb {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n@media (max-width: 800px) {\n  .NewsList_newsList__0XVSb {\n    grid-template-columns: 1fr;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/StaffPages/News/NewsList/NewsList.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;AACJ;AAAI;EANJ;IAOQ,aAAA;EAGN;AACF;;AADA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;AAIJ;AAHI;EAJJ;IAKQ,qCAAA;EAMN;AACF;AALI;EAPJ;IAQQ,0BAAA;EAQN;AACF","sourcesContent":[".news {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 60px;\n    padding: 60px;\n    @media (max-width: 800px) {\n        padding: 20px;\n    }\n}\n.newsList {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n    @media (max-width: 1000px) {\n        grid-template-columns: repeat(2, 1fr);\n    }\n    @media (max-width: 800px) {\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news": "NewsList_news__FobwW",
	"newsList": "NewsList_newsList__0XVSb"
};
export default ___CSS_LOADER_EXPORT___;
