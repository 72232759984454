import { useEffect, useMemo, useState } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useAppDispatch } from '../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/reduxHooks/useAppSelector';
import {
    getCompanyList,
    getVacancyList,
    updateReactionListPage,
} from '../../../store/reducers/StaffReactionSlice';
import Pagination from '../../StaffPages/SupportList/Pagination';
import styles from './ReactionList.module.scss';

import { useNavigate } from 'react-router-dom';
import Button from '../../../UI/Button/Button';
import { CustomSelect } from '../../../UI/CustomSelect/CustomSelect';
import {
    CandidateListItemType,
    VacancyListItemType,
    getCompanyReactionList,
    getReactionList,
    getVacancyReactionList,
} from '../../../store/reducers/StaffReactionSlice';

type StaffReactionItemProps = {
    candidate: CandidateListItemType;
    vacancy: VacancyListItemType;
    comment: string;
};
export const StaffReactionItem: React.FC<StaffReactionItemProps> = ({
    vacancy,
    candidate,
    comment,
}) => {
    const [showComment, setShowComment] = useState(false);
    const navigate = useNavigate();

    const showCommentButton = useMemo(() => {
        if (comment.length > 250) {
            return true;
        }
        return false;
    }, [comment]);

    const modifiedComment = useMemo(() => {
        if (!showCommentButton) {
            return comment;
        }
        return showComment ? comment : `${comment.slice(0, 250)}...`;
    }, [comment, showComment, showCommentButton]);

    return (
        <div className={styles.companyReactionItem}>
            <div className={styles.imageWrapper}>
                {candidate.photo ? (
                    <img src={candidate.photo} alt={candidate.second_name} />
                ) : null}
            </div>
            <div className={styles.content}>
                <div className={styles.contentTitle}>
                    <div className={styles.reactionWrapper}>
                        <h3 className={styles.name}>
                            {candidate.second_name || ''}{' '}
                            {candidate.first_name || ''}{' '}
                            {candidate.last_name || ''}
                        </h3>
                        <span className={styles.reaction}>
                            отклик на вакансию компании "{vacancy.company_title}
                            "
                        </span>
                        <span className={styles.position}>
                            {vacancy.position}
                        </span>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div>
                            <Button
                                className={styles.vacancyButton}
                                onClick={() => {
                                    navigate(
                                        `/staff/students_list/${candidate.id}`,
                                    );
                                }}
                            >
                                Подробнее
                            </Button>
                        </div>
                        <span className={styles.updateDate}>
                            {new Date(vacancy.date_update).toLocaleDateString(
                                'ru-RU',
                                {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                },
                            )}
                        </span>
                    </div>
                </div>
                {comment ? (
                    <div className={styles.comment}>
                        <span>Сопроводительное письмо</span>
                        <p>{modifiedComment}</p>
                        {showCommentButton ? (
                            <button
                                onClick={() =>
                                    setShowComment((value) => !value)
                                }
                            >
                                {showComment
                                    ? 'Скрыть письмо'
                                    : 'Посмотреть письмо'}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export const StaffReactionList = () => {
    const dispatch = useAppDispatch();

    // const [search, setSearch] = useState('');
    // const debouncedSearch = useDebounceCallBack(setSearch, 1000);

    const {
        reactionList,
        reactionListIsLoading,
        reactionListPage,
        vacancyList,
        vacancyListIsLoading,
        companyList,
        companyListIsLoading,
    } = useAppSelector((state) => state.staffReactionReducer);

    const [vacancy, setVacancy] = useState<{ value: string; label: string }>({
        value: '0',
        label: 'Все',
    });

    const [company, setCompany] = useState<{ value: string; label: string }>({
        value: '0',
        label: 'Все',
    });

    const refetchStaffReactionList = () => {
        if (company.value !== '0' && vacancy.value !== '0') {
            return dispatch(
                getVacancyReactionList({
                    page: reactionListPage,
                    size: 10,
                    id: vacancy.value,
                }),
            );
        }
        if (company.value !== '0') {
            return dispatch(
                getCompanyReactionList({
                    page: reactionListPage,
                    size: 10,
                    id: company.value,
                }),
            );
        }
        return dispatch(
            getReactionList({
                page: reactionListPage,
                size: 10,
            }),
        );
    };
    const optionsVacancy = useMemo(() => {
        if (vacancyList) {
            const vacancies = vacancyList?.objects.map((item) => ({
                value: String(item.id),
                label: item.position,
            }));

            return [...vacancies, { value: '0', label: 'Все' }];
        }
        return [];
    }, [vacancyList]);

    const optionsCompany = useMemo(() => {
        if (companyList) {
            const companies = companyList?.objects.map((item) => ({
                value: String(item.id),
                label: item.full_title,
            }));

            return [...companies, { value: '0', label: 'Все' }];
        }
        return [];
    }, [companyList]);

    useEffect(() => {
        dispatch(
            getReactionList({
                page: reactionListPage,
                size: 10,
            }),
        );
        dispatch(getCompanyList({ page: 1, size: 1000 }));
        dispatch(updateReactionListPage(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (company && company.value !== '0') {
            dispatch(
                getVacancyList({
                    page: 1,
                    size: 1000,
                    id: company.value,
                }),
            );
        } else {
            setVacancy({ value: '0', label: 'Все' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        refetchStaffReactionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactionListPage]);

    return (
        <div className={styles.companyVacancyList}>
            <div className={styles.companyVacancyListGrid}>
                {reactionListIsLoading ? <Loader /> : null}

                {!reactionListIsLoading &&
                reactionList?.objects.length === 0 ? (
                    <span className={styles.notificationText}>
                        {vacancy?.label
                            ? `Откликов на вакансию "${vacancy.label}" пока нет!`
                            : 'Откликов пока нет!'}
                    </span>
                ) : null}

                {!reactionListIsLoading &&
                    reactionList &&
                    reactionList.objects.map((item) => (
                        <StaffReactionItem
                            key={`vacancy-${item.id}`}
                            vacancy={item.vacancy}
                            candidate={item.candidate}
                            comment={item.comment}
                        />
                    ))}

                <Pagination
                    list={reactionList}
                    handlePagination={(p) => {
                        dispatch(updateReactionListPage(p));
                    }}
                    isLoading={reactionListIsLoading}
                />
            </div>

            <div className={styles.companyVacancyTitle}>
                <div className={styles.findWrapper}>
                    {/* <Input
                        placeholder={'Поиск студента'}
                        onChange={(e) => debouncedSearch(e.target.value)}
                    /> */}
                    <CustomSelect
                        onChange={(e: { value: string; label: string }) => {
                            setCompany(e);
                        }}
                        isDisabled={
                            !optionsCompany ||
                            companyListIsLoading ||
                            !companyList
                        }
                        value={company}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        placeholder={'Компания'}
                        options={optionsCompany}
                        isSearchable={false}
                        title="Компания"
                    />
                    <CustomSelect
                        onChange={(e: { value: string; label: string }) => {
                            setVacancy(e);
                        }}
                        isDisabled={
                            !optionsVacancy ||
                            vacancyListIsLoading ||
                            !vacancyList ||
                            company.value === '0'
                        }
                        value={vacancy}
                        borderRadius={'17px'}
                        fieldHeight={'55px'}
                        placeholderSize={'15px'}
                        placeholder={'Вакансия'}
                        options={optionsVacancy}
                        isSearchable={false}
                        title="Вакансия"
                    />
                    <Button
                        className={styles.button__accept}
                        onClick={() => {
                            dispatch(updateReactionListPage(1));
                            refetchStaffReactionList();
                        }}
                    >
                        Применить
                    </Button>
                    <Button
                        className={styles.button__reset}
                        onClick={() => {
                            setVacancy({ value: '0', label: 'Все' });
                            setCompany({ value: '0', label: 'Все' });
                            dispatch(updateReactionListPage(1));
                            dispatch(
                                getReactionList({
                                    page: reactionListPage,
                                    size: 10,
                                }),
                            );
                        }}
                    >
                        Сбросить фильтры
                    </Button>
                </div>
            </div>
        </div>
    );
};
