import axios from 'axios';
import { useEffect, useState } from 'react';
import { INewsCard } from '../types/types';

export const useFetchNews = (page: number = 1, size: number = 10) => {
    const [news, setNews] = useState<INewsCard[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>();
    const newsUrl = `${process.env.REACT_APP_BASE_URL}api/v1/news/?page=${page}&size=${size}`;

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(newsUrl)
            .then((response) => {
                setTotalCount(response.data.page_count);
                setNews(response.data.objects);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false));
    }, []);

    return { isLoading, news, totalCount };
};
