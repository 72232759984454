// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_button__sMVh4 {\n  border: 1px solid rgba(131, 131, 136, 0.5);\n  padding: 12px 21px;\n  font: var(--font-m);\n  font-weight: var(--font-weight-medium);\n  background-color: inherit;\n  border-radius: 17px;\n}\n.Button_button__sMVh4:disabled {\n  opacity: 50%;\n  pointer-events: none;\n}\n\n.Button_addbutton__by3TP {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n  background: #F5F8FF;\n  border: none;\n  max-width: 150px;\n  padding: 15px;\n  border-radius: 17px;\n  cursor: pointer;\n}\n.Button_addbutton__text__mFx\\+n {\n  font: var(--font-s);\n  font-weight: var(--font-weight-medium);\n  color: #11139F;\n}", "",{"version":3,"sources":["webpack://./src/UI/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,kBAAA;EACA,mBAAA;EACA,sCAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,oBAAA;AACR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AACI;EACI,mBAAA;EACA,sCAAA;EACA,cAAA;AACR","sourcesContent":[".button{\n    border: 1px solid rgba(131, 131, 136, 0.5); \n    padding: 12px 21px;\n    font: var(--font-m);\n    font-weight: var(--font-weight-medium);\n    background-color: inherit;\n    border-radius: 17px;\n\n    &:disabled {\n        opacity: 50%;\n        pointer-events: none;\n    }\n}\n\n.addbutton{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 15px;\n    background: #F5F8FF;\n    border: none;\n    max-width: 150px;\n    padding: 15px;\n    border-radius: 17px;\n    cursor: pointer;\n    &__text{\n        font: var(--font-s);\n        font-weight: var(--font-weight-medium);\n        color: #11139F;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button_button__sMVh4",
	"addbutton": "Button_addbutton__by3TP",
	"addbutton__text": "Button_addbutton__text__mFx+n"
};
export default ___CSS_LOADER_EXPORT___;
