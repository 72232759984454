import * as yup from 'yup';

export const validationSchema = yup.object().shape({
    current_password: yup
        .string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
            'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
        )
        .required('Поле обязательно')
        .max(35, 'Пароль не может быть больше 35 символов'),
    new_password: yup
        .string()
        .matches(
            /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
            'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
        )
        .required('Поле обязательно')
        .max(35, 'Пароль не может быть больше 35 символов'),
    repeat_new_password: yup
        .string()
        .required('Поле обязательно')
        .max(35, 'Пароль не может быть больше 35 символов')
        .oneOf([yup.ref('new_password')], 'Пароли не совпадают')
        .matches(
            /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
            'Пароль должен состоять минимум из 6 латинских символов, хотя бы одной заглавной буквы и одной цифры',
        ),
});
