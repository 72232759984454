export function useGetAge(dateString?: string | null) {
    if (!dateString) {
        return null;
    }
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    function declination(number: number, titles: string[]) {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[
            number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
        ];
    }

    const modifiedAge = `${age} ${declination(age, [' год', ' года', ' лет'])}`;

    return modifiedAge;
}
