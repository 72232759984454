import styles from "./PageHeader.module.scss";
import cn from "classnames";

interface PageHeaderProps {
	title: string;
	description?: string;
	className?: string;
}

export const PageHeader = ({
	title,
	description,
	className,
}: PageHeaderProps) => {
	return (
		<div className={cn(styles.pageheader, className)}>
			<h2 className={styles.pageheader__title}>{title}</h2>
			{description && (
				<p className={styles.pageheader__description}>{description}</p>
			)}
		</div>
	);
};
