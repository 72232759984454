// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditSkills_SkillArea__aCGsF {\n  width: 90%;\n}", "",{"version":3,"sources":["webpack://./src/pages/StudentPages/Resume/EditSkills/EditSkills.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ","sourcesContent":[".SkillArea{\n    width:90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SkillArea": "EditSkills_SkillArea__aCGsF"
};
export default ___CSS_LOADER_EXPORT___;
