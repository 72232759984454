import ReactSlider from 'react-slider';
import styles from './RangeInput.module.scss';
import './slider.scss';

interface RangeInputProps {
    title?: string;
    min: number; // минимум ползунка
    max: number; // максимум ползунка

    changeRange?: (range: [number, number]) => void; // смена текущих значений массива начало/конец ползунка

    startValue: number;
    endValue: number;

    changeStart: (value: string) => void;
    changeEnd: (value: string) => void;
}

enum InputType {
    FROM = 'from',
    TO = 'to',
}

export const RangeInput = ({
    title,
    min,
    max,
    changeRange,
    startValue,
    endValue,
    changeStart,
    changeEnd,
}: RangeInputProps) => {
    return (
        <div className={styles.range}>
            <h3 className={styles.range__title}>{title}</h3>
            <div className={styles.scale}>
                <span className={styles.scale__element}>{min}</span>
                <span className={styles.scale__element}>{max}</span>
            </div>
            <div className={styles.scale__slider}>
                <ReactSlider
                    className="slider"
                    onChange={changeRange}
                    value={[startValue, endValue]}
                    min={min}
                    max={max}
                />
            </div>
            <div className={styles.range__inputs}>
                <Input
                    type={InputType.FROM}
                    value={String(startValue)}
                    changeStart={changeStart}
                />
                <Input
                    type={InputType.TO}
                    value={String(endValue)}
                    changeEnd={changeEnd}
                />
            </div>
        </div>
    );
};

interface InputProps {
    type: InputType;
    value: string;
    onChange?: (value: string) => void;
    changeStart?: (value: string) => void;
    changeEnd?: (value: string) => void;
}

const Input = ({ type, value, changeStart, changeEnd }: InputProps) => {
    return (
        <div className={styles.input}>
            <span className={styles.input__text}>
                {type === InputType.FROM ? 'От' : 'До'}
            </span>
            <input
                className={styles.input__inner}
                value={value}
                onChange={(e) => {
                    if (type === InputType.FROM) {
                        changeStart && changeStart(e.target.value);
                        return;
                    }
                    changeEnd && changeEnd(e.target.value);
                }}
            ></input>
        </div>
    );
};
