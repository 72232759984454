import styles from './EventsPage.module.scss';

import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageLayout } from '../../../components/PageLayout/PageLayout';
import { PagePagination } from '../../../components/PagePagination/PagePagination';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { EventCard } from '../../../components/EventCard/EventCard';
import { EventsFilterWidget } from '../../../components/EventsFilterWidget/EventsFilterWidget';
import { Loader } from '../../../components/Loader/Loader';
import { DropdownOption, IEventCard } from '../../../types/types';
import Pagination from '../../StaffPages/SupportList/Pagination';

export const EventsPage = () => {
    const [events, setEvents] = useState<IEventCard[] | null>(null); // стэйт с новостями

    //пагинация
    const [page, setPage] = useState<number>(1); // стэйт - текущая страница в пагинации
    const [paginationArr, setPaginationArr] = useState<number>(1); // максимальное количество страниц

    //индикатор загрузки
    const [isLoading, setIsLoading] = useState<boolean>(false); //изначально нет загрузки

    //фильтры
    const initialState = {
        value: 'all',
        label: 'Все',
    };

    const [format, setFormat] = useState<DropdownOption>(initialState); // стэйт фильтрации по формату
    const [place, setPlace] = useState<DropdownOption>(initialState); // стэйт фильтрации по месту
    const [searchValue, setSearchValue] = useState<string>(''); // стэйт для поисковой строки

    const eventsUrl = `${
        process.env.REACT_APP_BASE_URL
    }api/v1/event/?page=${page}&size=10${
        format.value ? `&event_format=${format.value}` : ''
    }${place.value ? `&location=${place.value}` : ''}${
        searchValue !== '' ? `&search_query=${searchValue}` : ''
    }`;

    useEffect(() => {
        setIsLoading(true); // загрузка началась
        axios
            .get(eventsUrl)
            .then((response) => {
                const pagArray = response.data.page_count;
                setPaginationArr(pagArray); // массив булетов для отрисовки
                setEvents(response.data.objects); // данные по карточкам
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setIsLoading(false)); // загрузка закончена
    }, [page, format, place, searchValue]);

    return (
        <PageLayout>
            <div className={styles.eventspage}>
                <div className={styles.eventspage__wrapper}>
                    <PagePagination linkName={'Мероприятия'} />
                    <PageHeader title={'Мероприятия'} />
                    <div className={styles.eventspage__container}>
                        <EventsFilterWidget
                            setFormat={setFormat}
                            format={format}
                            setPlace={setPlace}
                            place={place}
                            setSearchValue={setSearchValue}
                        />
                        {isLoading ? (
                            <div className={styles.eventspage__loader}>
                                <Loader />
                            </div>
                        ) : (
                            <div className={styles.eventspage__cards}>
                                {events && <EventsList events={events} />}
                            </div>
                        )}

                        <Pagination
                            list={{
                                page_count: paginationArr,
                                current_page: page,
                            }}
                            handlePagination={(p) => {
                                setPage(p);
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};

interface EventsListProps {
    events: IEventCard[];
}
export const EventsList = ({ events }: EventsListProps) => {
    return (
        <div className={styles.EventsList}>
            {events.map((event) => (
                <EventCard event={event} key={event.id} />
            ))}
        </div>
    );
};
