import { useEffect } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import {
    getNewsList,
    updateNewsListPage,
} from '../../../../store/reducers/StaffNewsSlice';
import { Card as NewsItem } from '../../../MainPages/News/NewsPage';
import Pagination from '../../SupportList/Pagination';
import styles from './NewsList.module.scss';
export const NewsList = () => {
    const dispatch = useAppDispatch();
    const { newsList, newsListPage, newsListIsLoading } = useAppSelector(
        (state) => state.newsReducer,
    );

    useEffect(() => {
        dispatch(
            getNewsList({
                page: newsListPage,
                size: 10,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsListPage]);

    return (
        <div className={styles.news}>
            {newsListIsLoading ? <Loader /> : null}

            {!newsListIsLoading && newsList?.objects.length === 0 ? (
                <span className={styles.notificationText}>
                    Новостей пока нет!
                </span>
            ) : null}

            <div className={styles.newsList}>
                {!newsListIsLoading &&
                    newsList?.objects.map((item) => (
                        <NewsItem key={`news_item_${item.id}`} {...item} />
                    ))}
            </div>

            <Pagination
                list={newsList}
                handlePagination={(p) => {
                    dispatch(updateNewsListPage(p));
                }}
                isLoading={newsListIsLoading}
            />
        </div>
    );
};
