import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/RouterConfig';

//хок ограничивает возможность попадания на разные роуты разными ролями
export const RoleCheck = ({ children }: RoleCheckProps) => {
    const { pathname } = useLocation();
    const role = localStorage.getItem('role');
    const navigate = useNavigate();

    useEffect(() => {
        if (role && !pathname.includes(String(role))) {
            navigate(PATH_DASHBOARD.root);
        }
    }, []);

    return <>{children}</>;
};

interface RoleCheckProps {
    children: React.ReactNode;
}
