// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Location_location__-5HxP {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  font: var(--font-s);\n  font-weight: var(--font-weight-medium);\n}", "",{"version":3,"sources":["webpack://./src/components/Location/Location.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EAEA,mBAAA;EACA,sCAAA;AAAJ","sourcesContent":[".location{\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    \n    font: var(--font-s);\n    font-weight: var(--font-weight-medium);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location": "Location_location__-5HxP"
};
export default ___CSS_LOADER_EXPORT___;
