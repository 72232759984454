import { useMemo, useRef } from 'react';
import { Autoplay, Grid, Scrollbar, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../UI/Button/Button';
import { LeftArrow, RightArrow, RightArrowIcon } from '../../assets/icons';

import { useNavigate } from 'react-router-dom';
import 'swiper/css/grid';
import 'swiper/css/scrollbar';
import { useFetchNews } from '../../hooks/useFetchNews';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { Loader } from '../Loader/Loader';
import styles from './IntroSlider.module.scss';
import './slider.scss';

export const IntroSlider = () => {
    const swiperRef = useRef<SwiperType>();
    const windowWidth = useWindowWidth() || 900;
    const PAGE = 1;
    const COUNT_OF_SLIDES = 8;
    const { isLoading, news } = useFetchNews(PAGE, COUNT_OF_SLIDES);

    const showNavButtons = useMemo(() => {
        if (news && news.length > 2 && windowWidth >= 1000) {
            return true;
        }
        if (news && news.length > 1 && windowWidth < 1000) {
            return true;
        }
        return false;
    }, [windowWidth, news]);
    return (
        <div className={styles.introslider}>
            <div className={styles.introslider__slides}>
                <Swiper
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    scrollbar={{
                        hide: false,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    slidesPerView={windowWidth < 1000 ? 1 : 2}
                    spaceBetween={1}
                    className="mySwiper"
                    modules={[Grid, Autoplay, Scrollbar]}
                >
                    {isLoading ? (
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    ) : (
                        news &&
                        news.map((slide) => (
                            <SwiperSlide key={slide.id}>
                                <IntroSlide
                                    title={slide.title}
                                    id={slide.id}
                                    image={slide?.images[0]?.path}
                                />
                            </SwiperSlide>
                        ))
                    )}
                </Swiper>
            </div>
            {showNavButtons ? (
                <div className={styles.introslider__tools}>
                    <div className={styles.introslider__loader}></div>
                    <div className={styles.introslider__buttons}>
                        <button
                            className={styles.introslider__button}
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <LeftArrow />
                        </button>
                        <button
                            className={styles.introslider__button}
                            onClick={() => swiperRef.current?.slideNext()}
                        >
                            <RightArrow />
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const IntroSlide = ({ title, id, image }: IntroSlideProps) => {
    const navigate = useNavigate();
    return (
        <div className={styles.introslide}>
            {image ? <img src={image} alt="" /> : null}
            <div className={styles.introslide__wrapper}>
                <h2 className={styles.introslide__header}>{title}</h2>
                <div
                    className={styles.introslide__content}
                    onClick={() => navigate(`/news/${id}`)}
                >
                    <span className={styles.introslide__text}>К новости</span>
                    <Button className={styles.introslide__button}>
                        <div className={styles.introslide__button_wrapper}>
                            <RightArrowIcon />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

interface IntroSlideProps {
    title: string;
    id: number;
    image?: string;
}
