import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Experience } from '../../types/types';

const initialState: EducationState = {
    isLoading: false,
    error: null,
    experiances: null,
};

interface EducationState {
    isLoading: boolean;
    experiances: Experience[] | null;
    error: any;
}

export const fetchExperience = createAsyncThunk(
    'experience/fetchExperience',
    async function (id: number, { rejectWithValue }) {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}api/v1/candidate/${id}/experience/`;
            const data = await axios.get(url);
            return data.data.objects;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const experianceSlice = createSlice({
    name: 'experience',
    initialState,
    reducers: {
        clearExperiences(state) {
            state.experiances = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchExperience.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchExperience.fulfilled, (state, action) => {
            state.isLoading = false;
            state.experiances = action.payload;
        });
        builder.addCase(fetchExperience.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default experianceSlice.reducer;
