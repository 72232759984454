import { RightArrowIcon } from '../../../../../assets/icons';
import photo from '../../../../../assets/images/IntroBannerPhoto.png';
import styles from './IntroSection.module.scss';

export const IntroSection = () => {
    return (
        <div className={styles.intro}>
            <div className={styles.banner}>
                <div className={styles.banner__content}>
                    <h1 className={styles.banner__header}>
                        Центр карьеры Юридического факультета МГУ
                    </h1>
                    <p className={styles.banner__description}>
                        содействие занятости и трудоустройству студентов и
                        выпускников Юридического факультета МГУ
                    </p>
                    <a className={styles.banner__button} href="#vacancies">
                        <span>Смотреть вакансии</span>
                        <RightArrowIcon />
                    </a>
                </div>
                <div className={styles.banner__photos}>
                    <img
                        className={styles.banner__photo}
                        src={photo}
                        alt="banner"
                    />
                </div>
            </div>
        </div>
    );
};
