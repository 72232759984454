import cn from 'classnames';
import Button from '../../UI/Button/Button';
import styles from './AddNewsHeader.module.scss';

export const AddNewsHeader = ({
    dirty,
    isValid,
    onSubmit,
    resetForm,
    title,
    extraCondition,
    isLoading = false,
}: AddNewsHeaderProps) => {
    const disabledButton = !isValid && !dirty;
    return (
        <div className={styles.header}>
            <div className={styles.header__wrapper}>
                <h2 className={styles.header__text}>{title}</h2>
                <div className={styles.header__buttons}>
                    <Button
                        className={cn(
                            styles.header__button,
                            styles.header__button_save,
                            {
                                [styles.header__button_valid]:
                                    dirty &&
                                    isValid &&
                                    extraCondition &&
                                    !isLoading,
                            },
                        )}
                        isDisabled={disabledButton || isLoading}
                        onClick={onSubmit}
                        buttonType={'submit'}
                    >
                        Сохранить и опубликовать
                    </Button>
                    <Button
                        className={cn(
                            styles.header__button,
                            styles.header__button_cancel,
                        )}
                        onClick={resetForm}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
};

interface AddNewsHeaderProps {
    dirty: boolean;
    isValid: boolean;
    onSubmit: () => void;
    resetForm: () => void;
    title: string;
    extraCondition?: boolean | null;
    isLoading?: boolean;
}
