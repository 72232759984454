import styles from './StudentProfile.module.scss';

import { useEffect } from 'react';
import { fetchStudentInfo } from '../../../../store/reducers/CandidateSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks/useAppSelector';
import { useAppDispatch } from '../../../../hooks/reduxHooks/useAppDispatch';
import { ChangePassword } from '../../../../components/ChangePassword/ChangePassword';
import { UserProfileHeader } from '../../../../components/UserProfileHeader/UserProfileHeader';

export const StudentProfile = () => {
    const dispatch = useAppDispatch();
    const { id } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        id && dispatch(fetchStudentInfo(id));
    }, [dispatch, id]);

    return (
        <div className={styles.StudentProfile}>
            <UserProfileHeader />
            <ChangePassword />
        </div>
    );
};
