import { Dispatch } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { VacancyListItemType } from '../../../../store/reducers/CandidateVacancySlice';
import styles from './index.module.scss';

type StaffVacancyListItemProps = {
    vacancy: VacancyListItemType;
    setModal: Dispatch<number | undefined>;
};
export const StaffVacancyListItem: React.FC<StaffVacancyListItemProps> = ({
    vacancy,
    setModal,
}) => {
    const navigate = useNavigate();
    return (
        <div className={styles.companyVacancyListItem}>
            <div className={styles.title}>
                <Link
                    to={`/staff/vacancies/${vacancy.id}`}
                    className={styles.position}
                >
                    {vacancy.position}
                </Link>
                <span className={styles.updateDate}>
                    {new Date(vacancy.date_update).toLocaleDateString('ru-RU', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}
                </span>
            </div>

            <h3 className={styles.sallary}>
                {vacancy.salary_from} - {vacancy.salary_to} руб.
            </h3>
            <span className={styles.company}>{vacancy.company_title}</span>
            <span className={styles.description}>
                {vacancy.description.length >= 200
                    ? vacancy.description.slice(0, 200) + '...'
                    : vacancy.description}
            </span>
            {vacancy.status === 'OP' && (
                <span className={styles.reaction}>
                    Откликнулось {vacancy.reaction_count} человек
                </span>
            )}
            <div className={styles.buttons}>
                <button
                    onClick={() => navigate(`/staff/vacancies/${vacancy.id}`)}
                    className={styles.vacancyAddButton}
                >
                    Подробнее
                </button>
                <button
                    onClick={() => {
                        setModal(vacancy.id);
                    }}
                    className={styles.vacancyAddButton}
                >
                    Снять с публикации
                </button>
            </div>
        </div>
    );
};
