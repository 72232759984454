
import { IntroSlider } from "../../../../../components/IntroSlider/IntroSlider";
import styles from "./NewsSection.module.scss";

export const NewsSection = () => {
	return (
		<section className={styles.newssection}>
			<IntroSlider />
		</section>
	);
};
