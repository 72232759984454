// подаем на вход дату , а на выходе имеем число в виде числа и месяц в виде слов

export const dateConverter = (date: Date) => {
    var russianMonthNames = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    const day = date.getDate();
    const month = date.getMonth();
    const russianMonth = russianMonthNames[month];
    const formattedDate = day + ' ' + russianMonth;
    return formattedDate;
};
