import 'react-calendar/dist/Calendar.css';
import { EventCalendar } from '../../../../../components/Calendar/Calendar';
import EventsSlider from '../../../../../components/EventsSlider/EventsSlider';
import { Loader } from '../../../../../components/Loader/Loader';
import { PageLayout } from '../../../../../components/PageLayout/PageLayout';
import { useFetchEvents } from '../../../../../hooks/useFetchEvents';
import styles from './EventsSection.module.scss';

export const EventsSection = () => {
    const COUNT_OF_EVENT_ON_SLIDER = 12;
    const { eventsData, isLoading } = useFetchEvents(
        1,
        COUNT_OF_EVENT_ON_SLIDER,
    );

    const dates = eventsData?.map((data) => new Date(data.datetime));
    return (
        <PageLayout>
            <div className={styles.events}>
                <h2 className={styles.events__header}>
                    Записаться на мероприятие
                </h2>
                <div className={styles.events__content}>
                    <EventCalendar dates={dates} />
                    {isLoading ? (
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                    ) : (
                        <EventsSlider eventData={eventsData} />
                    )}
                </div>
            </div>
        </PageLayout>
    );
};
